import React, { FC } from "react";

interface BaseErrorProps {
  children: React.ReactNode;
}

const BaseError: FC<BaseErrorProps> = ({ children }) => {
  return <div className={"error-message"}> * {children}</div>;
};

export default BaseError;
