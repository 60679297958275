import { memo } from "react";
import { Table } from "antd";
import { BASE_URL_IMG } from "../../../../constants/Api";
import { TableActions, TableProps } from "../helpers/TableHelper";
import { isAdmin } from "../../../../utils/isSuperAdmin";
import userStore from "../../../../store/userStore";
import pub from "../../../../assets/images/pub.png";
import { parseHTML } from "../../../../utils/parseHTML";

const BlogTable = ({ data, onSelectItem, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string) => {
        return <p className={"base-responsive-table-name"}>{title}</p>;
      },
    },
    {
      title: "Image",
      dataIndex: "cover",
      key: "cover",
      render: (cover: string) => {
        return (
          <div className={"base-responsive-table-image"}>
            <img src={cover ? BASE_URL_IMG + cover : pub} alt="table_image" />
          </div>
        );
      },
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (content: string) => {
        return <p className={"base-responsive-table-description"}>{parseHTML(content)}</p>;
      },
    },
    {
      title: "Published",
      dataIndex: "isPublished",
      key: "isPublished",
      render: (isPublished: boolean) => {
        return (
          <p className="base-responsive-table-name">{isPublished ? "Published" : "Unpublished"}</p>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <TableActions
            item={item}
            onSelectItem={onSelectItem}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
            showEditDeleteActions={!isAdmin(userStore.user)}
          />
        );
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
      onRow={(record) => {
        return {
          onClick: () => {
            if (onSelectItem) {
              onSelectItem(record);
            }
          },
        };
      }}
    />
  );
};

export default memo(BlogTable);
