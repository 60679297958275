import { gql } from "@apollo/client";

export const GET_POST = gql`
  query getPost($id: Int!) {
    getPost(id: $id) {
      id
      description
      cover
      venueId
      totalLikes
      totalShares
      totalComments
      total
      hashtags {
        hashtagId
      }
      media {
        type
        key
        isCover
      }
    }
  }
`;
