import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import contactsStore from "../../store/contactsRequetsStore";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import NoData from "../../components/reusable/noData/NoData";
import ContactRequestsTable
  from "../../components/reusable/baseResponsiveItemsPanel/contactRequetsTable/ContactRequestsTable";
import { ContactStatus } from "./ContactRequests";
import { IContactRequest } from "../../types/contactRequests";

interface ContactRequestsViewProps {
  loading: any;
  data: any;
  openOrCloseDeleteModal: any;
  changePage: any;
  onViewClick: (contact: IContactRequest) => void;
  onUpdateStatus: ({
    status,
    id
  }: {
    status: ContactStatus;
    id: string;
  }) => void;
}

const ContactRequestsView: FC<ContactRequestsViewProps> = observer(({
  loading,
  data,
  openOrCloseDeleteModal,
  changePage,
  onViewClick,
  onUpdateStatus
}) => {
  return (
    <div className={"contacts-requests-page--container"}>
      {loading || !data ? (
        <BaseLoader />
      ) : (
        <>
          {data?.listAllContacts.contacts.length ? (
            <>
              <ContactRequestsTable
                data={contactsStore.contacts}
                onViewClick={onViewClick}
                onUpdateStatus={onUpdateStatus}
                onDeleteItem={openOrCloseDeleteModal}
              />
              <BasePagination
                totalPages={Math.ceil(contactsStore.allContactsCount / contactsStore.limit)}
                currentPage={contactsStore.offset / contactsStore.limit + 1}
                changePage={changePage}
              />
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
});

export default ContactRequestsView;