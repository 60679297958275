import { FC, useCallback, useState } from "react";
import {
  FieldErrors,
  UseFieldArrayUpdate,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import { IDaysItem, IVenue } from "../../../types/venue";
import BaseSelect from "../../../components/reusable/baseSelect/BaseSelect";
import { times } from "../../../constants/times";
import { VenueOption } from "../../EventForm/components/EventDetails/EventDetails";

type WorkingHours = "openingTime" | "closingTime";

interface VenueDaysItemProps {
  workingHour: IDaysItem;
  index: number;
  errors: FieldErrors<IVenue>;
  register: UseFormRegister<IVenue>;
  clearErrors: () => void;
  handleDaysTime: (time: { id: number; value: string; type?: string }, index: number) => void;
  getValues: UseFormGetValues<IVenue>;
  update: UseFieldArrayUpdate<IVenue, "workingHours">;
}

const VenueDaysItem: FC<VenueDaysItemProps> = ({
  workingHour,
  index,
  errors,
  register,
  clearErrors,
  handleDaysTime,
  update,
  getValues,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(workingHour.isOpen);

  const handleOpeningOur = () => {
    if (isOpen) {
      update(index, {
        openingTime: "",
        closingTime: "",
        isOpen: workingHour.isOpen,
        day: workingHour.day,
      });
    }
    setIsOpen((prev) => !prev);
    clearErrors();
  };

  const selectTime = (type: WorkingHours, time: { id: number; value: string; type?: string }) => {
    time.type = type;
    handleDaysTime(time, index);
  };

  const getFilteredArrByTypeName = useCallback(
    (type: WorkingHours) => {
      return times.filter((time, _, arr) => {
        const existElement = arr.find(
          (el) => el.name === getValues(`workingHours.${index}.${type}`)
        );

        // if (!!existElement) {
        //   switch (type) {
        //     case "openingTime":
        //       return existElement.value < time.value;
        //     case "closingTime":
        //       return existElement.value > time.value;
        //   }
        // }
        return times;
      });
    },
    [getValues, index]
  );

  const workingHours = useCallback(
    (type: WorkingHours) => {
      switch (type) {
        case "openingTime":
          return getFilteredArrByTypeName("closingTime");
        case "closingTime":
          return getFilteredArrByTypeName("openingTime");
      }
    },
    [getFilteredArrByTypeName]
  );

  return (
    <div className={"venue-days-form--content"}>
      <div className={"venue-days-form--content--block"}>
        <input
          id={workingHour.day}
          type="checkbox"
          className={"venue-days-form--checkbox"}
          defaultChecked={workingHour.isOpen}
          {...register(`workingHours.${index}.isOpen`, {
            required: false,
          })}
        />
        <label
          className={"venue-days-form--custom-checkbox"}
          htmlFor={workingHour.day}
          onClick={handleOpeningOur}
        >
          <div className={"venue-days-form--custom-checkbox--checked"} />
        </label>

        <div className={"venue-info--days-title-block"}>
          {index === 0 && (
            <div className={"venue-info--title required"}>
              <span>Working Days</span>
            </div>
          )}
          <input
            className={"venue-days-form--days"}
            type={"text"}
            value={workingHour.day}
            readOnly={true}
            {...register(`workingHours.${index}.day`, {
              required: false,
            })}
          />
        </div>
      </div>

      <div className={"venue-days-form--content--block"}>
        <div className={"venue-info--days-title-block"}>
          {index === 0 && (
            <div className={"venue-info--title required"}>
              <span>Start</span>
            </div>
          )}
          <BaseSelect
            title={"Opening"}
            value={getValues(`workingHours.${index}.openingTime`)}
            disabled={!isOpen}
            setValue={(value) => selectTime("openingTime", value as VenueOption)}
            showOnlyName
            options={workingHours("openingTime") || []}
            label={{
              // text: "Category",
              htmlFor: "categories",
            }}
            registerValidation={{
              type: "select",
              name: "timezone",
              placeholder: "Timezone",
              validation: {
                ...register(`workingHours.${index}.openingTime`, {
                  required: false,
                }),
              },
            }}
            error={errors?.workingHours && errors.workingHours[index]?.openingTime?.message}
          />
        </div>
        <div className={"venue-info--days-title-block"}>
          {index === 0 && (
            <div className={"venue-info--title required"}>
              <span>End</span>
            </div>
          )}
          <BaseSelect
            title={"Closing"}
            value={getValues(`workingHours.${index}.closingTime`)}
            disabled={!isOpen}
            setValue={(value) => selectTime("closingTime", value as VenueOption)}
            options={workingHours("closingTime") || []}
            showOnlyName
            label={{
              // text: "Category",
              htmlFor: "categories",
            }}
            registerValidation={{
              type: "select",
              name: "timezone",
              placeholder: "Timezone",
              validation: {
                ...register(`workingHours.${index}.closingTime`, {
                  required: false,
                }),
              },
            }}
            error={errors?.workingHours && errors.workingHours[index]?.closingTime?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default VenueDaysItem;
