import { gql } from "@apollo/client";

export const GET_NEWS_LIST = gql`
  query getAds($getAdsArgs: GetAdsInput!) {
    getAds(getAdsArgs: $getAdsArgs) {
      ads {
        id
        cover
        ad {
          id
          feedId
          title
          endDate
          venueId
        }
        hashtags {
          id
          name
        }
      }
      count
    }
  }
`;
