import * as React from "react";

const NewsSvg = (props: any) => (
  <svg width={25} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.308 28c-.24 0-.472-.08-.66-.226l-9.402-7.312H2.154A2.154 2.154 0 0 1 0 18.308V9.692A2.154 2.154 0 0 1 2.154 7.54h6.092L17.647.226a1.076 1.076 0 0 1 1.738.851v25.846A1.077 1.077 0 0 1 18.307 28ZM2.154 9.692v8.616h6.461c.24 0 .472.08.66.226l7.956 6.187V3.279L9.276 9.466a1.077 1.077 0 0 1-.66.226H2.153ZM23.692 18.308a1.077 1.077 0 0 1-1.077-1.077v-6.462a1.077 1.077 0 1 1 2.154 0v6.462a1.077 1.077 0 0 1-1.077 1.077Z"
      fill="#393939"
    />
  </svg>
);

export default NewsSvg;
