import { gql } from "@apollo/client";

export const GET_REPORT = gql`
  query getReportedFeed($id: Int!) {
    getReportedFeed(id: $id) {
      id
      reportCount
      feed {
        id
        description
        user {
          name
        }
        media {
          type
          key
        }
      }
      user {
        name
      }
      reports {
        type
        reason
        count
        otherReasonMessages {
          reason
          user {
            name
          }
          createdAt
        }
      }
    }
  }
`;
