import { gql } from "@apollo/client";

export const GET_SHARE_LIKE_COMMENTS_COUNT = gql`
  query shareLikeCommentsCount {
    shareLikeCommentsCount {
      totalShares
      totalLikes
      totalComments
      postChartData {
        count
        month
      }
    }
  }
`;
