import * as React from "react";

const ReportSvg = () => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.84375 2.53133C2.73601 2.53133 2.63267 2.57578 2.55649 2.6549C2.4803 2.73402 2.4375 2.84133 2.4375 2.95322V18.985C2.4375 19.2179 2.6195 19.4069 2.84375 19.4069H6.09375C6.41698 19.4069 6.72698 19.5402 6.95554 19.7776C7.1841 20.0149 7.3125 20.3369 7.3125 20.6725V24.3683L11.7325 19.7781C11.9608 19.5407 12.2706 19.4072 12.5938 19.4069H23.1562C23.264 19.4069 23.3673 19.3624 23.4435 19.2833C23.5197 19.2042 23.5625 19.0969 23.5625 18.985V2.95322C23.5625 2.84133 23.5197 2.73402 23.4435 2.6549C23.3673 2.57578 23.264 2.53133 23.1562 2.53133H2.84375ZM0 2.95322C0 1.32304 1.274 0 2.84375 0H23.1562C24.726 0 26 1.32304 26 2.95322V18.985C26 19.7682 25.7004 20.5194 25.1671 21.0732C24.6338 21.6271 23.9105 21.9382 23.1562 21.9382H13.0975L8.91638 26.2803C8.58526 26.624 8.16346 26.858 7.7043 26.9528C7.24513 27.0476 6.76921 26.9989 6.33669 26.8129C5.90416 26.6269 5.53445 26.3118 5.27428 25.9077C5.01411 25.5035 4.87516 25.0283 4.875 24.5421V21.9382H2.84375C2.08954 21.9382 1.36622 21.6271 0.832915 21.0732C0.299608 20.5194 0 19.7682 0 18.985V2.95322ZM14.625 15.188C14.625 15.6355 14.4538 16.0648 14.149 16.3813C13.8443 16.6977 13.431 16.8755 13 16.8755C12.569 16.8755 12.1557 16.6977 11.851 16.3813C11.5462 16.0648 11.375 15.6355 11.375 15.188C11.375 14.7404 11.5462 14.3112 11.851 13.9947C12.1557 13.6782 12.569 13.5004 13 13.5004C13.431 13.5004 13.8443 13.6782 14.149 13.9947C14.4538 14.3112 14.625 14.7404 14.625 15.188ZM14.2187 5.76567C14.2187 5.42999 14.0903 5.10806 13.8618 4.8707C13.6332 4.63335 13.3232 4.5 13 4.5C12.6768 4.5 12.3668 4.63335 12.1382 4.8707C11.9097 5.10806 11.7812 5.42999 11.7812 5.76567V10.5472C11.7812 10.8829 11.9097 11.2048 12.1382 11.4422C12.3668 11.6795 12.6768 11.8129 13 11.8129C13.3232 11.8129 13.6332 11.6795 13.8618 11.4422C14.0903 11.2048 14.2187 10.8829 14.2187 10.5472V5.76567Z"
      fill="#393939"
    />
  </svg>
);

export default ReportSvg;
