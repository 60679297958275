import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import DashboardLineChart from "../LineChart/LineChart";
import { GET_SHARE_LIKE_COMMENTS_COUNT } from "../../../graphql/query/getShareLikeCommentsCount";

const PostsStatistics = () => {
  const [chartData, setChartData] = useState([]);
  const [getPostStatistics, { data }] = useLazyQuery(GET_SHARE_LIKE_COMMENTS_COUNT);
  useEffect(() => {
    (async () => {
      await getPostStatistics();
    })();
  }, []);

  useEffect(() => {
    if (data) {
      setChartData(data.shareLikeCommentsCount.postChartData);
    }
  }, [data]);

  return <DashboardLineChart data={chartData} title={"Newly added posts per month"} />;
};

export default PostsStatistics;
