import { gql } from "@apollo/client";

export const UPDATE_STAFF = gql`
  mutation updateVenueStaff($updateVenueStaffInput: UpdateVenueStaffInput!) {
    updateVenueStaff(updateVenueStaffInput: $updateVenueStaffInput) {
      id
      name
      email
      phone
      position
    }
  }
`;
