import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
  mutation loginAdmins($userCredentials: UserLoginDto!) {
    loginAdmins(userCredentials: $userCredentials) {
      user {
        id
        role
      }
      token
    }
  }
`;
