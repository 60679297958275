import { memo, useState } from "react";
import { Button, Table } from "antd";
import { TableProps } from "../helpers/TableHelper";
import UserStatusModal from "../../../userStatusModal/UserStatusModal";

const UserTable = ({ data }: TableProps) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userModalVisible, setUserModalVisible] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <p>{email}</p>;
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: any) => {
        return (
          <>
            {tags?.map((tag: any, index: any) => (
              <div className={"user-item--hashtag"} key={tag + index}>
                {tag}
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "status",
      key: "status",
      render: (status: string, row: any) => {
        return (
          <>
            {status !== "deleted" ? (
              <Button
                type={status !== "blocked" ? "primary" : "default"}
                onClick={() => {
                  setUserInfo(row);
                  setUserModalVisible(true);
                }}
              >
                {status !== "blocked" ? "Block" : "Unblock"}
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      {userModalVisible ? (
        <UserStatusModal
          setUserInfo={setUserInfo}
          setUserModalVisible={setUserModalVisible}
          userInfo={userInfo}
          userModalVisible={userModalVisible}
        />
      ) : null}
      <Table
        className={"base-responsive-table"}
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={"id"}
      />
    </>
  );
};

export default memo(UserTable);
