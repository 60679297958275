import { FC } from "react";
import "./BaseCheckbox.scss";
import { ILabel, IValidationInput } from "../../../types/types";
import CheckSvg from "../../../assets/images/CheckSvg";

interface BaseCheckboxProps {
  label: ILabel;
  registerValidation: IValidationInput;
  checked?: boolean;
  defaultChecked?: boolean;
  handleChange?: () => void;
  error?: any;
}

const BaseCheckbox: FC<BaseCheckboxProps> = ({
  label,
  registerValidation,
  checked,
  defaultChecked,
  handleChange,
  error,
}) => {
  return (
    <>
      <div className={"base-checkbox"}>
        <input
          checked={checked}
          defaultChecked={defaultChecked}
          value={
            Number.isInteger(registerValidation.name)
              ? +registerValidation.name
              : registerValidation.name
          }
          id={`${registerValidation.name}`}
          type="checkbox"
          onChange={handleChange}
          {...registerValidation.validation}
        />
        <label htmlFor={`${label.htmlFor}`} className={"base-checkbox--checked"}>
          <CheckSvg />
        </label>
        <label htmlFor={`${label.htmlFor}`} className={"base-checkbox--label"}>
          {label?.text}
        </label>
      </div>
      <div>
        <span className={"base-input--error"}>{error}</span>
      </div>
    </>
  );
};

export default BaseCheckbox;
