import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import adminRequestsStore from "../../store/adminRequetsStore";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import NoData from "../../components/reusable/noData/NoData";
import { AdminStatus } from "./AdminRequests";
import AdminRequestsTable from "../../components/reusable/baseResponsiveItemsPanel/adminRequestsTable/AdminRequestsTable";
import { AdminRoles } from "../../types/types";
import { IAdminRequest } from "../../types/adminRequests";

interface AdminRequestsViewProps {
  loading: boolean;
  data: {
    getAdminRequestsLists: {
      adminRequests: {
        id: string;
        name: string;
        email: string;
        venue: {
          name: string;
          address: string;
        };
        role: AdminRoles;
        message: string;
        status: AdminStatus;
      }[];
    };
  } | null;
  changePage: (page: number) => void;
  onUpdateStatus: ({
    status,
    id,
    item,
  }: {
    status: AdminStatus;
    id: string;
    item: IAdminRequest;
  }) => void;
}

const AdminRequestsView: FC<AdminRequestsViewProps> = observer(
  ({ loading, data, changePage, onUpdateStatus }) => {
    return (
      <div className={"admin-requests-page--container"}>
        {loading || !data ? (
          <BaseLoader />
        ) : (
          <>
            {data?.getAdminRequestsLists.adminRequests.length ? (
              <>
                <AdminRequestsTable
                  data={adminRequestsStore.adminRequests}
                  onUpdateStatus={onUpdateStatus}
                />
                <BasePagination
                  totalPages={Math.ceil(
                    adminRequestsStore.allAdminsCount / adminRequestsStore.limit
                  )}
                  currentPage={adminRequestsStore.offset / adminRequestsStore.limit + 1}
                  changePage={changePage}
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    );
  }
);

export default AdminRequestsView;
