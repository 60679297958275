import { computed, makeAutoObservable } from "mobx";
import { IEvent, IEventData } from "../types/eventTypes";
import { IEventRequest } from "../types/eventRequestTypes";

export interface IEventsStore {
  eventsList: IEventData[];
  event: IEvent | any;
  limit: number;
  offset: number;
  eventsTotalCount: number;
}

class EventsStore implements IEventsStore {
  limit = 12;
  offset = 0;
  eventsTotalCount = 0;
  eventsList = [];
  event: IEvent | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addAllEventsTotalCount(count: number) {
    this.eventsTotalCount = count;
  }

  addEvents(eventsData: []) {
    this.eventsList = eventsData;
  }

  selectCurrentEvent(event: IEvent) {
    this.event = event;
  }

  hasEvent() {
    return this.event !== null;
  }

  restoreData(): void {
    this.event = null;
  }

  @computed
  get eventData(): IEvent | null {
    return this.event || null;
  }

  publishEvent(id: number, isPublished: boolean): void {
    if (this.event?.event?.id && +this.event.event.id === id) {
      this.event.feed.isPublished = isPublished;
    }
  }

  removeEvent(eventId: string | number) {
    this.eventsList = this.eventsList.filter((eventData: IEventData) => eventData.id !== eventId);
  }

  createEventRequest(eventId: string | number, data: IEventRequest) {
    // @ts-ignore
    this.eventsList = this.eventsList.map((eventData: IEventData) => {
      if (eventData.id === eventId) {
        eventData.eventRequest = data;
      }
      return eventData;
    });
  }
  addOffset(offset: number) {
    this.offset = offset;
  }

  resetEvents() {
    this.eventsList = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new EventsStore();
