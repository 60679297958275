import { IGetMe, UserRole } from "../types/types";

export const isSuperAdmin = (user: IGetMe | null) => {
  return user?.role === UserRole.superAdmin;
};

export const isAdmin = (user: IGetMe | null) => {
  return user?.role === UserRole.admin;
};

export const isVenueManager = (user: IGetMe | null) => {
  return user?.role === UserRole.venueManager;
};
