import React, { FC, memo } from "react";
import "./SubTitle.scss";

type Props = {
  children: string;
};

const SubTitle: FC<Props> = ({ children }) => {
  return (
    <div className={"subTitle"}>
      <span>{children}</span>
    </div>
  );
};

export default memo(SubTitle);
