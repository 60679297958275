import { IMediaItem } from "../types/mediaTypes";

export const getNavPath = (
  navItem: any,
  venueId: string | undefined,
  eventId: string | undefined
) => {
  let path = "";

  if (venueId) {
    path = `${path}/venues/${venueId}`;
  }

  if (eventId) {
    path = `${path}/events/${eventId}`;
  }

  return `${path}/${navItem.to}`;
};

export const capitalizeFirstLetter = (str: string): string => {
  // converting first letter to uppercase
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stripSpecialChars = (str: string): string => {
  // remove special characters from string
  return str.replace(/[-_'"]/g, " ");
};

export const hasDataUrl = (content: string) => {
  const regex = /data:image\/[a-zA-Z]*;base64,([^"]*)/;
  return regex.test(content);
};

type ClassValue = string | number | boolean | null | undefined;
export function cn(...inputs: ClassValue[]) {
  return inputs.filter(Boolean).join(" ");
}

export const checkHasImageExtension = (url: string) => {
  const regex = /\.(jpeg|jpg|png)$/i;
  return regex.test(url);
};

export const validateMediaTypes = ({
  validMediaFormat,
  mediaArray,
}: {
  validMediaFormat: {
    [key: string]: number;
  };
  mediaArray: IMediaItem[];
}) => {
  const mediaTypesCount = mediaArray.reduce((acc, media) => {
    const mediaType = media.type.split("/")[0];
    acc[mediaType] = acc[mediaType] ? acc[mediaType] + 1 : 1;
    return acc;
  }, {} as { [key: string]: number });

  if (Object.keys(mediaTypesCount).length !== Object.keys(validMediaFormat).length) return false;

  return Object.keys(mediaTypesCount).every((mediaType) => {
    return validMediaFormat[mediaType] === mediaTypesCount[mediaType];
  });
};
