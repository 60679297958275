import React, { FC, useEffect, useState } from "react";
import "./BasePagination.scss";
import Pagination from "react-responsive-pagination";

interface BasePaginationProps {
  totalPages: number;
  currentPage: number;
  changePage: (page: number) => void;
}

const BasePagination: FC<BasePaginationProps> = ({ totalPages, changePage, currentPage = 1 }) => {
  const [current, setCurrent] = useState(1);

  const handlePage = (page: number) => {
    changePage(page);
  };

  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="base-pagination-wrapper">
        <div className={"base-pagination"}>
          <Pagination
            previousLabel="<"
            nextLabel=">"
            current={current}
            total={totalPages}
            onPageChange={handlePage}
          />
        </div>
      </div>
    </div>
  );
};

export default BasePagination;
