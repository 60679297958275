import { gql } from "@apollo/client";

export const SEND_STAFF_MESSAGE = gql`
  mutation sendMessageToVenueStaff($sendMessageToVenueStaffInput: SendMessageToVenueStaffInput!) {
    sendMessageToVenueStaff(sendMessageToVenueStaffInput: $sendMessageToVenueStaffInput) {
      status
      message
    }
  }
`;
