import * as React from "react";

const CloseSvg = (props: any, width?: number, height?: number) => (
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m19.378 17.149-6.926-6.926 6.926-6.926c.68-.68.68-1.793 0-2.473a1.754 1.754 0 0 0-2.474 0L9.98 7.749 3.053.824a1.754 1.754 0 0 0-2.474 0c-.68.68-.68 1.793 0 2.473l6.926 6.926-6.926 6.926c-.68.68-.68 1.793 0 2.473.68.68 1.794.68 2.474 0l6.926-6.925 6.925 6.925c.68.68 1.794.68 2.474 0 .68-.68.68-1.793 0-2.473Z"
      fill="#E63335"
    />
  </svg>
);

export default CloseSvg;
