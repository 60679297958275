import React, { FC, useEffect, useState } from "react";
import "./WriteStaffEmailModalForm.scss";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IWriteEmail } from "../../types/types";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import BaseTable from "../../components/reusable/baseTable/BaseTable";
import staffStore from "../../store/adminStaffStore";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import BaseCheckbox from "../../components/reusable/baseCheckbox/BaseCheckbox";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import { useMutation } from "@apollo/client";
import { SEND_STAFF_MESSAGE } from "../../graphql/mutations/sendStaffMessage";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";

interface WriteStaffEmailModalFormProps {
  getStaffList: (count?: number) => void;
  closModal: (status: boolean) => void;
}

const WriteStaffEmailModalForm: FC<WriteStaffEmailModalFormProps> = ({
  getStaffList,
  closModal,
}) => {
  const [sendMessageToVenueStaff, { data, loading, error }] = useMutation(SEND_STAFF_MESSAGE);

  const validationSchema = yup.object().shape({
    subject: yup.string().required("This field is required"),
    body: yup.string().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IWriteEmail>({
    resolver: yupResolver(validationSchema),
  });

  const [shareEmail, setShareEmail] = useState(false);

  const handleCreateHashtag: SubmitHandler<IWriteEmail> = async (data) => {
    await sendMessageToVenueStaff({
      variables: {
        sendMessageToVenueStaffInput: {
          ...data,
          venueStaffIds: staffStore.selectedStaffIdList,
        },
      },
    });
  };

  const handleShareEmail = () => {
    setShareEmail(true);
  };

  const changePage = (page: number) => {
    staffStore.addOffset((page - 1) * staffStore.limit);
    getStaffList();
  };

  useEffect(() => {
    if (data?.sendMessageToVenueStaff) {
      closModal(true);
    }
    return () => {
      staffStore.emptyStaffListId();
    };
  }, [closModal, data]);

  return (
    <form onSubmit={handleSubmit(handleCreateHashtag)} className={"write-email-form"}>
      {loading ? <BaseCreateOrDeleteModalLoading /> : null}
      {error ? <BaseAlert type={"failed"} message={error?.message} /> : null}
      <div>
        <BaseInput
          newClassName={"modal-input"}
          label={{
            text: "Subject",
            htmlFor: "subject",
          }}
          registerValidation={{
            type: "text",
            name: "subject",
            placeholder: "Subject",
            validation: {
              ...register("subject", {
                required: true,
              }),
            },
          }}
          error={errors.subject?.message}
        />
      </div>

      <div className={"write-email-form--email-block"}>
        <label htmlFor="body" className={`base-input--label`}>
          Message
        </label>
        <textarea
          className={"write-email-form--textarea"}
          placeholder={"Type here..."}
          {...register("body", {
            required: false,
          })}
        />
        <span className={"write-email-form--error"}>{errors.body?.message}</span>
      </div>

      <div className={"write-email-form--share-btns"}>
        <BaseButton
          title={"SHARE WITH"}
          type={"button"}
          className={"write-email-form--share-btns--with"}
          onClick={handleShareEmail}
        />
      </div>

      {shareEmail ? (
        <div>
          <BaseCheckbox
            label={{
              htmlFor: "all",
              text: "Share with all",
            }}
            registerValidation={{
              name: "all",
            }}
          />

          <div className={"write-email-form--search-block"}>
            <BaseSearch />
          </div>

          <div className={"write-email-form--table-block"}>
            <BaseTable
              tableData={staffStore.dataList}
              type={"staff"}
              isStaffEmailTable={true}
              // onHandleClick={onHandleClick}
            />
          </div>

          <BasePagination
            totalPages={Math.ceil(staffStore.allDataListCount / staffStore.limit)}
            currentPage={staffStore.offset / staffStore.limit + 1}
            changePage={changePage}
          />
        </div>
      ) : null}

      <div className={"write-email-form--submit-block"}>
        <BaseButton title={"SEND"} type={"submit"} />
      </div>
    </form>
  );
};

export default WriteStaffEmailModalForm;
