import { gql } from "@apollo/client";

export const GET_EVENT = gql`
  query findEvent($id: Int!) {
    findEvent(id: $id) {
      event {
        id
        name
        description
        startDate
        endDate
        cover
        bandName
        price
        venueId
        address
        saleExpireDate
        saleStartDate
        media {
          type
          key
          isCover
          id
        }
        eventRequest {
          id
          status
        }
        color
      }
      hashtagIds
      feed {
        id
        isPublished
        description
      }
    }
  }
`;
