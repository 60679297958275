import * as React from "react";

const EventsSvg = (props: any) => (
  <svg width={26} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#393939">
      <path d="M17.093 28H8.477a1.077 1.077 0 0 1 0-2.154h8.616a1.077 1.077 0 1 1 0 2.154ZM2.15 23.692a2.153 2.153 0 0 1-1.86-3.23c.8-1.383 1.753-4 1.753-8.616V10.77A10.755 10.755 0 0 1 12.785 0h.073C18.744.045 23.53 4.93 23.53 10.89v.956c0 4.617.953 7.233 1.754 8.616a2.153 2.153 0 0 1-1.858 3.23H2.15ZM12.785 2.154a8.6 8.6 0 0 0-8.589 8.615v1.077c0 5.096-1.11 8.077-2.042 9.692H23.42c-.936-1.615-2.047-4.596-2.047-9.692v-.957c0-4.786-3.826-8.7-8.529-8.735h-.059Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25.568v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EventsSvg;
