import React, { useCallback, useEffect, useState } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import BaseError from "../../components/reusable/baseError/BaseError";
import VenueForm from "../../forms/VenueForm/VenueForm";
import { GET_VENUE } from "../../graphql/query/getVenue";
import { GET_ALL_CATEGORIES } from "../../graphql/query/getAllCategories";
import { GET_VENUE_MANAGERS } from "../../graphql/query/getVenueManagers";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import VenueView from "../../pageView/VenueView/VenueView";
import venueStore from "../../store/venueStore";

const Venue = () => {
  const { venueId } = useParams();
  const currentLocation = useLocation();
  const venueViewRoutes = [{ path: "/venues/view/:venueId" }];

  const matches = matchRoutes(venueViewRoutes, currentLocation);
  const [loading, setLoading] = useState(true);

  const [getVenue, { error, data }] = useLazyQuery(GET_VENUE, {
    fetchPolicy: "no-cache",
  });

  const [venueManagersList, { data: venueManagersListData }] = useLazyQuery(GET_VENUE_MANAGERS, {
    fetchPolicy: "no-cache",
  });

  const [getCategories, { data: categories }] = useLazyQuery(GET_ALL_CATEGORIES, {
    fetchPolicy: "no-cache",
  });

  const fetchVenue = useCallback(async () => {
    try {
      if (venueId) {
        await getVenue({
          variables: {
            id: +venueId,
          },
        });
        await venueManagersList({
          variables: {
            venueManagersListArgs: {
              limit: 100,
              offset: 0,
              venueId: +venueId,
            },
          },
        });
      }
      await getCategories();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [getCategories, getVenue, venueId, venueManagersList]);

  useEffect(() => {
    fetchVenue();
  }, [fetchVenue]);

  useEffect(() => {
    if (data?.getVenue) {
      venueStore.selectCurrentVenue(data?.getVenue);
    }
  }, [data]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <>
      {matches && matches.length ? (
        <VenueView
          venue={data?.getVenue}
          venueManagersListData={
            venueManagersListData?.venueManagersList.venueManagers.map(
              (manager: any) => manager.user
            ) || []
          }
          categories={categories?.getAllCategories}
        />
      ) : (
        <VenueForm
          venueData={data?.getVenue}
          venueManagersListData={
            venueManagersListData?.venueManagersList.venueManagers.map(
              (manager: any) => manager.user
            ) || []
          }
          categories={categories?.getAllCategories}
        />
      )}
    </>
  );
};

export default Venue;
