import { gql } from "@apollo/client";

export const CREATE_EVENT_REQUEST = gql`
  mutation createEventRequest($eventReqsArgs: EventRequestsInput!) {
    createEventRequest(eventReqsArgs: $eventReqsArgs) {
      id
      venueId
      creatorId
      eventId
      status
      createdAt
      updatedAt
    }
  }
`;
