import React, { FC, useEffect } from "react";
import AddressAutocomplete from "../../../../components/reusable/addressAutocomplete/AddressAutocomplete";
import "./EventLocationDetails.scss";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { IEventData } from "../../../../types/eventTypes";
import BaseInput from "../../../../components/reusable/baseInput/BaseInput";

interface EventLayoutProps {
  address: string;
  isDisabled: boolean;
  register: UseFormRegister<IEventData>;
  errors: FieldErrors<IEventData>;
  setValue: UseFormSetValue<IEventData>;
  watch: (fieldName: string, flag: boolean) => string;
}

const EventLocationDetails: FC<EventLayoutProps> = ({
  address,
  isDisabled,
  register,
  errors,
  setValue,
  watch,
}) => {
  const venueId = watch("venueId", false);

  useEffect(() => {
    if (venueId) {
      setValue("address", "");
    }
  }, [venueId, setValue]);

  return (
    <div className={"event-location-details"}>
      <BaseInput
        disabled={isDisabled}
        label={{
          text: "Dj / Band Name",
          htmlFor: "bandName",
        }}
        newClassName={"modal-input"}
        registerValidation={{
          type: "text",
          name: "bandName",
          placeholder: "Dj...",
          validation: {
            ...register("bandName"),
          },
        }}
        error={errors.bandName?.message}
      />
      {!venueId && (
        <AddressAutocomplete
          addressValue={address}
          disabled={isDisabled}
          label={{
            text: "Address",
            htmlFor: "address",
          }}
          handleChangeValue={(address) => setValue("address", address)}
          registerValidation={{
            type: "text",
            name: "address",
            placeholder: "Address...",
            validation: {
              ...register("address"),
            },
          }}
          error={errors.address?.message}
        />
      )}
      {/*<BaseInput*/}
      {/*    disabled={isDisabled}*/}
      {/*    label={{*/}
      {/*        text: "Price",*/}
      {/*        htmlFor: "bandName",*/}
      {/*    }}*/}
      {/*    newClassName={"modal-input"}*/}
      {/*    registerValidation={{*/}
      {/*        type: "number",*/}
      {/*        name: "price",*/}
      {/*        placeholder: "Price...",*/}
      {/*        validation: {*/}
      {/*            ...register("price", {*/}
      {/*                required: true,*/}
      {/*                valueAsNumber: true,*/}
      {/*            }),*/}
      {/*        },*/}
      {/*    }}*/}
      {/*    error={errors.price?.message}*/}
      {/*/>*/}
    </div>
  );
};

export default EventLocationDetails;
