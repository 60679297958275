import { useCallback, useEffect, useState } from "react";
import userStore from "../store/userStore";

export const useAuth = () => {
  const storageToken = "token";
  const [token, setToken] = useState<string | null>(null);
  const [ready, setReady] = useState<boolean>(false);

  const login = useCallback((jwtToken: string) => {
    setToken(jwtToken);
    localStorage.setItem(storageToken, jwtToken);
    userStore.addUserToken(jwtToken);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setReady(false);
    localStorage.removeItem(storageToken);
    userStore.removeUser();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(storageToken);
    if (token) {
      login(token);
      setReady(true);
    }
  }, [login]);

  return { login, logout, token, ready };
};
