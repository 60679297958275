import { FC, memo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../../assets//images/arrowDown.svg";
import { NavItem } from "./NavBar";
import useOnClickOutside from "../../hooks/useOutsideClickEvent";
import { cn } from "../../utils/common";

interface NavbarDropdownProps {
  navItem: NavItem;
  className?: string;
}

const NavbarDropdown: FC<NavbarDropdownProps> = ({ navItem, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setIsOpen(false));

  return (
    <>
      <div
        ref={menuRef}
        onClick={() => setIsOpen(!isOpen)}
        className={cn("nav-bar--menu-item", className)}
      >
        <div>
          <p className={"nav-bar--item-name"}>{navItem.name}</p>
        </div>
        <div>
          <ArrowDown
            className={isOpen ? "nav-bar--menu-item-icon-rotated" : "nav-bar--menu-item-icon"}
            width={10}
            height={5}
          />
        </div>
        <ul className={!isOpen ? "nav-bar--dropdown-hidden" : "nav-bar--dropdown-visible"}>
          {!!navItem?.menu &&
            navItem.menu.map((item) => (
              <li key={item.name}>
                <NavLink to={item.to} className="nav-bar--dropdown-item">
                  {item.name}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default memo(NavbarDropdown);
