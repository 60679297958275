import { UserRole } from "../types/types";

export const USER_ROLE_ALIASES = {
  [UserRole.superAdmin]: "Super Admin",
  [UserRole.admin]: "Event Admin",
  [UserRole.venueManager]: "Venue Manager",
  [UserRole.user]: "User",
};

export const EVENT_COLORS = [
  { label: "Blue", value: "#5355EE" },
  { label: "Slate Gray", value: "#4A576B" },
  { label: "Deep Blue", value: "#1626F3" },
  { label: "Medium Aquamarine", value: "#6DB58E" },
  { label: "Heliotrope", value: "#D67EFF" },
  { label: "Camouflage Green", value: "#839078" },
  { label: "Rosy Brown", value: "#BA9484" },
  { label: "Lavender", value: "#C8C8FF" },
];
