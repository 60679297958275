import React, { useCallback, useEffect, useState } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import TicketForm from "../../forms/TicketForm/TicketForm";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET } from "../../graphql/query/getTicket";
import ticketsStore from "../../store/ticketsStore";
import BaseError from "../../components/reusable/baseError/BaseError";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import TicketView from "../../pageView/TicketView/TicketView";

const Ticket = () => {
  const currentLocation = useLocation();
  const { ticketId, ticketName } = useParams();
  const [loading, setLoading] = useState(true);

  const [getTicket, { data, error }] = useLazyQuery(GET_TICKET, {
    fetchPolicy: "no-cache",
  });

  const fetchTicket = useCallback(async () => {
    try {
      if (ticketId) {
        await getTicket({
          variables: {
            id: +ticketId,
          },
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [getTicket, ticketId]);

  useEffect(() => {
    fetchTicket();
  }, [fetchTicket]);

  useEffect(() => {
    if (data?.findTicket) {
      ticketsStore.selectCurrentTicket(data?.findTicket);
    }
  }, [data]);

  if (error) {
    return <BaseError>{error?.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  const ticketViewRoutes = [
    { path: "/venues/:venueId/events/:eventId/tickets/view/:ticketId" },
    { path: "/events/:eventId/tickets/view/:ticketId" },
  ];
  const matches = matchRoutes(ticketViewRoutes, currentLocation);

  return (
    <>
      {matches && matches.length > 0 && data?.findTicket ? (
        <TicketView data={data?.findTicket} />
      ) : (
        <TicketForm ticketData={data?.findTicket} />
      )}
    </>
  );
};

export default Ticket;
