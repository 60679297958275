import "./ManagerNews.scss";

const ManagerNews = () => {
  return (
    <div className={"manager-news"}>
      <h2>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur corporis cum
        doloremque, eos, facilis inventore, ipsa ipsum iusto labore numquam quasi quibusdam
        repellendus tempora veritatis voluptas. Adipisci debitis explicabo similique.
      </h2>
    </div>
  );
};

export default ManagerNews;
