import { gql } from "@apollo/client";

export const GET_TICKETS_LIST = gql`
  query listEventTicketsAsAdmin($input: ListEventTicketsAsAdminInput!) {
    listEventTicketsAsAdmin(input: $input) {
      count
      tickets {
        id
        name
        description
        count
        soldCount
        amount
        saleAmount
        currency
        startTime
        endTime
        published
        image
        media {
          type
          key
          isCover
        }
        ticketableId
        ticketableType
        createdAt
        updatedAt
      }
    }
  }
`;
