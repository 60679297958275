import * as React from "react";

const CheckSvg = (props: any) => (
  <svg width={9} height={7} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.221 6.3a.668.668 0 0 1-.472-.198L.215 3.655a.706.706 0 0 1-.023-.99.686.686 0 0 1 .979-.024l2.062 1.981L7.829.194a.686.686 0 0 1 .98.023.706.706 0 0 1-.024.99L3.717 6.103a.767.767 0 0 1-.496.198Z"
      fill="#616161"
    />
  </svg>
);

export default CheckSvg;
