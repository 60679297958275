import React, { useCallback, useMemo } from "react";
import userStore from "../../store/userStore";
import { Button, Dropdown, MenuProps } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { USER_ROLE_ALIASES } from "../../constants/common";

const UserDropdown = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const userRole = userStore.user?.role;
  const userEmail = userStore.user?.email;

  const onLogout = useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);

  const items: MenuProps["items"] = useMemo(() => {
    if (userRole === "superAdmin") {
      return [
        {
          label: (
            <div className={"d-flex-column"}>
              <p>email:{userEmail}</p>
              <p>role: {USER_ROLE_ALIASES[userRole]}</p>
              <Button type={"primary"} onClick={onLogout}>
                Logout
              </Button>
            </div>
          ),
          key: "0",
        },
      ];
    } else {
      return [
        {
          label: (
            <div className={"d-flex-column gap-10"}>
              <p>
                <span>email:</span> {userEmail}
              </p>
              <p>
                <span>role:</span> {userRole && USER_ROLE_ALIASES[userRole]}
              </p>
              <Button type={"primary"} onClick={() => navigate("/settings")}>
                Settings
              </Button>
              <Button type={"primary"} onClick={onLogout}>
                Logout
              </Button>
            </div>
          ),
          key: "0",
        },
      ];
    }
  }, [userRole]);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      menu={{ items }}
      overlayClassName={"nav-bar-user-dropdown"}
    >
      <button className={"nav-bar-user"}>
        <h2>{userStore.user?.email.charAt(0)}</h2>
      </button>
    </Dropdown>
  );
};

export default UserDropdown;
