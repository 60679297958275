import React from "react";
import { observer } from "mobx-react-lite";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import venueStore from "../../store/venueStore";
import NoData from "../../components/reusable/noData/NoData";
import { PanelItem } from "../../types/types";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import VenueTable from "../../components/reusable/baseResponsiveItemsPanel/venueTable/VenueTable";
import { isAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import VenuesSearch from "./VenuesSearch";

interface IVenuesView {
  loading: any;
  data: any;
  goToCurrentVenue: any;
  openOrCloseDeleteModal: any;
  changePage: any;
  onEditVenue: (item: PanelItem) => void;
  createVenue: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const VenuesView: React.FC<IVenuesView> = observer(
  ({
    createVenue,
    loading,
    data,
    goToCurrentVenue,
    openOrCloseDeleteModal,
    changePage,
    onEditVenue,
    setSearch,
  }) => {
    return (
      <div className={"venue-page--container"}>
        {isAdmin(userStore.user) ? (
          <VenuesSearch setSearch={setSearch} createVenue={createVenue} />
        ) : null}
        {loading || !data ? (
          <BaseLoader />
        ) : (
          <>
            {data?.listVenues.venues.length ? (
              <>
                <VenueTable
                  data={venueStore.venues}
                  onSelectItem={goToCurrentVenue}
                  onEditItem={onEditVenue}
                  onDeleteItem={openOrCloseDeleteModal}
                />
                <BasePagination
                  totalPages={Math.ceil(venueStore.allVenuesCount / venueStore.limit)}
                  currentPage={venueStore.offset / venueStore.limit + 1}
                  changePage={changePage}
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    );
  }
);

export default VenuesView;
