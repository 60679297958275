import React from "react";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./Venues.scss";
import { useNavigate } from "react-router-dom";
import { PanelItem } from "../../types/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_VENUES_LIST } from "../../graphql/query/getVenues";
import { DELETE_VENUE } from "../../graphql/mutations/deleteVenue";
import BaseError from "../../components/reusable/baseError/BaseError";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { observer } from "mobx-react-lite";
import venueStore from "../../store/venueStore";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { isAdmin, isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import { useDebounce } from "../../hooks/useDebounce";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import VenuesSearch from "./VenuesSearch";
import VenuesView from "./VenuesView";

type argsOption = {
  limit: number;
  offset: number;
  name?: string;
};

const Venues = observer(() => {
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<PanelItem | null>(null);

  const debounced = useDebounce(search);

  const [listVenues, { loading, error, data }] = useLazyQuery(GET_VENUES_LIST, {
    fetchPolicy: "no-cache",
  });

  const [
    removeVenue,
    { data: dataRemove, loading: loadingRemove, error: errorRemove, reset: resetErrorRemove },
  ] = useMutation(DELETE_VENUE);

  const goToCurrentVenue = (venue: PanelItem) => {
    navigate(
      {
        pathname: `/venues/view/${venue.id.toString()}`,
      },
      { state: { venueId: venue.id } }
    );
  };

  const handleEditVenue = (item: PanelItem) => {
    navigate({
      pathname: `/venues/${item.id.toString()}`,
      search: "step-1",
    });
  };

  const createVenue = () => {
    navigate({
      pathname: "/venues/create",
      search: "step-1",
    });
  };

  const openOrCloseDeleteModal = useCallback((venue?: PanelItem) => {
    if (venue) {
      setSelectItem(venue);
    }
    setIsOpenDeleteModal((prev) => !prev);
  }, []);

  const deleteVenueConfirm = async () => {
    try {
      const { data } = await removeVenue({
        variables: {
          id: +selectItem?.id!,
        },
      });

      if (
        venueStore.offset / venueStore.limit + 1 <
          Math.ceil(venueStore.allVenuesCount / venueStore.limit) ||
        (venueStore.venues.length === 1 && venueStore.offset !== 0)
      ) {
        getVenuesList(venueStore.venues.length);
      } else {
        venueStore.removeVenue(data?.removeVenue.id);
      }
      setIsOpenDeleteModal(false);
    } catch (e) {
      setIsOpenDeleteModal(false);
    }
  };

  const getVenuesList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset = count === 1 ? venueStore.offset - venueStore.limit : venueStore.offset;

      const args: argsOption = {
        limit: venueStore.limit,
        offset: newOffset,
      };

      if (debounced) {
        args.name = debounced;
      }

      listVenues({
        variables: {
          listVenuesArgs: args,
        },
      });
    },
    [listVenues]
  );

  const fetchVenues = useCallback(async () => {
    await venueStore.resetPagination();
    if (debounced.length) {
      getVenuesList(null, debounced);
    } else {
      getVenuesList();
    }
  }, [debounced, getVenuesList]);

  const changePage = (page: number) => {
    venueStore.addOffset((page - 1) * venueStore.limit);
    getVenuesList();
  };

  useEffect(() => {
    if (data) {
      venueStore.addVenues(data.listVenues.venues);
      venueStore.addAllVenuesCount(data.listVenues.count);
      if (data.listVenues.count === 1 && !isSuperAdmin(userStore.user)) {
        goToCurrentVenue(data.listVenues.venues[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLayoutEffect(() => {
    venueStore.resetVenues();
    fetchVenues();
  }, [debounced, fetchVenues]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  return (
    <div className={"venue-page"}>
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteVenueConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this venue?"}
        />
      ) : null}
      {loadingRemove ? <BaseCreateOrDeleteModalLoading /> : null}
      {errorRemove ? <BaseAlert type={"failed"} message={errorRemove?.message} /> : null}
      {dataRemove?.removeVenue?.id ? (
        <BaseAlert
          type={"success"}
          message={"Venue successfully deleted"}
          reset={() => resetErrorRemove()}
        />
      ) : null}
      <PageContentLayout
        className={"d-flex-column"}
        actions={
          !isAdmin(userStore.user) ? (
            <VenuesSearch setSearch={setSearch} createVenue={createVenue} />
          ) : undefined
        }
        view={
          <VenuesView
            data={data}
            loading={loading}
            changePage={changePage}
            goToCurrentVenue={goToCurrentVenue}
            onEditVenue={handleEditVenue}
            openOrCloseDeleteModal={openOrCloseDeleteModal}
            createVenue={createVenue}
            setSearch={setSearch}
          />
        }
      />
    </div>
  );
});

export default Venues;
