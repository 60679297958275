import React, { FC, useEffect, useState } from "react";
import "./UsersHashtagModalForm.scss";
import { IHashtag, IUsersHashtagModalFormProps } from "../../types/hashtagsTypes";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import HashtagsList from "../../components/reusable/hashtagsList/HashtagsList";
import { CREATE_HASHTAG } from "../../graphql/mutations/createHashtag";
import hashtagsStore from "../../store/hashtagsStore";
import { useMutation } from "@apollo/client";
import { useDebounce } from "../../hooks/useDebounce";

type ICreateHashtagForm = {
  id: string;
  name: string;
  closeModal: () => void;
};

const UsersHashtagModalForm: FC<IUsersHashtagModalFormProps> = ({ closeModal }) => {
  const [search, setSearch] = useState<string>("");

  const [createHashtag, { data: createdHashtag }] = useMutation(CREATE_HASHTAG);

  const searchedText = useDebounce(search);

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateHashtagForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleCreateHashtag: SubmitHandler<ICreateHashtagForm> = async (hashtag: IHashtag) => {
    const { name } = hashtag;
    reset({ name: "" });
    await createHashtag({
      variables: {
        createHashtagInput: {
          name,
        },
      },
    });
  };

  useEffect(() => {
    if (createdHashtag && !searchedText) {
      hashtagsStore.addHashtag(createdHashtag.createHashtag);
    }
  }, [createdHashtag, searchedText]);

  return (
    <form onSubmit={handleSubmit(handleCreateHashtag)} className={"hashtag-form"}>
      <div className={"hashtag-form-title required"}>Add New Hashtag</div>
      <div className={"hashtag-form-add-section"}>
        <div className={"hashtag-form-add-section-input"}>
          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "",
              htmlFor: "name",
            }}
            registerValidation={{
              type: "text",
              name: "name",
              placeholder: "#HashTag",
              validation: {
                ...register("name", {
                  required: true,
                }),
              },
            }}
            error={errors.name?.message}
          />
        </div>
        <div className={"hashtag-form-add-section-button"}>
          <BaseButton type={"submit"} title={"Add"} />
        </div>
      </div>
      <div className={"hashtag-form-add-section-all"}>
        <div className={"hashtag-form-add-section-all-title"}>ALL HASHTAGS </div>
        <div className={"hashtag-form-add-section-all-search"}>
          <div className={"hashtag-form-add-section-all-search-input"}>
            <BaseSearch handleChange={(value) => setSearch(value)} />
          </div>
        </div>
        <div className={"hashtag-form-add-section-all-hashtags"}>
          <HashtagsList
            searchText={searchedText}
            isSelectable={false}
            isRemovable={true}
            selectedHashTags={[]}
          />
        </div>
        <div className={"hashtag-form-add-section-bottom"}>
          <BaseButton
            onClick={closeModal}
            title={"Ok"}
            className={"hashtag-form-add-section-bottom-button"}
          />
        </div>
      </div>
    </form>
  );
};

export default UsersHashtagModalForm;
