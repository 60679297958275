import "./VenueManager.scss";
import { IManagerItem } from "../../types/venue";
import { FC } from "react";

interface VenueManagerProps {
  venueManagersListData: IManagerItem[];
}

const VenueManager: FC<VenueManagerProps> = ({ venueManagersListData }) => {
  if (!venueManagersListData.length) {
    return (
      <div className={"venue-manager"}>
        <h2 className={"venue-manager--title"}>Managers</h2>
        <div className={"no-data"}>
          <p>You don't have managers yet</p>
        </div>
      </div>
    );
  }

  return (
    <div className={"venue-manager"}>
      <h2 className={"venue-manager--title"}>Managers</h2>
      <div className={"venue-manager--content"}>
        {venueManagersListData.map((manager) => (
          <div className={"venue-manager--block"} key={manager.id}>
            <div className={"venue-manager--info-block"}>
              <span className={"venue-manager--title"}>Manager Name</span>
              <div className={"venue-manager--data"}>
                <span>{manager.name}</span>
              </div>
            </div>
            <div className={"venue-manager--info-block"}>
              <span className={"venue-manager--title"}>Email</span>
              <div className={"venue-manager--data"}>
                <span>{manager.email}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VenueManager;
