import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import "./ViewField.scss";

type Props = {
  label?: string;
  text: any;
  reduceText?: boolean;
  maxLength?: number;
  withSeeMore?: boolean;
  characterToSearch?: string;
  showHiddenCount?: boolean;
  style?: CSSProperties;
};

const ViewField: FC<Props> = ({
  style,
  label,
  text,
  reduceText = true,
  maxLength = 100,
  withSeeMore = false,
  characterToSearch,
  showHiddenCount = false,
}) => {
  const [isTextCollapsed, setIsTextCollapsed] = useState(true);
  const [innerText, setInnerText] = useState("");
  const isEmptyText = useMemo(() => !text, [text]);

  const isTextLonger = text?.length >= maxLength;

  const hiddenCharactersCount = useMemo(() => {
    if (isEmptyText) {
      return 0;
    }
    return characterToSearch
      ? (text.slice(maxLength).match(new RegExp(`${characterToSearch}`, "g")) || "").length
      : text.slice(maxLength).length;
  }, [characterToSearch, isEmptyText, maxLength, text]);

  useEffect(() => {
    setInnerText(withSeeMore && isTextCollapsed ? text?.slice(0, maxLength) || "" : text);
  }, [text, maxLength, isTextCollapsed, withSeeMore]);

  return (
    <div style={style} className={`container`}>
      {!!label && (
        <div className={"viewFieldLabel"}>
          <span>{label}</span>
        </div>
      )}
      <div className={"viewFieldText"}>
        <span>{innerText || "N/A"}</span>
        {!isEmptyText && innerText && isTextLonger && withSeeMore && (
          <span
            className={"collapseButton"}
            onClick={() => {
              setIsTextCollapsed((prevState) => !prevState);
              setInnerText(isTextCollapsed ? text : text.slice(0, maxLength));
            }}
          >
            {isTextCollapsed
              ? `${
                  showHiddenCount && hiddenCharactersCount ? `.. +${hiddenCharactersCount}` : "..."
                } See More`
              : " See Less"}
          </span>
        )}
      </div>
    </div>
  );
};

export default ViewField;
