import * as yup from "yup";

export const validationSchema = yup.object().shape({
  type: yup.string(),
  name: yup
    .string()
    .required("This field is required")
    .min(3, "Must be exactly 3 digits")
    .max(150, "Must be exactly 150 digits"),
  description: yup.string().max(1000, "Must be exactly 1000 digits"),
  startTime: yup.string().required("This field is required"),
  endTime: yup.string().required("This field is required"),
  amount: yup.number().typeError("This field is required").required("This field is required"),
  count: yup.number().typeError("This field is required").required("This field is required"),
  currency: yup.string().required("This field is required"),
  earlyBirdCount: yup.number().when(["count"], {
    is: (count?: any) => count && count > 0,
    then: yup.number().max(yup.ref("count"), "Early bird count should be less than ticket count"),
  }),
});
