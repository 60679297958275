import React, { FC } from "react";
import "./UserLayout.scss";

interface UserLayoutProps {
  children: React.ReactNode;
}

const UserLayout: FC<UserLayoutProps> = ({ children }) => {
  return <div className={"user-layout"}>{children}</div>;
};

export default UserLayout;
