import { memo } from "react";
import { Table, Tag } from "antd";
import { TableActions, TableProps } from "../helpers/TableHelper";
import { BASE_URL_IMG } from "../../../../constants/Api";
import pub from "../../../../assets/images/pub.png";
import { humanReadableDate } from "../../../../utils/humanReadableDate";

const AdTable = ({ data, onSelectItem, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "ad",
      key: "title",
      render: (ad: any) => {
        return <p className={"base-responsive-table-name"}>{ad.title}</p>;
      },
    },
    {
      title: "Image",
      key: "cover",
      render: (ad: any) => {
        return (
          <div className={"base-responsive-table-image"}>
            <img src={ad.cover ? BASE_URL_IMG + ad.cover : pub} alt="table_image" />
          </div>
        );
      },
    },
    {
      title: "Hashtags",
      dataIndex: "hashtags",
      key: "hashtags",
      render: (hashtags: any) => {
        return (
          <>
            {hashtags?.slice(0, 3).map((hashtag: any) => (
              <Tag key={hashtag.id} color="default">
                {hashtag.name}
              </Tag>
            ))}
            {hashtags!.length > 3 && <span>...</span>}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "ad",
      key: "endDate",
      render: (ad: any) => {
        return (
          <>
            {new Date(`${ad.endDate}`).getTime() - new Date(Date.now()).getTime() < 0 ? (
              <>
                <span>{`Expired on ${humanReadableDate(ad.endDate!)}`}</span>
                <span className="pl-10">
                  <Tag color="error">Inactive</Tag>
                </span>
              </>
            ) : (
              <Tag color="success">Active</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <TableActions
            item={item}
            onSelectItem={onSelectItem}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
          />
        );
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
      onRow={(record) => {
        return {
          onClick: () => {
            onSelectItem && onSelectItem(record);
          },
        };
      }}
    />
  );
};

export default memo(AdTable);
