import React, { FC } from "react";
import { IReasonMessage } from "../../../types/reportedPostTypes";
import { convertToHtmlDate } from "../../../utils/convertDate";

interface ReportMessagesProps {
  reportMessages: IReasonMessage[];
}

const ReportMessages: FC<ReportMessagesProps> = ({ reportMessages }) => {
  return (
    <>
      {reportMessages.length > 0 ? (
        reportMessages.map((reportMessage, index) => (
          <div key={index} className={"reported-post-page-content-reasons-others-reports-item"}>
            <div className={"reported-post-page-content-reasons-others-reports-item-message"}>
              {reportMessage?.reason}
            </div>
            <div className={"reported-post-page-content-reasons-others-reports-item-footer"}>
              <div className={"reporter-name"}>{reportMessage?.user?.name}</div>
              <div className={"reporter-date"}>{convertToHtmlDate(reportMessage?.createdAt)}</div>
            </div>
          </div>
        ))
      ) : (
        <div>There is no other reason details</div>
      )}
    </>
  );
};

export default ReportMessages;
