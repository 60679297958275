import Evaporate from "evaporate";
import AWS from "aws-sdk";
import { AWS_BUCKET, AWS_KEY, AWS_REGION, SIGNER_URL } from "../constants/Api";
import uuid from "react-uuid";

export default async function uploadDocumentWithSignerToAWS(options = {}) {
  const { dir = "", filePrefix, withUserFullName, resizeWidth, resizeHeight, callback } = options;
  let { file } = options;
  if (resizeWidth && resizeHeight) {
    file = await getCroppedImg(file, resizeWidth, resizeHeight);
  }
  const config = {
    signerUrl: SIGNER_URL,
    aws_key: AWS_KEY,
    bucket: AWS_BUCKET,
    awsRegion: AWS_REGION,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (data) => AWS.util.crypto.md5(data, "base64"),
    cryptoHexEncodedHash256: (data) => AWS.util.crypto.sha256(data, "hex"),
  };
  const fileNameParams = file.name.split(".");
  const extension = fileNameParams.pop();
  const timestamp = uuid();

  let fileName = `${filePrefix ? `${filePrefix}-` : "file-"}${timestamp}.${extension}`;

  if (withUserFullName) {
    fileName = `${filePrefix ? `${filePrefix}-` : ""}${withUserFullName}.${extension}`;
  }

  let type = file.type.split("/").slice(-1)[0];
  // if (type === "pdf") {
  //   type = "octet-stream";
  // }

  const properties = {
    name: `${dir}/${fileName}`,
    file,
    contentType: `application/${type}`,
    xAmzHeadersAtInitiate: { "x-amz-acl": "public-read" },
  };
  const evaporate = await Evaporate.create(config);

  await evaporate.add({
    ...properties,
    complete: (_xhr) => {
      const { responseURL } = _xhr;
      const [URL] = responseURL.split("?");
      if (typeof callback === "function") {
        callback(URL);
      }
    },
    error: () => {
      // console.log("ERROR >>> ", err);
    },
    warn: () => {
      // console.log("WARNING >>> ", err);
    },
  });
}

export async function uploadMediaToAWS(options = {}) {
  const { id, collection, isPrivate, keyType, ...rest } = options;
  let dir = [];
  if (collection) {
    dir.push(collection);
  }

  if (id) {
    dir.push(`${keyType}/${id}`);
  }
  if (isPrivate) {
    dir.push("private");
  }
  dir = dir.join("/");
  return new Promise((resolve) => {
    const OPTIONS = {
      ...rest,
      dir,
      callback: (url) => {
        const fileName = url.split("/").pop();
        resolve({ url, fileName });
      },
    };

    uploadDocumentWithSignerToAWS(OPTIONS);
  });
}

const getCroppedImg = async (file, resizeWidth, resizeHeight) => {
  const source = await new Promise((resolve) => {
    const reader = new FileReader();
    // eslint-disable-next-line func-names
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
  return new Promise((resolve) => {
    const arr = source.split(",");
    const arr1 = arr[0].split(";");
    const arr2 = arr1[0].split(":");
    const MimeType = arr2[1];
    const image = new Image();
    // eslint-disable-next-line func-names
    image.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = resizeWidth;
      canvas.height = resizeHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight, 0, 0, resizeWidth, resizeHeight);
      const reader = new FileReader();
      canvas.toBlob(
        (blob) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(dataURLtoFile(reader.result, file.name));
          };
        },
        MimeType,
        1
      );
    };
    image.src = source;
  });
};

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const croppedImage = new File([u8arr], filename, { type: mime });
  return croppedImage;
};
