import { gql } from "@apollo/client";

export const GET_REPORTS = gql`
  query getReportedFeeds($getReportedFeedsArgs: PaginationInput!) {
    getReportedFeeds(getReportedFeedsArgs: $getReportedFeedsArgs) {
      count
      reports {
        id
        reports {
          type
          reason
          count
        }
        reportCount
        feed {
          description
          id
          cover
        }
        user {
          id
          name
          email
        }
      }
    }
  }
`;
