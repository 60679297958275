import { makeAutoObservable } from "mobx";
import { strongCopyData } from "../utils/strongCopyData";
import { IAdminOrStaffItem } from "../types/adminOrstaff";

export interface IAdminStaffStore {
  dataList: IAdminOrStaffItem[];
  selectedStaffIdList: Array<number | string>;
  allDataListCount: number;
  limit: number;
  offset: number;
}

class AdminStaffStore implements IAdminStaffStore {
  dataList = [];
  selectedStaffIdList: Array<number | string> = [];
  allDataListCount = 0;
  limit = 12;
  offset = 0;

  constructor() {
    makeAutoObservable(this);
  }

  addDataList(list: []) {
    this.dataList = list;
  }

  addAllDataListCount(count: number) {
    this.allDataListCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  addStaffListId(id: string | number) {
    if (this.selectedStaffIdList.some((staffId) => staffId === id)) {
      this.selectedStaffIdList = this.selectedStaffIdList.filter((staffId) => staffId !== id);
    } else {
      this.selectedStaffIdList = [id, ...this.selectedStaffIdList];
    }
  }

  emptyStaffListId() {
    this.selectedStaffIdList = [];
  }

  updateDataList(data: IAdminOrStaffItem) {
    this.dataList = strongCopyData(this.dataList).map((item: IAdminOrStaffItem) => {
      if (+item.id === +data.id) {
        item.id = data.id;
        item.name = data.name;
        item.email = data.email;
        item.phone = data.phone;
        item.position = data.position;
        if (data.permissions?.length === 1) {
          item.permissions = data.permissions[0] === 2 ? [0, 2] : [1, 0];
        } else {
          item.permissions = data.permissions;
        }
      }
      return item;
    });
  }

  removeData(id: string | number) {
    this.dataList = strongCopyData(this.dataList).filter((a: IAdminOrStaffItem) => a.id !== id);
  }

  resetDataList() {
    this.dataList = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new AdminStaffStore();
