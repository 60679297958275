import { useEffect, useState } from "react";

const useFetch = (callback: () => Promise<any>) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    callback().then((res) => {
      setData(res);
      setIsLoading(false);
    })
    .catch((err) => {
      setError(err);
      setIsLoading(false);
      setData(null);
    });
  }, [callback]);

  return { data, isLoading, error };
};

export default useFetch;