import { gql } from "@apollo/client";

export const GET_EVENTS_LIST = gql`
  query listEvents($listEventsArgs: ListEventsInput!) {
    listEvents(listEventsArgs: $listEventsArgs) {
      count
      events {
        id
        name
        description
        startDate
        endDate
        address
        bandName
        cover
        price
        venueId
        adminUserId
        media {
          type
          key
        }
        eventRequest {
          id
          venueId
          creatorId
          eventId
          status
          createdAt
          updatedAt
        }
        venue {
          name
        }
      }
    }
  }
`;
