import { NavLink } from "react-router-dom";
import { isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import ReportedPostsStore from "../../store/reportedPostsStore";
import { NavItem } from "./NavBar";
import NavbarDropdown from "./NavbarDropdown";

const badgeCount = (name: string) => {
  switch (name) {
    case "report":
      return ReportedPostsStore.totalReportCount;
    case "event requests":
      return 2;
    default:
      return 0;
  }
};

export const renderNavbarItem = (navItem: NavItem) => {
  if (navItem.name === "users" && !isSuperAdmin(userStore?.user)) {
    return null;
  }

  if (navItem?.menu?.length) {
    return <NavbarDropdown navItem={navItem} />;
  }
  const count = badgeCount(navItem.name);

  return (
    <NavLink
      to={navItem.to as string}
      key={navItem.name}
      className={({ isActive }) => `nav-bar--item ${isActive ? "nav-bar--item-active" : ""}`}
    >
      <span className={"nav-bar--item-name"}>
        {navItem.name}
        {!!navItem?.hasBadge && !!count ? <div className={"report-count"}>{count}</div> : null}
      </span>
    </NavLink>
  );
};
