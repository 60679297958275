import React, { useCallback } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import useFetch from "../../hooks/useFetch";
import BaseError from "../../components/reusable/baseError/BaseError";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import { GET_BLOG } from "../../graphql/query/getBlog";
import BlogForm from "../../forms/BlogForm/BlogForm";
import BlogView from "../../pageView/BlogView/BlogView";

const Blog = () => {
  const { blogId } = useParams();
  const currentLocation = useLocation();
  const blogViewRoutes = [{ path: "/blogs/view/:blogId" }];
  const matches = matchRoutes(blogViewRoutes, currentLocation);
  const [getBlog, { error, data }] = useLazyQuery(GET_BLOG);

  const fetchBlog = useCallback(async () => {
    if (blogId) {
      await getBlog({
        variables: {
          id: +blogId,
        },
      });
    }
  }, [blogId]);

  const { isLoading: loading } = useFetch(fetchBlog);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <>
      {matches && matches.length ? (
        <BlogView blog={data?.getOneBlog} />
      ) : (
        <BlogForm blogData={data?.getOneBlog} />
      )}
    </>
  );
};

export default Blog;