import { gql } from "@apollo/client";

export const GET_VENUE_STREAMS = gql`
  query getVenueStreams($input: GetAllStreamsInput!) {
    getVenueStreams(input: $input) {
      venueStreams {
        id
        name
        streamUrl
      }
      totalCount
    }
  }
`;
