import { makeAutoObservable } from "mobx";
import { INews } from "../types/news";
import { strongCopyData } from "../utils/strongCopyData";

export interface INewsItemStore {
  news: INews[];
  allNewsCount: number;
  limit: number;
  offset: number;
}

class NewsStore implements INewsItemStore {
  news = [];
  allNewsCount = 0;
  limit = 12;
  offset = 0;

  constructor() {
    makeAutoObservable(this);
  }

  addNews(news: []) {
    this.news = news;
  }

  addAllNewsCount(count: number) {
    this.allNewsCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  removeNews(id: string | number) {
    this.news = strongCopyData(this.news).filter((a: INews) => a.id !== id);
  }

  resetNews() {
    this.news = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new NewsStore();
