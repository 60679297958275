import React, { FC } from "react";
import { ReactComponent as Remove } from "../../../assets/images/Remove.svg";
import BaseInput from "../../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../../components/reusable/baseButton/BaseButton";
import { ReactComponent as Loader } from "../../../assets/images/Loader.svg";
import { IVenue } from "../../../types/venue";
import { FieldErrors, UseFormGetValues, UseFormRegister } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CREATE_VENUE_MANAGER } from "../../../graphql/mutations/createVenueManager";
import BaseAlert from "../../../components/reusable/baseAlert/BaseAlert";

interface VenueManagerItemProps {
  register: UseFormRegister<IVenue>;
  getValues: UseFormGetValues<IVenue>;
  errors: FieldErrors<IVenue>;
  isDisabled: boolean;
  fieldName: string;
  index: number;
  venueId: string | number | undefined;
  removeManagerListData: (index: number, isNew: boolean) => void;
  isNew: boolean;
}

const VenueManagerItem: FC<VenueManagerItemProps> = ({
  register,
  getValues,
  errors,
  isDisabled,
  fieldName,
  index,
  venueId,
  removeManagerListData,
  isNew,
}) => {
  const [
    createVenueManager,
    { loading: createVenueManagerLoading, error: createVenueManagerError },
  ] = useMutation(CREATE_VENUE_MANAGER);

  const addNewVenueManager = async (index: number) => {
    const manager = getValues("managers")[index];
    const newManagerList = {
      venueId: venueId,
      name: manager.name,
      email: manager.email,
    };
    await createVenueManager({
      variables: {
        createVenueManagerInput: newManagerList,
      },
    });
  };

  const handleRemove = (index: number, isNew: boolean) => {
    removeManagerListData(index, isNew);
  };

  return (
    <div className={"venue-manager-form--item"}>
      {createVenueManagerError ? (
        <BaseAlert type={"failed"} message={createVenueManagerError?.message} />
      ) : null}

      <Remove
        className={"venue-manager-form--remove-manager-icon"}
        onClick={() => handleRemove(index, isNew)}
      />
      <BaseInput
        disabled={isDisabled || !isNew}
        label={{
          text: "Name",
          htmlFor: "name",
        }}
        registerValidation={{
          type: "name",
          name: `${fieldName}.name`,
          placeholder: "Name...",
          validation: {
            ...register(`managers.${index}.name`),
          },
        }}
        error={errors?.managers && errors.managers[index]?.name?.message}
      />
      <BaseInput
        disabled={isDisabled || !isNew}
        label={{
          text: "Email",
          htmlFor: "email",
        }}
        registerValidation={{
          type: "email",
          name: `${fieldName}.email`,
          placeholder: "Email...",
          validation: {
            ...register(`managers.${index}.email`),
          },
        }}
        error={errors?.managers && errors.managers[index]?.email?.message}
      />
      {isNew && venueId ? (
        <BaseButton
          onClick={() => addNewVenueManager(index)}
          className={"outlined venue-manager-form--save"}
          title={"Save"}
          type={"button"}
          loader={createVenueManagerLoading && <Loader width={30} height={30} />}
        />
      ) : null}
    </div>
  );
};

export default VenueManagerItem;
