import { makeAutoObservable } from "mobx";
import { IMediaItem } from "../types/mediaTypes";

export interface IFilesUploadStore {
  filesAWSLoadCount: number;
  isStartFileUploadToAWS: boolean;
  mediaFiles: Array<IMediaItem>;
  mediaFilesToUploadBackend: Array<IMediaItem>;
  newMediaFiles: Array<IMediaItem>;
}

class FilesUploadStore implements IFilesUploadStore {
  filesAWSLoadCount = 0;
  isStartFileUploadToAWS = false;
  mediaFiles: Array<IMediaItem> = [];
  mediaFilesToUploadBackend: Array<IMediaItem> = [];
  newMediaFiles: Array<IMediaItem> = [];

  constructor() {
    makeAutoObservable(this);
  }

  assignMediaFiles(files: Array<IMediaItem>) {
    this.mediaFiles = files;
    this.mediaFilesToUploadBackend = files;
  }

  changeCover(key: string) {
    this.mediaFiles = this.mediaFiles.map((file: IMediaItem) => {
      file.isCover = file.key === key;
      return file;
    });
    this.mediaFilesToUploadBackend = this.mediaFilesToUploadBackend.map((file: IMediaItem) => {
      file.isCover = file.key === key;
      return file;
    });
  }

  addUploadFiles(file: IMediaItem) {
    this.mediaFiles = [...this.mediaFiles, file];
    this.newMediaFiles = [...this.newMediaFiles, file];
  }

  addAWSUploadFiles(file: IMediaItem) {
    this.mediaFilesToUploadBackend = [...this.mediaFilesToUploadBackend, file];
    this.filesAWSLoadCount++;
  }

  removeUploadFiles(key: string | number) {
    this.mediaFiles = this.mediaFiles.filter((file: IMediaItem) => file.key !== key);
    this.newMediaFiles = this.newMediaFiles.filter((file: IMediaItem) => file.key !== key);
    this.mediaFilesToUploadBackend = this.mediaFilesToUploadBackend.filter(
      (file: IMediaItem) => file.key !== key
    );
    if (this.filesAWSLoadCount) {
      this.filesAWSLoadCount = this.filesAWSLoadCount - 1;
    }
  }

  startFileUploadToAWS() {
    this.isStartFileUploadToAWS = true;
  }

  restoreFilesUploadStore() {
    this.filesAWSLoadCount = 0;
    this.isStartFileUploadToAWS = false;
    this.mediaFiles = [];
    this.mediaFilesToUploadBackend = [];
    this.newMediaFiles = [];
  }
}

export default new FilesUploadStore();
