import { makeAutoObservable } from "mobx";
import { IUsersItem } from "../types/user";

export interface IAppUsersStore {
  users: IUsersItem[];
  allUsersCount: number;
  limit: number;
  offset: number;
}

class AppUsersStore implements IAppUsersStore {
  users = [];
  allUsersCount = 0;
  limit = 12;
  offset = 0;

  constructor() {
    makeAutoObservable(this);
  }

  addUsers(users: []) {
    this.users = users;
  }

  addAllUsersCount(count: number) {
    this.allUsersCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetUsers() {
    this.users = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }

  updateUserStatusAction(data: any) {
    this.users = this.users.map((user: IUsersItem) => {
      if (user.id === data.updateUserStatus.id) {
        user.status = data.updateUserStatus.status;
      }
      return user;
    }) as never[];
  }
}

export default new AppUsersStore();
