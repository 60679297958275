import React, { FC } from "react";
import "./VenueInfoForm.scss";
import BaseInput from "../../../components/reusable/baseInput/BaseInput";
import { FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import BaseSelect from "../../../components/reusable/baseSelect/BaseSelect";
import { ICategories, IVenue } from "../../../types/venue";
import AddressAutocomplete from "../../../components/reusable/addressAutocomplete/AddressAutocomplete";
import { VenueOption } from "../../EventForm/components/EventDetails/EventDetails";

interface VenueInfoFormProps {
  venue?: IVenue;
  categories?: ICategories[];
  register: UseFormRegister<IVenue>;
  setValue: UseFormSetValue<IVenue>;
  clearErrors: UseFormClearErrors<IVenue>;
  adAddressLocation: (data: string[]) => void;
  isDisabled: boolean;
  errors: FieldErrors<IVenue>;
}

const VenueInfoForm: FC<VenueInfoFormProps> = ({
  venue,
  categories,
  register,
  setValue,
  clearErrors,
  adAddressLocation,
  isDisabled,
  errors,
}) => {
  const changeCategoryId = (value: { id: number; value: string; type?: string }) => {
    setValue("categoryId", value.id);
  };

  const changeVenueAddress = (address: string) => {
    setValue("address", address);
    clearErrors("address");
  };

  return (
    <div className={"venue-info-form"}>
      <div className={"venue-info-form--input-block"}>
        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Venue name",
            htmlFor: "name",
            className: "required",
          }}
          registerValidation={{
            type: "text",
            name: "name",
            placeholder: "Venue name",
            validation: {
              ...register("name", {
                required: true,
              }),
            },
          }}
          error={errors.name?.message}
        />
      </div>

      <div className={"venue-info-form--input-block"}>
        <AddressAutocomplete
          addressValue={venue?.address || ""}
          disabled={isDisabled}
          adAddressLocation={adAddressLocation}
          label={{
            text: "Venue Address",
            htmlFor: "address",
            className: "required",
          }}
          handleChangeValue={(address: string) => changeVenueAddress(address)}
          clearErrors={() => clearErrors("address")}
          newClassName={"event-page-field"}
          registerValidation={{
            type: "text",
            name: "address",
            placeholder: "Add address",
            validation: {
              ...register("address", {
                required: true,
              }),
            },
          }}
          error={errors.address?.message}
        />
      </div>

      <div className={"venue-info-form--input-block"}>
        <BaseInput
          newClassName={"modal-input"}
          label={{
            text: "Venue Capacity",
            htmlFor: "capacity",
          }}
          registerValidation={{
            type: "text",
            name: "capacity",
            placeholder: "capacity...",
            validation: {
              ...register("capacity", {
                required: false,
              }),
            },
          }}
        />
      </div>

      <div className={"venue-info-form--input-block"}>
        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Link to Website",
            htmlFor: "linkToWebsite",
          }}
          registerValidation={{
            type: "text",
            name: "linkToWebsite",
            placeholder: "link...",
            validation: {
              ...register("linkToWebsite", {
                required: false,
              }),
            },
          }}
        />
      </div>
      <div className={"venue-info-form--input-block"}>
        <BaseInput
          className={"venue-info-form--social-block-input"}
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Link to Facebook",
            htmlFor: "linkToFaceBook",
          }}
          registerValidation={{
            type: "text",
            name: "linkToFaceBook",
            placeholder: "Link to Facebook...",
            validation: {
              ...register("linkToFaceBook", {
                required: false,
              }),
            },
          }}
        />
      </div>
      <div className={"venue-info-form--input-block"}>
        <BaseInput
          className={"venue-info-form--social-block-input"}
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Link to Instagram",
            htmlFor: "linkToInstagram",
          }}
          registerValidation={{
            type: "text",
            name: "linkToInstagram",
            placeholder: "Link to Instagram...",
            validation: {
              ...register("linkToInstagram", {
                required: false,
              }),
            },
          }}
        />
      </div>
      <div className={"venue-info-form--input-block"}>
        <BaseInput
          className={"venue-info-form--social-block-input"}
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Link to Youtube",
            htmlFor: "linkToYoutube",
          }}
          registerValidation={{
            type: "text",
            name: "linkToYoutube",
            placeholder: "Link to Youtube...",
            validation: {
              ...register("linkToYoutube", {
                required: false,
              }),
            },
          }}
        />
      </div>
      <div className={"venue-info-form--input-block"}>
        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "International Phone Number",
            htmlFor: "internationalPhoneNumber",
          }}
          registerValidation={{
            type: "number",
            name: "internationalPhoneNumber",
            placeholder: "+44 12 3456 7890",
            validation: {
              ...register("internationalPhoneNumber", {
                required: false,
              }),
            },
          }}
        />
      </div>

      <div className={"venue-info-form--input-block"}>
        <BaseSelect
          showOnlyName
          value={categories?.find((category) => category.id === venue?.categoryId)?.name}
          disabled={isDisabled}
          setValue={(value) => changeCategoryId(value as VenueOption)}
          options={categories || []}
          label={{
            text: "Category",
            htmlFor: "categories",
          }}
          registerValidation={{
            type: "select",
            name: "timezone",
            placeholder: "Timezone",
            validation: {
              ...register("categoryId"),
            },
          }}
          error={errors.categoryId?.message}
        />
      </div>

      <div>
        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Venue Description",
            htmlFor: "description",
          }}
          registerValidation={{
            type: "textarea",
            name: "description",
            placeholder: "description...",
            validation: {
              ...register("description", {
                required: false,
              }),
            },
          }}
        />
      </div>
    </div>
  );
};

export default VenueInfoForm;
