import React, { FC } from "react";
import "./BaseButton.scss";

interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  className?: string;
  loader?: React.ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
}

const BaseButton: FC<BaseButtonProps> = ({ title, className = "", loader, type, ...props }) => {
  return (
    <button type={type} className={`base-button ${className}`} disabled={!!loader} {...props}>
      {loader ? loader : title}
    </button>
  );
};

export default BaseButton;
