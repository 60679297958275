import { gql } from "@apollo/client";

export const GET_SALES_ANALYTICS = gql`
  query getSalesAnalytics {
    getSalesAnalytics {
      totalSales
      totalRefunds
      ticketSales
      earlyBirdSales
    }
  }
`;
