import { gql } from "@apollo/client";

export const GET_TRANSACTIONS_BY_TICKET_ID = gql`
  query ($input: GetTransactionsByTicketIdInput!) {
    getTransactionsByTicketId(input: $input) {
      rows {
        id
        amount
        quantity
        currency
        status
        createdAt
        state
        user {
          id
          name
        }
        discount {
          id
          name
          code
          discount
        }
        ticket {
          id
          name
        }
      }
      count
    }
  }
`;
