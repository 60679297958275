import React, { FC } from "react";
import "./ConfirmationModal.scss";
import Modal from "../Modal/Modal";
import BaseButton from "../reusable/baseButton/BaseButton";
import { Button, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";

interface DeleteModalProps {
  closeModal: () => void;
  deleteHandle?: () => void;
  isOpen: boolean;
  title: string;
  buttonText: string;
  isReject?: boolean;
  rejectHandle?: (reason: string) => void;
}

const ConfirmationModal: FC<DeleteModalProps> = ({
  deleteHandle,
  closeModal,
  isOpen,
  title,
  buttonText,
  isReject,
  rejectHandle,
}) => {
  const [form] = useForm();
  return (
    <Modal closeModal={closeModal} modalIsOpen={isOpen} className={"delete-modal"}>
      <div className={"delete-modal--content"}>
        <p className={"delete-modal--title"}>{title}</p>
      </div>
      {isReject ? (
        <Form
          form={form}
          onFinish={(values) => {
            rejectHandle && rejectHandle(values.reason);
          }}
        >
          <FormItem name={"reason"} rules={[{ required: true, message: "You must add a reason" }]}>
            <TextArea rows={4} placeholder={"Please write a reason"} />
          </FormItem>
          <div className={"d-flex-column gap-10"}>
            <BaseButton type={"submit"} title={"Reject"} />
            <BaseButton
              type={"button"}
              title={"Cancel"}
              onClick={closeModal}
              className={"delete-modal--cancel-btn"}
            />
          </div>
        </Form>
      ) : (
        <div className={"delete-modal--btn-block"}>
          <BaseButton type={"button"} title={buttonText} onClick={deleteHandle} />
          <BaseButton
            type={"button"}
            title={"Cancel"}
            onClick={closeModal}
            className={"delete-modal--cancel-btn"}
          />
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
