import { FC } from "react";
import "./VenueInfo.scss";
import { ICategories, IVenue } from "../../types/venue";
import ViewInput from "../../components/reusable/viewInput/ViewInput";

interface VenueInfoProps {
  venue?: IVenue;
  categories?: ICategories[];
}

const VenueInfo: FC<VenueInfoProps> = ({ venue, categories }) => {
  return (
    <div className={"venue-info"}>
      <div className={"venue-info--left-block"}>
        <ViewInput title={"Venue Address"} value={venue?.address} />
        <ViewInput title={"Venue Capacity"} value={venue?.capacity} />
        <ViewInput
          title={"Venue Description"}
          value={venue?.description}
          newClass={"venue-info--data-desc"}
        />
        <ViewInput title={"Venue Link"} value={venue?.linkToWebsite} isLinkInput={true} />
        <ViewInput title={"Venue Facebook link"} value={venue?.linkToFaceBook} isLinkInput={true} />
        <ViewInput
          title={"Venue Instagram link"}
          value={venue?.linkToInstagram}
          isLinkInput={true}
        />
        <ViewInput title={"Venue YouTube link"} value={venue?.linkToYoutube} isLinkInput={true} />
        <ViewInput
          title={"Venue International Phone Number"}
          value={venue?.internationalPhoneNumber}
        />
        <ViewInput
          title={"Category"}
          value={categories?.find((category) => category.id === venue?.categoryId)?.name.toString()}
        />
      </div>

      <div className={"venue-info--right-block"}>
        {venue?.workingHours.map((workingHour, index) => (
          <div key={workingHour.day} className={"venue-info--working-hour"}>
            <div className={"venue-info--days-title-block"}>
              {index === 0 && (
                <div className={"venue-info--title"}>
                  <span>Working Days</span>
                </div>
              )}
              <span className={"venue-info--working-day"}>{workingHour.day}</span>
            </div>
            <div className={"venue-info--time-block"}>
              <div className={"venue-info--days-title-block"}>
                {index === 0 && (
                  <div className={"venue-info--title"}>
                    <span>Start</span>
                  </div>
                )}
                <div className={"venue-info--opening-time"}>
                  <span>{workingHour.openingTime || "xx:xx"}</span>
                </div>
              </div>
              <div className={"venue-info--days-title-block"}>
                {index === 0 && (
                  <div className={"venue-info--title"}>
                    <span>End</span>
                  </div>
                )}
                <div className={"venue-info--closing-time"}>
                  <span>{workingHour.closingTime || "xx:xx"}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VenueInfo;
