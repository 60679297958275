import { computed, makeAutoObservable } from "mobx";
import { IObjectKeys } from "../types/types";

export interface IPostsStore {
  postsList: any;
  post: IObjectKeys | any;
  limit: number;
  offset: number;
  postTotalCount: number;
  sortOptions: IObjectKeys | any;
}

class PostsStore implements IPostsStore {
  limit = 12;
  offset = 0;
  postTotalCount = 0;
  postsList = [] as any[];
  post: any | undefined;
  sortOptions: any | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  addAllPostsTotalCount(count: number) {
    this.postTotalCount = count;
  }

  addPosts(postData: any[]) {
    this.postsList = postData;
  }

  addSinglePost(post: IObjectKeys) {
    this.post = post;
  }
  addSortOptions(options: IObjectKeys) {
    this.sortOptions = options;
  }

  @computed
  get postData(): any | null {
    return this.post || null;
  }

  // publishEvent(id: number, isPublished: boolean): void {
  //     if (this.event?.event?.id && +this.event.event.id === id) {
  //         this.event.feed.isPublished = isPublished;
  //     }
  // }
  //
  // removeEvent(eventId: string | number) {
  //     this.eventsList = this.eventsList.filter((eventData: IEventData) => eventData.id !== eventId);
  // }

  // createEventRequest(eventId:string | number, data: IEventRequest){
  //     // @ts-ignore
  //     this.eventsList = this.eventsList.map((eventData: IEventData) => {
  //         if (eventData.id === eventId) {
  //             eventData.eventRequest = data;
  //         }
  //         return eventData
  //     })
  // }
  addOffset(offset: number) {
    this.offset = offset;
  }

  resetPosts() {
    this.postsList = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new PostsStore();
