import { gql } from "@apollo/client";

export const GET_ADMIN_SETTINGS = gql`
  query getAdminSettings($args: ListAllSettingsInput!) {
    listAllSettings(listAllSettingsInput: $args) {
      settings {
        key
        value
      }
      count
    }
  }
`;
