import * as React from "react";

const VenueSvg = (props: any) => (
  <svg width={22} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 15.132a4.3 4.3 0 1 1 0-8.6 4.3 4.3 0 0 1 0 8.6ZM11 8.2a2.633 2.633 0 1 0 0 5.267A2.633 2.633 0 0 0 11 8.2Z"
      fill="#393939"
    />
    <path
      d="M11 28a1 1 0 0 1-.573-.182C10 27.52 0 20.412 0 11a11 11 0 0 1 22 0c0 9.412-10 16.52-10.427 16.818A1 1 0 0 1 11 28Zm0-26a9.01 9.01 0 0 0-9 9c0 7.167 6.932 13.13 9 14.747 2.065-1.619 9-7.592 9-14.747a9.01 9.01 0 0 0-9-9Z"
      fill="#393939"
    />
  </svg>
);

export default VenueSvg;
