import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Calendar, Popover } from "antd";
import { useLazyQuery } from "@apollo/client";
import { GET_EVENTS_LIST } from "../../../graphql/query/getEvents";
import "./DashboardEventsCalendar.scss";
import { IObjectKeys } from "../../../types/types";

const DashboardEventsCalendar = () => {
  const [events, setEvents] = useState([]);
  // const [date, setDate] = useState(dayjs());

  // const onPanelChange = (value: any) => {
  //   setDate(dayjs(value));
  // };
  const [getEvents, { data: eventsList }] = useLazyQuery(GET_EVENTS_LIST, {
    fetchPolicy: "no-cache",
  });

  const dateFullCellRender = useCallback(
    (value: any) => {
      const date = dayjs(value).format("YYYY-MM-DD");
      const eventsOfDay = events.filter(
        (event: any) => dayjs(event.startDate).format("YYYY-MM-DD") === date
      );

      if (eventsOfDay.length > 0) {
        const isPastEvent = date < dayjs().format("YYYY-MM-DD");
        return (
          <div className="custom-day">
            {!isPastEvent ? (
              <Popover
                content={
                  <ul className="popover-event-list">
                    {eventsOfDay.map((event: any) => (
                      <li key={event.id} onClick={() => console.log(event)}>
                        <span className={"d-flex-aligned-center"}>
                          Event: <span className={"event-name"}> {event.name}</span>
                        </span>
                        <span>
                          Start:{" "}
                          <span className={"event-start"}>
                            {dayjs(event.startTime).format("HH:mm")}
                          </span>{" "}
                        </span>
                      </li>
                    ))}
                  </ul>
                }
              >
                <div className="event-day">{dayjs(value).format("DD")}</div>
              </Popover>
            ) : (
              <div className="past-event-day">{dayjs(value).format("DD")}</div>
            )}
          </div>
        );
      }

      return (
        <div className="normal-day">
          <div className={"event-day"}>{dayjs(value).format("DD")}</div>
        </div>
      );
    },
    [events]
  );

  useEffect(() => {
    (async () => {
      await getEvents({
        variables: {
          listEventsArgs: {},
        },
      });
    })();
  }, []);

  useEffect(() => {
    if (eventsList) {
      const newArr = eventsList.listEvents.events.map((item: IObjectKeys) => {
        return { ...item, date: item.startDate };
      });
      setEvents(newArr);
    }
  }, [eventsList]);

  return (
    <div className="calendar-wrapper">
      <Calendar
        fullCellRender={dateFullCellRender}
        fullscreen={false}
        mode={"month"}
        className="event-calendar"
      />
    </div>
  );
};

export default DashboardEventsCalendar;
