import { gql } from "@apollo/client";

export const GET_ADMIN_REQUESTS = gql`
  query getAdminRequestsLists($adminReqListInput: AdminReqListInput!) {
    getAdminRequestsLists(adminReqListInput: $adminReqListInput) {
      count
      adminRequests {
        id
        name
        role
        email
        phoneNumber
        venueId
        message
        status
        venue {
          name
          address
        }
      }
    }
  }
`;
