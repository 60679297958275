import { memo } from "react";
import { Table } from "antd";
import { TableActions, TableProps } from "../helpers/TableHelper";
import { BASE_URL_IMG } from "../../../../constants/Api";
import pub from "../../../../assets/images/pub.png";
import dayjs from "dayjs";
import { ITicket } from "../../../../types/ticket";

const TicketTable = ({ data, onSelectItem, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Image",
      dataIndex: "cover",
      key: "cover",
      render: (cover: string, record: ITicket) => {
        let src = pub;
        if (record.media) {
          const foundItem = record.media.find((r) => r.isCover);
          if (foundItem !== undefined) {
            src = BASE_URL_IMG! + foundItem.key;
          }
        }
        return (
          <div className={"base-responsive-table-image"}>
            <img src={src} alt="table_image" />
          </div>
        );
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime: string) => {
        return (
          <p className={"base-responsive-table-name"}>{dayjs(startTime).format("YYYY-MM-DD")}</p>
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime: string) => {
        return (
          <p className={"base-responsive-table-name"}>{dayjs(endTime).format("YYYY-MM-DD")}</p>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: string, record: any) => {
        return (
          <p className={"base-responsive-table-name"}>
            {record.amount} <span className="c-red">(+1)</span> {record.currency}
          </p>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => {
        return (
          <p className={"base-responsive-table-description"}>
            {description ? description : "No Description"}
          </p>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <TableActions
            item={item}
            onSelectItem={onSelectItem}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
          />
        );
      },
    },
  ];

  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            onSelectItem && onSelectItem(record);
          },
        };
      }}
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data as any}
      pagination={false}
      rowKey={"id"}
    />
  );
};

export default memo(TicketTable);
