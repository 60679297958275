import { gql } from "@apollo/client";

export const GET_BLOGS = gql`
  query listBlogs($ListBlogsInput: ListBlogsInput!) {
    listBlogs(ListBlogsInput: $ListBlogsInput) {
      count
      blogs {
        id
        title
        content
        cover
        createdAt
        updatedAt
        isPublished
      }
    }
  }
`;
