import { FC } from "react";
import "./DiscountItem.scss";
import { IDiscount, IDiscountTableHeader } from "../../../types/discount";
import { ReactComponent as Edit } from "../../../assets/images/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/images/RecycleBin.svg";

interface IDiscountItemProps {
  item: IDiscount | IDiscountTableHeader;
  onHandleClick?: (item: IDiscount, type?: string) => void;
  onHandleDelete?: (item: IDiscount, type?: string) => void;
  hasActions?: boolean;
}
const DiscountItem: FC<IDiscountItemProps> = (props: IDiscountItemProps) => {
  const { item, onHandleClick, onHandleDelete, hasActions } = props;
  return (
    <div className={"discount-item"}>
      <div className={"discount-item--content"}>
        <div className={"discount-item--column"}>
          <span>{item.name}</span>
        </div>
        <div className={"discount-item--column"}>
          <span>{item.code}</span>
        </div>
        <div className={"discount-item--column"}>
          <span>{item.discount}</span>
        </div>
        {hasActions && (
          <div className={"discount-item--column"}>
            <>
              {onHandleClick && (
                <Edit
                  className={"discount-item--edit-icon"}
                  onClick={() => onHandleClick(item as IDiscount, "edit")}
                />
              )}

              {onHandleDelete && (
                <Delete
                  className={"discount-item--delete-icon"}
                  onClick={() => onHandleDelete(item as IDiscount)}
                />
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscountItem;
