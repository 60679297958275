import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SALES_ANALYTICS } from "../../../graphql/query/getSalesAnalytics";
import { ReactComponent as TotalSalesIcon } from "../../../assets/images/total-sales-icon.svg";
import { ReactComponent as TicketIcon } from "../../../assets/images/ticket-icon.svg";
import { ReactComponent as BirdIcon } from "../../../assets/images/bird-icon.svg";
import { ReactComponent as RefundIcon } from "../../../assets/images/refund-icon.svg";
import "./Sales.scss";

const Sales = () => {
  const [salesData, setSalesData] = useState<any>(null);
  const [getSalesAnalytics, { data }] = useLazyQuery(GET_SALES_ANALYTICS, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    (async () => {
      await getSalesAnalytics();
    })();
  }, []);

  useEffect(() => {
    if (data) {
      setSalesData(data.getSalesAnalytics);
    }
  }, [data]);
  return (
    <div className={"sales d-flex-aligned-center w-full"}>
      <div className={"sales-item d-flex-aligned-center"}>
        <TotalSalesIcon />
        <div>
          <p>Total sales</p>
          <span>{salesData?.totalSales} GBP</span>
        </div>
      </div>
      <div className={"sales-item d-flex-aligned-center"}>
        <TicketIcon />
        <div>
          <p>Ticket sales</p>
          <span>{salesData?.ticketSales} GBP</span>
        </div>
      </div>
      <div className={"sales-item d-flex-aligned-center"}>
        <BirdIcon />
        <div>
          <p>Early bird sales</p>
          <span>{salesData?.earlyBirdSales} GBP</span>
        </div>
      </div>
      <div className={"sales-item d-flex-aligned-center"}>
        <RefundIcon />
        <div>
          <p>Total refunds</p>
          <span>{salesData?.totalRefunds} GBP</span>
        </div>
      </div>
    </div>
  );
};

export default Sales;
