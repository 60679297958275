import { FC } from "react";
import "./DatePicker.scss";
import { DatePicker } from "antd";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";

interface Props {
  title?: string;
  newClassName?: string;
  error?: any;
  // minDate?:any
  // maxDate?:any
}

const Picker: FC<Props & DatePickerProps> = ({ title, newClassName, error, ...rest }) => {
  return (
    <div className="date-picker">
      <label className="date-picker--label required">{title}</label>
      <div className="date-picker--input-block">
        <DatePicker
          {...rest}
          value={rest.value ? dayjs(rest.value) : null}
          className={`date-picker--input ${newClassName} ${
            error ? "date-picker--input-error" : ""
          }`}
        />
      </div>
      <span className={"base-input--error"}>{error}</span>
    </div>
  );
};

export default Picker;
