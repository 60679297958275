import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_QUANTITY_ANALYTICS } from "../../../graphql/query/getUserQuantityAnalytics";
import DashboardLineChart from "../LineChart/LineChart";

const UserQuantityAnalytics = () => {
  const [chartData, setChartData] = useState([]);
  const [getUserQuantityAnalytics, { data }] = useLazyQuery(GET_USER_QUANTITY_ANALYTICS, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    (async () => {
      await getUserQuantityAnalytics();
    })();
  }, []);

  useEffect(() => {
    if (data) {
      setChartData(data.getUserQuantityAnalytics);
    }
  }, [data]);
  return <DashboardLineChart data={chartData} title={"User quantity growth per month"} />;
};

export default UserQuantityAnalytics;
