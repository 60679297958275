import ForgotPasswordForm from "../../forms/AuthForm/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPassword;
