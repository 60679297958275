import { gql } from "@apollo/client";

export const GET_VENUE_MANAGERS = gql`
  query venueManagersList($venueManagersListArgs: ListVenueManagersArgs!) {
    venueManagersList(venueManagersListArgs: $venueManagersListArgs) {
      count
      venueManagers {
        id
        user {
          id
          name
          email
        }
      }
    }
  }
`;
