import React, { FC, useCallback, useLayoutEffect } from "react";
import { Form } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import "./ContactRequestModal.scss";
import { useForm } from "antd/es/form/Form";
import Modal from "../../components/Modal/Modal";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import { IContactRequest } from "../../types/contactRequests";
import { contactNames } from "./constants";

interface DeleteModalProps {
  closeModal: () => void;
  isOpen: boolean;
  onSuccess: (values: any) => void;
  contact: IContactRequest
}

const ContactRequestModal: FC<DeleteModalProps> = ({
  closeModal,
  isOpen,
  onSuccess,
  contact,
}) => {
  const [form] = useForm();
  useLayoutEffect(() => {
    const previousNote = form.getFieldValue('note');
    if (previousNote !== contact?.note) {
      form.setFieldsValue({ note: contact?.note });
    }
  }, [contact?.note])

  const handleSubmit = useCallback((values: any) => {
    onSuccess(values?.note);
    form.resetFields();
  }, []);

  if (!isOpen) return null;

  return (
    <Modal closeModal={closeModal} modalIsOpen={isOpen} className={"contact-view-modal"}>
      <div className={"contact-view-modal--content"}>
        <p className={"contact-view-modal--title"}>Add contact note</p>
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
      >
        <ul className="contact-view-modal--list-block">
          {
            contactNames.map(name => (
              <li key={name}><span className="contact-view-modal--list-segment">{name}</span>: {contact[name  as keyof IContactRequest]}</li>
            ))
          }
        </ul>
        <FormItem name="note" rules={[{ required: true, message: "You must add a note" }]}>
          <TextArea rows={4} placeholder={"Please write a note"} />
        </FormItem>
        <div className={"d-flex-column gap-10"}>
          <BaseButton type={"submit"} title={"Submit"} />
          <BaseButton
            type={"button"}
            title={"Cancel"}
            onClick={closeModal}
            className={"contact-view-modal--cancel-btn"}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default ContactRequestModal;
