import React, { FC, useCallback, useMemo, useState } from "react";
import "./MobileNavBar.scss";
import CloseSvg from "../../assets/images/CloseSvg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { EVENT_ADMIN_MENU, SUPER_ADMIN_MENU, VENUE_MANAGER_MENU } from "./Helpers";
import { isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import { Drawer } from "antd";
import { UserRole } from "../../types/types";
import NavbarDropdown from "./NavbarDropdown";
import { NavItem } from "./NavBar";

interface MobileNavBarProps {
  userRole: UserRole;
}

const MobileNavBar: FC<MobileNavBarProps> = ({ userRole }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const navItems = useMemo(() => {
    switch (userRole) {
      case UserRole.superAdmin:
        return SUPER_ADMIN_MENU;
      case UserRole.admin:
        return EVENT_ADMIN_MENU;
      case UserRole.venueManager:
      default:
        return VENUE_MANAGER_MENU;
    }
  }, [userRole]);

  const onLogout = useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);

  return (
    <div className={`mobile-nav-bar`}>
      <div className={"mobile-nav-bar-logo"}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div
        className={`menu-btn ${isOpenMenu ? "open" : ""}`}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
      >
        <div className="menu-btn__burger" />
      </div>
      <Drawer
        className="mobile-nav-bar-drawer"
        title=""
        placement="left"
        onClose={() => setIsOpenMenu(false)}
        open={isOpenMenu}
        width={320}
      >
        <div className={"mobile-nav-bar-drawer-header"}>
          <div className={"mobile-nav-bar-drawer-header-logo"}>
            <Logo />
          </div>
          <button
            className={"mobile-nav-bar-drawer-header-close"}
            onClick={() => setIsOpenMenu(false)}
          >
            <CloseSvg />
          </button>
        </div>
        <div className={"mobile-nav-bar-drawer-body"}>
          <div className={"mobile-nav-bar-drawer-body-list"}>
            <div className={"mobile-nav-bar-drawer-body-list-info"}>
              <h3>role: {userRole}</h3>
              <h3>email: {userStore.user?.email}</h3>
            </div>
            {navItems.map((navItem: NavItem) => {
              if (navItem.name === "users" && !isSuperAdmin(userStore?.user)) {
                return null;
              }
              if (navItem?.menu?.length) {
                return (
                  <NavbarDropdown
                    key={navItem.name}
                    navItem={navItem}
                    className="mobile-nav-bar-drawer-body--item"
                  />
                );
              }
              return (
                <NavLink
                  to={navItem.to as string}
                  key={navItem.name}
                  className={({ isActive }) =>
                    `mobile-nav-bar-drawer-body--item ${
                      isActive ? "mobile-nav-bar-drawer-body--item-active" : ""
                    }`
                  }
                >
                  <span className={"mobile-nav-bar-drawer-body--item-name"}>{navItem.name}</span>
                </NavLink>
              );
            })}
            {!isSuperAdmin(userStore.user) ? (
              <NavLink
                to={"/settings"}
                className={({ isActive }) =>
                  `mobile-nav-bar-drawer-body--item ${
                    isActive ? "mobile-nav-bar-drawer-body--item-active" : ""
                  }`
                }
              >
                <span className={"mobile-nav-bar-drawer-body--item-name"}>Settings</span>
              </NavLink>
            ) : null}
          </div>
          <button className={"mobile-nav-bar-drawer-body-logout"} onClick={onLogout}>
            Log Out
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNavBar;
