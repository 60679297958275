import "./Auth.scss";
import * as yup from "yup";
import { EMAIL_INVALID_TEXT, EMAIL_REQUIRED_TEXT } from "../../constants/errors";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../graphql/mutations/forgotPassword";
import React, { useEffect } from "react";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { ReactComponent as Loader } from "../../assets/images/Loader.svg";

type IForgotPasswordForm = {
  email: string;
};

interface NavigationState {
  title: string;
  subTitle: string;
}

const ForgotPasswordForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [forgotPassword, { error, loading, data }] = useMutation(FORGOT_PASSWORD);

  const validationSchema = yup.object().shape({
    email: yup.string().required(EMAIL_REQUIRED_TEXT).email(EMAIL_INVALID_TEXT),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit: SubmitHandler<IForgotPasswordForm> = async (data) => {
    await forgotPassword({
      variables: {
        forgotPasswordData: {
          email: data.email,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      navigate(
        {
          pathname: `/reset-password/${data?.forgotPassAdmins.sessionToken}`,
        },
        { state: data?.forgotPassAdmins.sessionToken }
      );
    }
  }, [data, navigate]);

  return (
    <div>
      {error ? <BaseAlert type={"failed"} message={error.message} /> : null}
      <form onSubmit={handleSubmit(onSubmit)} className={"auth-form"}>
        <div className={"auth-form--logo"}>
          <Logo />
          <p className={"auth-form--title"}>FORGOT PASSWORD</p>
        </div>

        <div className={"auth-form--expired-token-error"}>
          <p className={"auth-form--expired-token-error--title"}>
            {(state as NavigationState)?.title}
          </p>
          <p className={"auth-form--expired-token-error--sub-title"}>
            {(state as NavigationState)?.subTitle}
          </p>
          <span></span>
        </div>

        <BaseInput
          label={{
            text: "Email",
            htmlFor: "name",
          }}
          registerValidation={{
            type: "text",
            name: "email",
            placeholder: "Email...",
            validation: {
              ...register("email", { required: true, maxLength: 10 }),
            },
          }}
          error={errors.email?.message}
        />

        <BaseButton
          loader={loading && <Loader width={30} height={30} />}
          type={"submit"}
          title={"GET CODE"}
          className={"auth-form--login"}
        />

        <div className={"auth-form--forgot-password"}>
          <Link to={"/login"}>Login</Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
