import { GraphQLEnumType } from "graphql";

export interface IUsersItem {
  id: string | number;
  name: string;
  email: string;
  tags?: string[];
  status?: string;
}

export enum UserStatus {
  blocked,
  active,
  deleted,
}

const userStatusTypes = new GraphQLEnumType({
  name: "userStatusTypes",
  values: {
    blocked: { value: "blocked" },
    active: { value: "active" },
  },
});

export default userStatusTypes;
