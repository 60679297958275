import { FC } from "react";
import "../BaseResponsiveItemsPanel.scss";
import { PanelItem } from "../../../../types/types";
import { Button, Space } from "antd";
import TableDropdown from "./TableDropdown";

export interface TableProps {
  data: Array<PanelItem>;
  onSelectItem?: (item: PanelItem) => void;
  onEditItem?: (item: PanelItem, type?: string) => void;
  onDeleteItem?: (item: PanelItem) => void;
}

interface TableActionsProps {
  item: PanelItem;
  onSelectItem?: (item: PanelItem) => void;
  onEditItem?: (item: PanelItem, type?: string) => void;
  onDeleteItem?: (item: PanelItem) => void;
  showEditDeleteActions?: boolean;
  showViewAction?: boolean;
}

export const TableActions: FC<TableActionsProps> = ({
  item,
  onSelectItem,
  onEditItem,
  onDeleteItem,
  showEditDeleteActions = true,
  showViewAction = true,
}) => {
  return (
    <>
      <Space className="table-actions" wrap onClick={(e) => e.stopPropagation()}>
        {showViewAction && (
          <Button
            type="primary"
            onClick={() => {
              if (onSelectItem) {
                onSelectItem(item);
              }
            }}
          >
            View
          </Button>
        )}
        {showEditDeleteActions ? (
          <Space wrap>
            <Button
              type="primary"
              onClick={() => {
                if (onEditItem) {
                  onEditItem(item);
                }
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (onDeleteItem) {
                  onDeleteItem(item);
                }
              }}
              ghost
            >
              Delete
            </Button>
          </Space>
        ) : null}
      </Space>
      <TableDropdown
        item={item}
        onSelectItem={onSelectItem}
        onEditItem={onEditItem}
        onDeleteItem={onDeleteItem}
        showEditDeleteActions={showEditDeleteActions}
        showViewAction={showViewAction}
      />
    </>
  );
};
