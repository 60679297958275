import { FC } from "react";
import { Button, Space } from "antd";
import "../BaseResponsiveItemsPanel.scss";
import { IContactRequest } from "../../../../types/contactRequests";
import { ContactStatus } from "../../../../pages/ContactRequests/ContactRequests";
import "./TableActions.scss";

interface TableProps {
  item: IContactRequest;
  onViewClick: (contact: IContactRequest) => void;
  onUpdateStatus: ({
    status,
    id
  }: {
    status: ContactStatus;
    id: string;
  }) => void;
  onDeleteItem: (item: IContactRequest) => void;

}
const TableActions: FC<TableProps> = ({
  item,
  onViewClick,
  onUpdateStatus,
  onDeleteItem,
}) => {
  return (
    <Space wrap onClick={(e) => e.stopPropagation()}>
      <Button
        type="primary"
        onClick={() => onViewClick(item)}
      >
        View
      </Button>
      {
        item.status !== ContactStatus.REJECTED && (
          <Button
            type="primary"
            onClick={item.status !== ContactStatus.RESOLVED ? () => onUpdateStatus({
              status: ContactStatus.RESOLVED,
              id: `${item.id}`,
            }) : undefined}
            className={item.status === ContactStatus.RESOLVED ? 'btn-success' : ''}
          >
            {item.status === ContactStatus.RESOLVED ? 'Resolved' : 'Resolve'}
          </Button>
        )
      }
      {
        item.status !== ContactStatus.RESOLVED && (
          <Button
            type="primary"
            onClick={item.status !== ContactStatus.REJECTED ? () => onUpdateStatus({
              status: ContactStatus.REJECTED,
              id: `${item.id}`,
            }) : undefined}
            className={item.status === ContactStatus.REJECTED ? 'btn-danger' : ''}
          >
            {item.status === ContactStatus.REJECTED ? 'Rejected' : 'Reject'}
          </Button>
        )
      }
      <Button
        type="primary"
        onClick={() => onDeleteItem(item)}
      >
        Delete
      </Button>
    </Space>
  );
};

export default TableActions;
