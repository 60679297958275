import { CSSProperties, FC, memo, useCallback, useEffect, useMemo } from "react";
import { ITicket } from "../../types/ticket";
import ViewField from "../../components/ViewField/ViewField";
import SubTitle from "../../components/SubTitle/SubTitle";
import { IEventData } from "../../types/eventTypes";
import { ReactComponent as Edit } from "../../assets/images/Edit.svg";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import filesUploadStore from "../../store/filesUploadStore";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import { useQuery } from "@apollo/client";
import { GET_TRANSACTIONS_BY_TICKET_ID } from "../../graphql/query/getTransactionsByTicketId";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import "./TicketView.scss";
import dayjs from "dayjs";

type Props = {
  data: ITicket;
};

type ViewInfo = {
  key: keyof ITicket;
  title: string;
  size?: number;
  action?: (val: any) => string;
  style?: CSSProperties;
};

const TicketView: FC<Props> = ({ data }) => {
  const { ticketId, venueId, eventId } = useParams();
  const navigate = useNavigate();

  const generalInfo = useMemo<Array<ViewInfo>>(
    () => [
      { title: "Title", key: "name" },
      { title: "Event", key: "event", action: (val: IEventData) => val?.name },
      { title: "Description", key: "description", style: { width: "100%" } },
    ],
    []
  );

  const { data: transactionData } = useQuery(GET_TRANSACTIONS_BY_TICKET_ID, {
    variables: {
      input: {
        ticketId: +ticketId!,
      },
    },
    fetchPolicy: "no-cache",
  });

  const transactions = useMemo(
    () => transactionData?.getTransactionsByTicketId.rows || [],
    [transactionData?.getTransactionsByTicketId.rows]
  );

  const priceInfo = useMemo<Array<ViewInfo>>(
    () => [
      {
        title: "Price",
        key: "amount",
        action: (data: ITicket) => `${data.amount} ${data.currency}`,
      },
      { title: "Ticket Count", key: "count", action: (val) => val.toString() },
    ],
    []
  );

  const additionalInfo = useMemo<Array<ViewInfo>>(
    () => [
      {
        title: "Start Time",
        key: "startTime",
        action: (val) => `${dayjs(val).format("DD/MM/YYYY HH:mm")}`,
      },
      {
        title: "End Time",
        key: "endTime",
        action: (val) => `${dayjs(val).format("DD/MM/YYYY HH:mm")}`,
      },
    ],
    []
  );

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Created Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render(value) {
          return `${new Date(value).toLocaleDateString("en-US")}`;
        },
      },
      {
        title: "User",
        dataIndex: "user",
        key: "username",
        render(value) {
          return value?.name;
        },
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Original Price",
        dataIndex: "amount",
        key: "originalPrice",
        render(value, record) {
          if (!!value && !!record?.discount) {
            return `${(value / (100 - record.discount.discount)) * 100} ${record.currency}`;
          }
        },
      },
      {
        title: "Discount Code",
        dataIndex: "discount",
        key: "discountCode",
        render(value) {
          return value?.code;
        },
      },
      {
        title: "Discount Percent",
        dataIndex: "discount",
        key: "discountPrice",
        render(value) {
          return value?.discount ? `${value?.discount}%` : "";
        },
      },
      {
        title: "Final Price",
        dataIndex: "amount",
        key: "finalPrice",
        render(value, record) {
          return `${value} ${record.currency}`;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    ],
    []
  );

  const generateBox = useCallback(
    ({ key, title, size = 3, action, style }: ViewInfo) => {
      let value = data[key];

      if (key === "amount") {
        value = data as any;
      }

      if (action) {
        value = action(value as keyof ITicket);
      }

      return (
        <ViewField
          style={style}
          withSeeMore
          key={key}
          label={title}
          text={value}
          maxLength={1000}
        />
      );
    },
    [data]
  );

  const editTicket = useCallback(() => {
    if (data.id) {
      let path = venueId
        ? `/venues/${venueId}/events/${eventId}/tickets/${data.id.toString()}`
        : `/events/${eventId}/tickets/${data.id.toString()}`;

      navigate({
        pathname: `${path}`,
      });
    }
  }, [data.id, navigate, eventId, venueId]);

  useEffect(() => {
    filesUploadStore.assignMediaFiles(filterMediaTypename(data.media) || []);
  }, [data.media]);

  return (
    <div className={"ticket-view"}>
      <Edit className={"ticket-view--edit"} width={24} height={24} onClick={editTicket} />
      <SubTitle>General Info</SubTitle>
      <div className="row">{generalInfo.map(generateBox)}</div>
      <div className={"divider"} />
      <SubTitle>Price</SubTitle>
      <div className="row">{priceInfo.map(generateBox)}</div>
      <div className={"divider"} />
      <SubTitle>Additional Info</SubTitle>
      <div className="row">{additionalInfo.map(generateBox)}</div>
      <div className={"divider"} />
      <SubTitle>Media</SubTitle>
      {data.id && <ImageVideoUploadCarousel readOnly={true} keyType={"tickets"} id={data.id} />}
      <div className={"divider"} />
      <SubTitle>Transactions</SubTitle>
      <Table dataSource={transactions} pagination={false} columns={columns} />
    </div>
  );
};

export default memo(TicketView);
