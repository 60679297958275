import React from "react";
import { observer } from "mobx-react-lite";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";

interface IVenuesSearch {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}
const AdminsHistorySearch: React.FC<IVenuesSearch> = observer(({ setSearch }) => {
  return (
    <div className={"base-page-header admins-history-actions d-flex-column gap-20"}>
      <BaseSearch handleChange={(value) => setSearch(value)} />
    </div>
  );
});

export default AdminsHistorySearch;
