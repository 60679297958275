import { FC } from "react";
import "./UserItem.scss";
import { IUsersItem } from "../../../types/user";

interface IUserItemProps {
  item: IUsersItem;
}
const UserItem: FC<IUserItemProps> = ({ item }) => {
  return (
    <div className={"user-item"}>
      <div className={"user-item--content"}>
        <div className={"user-item--column"}>
          <span>{item?.name}</span>
        </div>
        <div className={"user-item--column"}>
          <span>{item?.email}</span>
        </div>
        <div className={"user-item--column tags-column"}>
          {item?.tags?.map((tag, index) => (
            <div className={"user-item--hashtag"} key={tag + index}>
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserItem;
