import { gql } from "@apollo/client";

export const GET_DISCOUNTS_LIST = gql`
  query listDiscounts($input: ListDiscountsInput!) {
    listDiscounts(input: $input) {
      count
      discounts {
        id
        name
        description
        code
        discount
        type
        currency
        startDate
        endDate
        deleted
        createdAt
        updatedAt
      }
    }
  }
`;
