import { IMediaItem } from "../types/mediaTypes";

export const filterMediaTypename = (venueMedia: any) => {
  return venueMedia?.map((media: IMediaItem) => {
    return {
      id: media.id,
      contentLength: media.contentLength,
      contentType: media.contentType,
      type: media.type,
      key: media.key,
      fileName: media.fileName,
      isCover: media.isCover,
    };
  });
};
