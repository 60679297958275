import React, { FC, useEffect } from "react";
import "./Modal.scss";
import CloseSvg from "../../assets/images/CloseSvg";

interface ModalProps {
  closeModal: () => void;
  modalIsOpen: boolean;
  children?: React.ReactNode;
  className?: string;
  showCloseButton?: boolean;
}

const Modal: FC<ModalProps> = ({
  showCloseButton,
  closeModal,
  modalIsOpen,
  children,
  className = "",
}) => {
  useEffect(() => {
    if (!modalIsOpen) return;

    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [modalIsOpen]);

  if (!modalIsOpen) {
    return null;
  }
  return (
    <div className={"custom-modal"} onClick={closeModal}>
      <div className={`custom-modal--content ${className}`} onClick={(e) => e.stopPropagation()}>
        {showCloseButton && <CloseSvg className={"custom-modal--close"} onClick={closeModal} />}
        <div className={"custom-modal--children"}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
