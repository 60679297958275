import { IBLog } from "../../types/types";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import { ReactComponent as Edit } from "../../assets/images/Edit.svg";
import BlogInfo from "./BlogInfo";
import "./BlogView.scss";
import filesUploadStore from "../../store/filesUploadStore";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import { checkHasImageExtension } from "../../utils/common";

interface BlogViewProps {
  blog: IBLog;
}

const BlogView: FC<BlogViewProps> = ({ blog }) => {
  const navigate = useNavigate();

  const editVenue = () => {
    navigate({
      pathname: `/blogs/${blog.id.toString()}`,
    });
  };

  useEffect(() => {
    const isImage = checkHasImageExtension(blog?.cover);
    filesUploadStore.assignMediaFiles(
      filterMediaTypename(
        blog?.media || [
          {
            key: blog.cover,
            type: isImage ? "image" : "video",
            isCover: true,
          },
        ]
      ) || []
    );
  }, [blog.media]);

  return (
    <div className={"blog-view"}>
      <h2 className={"blog-view--name"}>{blog.title}</h2>
      {!isAdmin(userStore.user) ? (
        <Edit className={"blog-view--edit"} width={24} height={24} onClick={editVenue} />
      ) : null}
      <div className={"blog-view--top-block"}>
        <BlogInfo blog={blog} />
      </div>
      <div className={"blog-view--bottom-block"}>
        <h2 className={"blog-view--bottom-block--title"}>Img / Video</h2>
        <ImageVideoUploadCarousel readOnly={true} keyType="blogs" id={blog?.id!} />
      </div>
    </div>
  );
};

export default BlogView;
