import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_POST } from "../../graphql/query/getSinglePost";
import { useParams } from "react-router-dom";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import BaseError from "../../components/reusable/baseError/BaseError";
import filesUploadStore from "../../store/filesUploadStore";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import postsStore from "../../store/postsStore";
import { observer } from "mobx-react-lite";
import "./PostView.scss";
import SubTitle from "../../components/SubTitle/SubTitle";
import { IObjectKeys } from "../../types/types";
import { strongCopyData } from "../../utils/strongCopyData";
import HashtagsList from "../../components/reusable/hashtagsList/HashtagsList";

const PostView = observer(() => {
  const { postId } = useParams();
  const [loading, setLoading] = useState(true);
  const [singlePost, setSinglePost] = useState<IObjectKeys>({});
  const [getPost, { error, data: postData }] = useLazyQuery(GET_POST, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    console.log(postData);
  }, [postData]);

  const fetchPost = useCallback(async () => {
    try {
      if (postId) {
        await getPost({
          variables: {
            id: +postId,
          },
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [postId, getPost]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  useEffect(() => {
    if (postData?.getPost) {
      postsStore.addSinglePost(postData.getPost);
      filesUploadStore.assignMediaFiles(filterMediaTypename(postData.getPost.media) || []);
      setSinglePost(postData?.getPost);
    }
  }, [postData]);

  useEffect(() => {
    if (postsStore.post) {
      console.log(JSON.parse(JSON.stringify(postsStore.post)));
    }
  }, [postsStore.post]);
  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <div className={"post-view"}>
      <div className={"d-flex post-view-info"}>
        <div className={"d-flex-column gap-20 mb-20"}>
          <div>
            <SubTitle>Total Likes</SubTitle>
            <p>{singlePost?.totalLikes}</p>
          </div>
          <div>
            <SubTitle>Total Shares</SubTitle>
            <p>{singlePost?.totalShares}</p>
          </div>
          <div>
            <SubTitle>Total Comments</SubTitle>
            <p>{singlePost?.totalComments}</p>
          </div>
          <div>
            <SubTitle>Total</SubTitle>
            <p>{singlePost?.total}</p>
          </div>
        </div>
        <div className={"post-view-info-right mb-20 d-flex-column"}>
          <div>
            <SubTitle>Description</SubTitle>
            <p>{singlePost?.description}</p>
          </div>
          <div>
            <SubTitle>Media</SubTitle>
            <ImageVideoUploadCarousel readOnly={true} keyType={"venues"} id={postsStore.post?.media} />
          </div>
        </div>
      </div>
      <SubTitle>Hashtags</SubTitle>
      <HashtagsList
        isRemovable={false}
        isSelectable={false}
        hideTitle={true}
        selectedHashTags={
          strongCopyData(singlePost?.hashtags)?.map(
            (hashtag: { hashtagId: number }) => hashtag.hashtagId
          ) || []
        }
      />
    </div>
  );
});

export default PostView;
