import { gql } from "@apollo/client";

export const GET_BLOG = gql`
  query getOneBlog($id: Int!) {
    getOneBlog(id: $id) {
      id
      title
      content
      media {
        id
        type
        key
        fileName
        contentType
        contentLength
        isCover
        sizes
      }
      createdAt
      updatedAt
      cover
      isPublished
    }
  }
`;
