import React, { useEffect, useState } from "react";
import { Button, Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { DataType } from "aws-sdk/clients/iottwinmaker";

interface IPostTable {
  data: any;
  onSort: (sorter: any) => void;
  onSelectItem: (post: any) => void;
}

const PostTable: React.FC<IPostTable> = ({ data, onSort, onSelectItem }) => {
  const [dataSource, setDataSource] = useState(data);
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return <p className={"base-responsive-table-name"}>{id}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (id: string, row: any) => {
        return <p className={"base-responsive-table-name"}>{row?.user?.name}</p>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => {
        return (
          <p className={"base-responsive-table-name"}>
            {description || <span>no description</span>}
          </p>
        );
      },
    },
    {
      title: "Likes",
      dataIndex: "totalLikes",
      key: "totalLikes",
      render: (totalLikes: string) => {
        return <p className={"base-responsive-table-name"}>{totalLikes}</p>;
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "Comments",
      dataIndex: "totalComments",
      key: "totalComments",
      render: (totalComments: string) => {
        return <p className={"base-responsive-table-name"}>{totalComments}</p>;
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "Shares",
      dataIndex: "totalShares",
      key: "totalShares",
      render: (totalShares: string) => {
        return <p className={"base-responsive-table-name"}>{totalShares}</p>;
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total: string) => {
        return <p className={"base-responsive-table-name"}>{total}</p>;
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, row) => {
        return (
          <Button
            type={"primary"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelectItem(row);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];
  const handleChange: TableProps<DataType>["onChange"] = (pagination, filters, sorter) => {
    onSort(sorter);
  };

  useEffect(() => {
    setDataSource(data);
  }, [data]);
  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey={"id"}
      onChange={handleChange}
      onRow={(record) => {
        return {
          onClick: () => {
            onSelectItem(record);
          },
        };
      }}
    />
  );
};

export default PostTable;
