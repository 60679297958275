import { gql } from "@apollo/client";

export const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($updateUserStatusArgs: UpdateUserStatusInput!) {
    updateUserStatus(updateUserStatusArgs: $updateUserStatusArgs) {
      id
      name
      status
    }
  }
`;
