import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./Admin.scss";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import Modal from "../../components/Modal/Modal";
import AdminOrStaffModalViews from "../../forms/AdminOrStaffModalForm/AdminOrStaffModalForm";
import { useLazyQuery, useMutation } from "@apollo/client";
import BaseError from "../../components/reusable/baseError/BaseError";
import { GET_ADMIN_LIST } from "../../graphql/query/getAdmins";
import { observer } from "mobx-react-lite";
import adminStore from "../../store/adminStaffStore";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import { IAdminOrStaffItem } from "../../types/adminOrstaff";
import venueStore from "../../store/venueStore";
import { useDebounce } from "../../hooks/useDebounce";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import NoData from "../../components/reusable/noData/NoData";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { DELETE_ADMIN } from "../../graphql/mutations/deleteAdmin";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import { Button } from "antd";
import AdminTable from "../../components/reusable/baseResponsiveItemsPanel/adminTable/AdminTable";

type argsOption = {
  limit: number;
  offset: number;
  query?: string;
};

const Admin = observer(() => {
  const [search, setSearch] = useState<string>("");
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [editAdminCandidate, setEditAdminCandidate] = useState<IAdminOrStaffItem | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const debounced = useDebounce(search);

  const [getAdmins, { loading, error, data }] = useLazyQuery(GET_ADMIN_LIST, {
    fetchPolicy: "no-cache",
  });

  const [removeAdmin] = useMutation(DELETE_ADMIN);

  const openAdminModal = (admin?: IAdminOrStaffItem) => {
    admin && setEditAdminCandidate(admin);
    setIsAdminModalOpen(true);
  };

  const closeAdminModal = () => {
    setIsAdminModalOpen(false);
    setEditAdminCandidate(null);
  };

  const openOrCloseDeleteModal = (admin?: IAdminOrStaffItem) => {
    admin && setEditAdminCandidate(admin);
    setIsOpenDeleteModal((prev) => !prev);
    setIsAdminModalOpen(false);
  };

  const deleteAdminConfirm = async () => {
    const { data } = await removeAdmin({
      variables: {
        id: editAdminCandidate?.id,
      },
    });
    openOrCloseDeleteModal();
    calculatePagination(data?.removeAdmin?.id);
  };

  const calculatePagination = (id: number) => {
    if (
      adminStore.offset > 0 &&
      (adminStore.offset / adminStore.limit + 1 <
        Math.ceil(adminStore.allDataListCount / adminStore.limit) ||
        adminStore.dataList.length === 1)
    ) {
      getAdminsList(adminStore.dataList.length);
    } else {
      adminStore.removeData(id);
    }
  };

  const changePage = (page: number) => {
    adminStore.addOffset((page - 1) * adminStore.limit);
    getAdminsList();
  };

  const getAdminsList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset = count === 1 ? adminStore.offset - adminStore.limit : adminStore.offset;

      const args: argsOption = {
        limit: venueStore.limit,
        offset: newOffset,
      };

      if (debounced) {
        args.query = debounced;
      }

      getAdmins({
        variables: {
          getAdminsArgs: args,
        },
      });
    },
    [getAdmins]
  );

  const fetchAdmin = useCallback(async () => {
    await adminStore.resetPagination();
    if (debounced.length) {
      getAdminsList(null, debounced);
    } else {
      getAdminsList();
    }
  }, [debounced, getAdminsList]);

  useEffect(() => {
    if (data) {
      adminStore.addDataList(data.getAdmins.admins);
      adminStore.addAllDataListCount(data.getAdmins.count);
    }
  }, [data]);

  useLayoutEffect(() => {
    adminStore.resetDataList();
    fetchAdmin();
  }, [debounced, fetchAdmin]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  const adminSearch = (
    <div className={"base-page-header space-between gap-20"}>
      <BaseSearch handleChange={(value) => setSearch(value)} />
      <div className={"d-flex-aligned-end"}>
        <Button type="primary" size="large" onClick={() => openAdminModal()}>
          Add Admin
        </Button>
      </div>
    </div>
  );

  return (
    <div className={"admin-page"}>
      {isAdminModalOpen ? (
        <Modal
          closeModal={closeAdminModal}
          modalIsOpen={isAdminModalOpen}
          className={"admin-page--modal"}
        >
          <AdminOrStaffModalViews
            type={"admin"}
            closModal={closeAdminModal}
            editAdminOrStaffCandidate={editAdminCandidate}
            getAdminsOrStaffList={getAdminsList}
          />
        </Modal>
      ) : null}
      {isOpenDeleteModal && (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteAdminConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this admin?"}
        />
      )}

      <div className={"admin-page--container"}>
        {loading || !data ? (
          <BaseLoader />
        ) : (
          <>
            <>
              <PageContentLayout
                className={"d-flex-column"}
                actions={adminSearch}
                view={
                  <>
                    {data?.getAdmins.admins.length ? (
                      <>
                        <AdminTable
                          data={adminStore.dataList}
                          onEditItem={(admin) => openAdminModal(admin as IAdminOrStaffItem)}
                          onDeleteItem={(admin) =>
                            openOrCloseDeleteModal(admin as IAdminOrStaffItem)
                          }
                        />
                        {/*<BaseTable*/}
                        {/*  tableData={adminStore.dataList}*/}
                        {/*  type={"admin"}*/}
                        {/*  onHandleClick={(admin) => openAdminModal(admin as IAdminOrStaffItem)}*/}
                        {/*  onHandleDelete={(admin) => openOrCloseDeleteModal(admin as IAdminOrStaffItem)}*/}
                        {/*/>*/}
                        <BasePagination
                          totalPages={Math.ceil(adminStore.allDataListCount / adminStore.limit)}
                          currentPage={adminStore.offset / adminStore.limit + 1}
                          changePage={changePage}
                        />
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                }
              />
            </>
          </>
        )}
      </div>
    </div>
  );
});

export default Admin;
