import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseAlert from "components/reusable/baseAlert/BaseAlert";
import BaseButton from "components/reusable/baseButton/BaseButton";
import BaseCreateOrDeleteModalLoading from "components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseInput from "components/reusable/baseInput/BaseInput";
import { CREATE_VENUE_STREAM } from "graphql/mutations/createVenueStream";
import { UPDATE_VENUE_STREAM } from "graphql/mutations/updateVenueStream";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { IStream } from "types/venue";
import * as yup from "yup";

interface EditStreamModalFormProps {
  venueId?: string;
  closeModal: () => void;
  streamItem?: IStream | null;
  refetch: Function;
}

const EditStreamModalForm: FC<EditStreamModalFormProps> = ({
  venueId,
  streamItem,
  closeModal,
  refetch,
}) => {
  const [create, { loading: createLoading, error: createError }] = useMutation(CREATE_VENUE_STREAM);
  const [update, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_VENUE_STREAM);

  const handleCreate = useCallback(
    (data: any) =>
      create({ variables: { input: { ...data, venueId: Number(venueId) } } }).then(() => {
        refetch();
        closeModal();
      }),
    [closeModal, create, refetch, venueId]
  );

  const handleEdit = useCallback(
    ({ __typename, ...data }: any) =>
      update({ variables: { updateVenueStreamInput: { ...data, venueId: Number(venueId) } } }).then(
        () => {
          refetch();
          closeModal();
        }
      ),
    [closeModal, refetch, update, venueId]
  );

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    streamUrl: yup.string().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<IStream, "id">>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...streamItem },
  });

  return (
    <form
      onSubmit={handleSubmit(streamItem ? handleEdit : handleCreate)}
      className={"discount-form"}
    >
      {createLoading || updateLoading ? <BaseCreateOrDeleteModalLoading /> : null}
      {createError || updateError ? (
        <BaseAlert type={"failed"} message={createError?.message || updateError?.message} />
      ) : null}
      <BaseInput
        newClassName={"modal-input"}
        label={{
          text: "Name",
          htmlFor: "name",
          className: "required"
        }}
        registerValidation={{
          type: "text",
          name: "name",
          placeholder: "",
          validation: {
            ...register("name"),
          },
        }}
        error={errors.name?.message}
      />
      <BaseInput
        newClassName={"modal-input"}
        label={{
          text: "Stream URL",
          htmlFor: "streamUrl",
          className: "required"
        }}
        registerValidation={{
          type: "text",
          name: "streamUrl",
          placeholder: "",
          validation: {
            ...register("streamUrl"),
          },
        }}
        error={errors.name?.message}
      />
      <div className={"discount-form--btn"}>
        <BaseButton type={"submit"} title={streamItem ? "Update Stream" : "Add Stream"} />
      </div>
    </form>
  );
};

export default EditStreamModalForm;
