import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import postsStore from "../../store/postsStore";
import { useLazyQuery } from "@apollo/client";
import { GET_POSTS } from "../../graphql/query/getPostsList";
import eventsStore from "../../store/eventsStore";
import { useDebounce } from "../../hooks/useDebounce";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import PostTable from "../../components/reusable/baseResponsiveItemsPanel/postsTable/PostTable";
import NoData from "../../components/reusable/noData/NoData";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import BaseError from "../../components/reusable/baseError/BaseError";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const Posts = observer(() => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  type argsOption = {
    limit?: number;
    offset: number;
    searchValue?: string;
    sortBy?: string;
    orderType?: string;
  };
  const [getPosts, { loading, error: getPostsError, data: postsList }] = useLazyQuery(GET_POSTS, {
    fetchPolicy: "no-cache",
  });
  const debounced = useDebounce(search);
  const getPostsList = useCallback(
    (count?: number | null, debounced?: string, sortKey?: string, orderType?: string) => {
      const newOffset = count === 1 ? postsStore.offset - postsStore.limit : postsStore.offset;
      const args: argsOption = {
        offset: newOffset,
        limit: postsStore.limit,
      };
      if (debounced) {
        args.searchValue = debounced;
      }
      if (orderType) {
        args.orderType = orderType;
        if (sortKey) {
          args.sortBy = sortKey;
        }
      }
      getPosts({
        variables: {
          listPostsArgs: args,
        },
      });
    },
    [getPosts]
  );

  const onSort = useCallback(
    (sorter: any) => {
      if (!sorter.order) {
        getPostsList(postsStore.offset, debounced, sorter.columnKey);
        postsStore.addSortOptions({});
      } else {
        switch (sorter.order) {
          case "ascend":
            getPostsList(postsStore.offset, debounced, sorter.columnKey, "ASC");
            postsStore.addSortOptions({ columnKey: sorter.columnKey, order: "ASC" });
            break;
          case "descend":
            getPostsList(postsStore.offset, debounced, sorter.columnKey, "DESC");
            postsStore.addSortOptions({ columnKey: sorter.columnKey, order: "DESC" });
            break;
          default:
            break;
        }
      }
    },
    [debounced, getPostsList]
  );

  const fetchEvents = useCallback(async () => {
    await eventsStore.resetPagination();
    if (debounced.length) {
      getPostsList(null, debounced);
    } else {
      getPostsList();
    }
  }, [debounced, getPostsList]);

  const changePage = useCallback(
    (page: number) => {
      postsStore.addOffset((page - 1) * postsStore.limit);
      if (Object.keys(postsStore?.sortOptions)?.length) {
        getPostsList(
          null,
          debounced,
          postsStore.sortOptions.columnKey,
          postsStore.sortOptions.order
        );
      } else {
        getPostsList(null, debounced);
      }
    },
    [postsStore.sortOptions]
  );

  const goToCurrentPost = (post: any) => {
    const pathname = `/posts/view/${post.id}`;
    navigate({
      pathname,
    });
  };

  useEffect(() => {
    postsStore.resetPosts();
    fetchEvents();
  }, [debounced, fetchEvents]);

  useEffect(() => {
    if (postsList?.listPosts?.posts) {
      postsStore.addPosts(postsList.listPosts.posts);
      postsStore.addAllPostsTotalCount(postsList.listPosts.count);
    }
  }, [postsList?.listPosts]);

  useEffect(() => {
    return () => {
      postsStore.resetPosts();
      postsStore.resetPagination();
    };
  }, []);

  const searchField = useMemo(
    () => (
      <div className={"base-page-header d-flex-column gap-20"}>
        <BaseSearch handleChange={(value) => setSearch(value)} />
      </div>
    ),
    []
  );

  if (getPostsError) {
    return <BaseError>{getPostsError.message}</BaseError>;
  }

  return (
    <div>
      <PageContentLayout
        view={
          <div className={"posts-page"}>
            {searchField}
            {loading && !postsStore.postsList?.length ? (
              <BaseLoader />
            ) : (
              <>
                {postsStore.postsList ? (
                  <>
                    <PostTable
                      data={postsStore.postsList}
                      onSort={onSort}
                      onSelectItem={goToCurrentPost}
                    />
                    <div className={"mt-40"}>
                      <BasePagination
                        totalPages={Math.ceil(postsStore.postTotalCount / postsStore.limit)}
                        currentPage={postsStore.offset / postsStore.limit + 1}
                        changePage={changePage}
                      />
                    </div>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        }
      />
    </div>
  );
});
export default Posts;
