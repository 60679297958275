import React, { FC, useCallback, useEffect } from "react";
import { GET_HASHTAGS_LIST } from "../../../graphql/query/getHashtags";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IHashtag } from "../../../types/hashtagsTypes";
import "./HashtagsList.scss";
import { REMOVE_HASHTAG_FROM_FEED } from "../../../graphql/mutations/removeHashtagFromFeed";
import { REMOVE_HASHTAG } from "../../../graphql/mutations/removeHashtag";
import BaseAlert from "../baseAlert/BaseAlert";
import { LINK_HASHTAG_TO_FEED } from "../../../graphql/mutations/linkHashtagsToFeed";
import hashtagsStore from "../../../store/hashtagsStore";
import { observer } from "mobx-react-lite";
import { ReactComponent as Remove } from "../../../assets/images/Remove.svg";
import BaseLoader from "../baseLoader/BaseLoader";

export interface IHashtagsProps {
  searchText?: string;
  isRemovable: boolean;
  isSelectable: boolean;
  setSelectedHashTags?: (data: Array<string>) => void;
  startAction?: boolean;
  feedEventId?: string | number;
  selectedHashTags: Array<string>;
  hideTitle?: boolean;
}

type argsOption = {
  limit: number;
  offset: number;
  search?: string;
};

const HashtagsList: FC<IHashtagsProps> = observer(
  ({
    searchText,
    feedEventId,
    isRemovable,
    isSelectable,
    selectedHashTags,
    setSelectedHashTags,
    hideTitle = false,
  }) => {
    const [removeHashtag, { error: errorRemoveHashtag }] = useMutation(REMOVE_HASHTAG);
    const [removeHashtagFromFeed, { error: errorRemoveHashtagFromFeed }] =
      useMutation(REMOVE_HASHTAG_FROM_FEED);

    const [linkHashtagsToFeed, { error: errorAddHashtags }] = useMutation(LINK_HASHTAG_TO_FEED);
    const [getHashtags, { data: hashtagsGetData, loading }] = useLazyQuery(GET_HASHTAGS_LIST, {
      fetchPolicy: "network-only",
    });

    const handleRemoveHashtag = async (id: string) => {
      await removeHashtag({
        variables: {
          id,
        },
      });
      hashtagsStore.removeHashtagFromList(id);
    };

    const addOrRemoveHashtag = (hashtagId: string) => {
      if (selectedHashTags.some((selectHashtagId) => selectHashtagId === hashtagId)) {
        setSelectedHashTags!(
          selectedHashTags.filter((selectHashtagId) => selectHashtagId !== hashtagId)
        );
        return true;
      } else {
        setSelectedHashTags!([...selectedHashTags, hashtagId]);
        return false;
      }
    };

    const updateHashtag = async (hashtagId: string) => {
      const isHashtag = addOrRemoveHashtag(hashtagId);
      if (isHashtag) {
        await removeHashtagFromFeed({
          variables: {
            removeHashtagFromFeedInput: {
              hashtagId: hashtagId,
              feedId: feedEventId,
            },
          },
        });
      } else {
        await linkHashtagsToFeed({
          variables: {
            linkHashtagsToFeedInput: {
              feedId: feedEventId,
              hashtagIds: [hashtagId],
            },
          },
        });
      }
    };

    const handleSelectTag = (hashtagId: string) => {
      addOrRemoveHashtag(hashtagId);
    };

    const handleClickHashtag = (id: string) => {
      if (feedEventId) {
        updateHashtag(id);
      } else {
        handleSelectTag(id);
      }
    };

    const getHashtagsList = useCallback(
      (searchText?: string) => {
        const args: argsOption = {
          limit: hashtagsStore.limit,
          offset: hashtagsStore.offset,
        };
        if (searchText) {
          args.search = searchText;
        }
        getHashtags({
          variables: {
            getHashtagsArgs: args,
          },
        });
        hashtagsStore.addOffset(hashtagsStore.offset + hashtagsStore.limit);
      },
      [getHashtags]
    );

    useEffect(() => {
      if (hashtagsGetData) {
        hashtagsStore.addHashtags(hashtagsGetData.listHashtags.hashtags);
        hashtagsStore.addHashtagsCount(hashtagsGetData.listHashtags.count);
      }
    }, [hashtagsGetData]);

    useEffect(() => {
      hashtagsStore.resetHashtags();
      hashtagsStore.resetPagination();
      if (searchText) {
        getHashtagsList(searchText);
      } else {
        getHashtagsList();
      }
    }, [getHashtagsList, searchText]);

    if (loading) {
      return (
        <div className={"hashtags-list"}>
          <div className={"hashtags-list--content"}>
            <BaseLoader />
          </div>
        </div>
      );
    }

    return (
      <div className={"hashtags-list"}>
        {(errorRemoveHashtagFromFeed || errorAddHashtags || errorRemoveHashtag) && (
          <BaseAlert type={"failed"} message={"Error"} />
        )}
        {!isRemovable && !hideTitle && <div className={"hashtags-list--title"}>HASHTAGS</div>}
        <div className={"hashtags-list--content"}>
          {hashtagsStore.hashtagsList?.map((hashTag: IHashtag, index) => (
            <div className={"hashtags-list--hashtag-block"} key={index}>
              <div
                className={`hashtags-list--hashtag ${
                  isSelectable ? "hashtags-list--hashtag--selectable" : ""
                } ${
                  selectedHashTags?.includes(hashTag.id) ? "hashtags-list--hashtag--selected" : ""
                }`}
                onClick={() => isSelectable && handleClickHashtag(hashTag?.id)}
              >
                <div>{hashTag?.name}</div>
                {isRemovable && (
                  <div
                    onClick={() => handleRemoveHashtag(hashTag.id)}
                    className={"hashtags-list--remove-icon"}
                  >
                    <Remove />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {hashtagsStore.allHashtagsCount > hashtagsStore.hashtagsList?.length && (
          <div className={"hashtags-list--load-more"}>
            <button
              type={"button"}
              className={"hashtags-list--load-more--btn"}
              onClick={() => getHashtagsList()}
            >
              Load more
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default HashtagsList;
