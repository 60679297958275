import React, { FC, useCallback, useLayoutEffect, useState } from "react";
import "./VenueDaysForm.scss";
import {
  FieldErrors,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import VenueDaysItem from "./VenueDaysItem";
import { IVenue } from "../../../types/venue";

interface VenueDaysFormProps {
  venue?: IVenue;
  register: UseFormRegister<IVenue>;
  clearErrors: UseFormClearErrors<IVenue>;
  getValues: UseFormGetValues<IVenue>;
  isDisabled: boolean;
  errors: FieldErrors<IVenue>;
  update: UseFieldArrayUpdate<IVenue, "workingHours">;
  replace: UseFieldArrayReplace<IVenue, "workingHours">;
}

const VenueDaysForm: FC<VenueDaysFormProps> = ({
  venue,
  replace,
  register,
  clearErrors,
  getValues,
  update,
  isDisabled,
  errors,
}) => {
  const [workingHours] = useState(
    venue?.workingHours || [
      {
        day: "Monday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
      {
        day: "Tuesday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
      {
        day: "Wednesday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
      {
        day: "Thursday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
      {
        day: "Friday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
      {
        day: "Saturday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
      {
        day: "Sunday",
        openingTime: "",
        closingTime: "",
        isOpen: true,
      },
    ]
  );
  const handleDaysTime = useCallback(
    (time: { id: number; value: string; type?: string }, dayIndex: number) => {
      const workingHours = getValues("workingHours");
      const newWorkingHours = [...workingHours];
      const changeTime = time.type === "openingTime" ? "openingTime" : "closingTime";
      newWorkingHours[dayIndex][changeTime] = time.value;
      clearErrors(`workingHours.${dayIndex}.${changeTime}`);
    },
    [clearErrors, getValues]
  );

  useLayoutEffect(() => {
    replace(workingHours);
  }, [replace, workingHours]);

  return (
    <div className={`venue-days-form ${isDisabled ? "disabled-element" : ""}`}>
      <p className={"venue-days-form--error"}>
        {errors.workingHours && "Please fill in the required fields."}
      </p>
      {workingHours.map((workingHour, index) => {
        return (
          <VenueDaysItem
            key={workingHour.day}
            workingHour={workingHour}
            index={index}
            errors={errors}
            register={register}
            clearErrors={() => clearErrors(`workingHours.${index}`)}
            handleDaysTime={handleDaysTime}
            getValues={getValues}
            update={update}
          />
        );
      })}
    </div>
  );
};

export default VenueDaysForm;
