import { gql } from "@apollo/client";

export const GET_EVENT_REQUESTS_LIST = gql`
  query listEventRequests($listEventReqsArgs: ListEventReqsInput!) {
    listEventRequests(listEventReqsArgs: $listEventReqsArgs) {
      count
      eventRequests {
        id
        venueId
        creatorId
        eventId
        status
        createdAt
        updatedAt
        event {
          id
          venueId
          name
          description
          address
          media {
            type
            key
          }
        }
      }
    }
  }
`;
