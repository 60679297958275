import { PanelItem } from "../../../../types/types";
import { Button, Space } from "antd";
import { IEventRequest } from "../../../../types/eventRequestTypes";

export interface EventRequestsTableProps {
  data: Array<IEventRequest>;
  onSelectItem?: (item: PanelItem) => void;
  onAcceptItem?: (item: PanelItem) => void;
  onRejectItem?: (item: PanelItem) => void;
}
export const EventRequestsTableActions = (
  item: any,
  onSelectItem?: (item: PanelItem) => void,
  onAcceptItem?: (item: PanelItem, type?: string) => void,
  onRejectItem?: (item: PanelItem) => void
) => {
  return (
    <Space
      wrap
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Button
        type="primary"
        onClick={(e) => {
          if (onSelectItem) {
            onSelectItem(item);
          }
        }}
      >
        View
      </Button>
      {item.status === "pending" && (
        <>
          <Button
            type="primary"
            onClick={(e) => {
              if (onAcceptItem) {
                onAcceptItem(item);
              }
            }}
          >
            Accept
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              if (onRejectItem) {
                onRejectItem(item);
              }
            }}
            ghost
          >
            Reject
          </Button>
        </>
      )}
    </Space>
  );
};
