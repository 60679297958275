import { gql } from "@apollo/client";

export const CREATE_HASHTAG = gql`
  mutation createHashtagInput($createHashtagInput: CreateHashtagInput!) {
    createHashtag(createHashtagInput: $createHashtagInput) {
      id
      name
    }
  }
`;
