import { makeAutoObservable } from "mobx";
import { IDiscount } from "../types/discount";

export interface IDiscountsStore {
  discountsList: IDiscount[];
  discount: IDiscount | null;
  limit: number;
  offset: number;
  discountsTotalCount: number;
}

class DiscountsStore implements IDiscountsStore {
  limit = 12;
  offset = 0;
  discountsTotalCount = 0;
  discountsList = [];
  discount: IDiscount | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addAllDiscountsTotalCount(count: number) {
    this.discountsTotalCount = count;
  }

  addDiscounts(discountsData: []) {
    this.discountsList = discountsData;
  }

  selectCurrentDiscount(discount: IDiscount) {
    this.discount = discount;
  }

  removeDiscount(discountId: string | number) {
    this.discountsList = this.discountsList.filter(
      (discountData: IDiscount) => discountData.id !== discountId
    );
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetDiscounts() {
    this.discountsList = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new DiscountsStore();
