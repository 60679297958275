import { gql } from "@apollo/client";

export const GET_REPORTED_POST_ANALYTICS = gql`
  query getReportedPostAnalytics {
    getReportedPostAnalytics {
      abuse
      violence
      spam
      hate_speech
      nudity
      other
    }
  }
`;
