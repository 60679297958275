import { memo } from "react";
import { Table, Tag } from "antd";
import { BASE_URL_IMG } from "../../../../constants/Api";
import pub from "../../../../assets/images/pub.png";
import { EventRequestsTableActions, EventRequestsTableProps } from "./EventRequestsTableActions";
import { EventReqStatusEnum, IEventRequest } from "../../../../types/eventRequestTypes";
import { capitalizeFirstLetter } from "../../../../utils/common";

const EventStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "warning";
    case "accepted":
      return "success";
    case "rejected":
      return "error";
  }
};

const EventRequestsTable = ({
  data,
  onSelectItem,
  onAcceptItem,
  onRejectItem,
}: EventRequestsTableProps) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: number) => {
        return <p className={"base-responsive-table-id"}>{id}</p>;
      },
    },
    {
      title: "Event ID",
      dataIndex: "event",
      key: "eventId",
      render: (data: any, row: IEventRequest) => {
        return <p className={"base-responsive-table-id"}>{row.event?.id}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "event",
      key: "name",
      render: (data: any, row: IEventRequest) => {
        return <p className={"base-responsive-table-name"}>{row.event?.name}</p>;
      },
    },
    {
      title: "Image",
      dataIndex: "event",
      key: "cover",
      render: (data: any, row: IEventRequest) => {
        return (
          <div className={"base-responsive-table-image"}>
            <img src={row.event?.cover ? BASE_URL_IMG + row.event.cover : pub} alt="table_image" />
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "event",
      key: "address",
      render: (data: any, row: IEventRequest) => {
        return <p className={"base-responsive-table-name"}>{row.event?.address}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: EventReqStatusEnum) => {
        return (
          <>
            {status ? (
              <Tag color={EventStatusColor(status)}>{capitalizeFirstLetter(status)}</Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return EventRequestsTableActions(item, onSelectItem, onAcceptItem, onRejectItem);
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
      onRow={(record) => {
        return {
          onClick: () => {
            onSelectItem && onSelectItem(record);
          },
        };
      }}
    />
  );
};

export default memo(EventRequestsTable);
