import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { IUsersItem } from "../../types/user";
import { DELETE_REPORTED_FEED } from "../../graphql/mutations/deleteReportedPost";
import { IFeed, IReportType } from "../../types/reportedPostTypes";
import ReportMessages from "./components/ReportMessages";
import { IGNORE_REPORTED_FEED } from "../../graphql/mutations/ignoreReportedPost";
import { observer } from "mobx-react-lite";
import "./ReportedPostForm.scss";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";

interface ReportedPostFormProps {
  reportedPost: {
    id: number;
    type: string;
    reason: string;
    reports: IReportType[];
    reportCount: number;
    feed: IFeed;
    user: IUsersItem;
  };
}

const ReportedPostForm: FC<ReportedPostFormProps> = observer(({ reportedPost }) => {
  const [
    deleteReportedPost,
    {
      data: deleteReportedPostData,
      loading: deleteReportedPostLoading,
      error: deleteReportedPostError,
    },
  ] = useMutation(DELETE_REPORTED_FEED);

  const [
    ignoreReportedPost,
    {
      data: ignoreReportedPostData,
      loading: ignoreReportedPostLoading,
      error: ignoreReportedPostError,
    },
  ] = useMutation(IGNORE_REPORTED_FEED);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenIgnoreModal, setIsOpenIgnoreModal] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState<string | number>(reportedPost?.feed?.cover || "");
  const navigate = useNavigate();

  const openOrCloseDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };

  const openOrCloseIgnoreModal = () => {
    setIsOpenIgnoreModal((prev) => !prev);
  };

  const ignoreReportedPostConfirm = () => {
    const reportedPostId = reportedPost?.id;
    if (reportedPostId) {
      ignoreReportedPost({
        variables: {
          id: reportedPostId,
        },
      });
    }
  };

  const deleteReportedPostConfirm = async (reason: string) => {
    const reportedPostId = reportedPost?.feed?.id;
    if (reportedPostId) {
      await deleteReportedPost({
        variables: {
          deleteReportedFeedArgs: {
            feedId: reportedPostId,
            reason,
          },
        },
      });
    }
  };

  const getReportMessages = () => {
    let otherReports = reportedPost?.reports.find((reportType) => reportType.type === "OTHER");
    if (otherReports && otherReports?.otherReasonMessages) {
      return otherReports.otherReasonMessages;
    }
    return [];
  };
  const updateCover = useCallback((cover: string | number) => {
    setCoverImage(cover);
  }, []);

  useEffect(() => {
    if (deleteReportedPostData || ignoreReportedPostData) {
      navigate("/reports");
    }
  }, [deleteReportedPostData, ignoreReportedPostData, navigate]);

  return (
    <div>
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          rejectHandle={deleteReportedPostConfirm}
          buttonText={"Delete"}
          isReject={true}
          title={"Are you sure you want to delete reported post?"}
        />
      ) : null}
      {isOpenIgnoreModal ? (
        <ConfirmationModal
          isOpen={isOpenIgnoreModal}
          closeModal={openOrCloseIgnoreModal}
          deleteHandle={ignoreReportedPostConfirm}
          buttonText={"Ignore"}
          title={"Are you sure to ignore all reports?"}
        />
      ) : null}
      {ignoreReportedPostLoading || deleteReportedPostLoading ? (
        <BaseCreateOrDeleteModalLoading />
      ) : null}
      {ignoreReportedPostError || deleteReportedPostError ? (
        <BaseAlert
          type={"failed"}
          message={ignoreReportedPostError?.message || deleteReportedPostError?.message}
        />
      ) : null}
      <div className={"reported-post-page"}>
        <div className={"reported-post-page-header"}>
          <span className={"reported-post-page-header-title"}>Report</span>
          <span className={"reported-post-page-header-reports-count"}>
            Post reported - <span className={"reports-count"}>{reportedPost?.reportCount}</span>
            times
          </span>
        </div>
        <div className={"reported-post-page-content"}>
          <div className={"reported-post-page-content-reasons"}>
            <div className={"reported-post-page-content-reasons-title"}>Reported reasons</div>
            <div className={"reported-post-page-content-reasons-types"}>
              {reportedPost?.reports &&
                reportedPost.reports.map(({ reason, count }, index) => (
                  <div key={index} className={"reported-post-page-content-reasons-types-item"}>
                    {reason.toLowerCase()} - {count}
                  </div>
                ))}
            </div>
            <div className={"reported-post-page-content-reasons-others-title"}>Other Reasons</div>
            <div className={"reported-post-page-content-reasons-others-reports"}>
              {reportedPost?.reports.length && (
                <ReportMessages reportMessages={getReportMessages()} />
              )}
            </div>
          </div>
          <div className={"reported-post-page-content-post-details"}>
            <div className={"reported-post-page-content-post-details-title"}>POST</div>
            <div className={"reported-post-page-content-post-details-media"}>
              {/*<ImageVideoUploadCarousel*/}
              {/*  readOnly={true}*/}
              {/*  updateCover={updateCover}*/}
              {/*  cover={coverImage}*/}
              {/*  media={reportedPost?.feed?.media}*/}
              {/*  id={reportedPost?.feed?.id}*/}
              {/*  startUpload={false}*/}
              {/*  uploadFor={""}*/}
              {/*/>*/}
            </div>
            <div className={"reported-post-page-content-post-details-description"}>
              <div className={"reported-post-page-content-post-details-description-label"}>
                Reported Post Description
              </div>
              <div className={"reported-post-page-content-post-details-description-text"}>
                {reportedPost?.feed?.description}
              </div>
            </div>
            <div className={"reported-post-page-content-post-details-author"}>
              <div className={"reported-post-page-content-post-details-author-label"}>Author</div>
              <div className={" reported-post-page-content-post-details-author-name"}>
                {reportedPost?.user?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"reported-post-page-button"}>
        <BaseButton
          onClick={openOrCloseIgnoreModal}
          type={"button"}
          title={"Ignore"}
          className={"ignore-button"}
        />
        <BaseButton
          onClick={openOrCloseDeleteModal}
          type={"button"}
          title={"Delete post"}
          className={"delete-button outlined"}
        />
      </div>
    </div>
  );
});

export default ReportedPostForm;
