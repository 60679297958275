import { gql } from "@apollo/client";

export const GET_STAFF_LIST = gql`
  query listVenueStaff($listVenueStaffArgs: ListVenueStaffArgs!) {
    listVenueStaff(listVenueStaffArgs: $listVenueStaffArgs) {
      count
      venueStaffs {
        id
        name
        phone
        position
        email
      }
    }
  }
`;
