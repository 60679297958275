import { makeAutoObservable } from "mobx";
import { IVenue } from "../types/venue";
import { strongCopyData } from "../utils/strongCopyData";

export interface IVenueStore {
  venues: IVenue[];
  venue: IVenue | null;
  allVenuesCount: number;
  limit: number;
  offset: number;
}

class VenueStore implements IVenueStore {
  limit = 12;
  offset = 0;
  allVenuesCount = 0;
  venues = [];
  venue: IVenue | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addVenues(admins: []) {
    this.venues = admins;
  }

  selectCurrentVenue(venue: IVenue) {
    this.venue = venue;
  }

  addAllVenuesCount(count: number) {
    this.allVenuesCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetVenues() {
    this.venues = [];
  }

  removeVenue(id: string | number) {
    this.venues = strongCopyData(this.venues).filter((a: IVenue) => a.id !== id);
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new VenueStore();
