import { makeAutoObservable, observable } from "mobx";
import { ITicket } from "../types/ticket";

export interface ITicketsStore {
  ticketsList: ITicket[];
  ticket: ITicket | null;
  limit: number;
  offset: number;
  ticketsTotalCount: number;
}

class TicketsStore implements ITicketsStore {
  limit = 12;
  offset = 0;
  ticketsTotalCount = 0;
  ticketsList = [];
  @observable ticket: ITicket | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addAllTicketsTotalCount(count: number) {
    this.ticketsTotalCount = count;
  }

  addTickets(ticketsData: []) {
    this.ticketsList = ticketsData;
  }

  selectCurrentTicket(ticket: ITicket) {
    this.ticket = ticket;
  }

  removeTicket(ticketId: string | number) {
    this.ticketsList = this.ticketsList.filter((ticketData: ITicket) => ticketData.id !== ticketId);
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetTickets() {
    this.ticketsList = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new TicketsStore();
