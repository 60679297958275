import { FC } from "react";
import "./AdminOrStaffItem.scss";
import CheckSvg from "../../../assets/images/CheckSvg";
import { ReactComponent as Edit } from "../../../assets/images/Edit.svg";
import { ReactComponent as Delete } from "../../../assets/images/RecycleBin.svg";
import { IAdminOrStaffItem } from "../../../types/adminOrstaff";
import BaseButton from "../baseButton/BaseButton";
import BaseCheckbox from "../baseCheckbox/BaseCheckbox";
import staffStore from "../../../store/adminStaffStore";

interface AdminItemProps {
  item: IAdminOrStaffItem;
  onHandleClick?: (item: IAdminOrStaffItem, type?: string) => void;
  onHandleDelete?: (item: IAdminOrStaffItem, type?: string) => void;
  type: string;
  isStaffEmailTable?: boolean;
}
const AdminOrStaffItem: FC<AdminItemProps> = ({
  item,
  onHandleClick,
  type,
  isStaffEmailTable,
  onHandleDelete,
}) => {
  const handleSelectStaff = () => {
    staffStore.addStaffListId(item.id);
  };

  return (
    <div className={"admin-item"}>
      <div className={"admin-item--content"}>
        <div className={"admin-item--column"}>
          <span className={"admin-item--column-content"}>{item?.name}</span>
        </div>

        <div className={"admin-item--column"}>
          <span className={"admin-item--column-content"}>{item?.email}</span>
        </div>

        {type === "staff" ? (
          <>
            <div className={"admin-item--column"}>
              <span className={"admin-item--column-content"}>{item?.phone}</span>
            </div>
            <div className={"admin-item--column"}>
              <span className={"admin-item--column-content"}>{item?.position}</span>
            </div>
          </>
        ) : null}

        {/* {type === "admin" ? (
          <>
            <div className={"admin-item--column"}>
              {item?.permissions?.includes(2) ? (
                <span className={"admin-item--column-management"}>
                  Venues Management <CheckSvg />
                </span>
              ) : null}
            </div>
            <div className={"admin-item--column"}>
              {item?.permissions?.includes(1) ? (
                <span className={"admin-item--column-management"}>
                  Event Management <CheckSvg />
                </span>
              ) : null}
            </div>
          </>
        ) : null} */}

        <div className={"admin-item--column admin-item--column--btn"}>
          {isStaffEmailTable ? (
            <BaseCheckbox
              defaultChecked={staffStore.selectedStaffIdList.some((id) => id === item.id)}
              handleChange={handleSelectStaff}
              label={{
                htmlFor: `${item.id}`,
              }}
              registerValidation={{
                name: `${item.id}`,
              }}
            />
          ) : (
            <>
              <Edit
                className={"admin-item--edit-icon"}
                onClick={() => onHandleClick && onHandleClick(item, "edit")}
              />
              <Delete
                className={"admin-item--delete-icon"}
                onClick={() => onHandleDelete && onHandleDelete(item)}
              />
              {type === "staff" ? (
                <BaseButton
                  className={"admin-item--write-email-btn"}
                  title={"write Email"}
                  onClick={() => onHandleClick && onHandleClick(item, "email")}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminOrStaffItem;
