import "./LeftNavBar.scss";
import { matchRoutes, NavLink, useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { getNavPath } from "../../utils/common";
import userStore from "../../store/userStore";
import { isAdmin } from "../../utils/isSuperAdmin";

const venueNavItems = [
  // {
  //     name: "Staff",
  //     to: `staff`,
  // },
  {
    name: "Events",
    to: `events`,
  },
  // {
  //   name: "News",
  //   to: `news`,
  // },
  {
    name: "Discounts",
    to: `discounts`,
  },
  {
    name: "Venue Viewer",
    to: `viewer`,
  },
];

const eventNavItems = [
  {
    name: "Tickets",
    to: `tickets`,
  },
];

const LeftNavBar = observer(() => {
  const { venueId, eventId } = useParams();

  const currentLocation = useLocation();
  const eventRoutes = [
    { path: "/venues/:venueId/events/view/:eventId" },
    { path: "/venues/:venueId/events/:eventId" },
    { path: "/venues/:venueId/events/:eventId/tickets" },
  ];

  const venueRoutes = [
    { path: "/venues/create" },
    { path: "/venues/:venueId" },
    { path: "/venues/view/:venueId" },
    { path: "/venues/:venueId/staff" },
    { path: "/venues/:venueId/events" },
    { path: "/venues/:venueId/discounts" },
    { path: "/venues/:venueId/viewer" },
  ];

  const matches = matchRoutes(eventRoutes, currentLocation);
  const venueMatches = matchRoutes(venueRoutes, currentLocation);

  let navItems: Array<{ name: any; to: String }> = [];

  if (isAdmin(userStore.user)) {
    const adminEventRoutes = [{ path: "/events/:eventId" }, { path: "/events/view/:eventId" }];
    const eventMatches = matchRoutes(adminEventRoutes, currentLocation);
    if (eventMatches && eventMatches.length > 0) {
      navItems = eventNavItems;
    }
  } else {
    if (venueMatches && venueMatches.length > 0) {
      navItems = venueNavItems;
    } else if (matches && matches.length > 0) {
      navItems = eventNavItems;
    }
  }

  return (
    <>
      {navItems.length > 0 && (
        <div className="left-nav-bar">
          <div className={`top-nav-bar d-flex-column`}>
            {navItems.map((navItem) => (
              <NavLink
                className={"top-nav-bar-item d-flex-aligned-center"}
                to={{
                  pathname: getNavPath(navItem, venueId, eventId),
                }}
                key={navItem.name}
              >
                {navItem.name}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
});

export default LeftNavBar;
