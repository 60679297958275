import { FC } from "react";
import { Table } from "antd";
import TableActions from "./TableActions";
import { IContactRequest } from "../../../../types/contactRequests";
import { ContactStatus } from "../../../../pages/ContactRequests/ContactRequests";

interface ContactRequestsTableProps {
  data: IContactRequest[];
  onViewClick: (contact: IContactRequest) => void;
  onDeleteItem: (item: IContactRequest) => void;
  onUpdateStatus: ({
    status,
    id
  }: {
    status: ContactStatus;
    id: string;
  }) => void;
}

const ContactRequestsTable: FC<ContactRequestsTableProps> = ({
  data,
  onViewClick,
  onUpdateStatus,
  onDeleteItem,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber: string) => {
        return (
          <p className={"base-responsive-table-name"}>{phoneNumber}</p>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return (
          <p className={"base-responsive-table-name"}>{email}</p>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (message: string) => {
        return (
          <p className={"base-responsive-table-description"}>
            {message ? message : "No Message"}
          </p>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      render: (note: string) => {
        return (
          <p className={"base-responsive-table-description"}>
            {note ? note : "No note"}
          </p>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <TableActions
            item={item}
            onViewClick={onViewClick}
            onUpdateStatus={onUpdateStatus}
            onDeleteItem={onDeleteItem}
          />
        );
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
    />
  );
};

export default ContactRequestsTable;