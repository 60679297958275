import React, { useEffect } from "react";
import "./PermissionLayout.scss";
import { matchRoutes, Outlet, useLocation, useNavigate } from "react-router-dom";
import { isAdmin, isSuperAdmin } from "../../../utils/isSuperAdmin";
import userStore from "../../../store/userStore";
import LeftNavBar from "../../NavBar/LeftNavBar";
import PageContentLayout from "../PageContentLayout/PageContentLayout";

const PermissionLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const ticketsRoutes = [
    { path: "/venues/:venueId/events/:eventId/tickets/:ticketId" },
    { path: "/venues/:venueId/events/:eventId/tickets/view/:ticketId" },
  ];

  const eventRoutes = [{ path: "/venues/:venueId/events/create" }];

  const RoutesWithoutLeftSidebar = [{ path: "/venues/create" }, ...ticketsRoutes, ...eventRoutes];

  useEffect(() => {
    if (!isSuperAdmin(userStore.user) && pathname === "/venue") {
      navigate("/");
    }
  }, [navigate, pathname]);

  const matches = matchRoutes(RoutesWithoutLeftSidebar, currentLocation);

  return (
    <div className={"permission-layout"}>
      {!!matches && matches.length > 0 ? (
        <Outlet />
      ) : (
        <PageContentLayout view={<Outlet />} actions={<LeftNavBar />} />
      )}
    </div>
  );
};

export default PermissionLayout;
