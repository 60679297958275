import { gql } from "@apollo/client";

export const CREATE_ADMIN = gql`
  mutation createAdmin($createAdminInput: CreateAdminInput!) {
    createAdmin(createAdminInput: $createAdminInput) {
      name
      email
      permissions
    }
  }
`;
