import { gql } from "@apollo/client";

export const PUBLISH_EVENT = gql`
  mutation updatePublishedState($updatePublishedStateInput: updatePublishedStateInput!) {
    updatePublishedState(updatePublishedStateInput: $updatePublishedStateInput) {
      id
      name
    }
  }
`;
