import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import EventDetails from "./components/EventDetails/EventDetails";
import { IEvent, IEventData } from "../../types/eventTypes";
import EventLocationDetails from "./components/EventLocationDetails/EventLocationDetails";
import { SubmitHandler, useForm } from "react-hook-form";
import HashtagsList from "../../components/reusable/hashtagsList/HashtagsList";
import { CREATE_EVENT } from "../../graphql/mutations/createEvent";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import filesUploadStore from "../../store/filesUploadStore";
import * as yup from "yup";
import "./EventForm.scss";
import { observer } from "mobx-react-lite";
import { UPDATE_EVENT } from "../../graphql/mutations/updateEvent";
import { IVenue } from "../../types/venue";
import Stepper from "../../components/Stepper/Stepper";
import { UPLOAD_MEDIA } from "../../graphql/mutations/uploadMedia";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import breadcrumbStore from "../../store/breadcrumbStore";
import { isAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import { Button, message } from "antd";
import { PUBLISH_EVENT } from "../../graphql/mutations/publishEvent";
import eventsStore from "../../store/eventsStore";
import { EventReqStatusEnum } from "../../types/eventRequestTypes";
import { validateMediaTypes } from "../../utils/common";

interface EventFormProps {
  eventData?: IEvent | null;
  venueList: IVenue[];
  venueId: string | undefined;
}

const EventForm: FC<EventFormProps> = observer(({ eventData, venueList, venueId }) => {
  const { search } = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [steppers] = useState<Array<string>>(["Info", "Hashtags", "Image"]);
  const [stepCount, setStepCount] = useState<number>(+search.split("-")[1] || 1);
  const [isSaveAndBack, seIsSaveAndBack] = useState<boolean>(false);
  const [selectedHashTags, setSelectedHashTags] = useState<string[]>([]);

  const [createEvent, { data: createData, loading: createLoading, error: createError }] =
    useMutation(CREATE_EVENT);
  const [updateEvent, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_EVENT);
  const [publishEvent] = useMutation(PUBLISH_EVENT);
  const [uploadMedia, { loading: uploadMediaLoading, error: uploadMediaError }] =
    useMutation(UPLOAD_MEDIA);

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required").max(50, "Name must be exactly 50 digits"),
    description: yup.string(),
    startDate: yup.date().required("This field is required"),
    endDate: yup.date().required("This field is required"),
    saleStartDate: yup.date().nullable(),
    saleExpireDate: yup.date().nullable(),
    address: yup.string(),
    venueId: yup
      .number()
      .when("address", (address, schema) => {
        return !address ? schema.required("Select venue or write address") : schema.notRequired();
      })
      .nullable(true),
    bandName: yup.string(),
    price: yup.number().nullable(),
    timezone: yup.string(),
    color: yup.string().nullable().required("This field is required"),
  });

  const {
    reset,
    clearErrors,
    watch,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEventData>({
    resolver: yupResolver(validationSchema),
    defaultValues: eventData?.event || {},
  });

  const decrementStepCount = () => {
    navigate({
      search: `step-${stepCount - 1}`,
    });
  };

  const onCreateEvent: SubmitHandler<IEventData> = async (event) => {
    if (
      steppers[stepCount - 1] === "Hashtags" ||
      (isSaveAndBack && steppers[stepCount - 1] !== "Image")
    ) {
      try {
        const createData = {
          name: event.name,
          description: event.description,
          startDate: event.startDate,
          endDate: event.endDate,
          saleStartDate: event.saleStartDate,
          saleExpireDate: event.saleExpireDate,
          // startTime: event.startTime,
          // endTime: event.endTime,
          address: event.address,
          venueId: event.venueId,
          bandName: event.bandName,
          // price: event.price,
          timezone: event.timezone,
          color: event.color,
        };

        const { data } = await createEvent({
          variables: {
            createEventInput: {
              ...createData,
              hashtagIds: selectedHashTags,
            },
          },
        });
        if (!isSaveAndBack) {
          navigate({
            pathname: venueId
              ? `/venues/${venueId}/events/${data.createEvent.id}`
              : `/events/${data.createEvent.id}`,
            search: `step-${stepCount + 1}`,
          });
        } else {
          if (isAdmin(userStore.user)) {
            navigate("/events");
          } else {
            navigate({
              pathname: venueId ? `/venues/${venueId}/events` : "/events",
            });
          }
        }
        return;
      } catch (e) {
        console.error(e);
        return;
      }
    }
    navigate({ search: `step-${stepCount + 1}` });
  };

  const updateEventMedia = useCallback(async () => {
    try {
      await uploadMedia({
        variables: {
          uploadMediaFromAdminInput: {
            type: "event",
            id: +eventId!,
            media: filesUploadStore.mediaFilesToUploadBackend,
          },
        },
      });
      filesUploadStore.restoreFilesUploadStore();
      navigate({
        pathname: venueId ? `/venues/${venueId}/events` : "/events",
      });
    } catch (e) {
      filesUploadStore.restoreFilesUploadStore();
    }
  }, [uploadMedia, eventId, navigate, venueId]);

  const onUpdateEvent: SubmitHandler<IEventData> = async (data) => {
    if (
      steppers[stepCount - 1] === "Info" ||
      (isSaveAndBack && steppers[stepCount - 1] !== "Image")
    ) {
      const updateData = {
        id: data.id,
        name: data.name,
        description: data.description,
        startDate: data.startDate,
        endDate: data.endDate,
        saleStartDate: data.saleStartDate,
        saleExpireDate: data.saleExpireDate,
        // startTime: new Date(`${dayjs(data.startDate).format("M/D/YYYY")}/${data.startTime}`),
        // endTime: new Date(`${dayjs(data.startDate).format("M/D/YYYY")}/${data.endTime}`),
        address: data.address,
        venueId: data.venueId,
        bandName: data.bandName,
        //price: data.price,
        timezone: data.timezone,
        color: data.color,
      };
      await updateEvent({
        variables: {
          updateEventInput: updateData,
        },
      }).then((res) => {
        if (res?.data?.updateEvent) {
          const eventVenue = venueList.find((v) => v.id === data.venueId);
          if (eventVenue !== undefined) {
            breadcrumbStore.addEvent({
              id: data.id,
              name: data.name,
              venue: {
                id: eventVenue.id,
                name: eventVenue.name,
              },
            });
          }
        }
      });
    }
    if (steppers[stepCount - 1] === "Image") {
      const isValidMedia = validateMediaTypes({
        validMediaFormat: {
          image: 2,
          video: 1,
        },
        mediaArray: filesUploadStore.mediaFiles,
      });
      if (!isValidMedia) {
        message.error("Please upload exactly 2 images and 1 video");
        return;
      }

      if (filesUploadStore.newMediaFiles.length) {
        filesUploadStore.startFileUploadToAWS();
      } else {
        updateEventMedia();
      }
    } else {
      if (!isSaveAndBack) {
        navigate({
          search: `step-${stepCount + 1}`,
        });
      } else {
        navigate({
          pathname: venueId ? `/venues/${venueId}/events` : "/events",
        });
      }
    }
  };

  useEffect(() => {
    search && setStepCount(+search.split("-")[1]);
  }, [search]);

  useEffect(() => {
    if (eventData) {
      setSelectedHashTags(eventData.hashtagIds);
      filesUploadStore.assignMediaFiles(filterMediaTypename(eventData.event.media) || []);
    }
  }, [reset, eventData, setValue]);

  const getExtraButtons = useCallback(() => {
    let btnDisabled = false;
    if (isAdmin(userStore.user)) {
      // Check if user is event admin then publish | unpublish buttons can be visible only when event request gets accepted
      btnDisabled = eventData?.event?.eventRequest?.status !== EventReqStatusEnum.accepted;
    }
    if (eventId && eventData) {
      return (
        <Button
          type="primary"
          size="large"
          disabled={btnDisabled}
          className={eventData.feed.isPublished ? "btn-danger" : ""}
          onClick={() => {
            const isPublished = eventData.feed.isPublished;
            publishEvent({
              variables: {
                updatePublishedStateInput: {
                  id: +eventId,
                  isPublished: !isPublished,
                },
              },
            })
              .then(() => {
                eventsStore.publishEvent(+eventId, !isPublished);
                const publishedStatus = eventData.feed.isPublished ? "unpublished" : "published";
                return message.success(`Event was successfully ${publishedStatus}`, 0.8);
              })
              .catch((error) => {
                return message.error(error.message);
              });
          }}
        >
          {eventData.feed.isPublished ? "Unpublish" : "Publish"}
        </Button>
      );
    }
    return <></>;
  }, [eventId, eventData, publishEvent]);

  return (
    <form
      onSubmit={handleSubmit(eventData ? onUpdateEvent : onCreateEvent)}
      className={"event-form"}
    >
      <Stepper
        steppers={steppers}
        stepCount={stepCount}
        decrementStepCount={decrementStepCount}
        saveAndBack={() => seIsSaveAndBack(true)}
        isUpdateMode={!!eventId}
        extraButtons={getExtraButtons()}
        isEventForm={true}
      >
        <div className={"event-form--content"}>
          <div
            className={`${stepCount === 1 ? "event-form--info-step" : "event-form--display-none"}`}
          >
            <EventDetails
              isDisabled={false}
              venueList={venueList}
              selectVenueId={eventData?.event?.venueId || venueId!}
              clearErrors={clearErrors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
            />
          </div>
          <div
            className={`${stepCount === 2 ? "event-form--info-step" : "event-form--display-none"}`}
          >
            <EventLocationDetails
              isDisabled={false}
              address={eventData?.event?.address || ""}
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <HashtagsList
              feedEventId={eventData?.feed?.id}
              isRemovable={false}
              isSelectable={true}
              setSelectedHashTags={setSelectedHashTags}
              selectedHashTags={selectedHashTags}
            />
          </div>

          <div
            className={`${stepCount === 3 ? "event-form--info-step" : "event-form--display-none"}`}
          >
            <ImageVideoUploadCarousel
              id={createData?.createEvent.id || eventData?.event.id}
              readOnly={false}
              updateMedia={updateEventMedia}
              keyType={"events"}
            />
          </div>
        </div>
      </Stepper>

      {createLoading || updateLoading || uploadMediaLoading ? (
        <BaseCreateOrDeleteModalLoading />
      ) : null}

      {createError || updateError || uploadMediaError ? (
        <BaseAlert type={"failed"} message={updateError?.message || createError?.message} />
      ) : null}
    </form>
  );
});

export default EventForm;
