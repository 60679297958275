import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PASSWORD_CONFIRM_TEXT,
  PASSWORD_MAXIMUM_TEXT,
  PASSWORD_MINIMUM_TEXT,
  PASSWORD_REQUIRED_TEXT,
} from "../../constants/errors";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { useMutation } from "@apollo/client";
import { ReactComponent as Loader } from "../../assets/images/Loader.svg";
import { RESET_PASSWORD } from "../../graphql/mutations/resetPassword";
import { useAuth } from "../../hooks/useAuth";
import userStore from "../../store/userStore";

interface ResetPasswordFormProps {
  token?: string;
}

type Inputs = {
  code: number;
  password: string;
  passwordConfirmation: string;
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ token }) => {
  const [checkOtp, { error, loading }] = useMutation(RESET_PASSWORD);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const validationSchema = yup.object().shape({
    code: yup.string().required("This field is required").min(4, "Min and max code length 4"),
    password: yup
      .string()
      .required(PASSWORD_REQUIRED_TEXT)
      .min(6, PASSWORD_MINIMUM_TEXT)
      .max(30, PASSWORD_MAXIMUM_TEXT),
    passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], PASSWORD_CONFIRM_TEXT),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    const { data } = await checkOtp({
      variables: {
        checkOtpData: {
          otp: formData.code,
          sessionToken: state,
          password: formData.password,
          passwordConfirm: formData.passwordConfirmation,
        },
      },
    });

    if (data) {
      login(data.checkOtp.token);
      userStore.addUserName(data.checkOtp.user.name);
      navigate("/");
    }
  };

  return (
    <div>
      {state ? (
        <BaseAlert type={"success"} message={"Verification code has been sent to your email"} />
      ) : null}
      {error ? <BaseAlert type={"failed"} message={error.message} /> : null}
      <form onSubmit={handleSubmit(onSubmit)} className={"auth-form"}>
        <div className={"auth-form--logo"}>
          <Logo />
          <p className={"auth-form--title"}>RESET PASSWORD</p>
        </div>

        <BaseInput
          label={{
            text: "Permission code",
            htmlFor: "code",
          }}
          registerValidation={{
            type: "string",
            name: "code",
            placeholder: "Permission code...",
            validation: {
              ...register("code", { required: true, maxLength: 10 }),
            },
          }}
          error={errors.code?.message}
        />

        <BaseInput
          label={{
            text: "Password",
            htmlFor: "password",
          }}
          registerValidation={{
            type: "password",
            name: "password",
            placeholder: "Password...",
            validation: {
              ...register("password", { required: true, maxLength: 10 }),
            },
          }}
          error={errors.password?.message}
        />

        <BaseInput
          label={{
            text: "Password Confirm",
            htmlFor: "password-confirm",
          }}
          registerValidation={{
            type: "password",
            name: "new-password",
            placeholder: "Password...",
            validation: {
              ...register("passwordConfirmation", {
                required: true,
                maxLength: 10,
              }),
            },
          }}
          error={errors.passwordConfirmation?.message}
        />

        <BaseButton
          loader={loading && <Loader width={30} height={30} />}
          type={"submit"}
          title={"RESET PASSWORD"}
          className={"auth-form--login"}
        />
      </form>
    </div>
  );
};

export default ResetPasswordForm;
