import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SALES_ANALYTICS } from "../../../graphql/query/getSalesAnalytics";
import { GET_VENUE_COUNT } from "../../../graphql/query/getVenueCount";
import { GET_EVENTS_COUNT } from "../../../graphql/query/getEventsCount";

const ActiveVenuesEventsQuantity = () => {
  const [getVenuesCount, { data: venuesCount }] = useLazyQuery(GET_VENUE_COUNT, {
    fetchPolicy: "no-cache",
  });
  const [getEventsCount, { data: eventsCount }] = useLazyQuery(GET_EVENTS_COUNT, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    (async () => {
      await getVenuesCount();
      await getEventsCount({ variables: { eventsCountArgs: { status: "active" } } });
    })();
  }, []);
  return (
    <div className={"dashboard-page-active-quantity d-flex-column gap-20"}>
      <h2 className={"dashboard-page-title"}>Admin Information</h2>
      <div className={"dashboard-page-content-left-counts-item d-flex space-between gap-10"}>
        Active Venues
        <p>{venuesCount?.getVenueCount?.count}</p>
      </div>
      <div className={"dashboard-page-content-left-counts-item d-flex space-between gap-10"}>
        Active Events
        <p>{eventsCount?.getEventsCount?.count}</p>
      </div>
    </div>
  );
};

export default ActiveVenuesEventsQuantity;
