import { gql } from "@apollo/client";

export const REMOVE_TICKET = gql`
  mutation removeTicket($id: Int!) {
    removeTicket(id: $id) {
      status
      message
    }
  }
`;
