import React, { FC } from "react";
import "./AdvertisementInfo.scss";
import { INews } from "../../types/news";
import ViewInput from "../../components/reusable/viewInput/ViewInput";
import { IVenue } from "../../types/venue";
import { formatDate } from "../../utils/formatDate";

interface AdvertisementInfoProps {
  news: INews;
  venueList: IVenue[];
}

const AdvertisementInfo: FC<AdvertisementInfoProps> = ({ news, venueList }) => {
  return (
    <div className={"advertisement-info"}>
      <ViewInput
        newClass={"advertisement-info--data-desc"}
        title={"Description"}
        value={news?.description}
      />

      <ViewInput
        title={"Venue"}
        value={venueList?.find((venue) => venue.id === news?.ad.venueId)?.name}
      />
      <ViewInput title={"Active till"} value={formatDate(new Date(news?.ad.endDate!))} />
    </div>
  );
};

export default AdvertisementInfo;
