import { memo } from "react";
import { Table } from "antd";
import { TableActions, TableProps } from "../helpers/TableHelper";

const DiscountTable = ({ data, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (code: string) => {
        return <p>{code}</p>;
      },
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (discount: string) => {
        return <p>{discount}</p>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (currency: string) => {
        return <p>{currency}</p>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => (
        <TableActions
          item={item}
          onEditItem={onEditItem}
          onDeleteItem={onDeleteItem}
          showEditDeleteActions
          showViewAction={false}
        />
      ),
    },
  ];

  return (
    <Table
      className={"base-responsive-table base-responsive-table-mobile"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
      scroll={{
        x: true,
      }}
    />
  );
};

export default memo(DiscountTable);
