import { gql } from "@apollo/client";

export const GET_USER_QUANTITY_ANALYTICS = gql`
  query getUserQuantityAnalytics {
    getUserQuantityAnalytics {
      month
      count
    }
  }
`;
