import { FC } from "react";
import "./BlogInfo.scss";
import { IBLog } from "../../types/types";

interface BlogInfoProps {
  blog: IBLog;
}

const BlogInfo: FC<BlogInfoProps> = ({ blog }) => {
  return (
    <div className={"blog-info"}>
      <div className={"blog-info--block"}>
        <div dangerouslySetInnerHTML={{__html: blog?.content}}></div>
      </div>
    </div>
  );
};

export default BlogInfo;
