import { gql } from "@apollo/client";

export const UPDATE_EVENT = gql`
  mutation updateEvent($updateEventInput: UpdateEventInput!) {
    updateEvent(updateEventInput: $updateEventInput) {
      name
      id
    }
  }
`;
