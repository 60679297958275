import React from "react";
import { IUsersItem } from "./user";
import { IAdminOrStaffItem } from "./adminOrstaff";
import { IAd } from "./news";
import { IHashtag } from "./hashtagsTypes";
import { IReportedPost } from "./reportedPostTypes";
import { IDiscount } from "./discount";
import type { Dayjs } from "dayjs";
import { IMediaItem } from "./mediaTypes";
import { Breakpoint } from "antd/es/_util/responsiveObserver";

export enum UserRole {
  superAdmin = "superAdmin",
  admin = "admin",
  venueManager = "venueManager",
  user = "user",
}

export enum AdminRoles {
  admin = "admin",
  venueManager = "venueManager",
  eventManager = "eventManager",
  companyManager = "companyManager",
}

export interface IBLog {
  id: string | number;
  title: string;
  content: string;
  media: Array<IMediaItem>;
  cover: string;
  createdAt: string;
  updatedAt: string;
}

export interface IBLogForm {
  title: string;
  content: string;
  media?: Array<IMediaItem>;
}

export type IObjectKeys = {
  [key: string]: any;
};

export interface ILabel {
  htmlFor?: string;
  text?: string;
  className?: string;
}

export interface IValidationInput {
  validation?: object;
  type?: string;
  name: string;
  className?: string;
  placeholder?: string;
  value?: string;
}

export interface NavBarItem {
  icon: React.ReactNode;
  name: string;
  to: string;
}

export interface IFeaturedImageUrlProps {
  key?: string;
  type?: string;
}

export interface PanelItem {
  id: string | number;
  name: string;
  description?: string;
  cover?: string | null;
  startDate?: string | Date | Dayjs;
  avgRate?: number;
  featuredImageUrl?: IFeaturedImageUrlProps;
  ad?: IAd;
  hashtags?: Array<IHashtag>;
  startTime?: string | Date | Dayjs;
  endTime?: string | Date | Dayjs;
  streamUrl?: string;
  responsive?: Breakpoint[];
}

// export interface IFileItem {
//   key: string | number;
//   type: string;
//   id?: string | number;
//   file?: File;
//   isCover?: boolean;
// }

export interface ISelect {
  type?: string;
  name: string | number;
  id?: string | number;
}

export interface IWriteEmail {
  subject: string;
  body: string;
}

export interface IGetMe {
  id: string | number;
  name: string;
  email: string;
  role: UserRole;
}

export type IBaseTable = IAdminOrStaffItem | IUsersItem | IReportedPost | IDiscount;

export type Maybe<T> = T | null;
