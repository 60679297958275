import { IMediaItem } from "./mediaTypes";
import { PanelItem } from "./types";
import { IVenueBreadcrumb } from "./venue";
import type { Dayjs } from "dayjs";
import { IEventData } from "./eventTypes";

export enum EventReqStatusEnum {
  "pending" = "pending",
  "accepted" = "accepted",
  "rejected" = "rejected",
}

export interface IEventRequest extends PanelItem {
  venueId: string | number;
  creatorId: number;
  eventId: number;
  event?: IEventData;
  status: EventReqStatusEnum;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export enum EventRequestsFilterStatus {
  ALL = "",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}
