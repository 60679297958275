import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import "./ImageVideoUploadCarousel.scss";
import { ReactComponent as Upload } from "../../assets/images/Upload.svg";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/images/LeftArrow.svg";
import ImageVideoUploadCarouselItem from "./components/ImageVideoUploadCarouselItem";
import filesUploadStore from "../../store/filesUploadStore";
import { observer } from "mobx-react-lite";
import uuid from "react-uuid";
import { Grid } from "antd";

interface ImageVideoUploadCarouselProps {
  id: string | number;
  readOnly: boolean;
  updateMedia?: () => void;
  keyType: "events" | "venues" | "feeds" | "tickets" | "blogs";
  hasMultipleOptions?: boolean;
}

const ImageVideoUploadCarousel: FC<ImageVideoUploadCarouselProps> = observer(
  ({ readOnly, id, updateMedia, keyType, hasMultipleOptions = true }) => {
    const sliderRef = useRef<HTMLInputElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const uploadFile = async (event: React.SyntheticEvent<EventTarget>) => {
      Object.entries((event.target as HTMLFormElement).files).map((file: any) => {
        const supportedImages = ["image/png", "image/jpg", "image/jpeg"];
        const fileType = supportedImages.includes(file[1].type) ? "image" : "video";
        filesUploadStore.addUploadFiles({
          key: uuid(),
          type: fileType,
          file: file,
          isCover: !filesUploadStore.mediaFiles.filter((e) => e.type !== "video").length,
        });
      });
    };

    const sliderBrowse = (direction: string) => {
      if (sliderRef.current) {
        const scrollTo = direction === "left" ? scrollPosition - 220 : scrollPosition + 220;
        const { clientWidth } = sliderRef.current;
        if (scrollTo * -1 < clientWidth) {
          if (scrollTo * -1 === clientWidth + 220) {
            sliderRef.current.style.left = `${0}px`;
            setScrollPosition(0);
          } else if (scrollTo > 0) {
            sliderRef.current.style.left = `${-clientWidth + 220}px`;
            setScrollPosition(-clientWidth + 220);
          } else {
            sliderRef.current.style.left = `${scrollTo}px`;
            setScrollPosition(scrollTo);
          }
        } else {
          sliderRef.current.style.left = `${0}px`;
          setScrollPosition(0);
        }
      }
    };

    const screensLength = useMemo(
      () => Object.values(screens).filter((item) => item).length,
      [screens]
    );

    useEffect(() => {
      if (
        filesUploadStore.filesAWSLoadCount > 0 &&
        filesUploadStore.filesAWSLoadCount === filesUploadStore.newMediaFiles.length
      ) {
        if (updateMedia) {
          updateMedia();
        }
      }
    }, [filesUploadStore.filesAWSLoadCount, filesUploadStore.newMediaFiles.length]);

    useEffect(() => {
      return () => {
        filesUploadStore.restoreFilesUploadStore();
      };
    }, []);

    return (
      <div className={"img-video-form"}>
        <div className={"img-video-form--content"}>
          {!readOnly &&
            (keyType !== "blogs" ||
              (keyType === "blogs" && !filesUploadStore.mediaFiles?.length)) && (
              <div className={"img-video-form--upload-block"}>
                <label htmlFor="file" className={"img-video-form--upload-label"}>
                  Img/Video
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  accept="image/jpg, image/png, video/*"
                  className={"img-video-form--upload-input"}
                  onChange={uploadFile}
                  multiple={hasMultipleOptions}
                />
                <label htmlFor="file" className={"img-video-form--upload-label"}>
                  <Upload />
                </label>
              </div>
            )}
          <div className={"img-video-form--carousel"}>
            {filesUploadStore.mediaFiles.length > screensLength ? (
              <LeftArrow
                className={"img-video-form--carousel--left-arrow"}
                onClick={() => sliderBrowse("left")}
              />
            ) : null}

            <div className={"img-video-form--image-carousel"}>
              {!filesUploadStore.mediaFiles.length ? (
                <div className={"img-video-form--carousel--empty"}>
                  {readOnly ? "No Media" : "Upload your first Img/Video"}
                </div>
              ) : null}

              <div className={"img-video-form--image-carousel--slider"} ref={sliderRef}>
                {filesUploadStore.mediaFiles.map((mediaItem, index) => (
                  <ImageVideoUploadCarouselItem
                    key={mediaItem?.key + index}
                    id={id}
                    keyType={keyType}
                    readOnly={readOnly}
                    mediaItem={mediaItem}
                    canAddCover={keyType !== "blogs"}
                  />
                ))}
              </div>
            </div>
            {filesUploadStore.mediaFiles.length > screensLength ? (
              <RightArrow
                className={"img-video-form--carousel--right-arrow"}
                onClick={() => sliderBrowse("right")}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

export default ImageVideoUploadCarousel;
