import { FC } from "react";
import { IReportedPost } from "../../../types/reportedPostTypes";
import { useNavigate } from "react-router-dom";
import { BASE_URL_IMG } from "../../../constants/Api";
import DefaultImage from "../../../assets/images/pub.png";
import "./ReportedPostItem.scss";

interface IReportedPostItemProps {
  reportedPostItem: IReportedPost;
}
const ReportedPostItem: FC<IReportedPostItemProps> = ({ reportedPostItem }) => {
  const navigate = useNavigate();

  const navigateReportedPostPage = () => {
    navigate({
      pathname: "/reported-post",
      search: reportedPostItem.id.toString(),
    });
  };

  return (
    <div className={"reported-post-item cursor-pointer"} onClick={navigateReportedPostPage}>
      <div className={"reported-post-item--column avatar"}>
        <div className={"avatar-picture"}>
          {/*panelItem.cover ? BASE_URL_IMG + panelItem?.cover : DefaultImage*/}
          <img
            src={
              reportedPostItem?.feed?.cover
                ? BASE_URL_IMG + reportedPostItem.feed.cover
                : DefaultImage
            }
            alt="avatar"
          />
        </div>
        <span className={"avatar-name"}>{reportedPostItem?.user?.name}</span>
      </div>
      <div className={"reported-post-item--column description"}>
        <span>{reportedPostItem?.feed?.description}</span>
      </div>
      {reportedPostItem?.reports &&
        reportedPostItem.reports.map((reportTypeData, index) => (
          <div
            key={index}
            className={`${
              !reportTypeData?.count ? "absence" : ""
            }  reported-post-item--column report-type-info`}
          >
            <div className={"report-type-info-name"}>{reportTypeData?.reason.toLowerCase()}</div>
            <div className={"report-type-info-count"}>{reportTypeData?.count}</div>
          </div>
        ))}
      <div className={"reported-post-item--column report-type-info"}>
        <div className="total-text">Total</div>
        <div className="total-count">{reportedPostItem?.reportCount}</div>
      </div>
    </div>
  );
};

export default ReportedPostItem;
