import { memo } from "react";
import { Button, Space, Table } from "antd";
import { TableProps } from "../helpers/TableHelper";

const StaffTable = ({ data, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <p>{email}</p>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phone: string) => {
        return <p>{phone}</p>;
      },
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (position: string) => {
        return <p>{position}</p>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <Space wrap>
            <Button type="primary" onClick={(e) => onEditItem && onEditItem(item, "edit")}>
              Edit
            </Button>
            <Button type="primary" onClick={(e) => onEditItem && onEditItem(item, "email")}>
              Write Email
            </Button>
            {/*<Button type="primary" onClick={(e) => onDeleteItem && onDeleteItem(item)} ghost>*/}
            {/*  Delete*/}
            {/*</Button>*/}
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
    />
  );
};

export default memo(StaffTable);
