import { gql } from "@apollo/client";

export const GET_ADMIN_LIST = gql`
  query getAdmins($getAdminsArgs: ListAdminsInput!) {
    getAdmins(getAdminsArgs: $getAdminsArgs) {
      count
      admins {
        id
        name
        email
        permissions
      }
    }
  }
`;
