import React, { useCallback, useMemo, useState } from "react";
import "./AdminSettings.scss";
import { Switch } from "antd";
import BaseSelect from "../../components/reusable/baseSelect/BaseSelect";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ADMIN_SETTINGS } from "graphql/query/getAdminSettings";
import { UPDATE_ADMIN_SETTINGS } from "graphql/mutations/updateAdminSettings";
import BaseAlert from "components/reusable/baseAlert/BaseAlert";
import BaseCreateOrDeleteModalLoading from "components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import { VenueOption } from "../../forms/EventForm/components/EventDetails/EventDetails";

const options = [
  { type: "15m", name: "15 minutes" },
  { type: "30m", name: "30 minutes" },
  { type: "1h", name: "1 hour" },
];

const AdminSettings = () => {
  const [settings, setSettings] = useState<Record<string, string>>({});

  const { loading } = useQuery(GET_ADMIN_SETTINGS, {
    variables: {
      args: {
        limit: 100,
        offset: 0,
      },
    },
    onCompleted: (data) => {
      setSettings(convertSettings(data));
    },
  });

  const [updateSetting, { error: updateError }] = useMutation(UPDATE_ADMIN_SETTINGS);

  const convertSettings = useCallback(
    (data: any): Record<string, string> =>
      data?.listAllSettings?.settings.reduce(
        (acc: Record<string, string>, curr: { key: string; value: string }) => ({
          ...acc,
          [curr.key]: curr.value,
        }),
        {}
      ) || [],
    []
  );

  const handleChange = useCallback(
    async (key: string, value: any) => {
      const initialValue = settings[key];

      try {
        settings[key] = typeof value === "string" ? value : JSON.stringify(value);
        setSettings(settings);
        await updateSetting({
          variables: {
            args: { key, value: typeof value === "string" ? value : JSON.stringify(value) },
          },
        });
      } catch (error) {
        settings[key] = initialValue;
        setSettings(settings);
      }
    },
    [settings, updateSetting]
  );

  const venueViewerEnabled = useMemo(
    () => (settings["venueViewer"] ? JSON.parse(settings["venueViewer"]) : false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings["venueViewer"]]
  );

  return (
    <div className="admin-settings">
      {loading ? <BaseCreateOrDeleteModalLoading /> : null}
      {updateError ? <BaseAlert type={"failed"} message={updateError.message} /> : null}
      <form className="content">
        <div className="venue_viewer">
          <h2 className="venue_viewer_enable">Enable venue viewer</h2>
          <Switch
            checked={venueViewerEnabled}
            onChange={(checked) => handleChange("venueViewer", checked)}
          />
        </div>
        <BaseSelect
          showOnlyName
          value={options.find((i) => i.type === settings["snapshotTime"])?.name}
          setValue={(value) => handleChange("snapshotTime", (value as VenueOption).type)}
          options={options}
          label={{
            text: "Time intervals of snapshots:",
            htmlFor: "venueViewerInterval",
          }}
          disabled={!venueViewerEnabled}
          registerValidation={{
            type: "select",
            name: "venueViewerInterval",
          }}
        />
      </form>
    </div>
  );
};

export default AdminSettings;
