import ResetPasswordForm from "../../forms/AuthForm/ResetPasswordForm";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams();

  return (
    <div>
      <ResetPasswordForm token={token} />
    </div>
  );
};

export default ResetPassword;
