import { gql } from "@apollo/client";

export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount($input: UpdateDiscountInput!) {
    updateDiscount(input: $input) {
      id
      createdBy
      name
      description
      code
      discount
      type
      currency
      startDate
      endDate
      deleted
      createdAt
      updatedAt
      createdByUser {
        id
        name
        email
      }
    }
  }
`;
