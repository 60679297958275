import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { isAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import AdminsHistorySearch from "./AdminsHistorySearch";
import './AdminsHistory.scss';
import AdminsHistoryView from "./AdminsHistoryView";
import adminsHistoryStore from "../../store/adminsHistoryStore";
import venueStore from "../../store/venueStore";
import { useDebounce } from "../../hooks/useDebounce";
import BaseError from "../../components/reusable/baseError/BaseError";
import { GET_ADMINS_HISTORY } from "../../graphql/query/getAdminsHistory";

type argsOption = {
  limit: number;
  offset: number;
  searchValue?: string;
};

const AdminsHistory = () => {
  const [search, setSearch] = useState<string>("");
  const [listAdminsHistory, { loading, error, data }] = useLazyQuery(GET_ADMINS_HISTORY, {
    fetchPolicy: "no-cache",
  });
  const debounced = useDebounce(search);
  const getAdminsHistoryList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset = count === 1 ? adminsHistoryStore.offset - adminsHistoryStore.limit : adminsHistoryStore.offset;

      const args: argsOption = {
        limit: adminsHistoryStore.limit,
        offset: newOffset,
      };

      if (debounced) {
        args.searchValue = debounced;
      }

      listAdminsHistory({
        variables: {
          ListLogsInputArgs: args,
        },
      });
    },
    [listAdminsHistory]
  );

  const fetchAdminsHistory = useCallback(async () => {
    await venueStore.resetPagination();
    if (debounced.length) {
      getAdminsHistoryList(null, debounced);
    } else {
      getAdminsHistoryList();
    }
  }, [debounced, getAdminsHistoryList]);

  const changePage = (page: number) => {
    adminsHistoryStore.addOffset((page - 1) * adminsHistoryStore.limit);
    getAdminsHistoryList();
  };

  useEffect(() => {
    if (data?.listLogs) {
      adminsHistoryStore.addAdminsHistory(data.listLogs.logs);
      adminsHistoryStore.addAllAdminsHistoryCount(data.listLogs.count);
    }
  }, [data]);

  useLayoutEffect(() => {
    adminsHistoryStore.resetAdminsHistory();
    fetchAdminsHistory();
  }, [debounced, fetchAdminsHistory]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }
  return (
    <div className={"admins-history-page"}>
      <PageContentLayout
        isVerticalAlign
        actions={
          !isAdmin(userStore.user) ? (
            <AdminsHistorySearch setSearch={setSearch} />
          ) : undefined
        }
        view={
          <AdminsHistoryView
            loading={loading}
            data={data}
            changePage={changePage}
          />
        }
      />
    </div>
  )
};

export default AdminsHistory;