import React, { FC, useCallback, useEffect, useState } from "react";
import "./EditDiscountModalForm.scss";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { useMutation } from "@apollo/client";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { IDiscount } from "../../types/discount";
import { CREATE_DISCOUNT } from "../../graphql/mutations/createDiscount";
import { UPDATE_DISCOUNT } from "../../graphql/mutations/updateDiscount";
// import { DELETE_DISCOUNT } from "../../graphql/mutations/deleteDiscount";
import discountsStore from "../../store/discountsStore";
import { currencyList } from "../../constants/currency";
import BaseSelect from "../../components/reusable/baseSelect/BaseSelect";
import { discountTypesList } from "../../constants/discountTypes";
import dayjs from "dayjs";
import DatePicker from "../../components/reusable/datePicker/DatePicker";
import type { Dayjs } from "dayjs";
import { VenueOption } from "../EventForm/components/EventDetails/EventDetails";

interface DiscountModalFormProps {
  venueId?: string;
  closModal: () => void;
  discountItem?: IDiscount | null;
  getDiscountsList: (count?: number) => void;
}

const EditDiscountModalForm: FC<DiscountModalFormProps> = ({
  venueId,
  closModal,
  discountItem,
  getDiscountsList,
}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const [createDiscount, { loading: createDiscountLoading, error: createDiscountError }] =
    useMutation(CREATE_DISCOUNT);

  const [updateDiscount, { loading: updateDiscountLoading, error: updateDiscountError }] =
    useMutation(UPDATE_DISCOUNT);

  const [removeDiscount, { loading: removeDiscountLoading, error: removeDiscountError }] =
    useMutation(UPDATE_DISCOUNT);

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    description: yup.string().notRequired(),
    code: yup.string().required("This field is required"),
    discount: yup.number().typeError("This field is required").required("This field is required"),
    type: yup.string().required("This field is required"),
    currency: yup.string().required("This field is required"),
    startDate: yup.string().required("This field is required"),
    endDate: yup.string().required("This field is required"),
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IDiscount>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...discountItem,
      startDate: discountItem?.startDate
        ? dayjs(discountItem.startDate).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
      endDate: discountItem?.endDate ? dayjs(discountItem.endDate).format("YYYY-MM-DD") : undefined,
    },
  });

  const changeStartDate = useCallback(
    (value: Dayjs | null) => {
      if (value) {
        setValue("startDate", value);
        setValue("endDate", value);
      }
      clearErrors("startDate");
    },
    [clearErrors, setValue]
  );
  const changeEndDate = useCallback(
    (value: Dayjs | null) => {
      value && setValue("endDate", value);
      clearErrors("endDate");
    },
    [clearErrors, setValue]
  );

  const calculatePagination = (id: number) => {
    if (
      discountsStore.offset > 0 &&
      (discountsStore.offset / discountsStore.limit + 1 <
        Math.ceil(discountsStore.discountsTotalCount / discountsStore.limit) ||
        discountsStore.discountsList.length === 1)
    ) {
      getDiscountsList(discountsStore.discountsList.length);
    } else {
      discountsStore.resetDiscounts();
    }
    closModal();
  };

  const onUpdateDiscount: SubmitHandler<IDiscount> = async (data) => {
    try {
      const newData = {
        id: data.id,
        name: data.name,
        description: data.description,
        code: data.code,
        currency: data.currency,
        type: data.type,
        discount: data.discount,
        startDate: dayjs(data.startDate),
        endDate: dayjs(data.endDate),
      };
      const { data: discount } = await updateDiscount({
        variables: {
          input: newData,
        },
      });
      discountsStore.selectCurrentDiscount(discount);
      closModal();
      getDiscountsList();
    } catch (e) {}
  };
  const onCreateDiscount: SubmitHandler<IDiscount> = async (data) => {
    const newData = {
      venueId: parseInt(venueId!),
      name: data.name,
      description: data.description,
      code: data.code,
      currency: data.currency,
      type: data.type,
      discount: data.discount,
      startDate: dayjs(data.startDate),
      endDate: dayjs(data.endDate),
    };
    try {
      await createDiscount({
        variables: {
          input: newData,
        },
      });
      discountsStore.addOffset(0);
      closModal();
      getDiscountsList();
    } catch (e) {}
  };

  const deleteDiscountConfirm = async () => {
    const { data } = await removeDiscount({
      variables: {
        id: discountItem?.id,
      },
    });

    calculatePagination(data?.removeDiscount?.id);
  };

  const openOrCloseDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };

  const changeDiscountCurrency = (value: { id: number; value: string; type?: string }) => {
    setValue("currency", value.type || "gbp");
    //clearErrors("currency");
  };

  const changeDiscountType = (value: { id: number; value: string; type?: string }) => {
    setValue("type", value.value || "amount");
  };

  useEffect(() => {
    if (discountItem) {
      reset(discountItem);
    }
  }, [discountItem, reset]);

  return (
    <form
      onSubmit={handleSubmit(discountItem ? onUpdateDiscount : onCreateDiscount)}
      className={"discount-form"}
    >
      {createDiscountLoading || removeDiscountLoading || updateDiscountLoading ? (
        <BaseCreateOrDeleteModalLoading />
      ) : null}

      {createDiscountError || removeDiscountError || updateDiscountError ? (
        <BaseAlert
          type={"failed"}
          message={
            createDiscountError?.message ||
            removeDiscountError?.message ||
            updateDiscountError?.message
          }
        />
      ) : null}
      {isOpenDeleteModal && (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteDiscountConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this discount ?"}
        />
      )}
      <div className={"discount-form--block"}>
        <div className={"discount-form--left-block"}>
          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Name",
              htmlFor: "name",
              className: "required",
            }}
            registerValidation={{
              type: "text",
              name: "name",
              placeholder: "Name ...",
              validation: {
                ...register("name"),
              },
            }}
            error={errors.name?.message}
          />

          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Description",
              htmlFor: "description",
              className: "required",
            }}
            registerValidation={{
              type: "textarea",
              name: "description",
              placeholder: "Description ...",
              validation: {
                ...register("description"),
              },
            }}
            error={errors.description?.message}
          />

          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Code",
              htmlFor: "code",
              className: "required",
            }}
            registerValidation={{
              type: "text",
              name: "code",
              placeholder: "Code ...",
              validation: {
                ...register("code"),
              },
            }}
            error={errors.code?.message}
          />

          <div className="discount-form--block">
            <div className="discount-form--left-block">
              <BaseInput
                newClassName={"modal-input"}
                label={{
                  text: "Amount",
                  htmlFor: "Amount",
                  className: "required",
                }}
                registerValidation={{
                  type: "number",
                  name: "discount",
                  placeholder: "Amount ...",
                  validation: {
                    ...register("discount"),
                  },
                }}
                error={errors.discount?.message}
              />
            </div>

            <div className="discount-form--right-block">
              <BaseSelect
                showOnlyType={true}
                value={
                  discountTypesList?.find(
                    (discountType) => discountType.name === discountItem?.type
                  )?.type
                }
                setValue={(value) => changeDiscountType(value as VenueOption)}
                options={discountTypesList}
                label={{
                  text: "Discount Type",
                  htmlFor: "type",
                  className: "required",
                }}
                registerValidation={{
                  type: "select",
                  name: "type",
                  placeholder: "Discount Type",
                  validation: {},
                }}
                error={errors.type?.message}
              />
            </div>
          </div>

          <BaseSelect
            value={currencyList?.find((currency) => currency.type === discountItem?.currency)?.name}
            setValue={(value) => changeDiscountCurrency(value as VenueOption)}
            options={currencyList}
            label={{
              text: "Currency",
              htmlFor: "currency",
              className: "required",
            }}
            registerValidation={{
              type: "select",
              name: "currency",
              placeholder: "Currency",
              validation: {},
            }}
            error={errors.currency?.message}
          />

          <div className="discount-form--block">
            <div className="discount-form--left-block">
              <DatePicker
                title="Start Date"
                newClassName="modal-input"
                error={errors.startDate?.message}
                onChange={changeStartDate}
                value={getValues("startDate")}
                disabledDate={(d) =>
                  !d ||
                  d.isBefore(dayjs().subtract(1, "day")) ||
                  (d.isSame(dayjs(getValues("endDate") || "")) &&
                    d.isAfter(dayjs(getValues("endDate") || "")))
                }
                format={"DD-MM-YYYY"}
              />
            </div>
            <div className="discount-form--right-block">
              <DatePicker
                title="End Date"
                newClassName="modal-input"
                error={errors.endDate?.message}
                onChange={changeEndDate}
                value={getValues("endDate")}
                disabledDate={(d) => !d || d.isBefore(dayjs(getValues("startDate") || ""))}
                format={"DD-MM-YYYY"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"discount-form--btn"}>
        <BaseButton type={"submit"} title={discountItem ? "Update Discount" : "Add Discount"} />
      </div>
    </form>
  );
};

export default EditDiscountModalForm;
