import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IBLog, IBLogForm } from "../../types/types";
import "./BlogForm.scss";
import { CREATE_BLOG } from "../../graphql/mutations/createBlog";
import Editor from "./Editor/Editor";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import filesUploadStore from "../../store/filesUploadStore";
import { UPLOAD_MEDIA } from "../../graphql/mutations/uploadMedia";
import breadcrumbStore from "../../store/breadcrumbStore";
import { UPDATE_BLOG } from "../../graphql/mutations/updateBlog";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { GET_BLOGS } from "../../graphql/query/getBlogs";
import blogStore from "../../store/blogStore";
import PublishButton from "./PublishButton";
import { checkHasImageExtension } from "../../utils/common";

interface BlogFormProps {
  blogData: IBLog;
}

const BlogForm: FC<BlogFormProps> = ({ blogData }) => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState<any>(blogData);
  const validationSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    content: yup.string().required("This field is required"),
  });

  const [createBlog, { data: createData }] = useMutation(CREATE_BLOG);
  const [uploadMedia] = useMutation(UPLOAD_MEDIA);
  const [updateBlog, { error: blogUpdateError }] = useMutation(UPDATE_BLOG);
  const navigate = useNavigate();

  const {
    reset,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<IBLogForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: blogData?.title,
      content: blogData?.content,
    },
  });

  useEffect(() => {
    return () => {
      filesUploadStore.restoreFilesUploadStore();
    };
  }, []);

  const updateBlogMedia = useCallback(async () => {
    try {
      await uploadMedia({
        variables: {
          uploadMediaFromAdminInput: {
            type: "blog",
            id: +blogId!,
            media: filesUploadStore.mediaFilesToUploadBackend,
          },
        },
        refetchQueries: [
          {
            query: GET_BLOGS,
            variables: {
              ListBlogsInput: {
                limit: blogStore.limit,
                offset: blogStore.offset,
              },
            },
          },
        ],
      });
      filesUploadStore.restoreFilesUploadStore();
      navigate({
        pathname: "/blogs",
      });
    } catch (e) {
      filesUploadStore.restoreFilesUploadStore();
    }
  }, [blogId]);

  const onCreateBlog: SubmitHandler<IBLogForm> = useCallback(
    async (data: IBLogForm) => {
      try {
        const blogData = {
          id: Number(blogId),
          title: data.title,
          content: data.content,
        };
        if (filesUploadStore.newMediaFiles.length) {
          filesUploadStore.startFileUploadToAWS();
        }

        await updateBlog({
          variables: {
            updateBlogInput: blogData,
          },
          ...(!filesUploadStore.newMediaFiles.length
            ? {
                refetchQueries: [
                  {
                    query: GET_BLOGS,
                    variables: {
                      ListBlogsInput: {
                        limit: blogStore.limit,
                        offset: blogStore.offset,
                      },
                    },
                  },
                ],
              }
            : {}),
        }).then((res) => {
          if (res?.data) {
            breadcrumbStore.addBlog({
              id: blogId!,
              name: data.title,
            });
            if (!filesUploadStore.isStartFileUploadToAWS) {
              navigate({
                pathname: "/blogs",
              });
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    [updateBlog, blogId]
  );

  useEffect(() => {
    if (blogData) {
      setBlog(blogData);
    }
  }, [blogData]);

  useEffect(() => {
    if (blog) {
      reset({
        ...blog,
      });
      const isImage = checkHasImageExtension(blog?.cover);
      const blogDefaultFile = blog.cover
        ? [
            {
              key: blog.cover,
              type: isImage ? "image" : "video",
              isCover: true,
            },
          ]
        : [];
      filesUploadStore.assignMediaFiles(filterMediaTypename(blog.media) || blogDefaultFile);
    }
  }, [reset, blog]);

  const onEditContent = useCallback(
    (content: string) => {
      if (content) {
        setValue("content", content, { shouldValidate: true });
      }

      if (blog) {
        const blogTitle = getValues("title") || blog.title;
        updateBlog({
          variables: {
            updateBlogInput: {
              id: Number(blogId),
              title: blogTitle,
              content: content,
            },
          },
        }).catch(() => {});

        return;
      }

      createBlog({
        variables: {
          createBlogInput: {
            title: "",
            content: content,
          },
        },
        refetchQueries: [
          {
            query: GET_BLOGS,
            variables: {
              ListBlogsInput: {
                limit: blogStore.limit,
                offset: blogStore.offset,
              },
            },
          },
        ],
      }).then((res) => {
        if (res?.data) {
          setBlog({
            ...blog,
            content: content,
          });
        }
      });
    },
    [blog, blogId, createBlog, updateBlog]
  );

  const publishBlog = useCallback(
    async (isPublished: boolean, callback: () => void) => {
      const blogTitle = getValues("title") || blog.title;
      const blogContent = getValues("content") || blog.content;
      if (!blogId || !blogTitle || !blogContent) {
        message.error("Please fill in the required fields", 0.8);
        return;
      }

      try {
        if (isPublished) {
          if (filesUploadStore.newMediaFiles.length) {
            filesUploadStore.startFileUploadToAWS();
          }
        }

        updateBlog({
          variables: {
            updateBlogInput: {
              id: Number(blogId),
              title: blogTitle,
              content: blogContent,
              isPublished,
            },
          },
        }).then((res) => {
          if (res?.data) {
            callback();
          }
        });
      } catch (error) {}
    },
    [blog, blogId]
  );

  return (
    <form className="blog-form" onSubmit={handleSubmit(onCreateBlog)}>
      {blogUpdateError && <BaseAlert type={"failed"} message={blogUpdateError?.message} />}
      <div className={"event-form--content"}>
        <div className="blog-form--title">
          <div>
            <BaseInput
              newClassName={"modal-input"}
              label={{
                text: "Title",
                htmlFor: "title",
              }}
              registerValidation={{
                type: "text",
                name: "title",
                placeholder: "Title...",
                validation: {
                  ...register("title"),
                },
              }}
              error={errors.title?.message}
            />
          </div>
        </div>
        <ImageVideoUploadCarousel
          id={createData?.createBlog.id || blogData?.id}
          readOnly={false}
          updateMedia={updateBlogMedia}
          keyType="blogs"
          hasMultipleOptions={false}
        />
        <Editor
          id={createData?.createBlog.id || blogData?.id}
          type="blogs"
          onEditContent={onEditContent}
          error={errors.content?.message}
          content={blog?.content}
        />
      </div>
      <div className="blog-form--button-wrapper">
        <BaseButton className={"blog-form--buttons--save"} type="submit" title="Save" />
        <PublishButton
          isPublishedInitialValue={blog?.isPublished}
          publishBlog={publishBlog}
          hasFormErrors={!!Object.keys(errors).length}
        />
      </div>
    </form>
  );
};

export default BlogForm;
