import { FC, memo } from "react";
import clsx from "clsx";
import "./EventColorOption.scss";

interface EventColorOptionProps {
  option: {
    label: string;
    value: string;
  };
  onClick?: () => void;
  isActive?: boolean;
}

const EventColorOption: FC<EventColorOptionProps> = ({ option, isActive = false, onClick }) => (
  <div onClick={onClick} className="event-color-option-container">
    <div
      style={{ backgroundColor: option.value }}
      className={clsx("event-color-option", isActive ? "event-color-option-active" : "")}
    />
    <p className="event-color-text">{option.label}</p>
  </div>
);

export default memo(EventColorOption);
