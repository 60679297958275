import { gql } from "@apollo/client";

export const ACCEPT_EVENT_REQUEST = gql`
  mutation acceptEventRequest($id: Int!) {
    acceptEventRequest(id: $id) {
      id
      venueId
      creatorId
      eventId
      status
      createdAt
      updatedAt
    }
  }
`;
