import { gql } from "@apollo/client";

export const GET_ADMINS_HISTORY = gql`
  query listLogs($ListLogsInputArgs: ListLogsInput!) {
    listLogs(ListLogsInputArgs: $ListLogsInputArgs) {
      count
      logs {
        id
        userId
        user {
          name
        }
        actionType
        action
        resource
        updatedAt
      }  
    }
  }
`;
