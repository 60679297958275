import { makeAutoObservable } from "mobx";
import { IEventRequest } from "../types/eventRequestTypes";

export interface IEventRequestStore {
  eventRequestsList: IEventRequest[];
  eventRequest: IEventRequest | null;
  limit: number;
  offset: number;
  eventRequestsTotalCount: number;
}

class EventRequestStore implements IEventRequestStore {
  limit = 12;
  offset = 0;
  eventRequestsTotalCount = 0;
  eventRequestsList = [];
  eventRequest: IEventRequest | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addAllEventRequestsTotalCount(count: number) {
    this.eventRequestsTotalCount = count;
  }

  addEventRequests(data: []) {
    this.eventRequestsList = data;
  }

  selectCurrentEvent(eventRequest: IEventRequest | null) {
    this.eventRequest = eventRequest;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetEventRequests() {
    this.eventRequestsList = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new EventRequestStore();
