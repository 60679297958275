import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import ContactRequestsView from "./ContactRequestsView";
import "./ContactRequests.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CONTACT_REQUESTS } from "../../graphql/query/getContactRequests";
import { DELETE_CONTACT_REQUESTS } from "../../graphql/mutations/deleteContact";
import contactsStore from "../../store/contactsRequetsStore";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import BaseCreateOrDeleteModalLoading
  from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import { UPDATE_CONTACT_REQUEST } from "../../graphql/mutations/updateContactRequests";
import ContactRequestModal from "./ContactRequestModal";
import { IContactRequest } from "../../types/contactRequests";

type argsOption = {
  limit: number;
  offset: number;
};

export enum ContactStatus {
  RESOLVED = "resolved",
  PENDING = "pending",
  REJECTED = "rejected",
}

const ContactRequests = () => {
  const [listContactRequests, { loading, data }] = useLazyQuery(GET_CONTACT_REQUESTS, {
    fetchPolicy: "no-cache",
  });

  const [
    removeContact,
    { data: dataRemove, loading: loadingRemove, error: errorRemove, reset: resetErrorRemove },
  ] = useMutation(DELETE_CONTACT_REQUESTS);
  const [
    updateContact
  ] = useMutation(UPDATE_CONTACT_REQUEST);

  const [selectItem, setSelectItem] = useState<IContactRequest | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenViewModal, setIsOpenViewModal] = useState<boolean>(false);

  const getContactRequestsList = useCallback(
    (count?: number | null) => {
      const newOffset = count === 1 ? contactsStore.offset - contactsStore.limit : contactsStore.offset;

      const args: argsOption = {
        limit: contactsStore.limit,
        offset: newOffset,
      };

      listContactRequests({
        variables: {
          listAllInput: args,
        },
      });
    },
    [listContactRequests]
  );

  const fetchContactRequests = useCallback(async () => {
    await contactsStore.resetPagination();
    getContactRequestsList();
  }, [getContactRequestsList]);

  const changePage = (page: number) => {
    contactsStore.addOffset((page - 1) * contactsStore.limit);
    getContactRequestsList();
  };

  useEffect(() => {
    if (data?.listAllContacts?.contacts) {
      contactsStore.addContacts(data.listAllContacts.contacts);
      contactsStore.addAllContactsCount(data.listAllContacts.count);
    }
  }, [data?.listAllContacts]);

  useLayoutEffect(() => {
    fetchContactRequests();
  }, [fetchContactRequests]);

  const openOrCloseDeleteModal = useCallback((contact?: IContactRequest) => {
    if (contact) {
      setSelectItem(contact);
    }
    setIsOpenDeleteModal((prev) => !prev);
  }, []);

  const deleteContactRequestConfirm = useCallback(async () => {
    try {
      const { data } = await removeContact({
        variables: {
          id: +selectItem?.id!,
        },
      });

      if (
        contactsStore.offset / contactsStore.limit + 1 <
        Math.ceil(contactsStore.allContactsCount / contactsStore.limit) ||
        (contactsStore.contacts.length === 1 && contactsStore.offset !== 0)
      ) {
        getContactRequestsList(contactsStore.contacts.length);
      } else {
        contactsStore.removeContactsRequest(data?.deleteContactInfo.id);
      }
      setIsOpenDeleteModal(false);
    } catch (e) {
      setIsOpenDeleteModal(false);
    }
  }, [removeContact, selectItem?.id, getContactRequestsList]);

  const onViewClick = useCallback((contact: IContactRequest) => {
    setSelectItem(contact);
    setIsOpenViewModal(true);
  }, [setSelectItem, setIsOpenViewModal]);

  const onUpdateStatus = async ({
    status,
    id
  }: {
    status: ContactStatus;
    id: string;
  }) => {
    contactsStore.updateContactRequest({
      id,
      updatedFields: {
        status,
      }
    });
    await updateContact({
      variables: {
        updateContactInfoInput: {
          id,
          status,
        }
      },
    });
  }

  const onAddNote = useCallback(async (note: string) => {
    setIsOpenViewModal(false);
    await updateContact({
      variables: {
        updateContactInfoInput: {
          id: `${selectItem?.id!}`,
          note,
        }
      },
    });
    setSelectItem(null);
    contactsStore.updateContactRequest({
      id: selectItem?.id!,
      updatedFields: {
        note,
      }
    });
  }, [selectItem?.id, updateContact]);

  return (
    <div className="contact-requests-page">
      {loadingRemove ? <BaseCreateOrDeleteModalLoading /> : null}
      {errorRemove ? <BaseAlert type={"failed"} message={errorRemove?.message} /> : null}
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteContactRequestConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this contact?"}
        />
      ) : null}
      {
        !!selectItem && (
          <ContactRequestModal
            isOpen={isOpenViewModal}
            closeModal={() => setIsOpenViewModal(false)}
            contact={selectItem}
            onSuccess={onAddNote}
          />
        )
      }
      {dataRemove?.deleteContactInfo?.id ? (
        <BaseAlert
          type={"success"}
          message={"Contact successfully deleted"}
          reset={resetErrorRemove}
        />
      ) : null}
      <PageContentLayout
        view={
          <ContactRequestsView
            data={data}
            loading={loading}
            changePage={changePage}
            openOrCloseDeleteModal={openOrCloseDeleteModal}
            onViewClick={onViewClick}
            onUpdateStatus={onUpdateStatus}
          />
        }
      />
    </div>
  )
};

export default ContactRequests;