import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation checkOtp($checkOtpData: CheckOtpInput!) {
    checkOtp(checkOtpData: $checkOtpData) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;
