import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./TicketForm.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ICreateTicket, ITicket, TicketableTypeEnum } from "../../types/ticket";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Stepper from "../../components/Stepper/Stepper";
import TicketInfoForm from "./components/TicketInfoForm";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_VENUE } from "../../graphql/query/getVenue";
import { GET_EVENT } from "../../graphql/query/getEvent";
import { observer } from "mobx-react-lite";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import { CREATE_TICKET } from "../../graphql/mutations/createTicket";
import { validationSchema } from "./validation";
import { UPDATE_TICKET } from "../../graphql/mutations/updateTicket";
import dayjs from "dayjs";
import filesUploadStore from "../../store/filesUploadStore";
import { UPLOAD_MEDIA } from "../../graphql/mutations/uploadMedia";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { filterMediaTypename } from "../../utils/filterMediaTypename";

interface TicketFormProps {
  ticketData: ITicket;
}

const TicketForm: FC<TicketFormProps> = observer(({ ticketData }) => {
  const { search } = useLocation();
  const { venueId, eventId, ticketId } = useParams();
  const navigate = useNavigate();

  const [steppers] = useState<Array<string>>(["Info", "Image"]);
  const [stepCount, setStepCount] = useState<number>(+search.split("-")[1] || 1);
  const [isSaveAndBack, setIsSaveAndBack] = useState<boolean>(false);

  const [createTicket, { data: createData, loading: ticketLoading, error: ticketError }] =
    useMutation(CREATE_TICKET);

  const [updateTicket, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_TICKET);

  const [uploadMedia, { loading: uploadMediaLoading, error: uploadMediaError }] =
    useMutation(UPLOAD_MEDIA);

  const [getVenue, { loading: venueLoading, data: venueData, error: venueError }] = useLazyQuery(
    GET_VENUE,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getEvent, { loading: eventLoading, data: eventData, error: eventError }] = useLazyQuery(
    GET_EVENT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const defaultValues = !!ticketData
    ? ticketData
    : {
        id: 0,
        name: "",
        description: "",
        venueId: venueId!,
        currency: "GBP",
        ticketableId: eventId!,
        ticketableType: TicketableTypeEnum.EVENT,
        earlyBirdCount: 0,
      };

  const {
    reset,
    clearErrors,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ITicket>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const updateTicketMedia = useCallback(async () => {
    try {
      await uploadMedia({
        variables: {
          uploadMediaFromAdminInput: {
            type: "ticket",
            id: +ticketId!,
            media: filesUploadStore.mediaFilesToUploadBackend,
          },
        },
      });
      filesUploadStore.restoreFilesUploadStore();

      navigate({
        pathname: venueId
          ? `/venues/${venueId}/events/${eventId}/tickets`
          : `/events/${eventId}/tickets`,
      });
    } catch (e) {
      filesUploadStore.restoreFilesUploadStore();
    }
  }, [navigate, uploadMedia, eventId, venueId, ticketId]);

  const onCreateTicket: SubmitHandler<ITicket> = async (ticket: ITicket) => {
    if (steppers[stepCount - 1] === "Info" || steppers[stepCount - 1] !== "Image") {
      try {
        const ticketInput: ICreateTicket = {
          ticketableId: +eventId!,
          ticketableType: TicketableTypeEnum.EVENT,
          name: ticket.name,
          description: ticket.description,
          count: ticket.count,
          amount: ticket.amount,
          currency: ticket.currency,
          startTime: dayjs(ticket.startTime),
          endTime: dayjs(ticket.endTime),
        };
        if (ticket.earlyBirdAmount) {
          ticketInput.earlyBirdAmount = +ticket.earlyBirdAmount;
        }
        if (ticket.earlyBirdCount) {
          ticketInput.earlyBirdCount = +ticket.earlyBirdCount;
        }
        const { data } = await createTicket({
          variables: {
            input: ticketInput,
          },
        });
        if (!isSaveAndBack) {
          navigate({
            pathname: venueId
              ? `/venues/${venueId}/events/${eventId!}/tickets/${data.createTicket.id}`
              : `/events/${eventId!}/tickets/${data.createTicket.id}`,
            search: `step-${stepCount + 1}`,
          });
        } else {
          navigate({
            pathname: venueId
              ? `/venues/${venueId}/events/${eventId!}/tickets`
              : `/events/${eventId!}/tickets`,
          });
        }
        return;
      } catch (e) {
        console.error(e);
        return;
      }
    }
    navigate({
      search: `step-${stepCount + 1}`,
    });
  };

  const onUpdateTicket: SubmitHandler<ITicket> = async (updateTicketItem: ITicket) => {
    if (
      steppers[stepCount - 1] === "Info" ||
      (isSaveAndBack && steppers[stepCount - 1] !== "Image")
    ) {
      const ticketInput: any = {
        id: ticketData.id,
        name: updateTicketItem.name,
        description: updateTicketItem.description,
        count: updateTicketItem.count,
        amount: updateTicketItem.amount,
        currency: updateTicketItem.currency,
        startTime: dayjs(updateTicketItem.startTime),
        endTime: dayjs(updateTicketItem.endTime),
      };
      if (updateTicketItem.earlyBirdCount) {
        ticketInput.earlyBirdCount = +updateTicketItem.earlyBirdCount;
      }
      if (updateTicketItem.earlyBirdAmount) {
        ticketInput.earlyBirdAmount = +updateTicketItem.earlyBirdAmount;
      }
      const { data } = await updateTicket({
        variables: {
          input: ticketInput,
        },
      });
      if (isSaveAndBack) {
        navigate({
          pathname: venueId
            ? `/venues/${venueId}/events/${eventId!}/tickets`
            : `/events/${eventId!}/tickets`,
        });
      } else {
        const pathname = venueId
          ? `/venues/${venueId}/events/${eventId!}/tickets/${data.updateTicket.id}`
          : `/events/${eventId!}/tickets/${data.updateTicket.id}`;
        const search = `step-${stepCount + 1}`;
        navigate({
          pathname,
          search,
        });
      }
    }

    if (steppers[stepCount - 1] === "Image") {
      if (filesUploadStore.newMediaFiles.length) {
        filesUploadStore.startFileUploadToAWS();
      } else {
        updateTicketMedia();
      }
    }
  };

  const decrementStepCount = () => {
    navigate({
      search: `step-${stepCount - 1}`,
    });
  };

  useLayoutEffect(() => {
    if (eventId) {
      getEvent({
        variables: {
          id: +eventId!,
        },
      });
    }
  }, [getEvent, eventId]);

  useEffect(() => {
    if (ticketData) {
      reset({
        ...ticketData,
        endTime: ticketData?.endTime
          ? dayjs(ticketData.endTime).format("YYYY-MM-DD HH:mm")
          : undefined,
        startDate: ticketData?.startTime
          ? dayjs(ticketData.startDate).format("YYYY-MM-DD HH:mm")
          : undefined,
        currency: "GBP",
      });
      filesUploadStore.assignMediaFiles(filterMediaTypename(ticketData?.media) || []);
    }
  }, [reset, ticketData]);

  useEffect(() => {
    search && setStepCount(+search.split("-")[1]);
  }, [search]);

  return (
    <form
      onSubmit={handleSubmit(ticketData ? onUpdateTicket : onCreateTicket)}
      className={"ticket-form"}
    >
      <Stepper
        steppers={steppers}
        stepCount={stepCount}
        decrementStepCount={decrementStepCount}
        saveAndBack={() => {
          setIsSaveAndBack(true);
        }}
        isUpdateMode={!!eventId}
      >
        <div className={"ticket-form--content"}>
          <div
            className={`${
              stepCount === 1 ? "ticket-form--info-step" : "ticket-form--display-none"
            }`}
          >
            <TicketInfoForm
              ticketData={!!ticketData ? ticketData : defaultValues}
              register={register}
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              isDisabled={false}
              errors={errors}
            />
          </div>
          <div
            className={`${stepCount === 2 ? "event-form--info-step" : "event-form--display-none"}`}
          >
            {
              <ImageVideoUploadCarousel
                id={createData?.ticketData?.id || ticketData?.id}
                readOnly={false}
                updateMedia={updateTicketMedia}
                keyType={"tickets"}
              />
            }
          </div>
        </div>
      </Stepper>

      {ticketLoading || updateLoading || uploadMediaLoading ? (
        <BaseCreateOrDeleteModalLoading />
      ) : null}

      {ticketError || updateError || uploadMediaError ? (
        <BaseAlert type={"failed"} message={updateError?.message || ticketError?.message} />
      ) : null}
    </form>
  );
});

export default TicketForm;
