import { FC } from "react";
import { Table } from "antd";
import TableActions from "./TableActions";
import { IAdminRequest } from "../../../../types/adminRequests";
import { AdminStatus } from "../../../../pages/AdminRequests/AdminRequests";
import { AdminRoles } from "../../../../types/types";
import { IVenue } from "../../../../types/venue";
import { categoryMap } from "./constants";

interface ContactRequestsTableProps {
  data: IAdminRequest[];
  onUpdateStatus: ({
    status,
    id,
    item,
  }: {
    status: AdminStatus;
    id: string;
    item: IAdminRequest;
  }) => void;
}

const AdminRequestsTable: FC<ContactRequestsTableProps> = ({ data, onUpdateStatus }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <p className={"base-responsive-table-name"}>{email}</p>;
      },
    },
    {
      title: "Venue name",
      dataIndex: "venue",
      key: "venueName",
      render: (venue: IVenue) => {
        return <p className={"base-responsive-table-name"}>{venue?.name || "-"}</p>;
      },
    },
    {
      title: "Venue address",
      dataIndex: "venue",
      key: "venueAddress",
      render: (venue: IVenue) => {
        return <p className={"base-responsive-table-name"}>{venue?.address || "-"}</p>;
      },
    },
    {
      title: "Category",
      dataIndex: "role",
      key: "role",
      render: (role: AdminRoles) => {
        return <p className={"base-responsive-table-name"}>{categoryMap[role]}</p>;
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (message: string) => {
        return (
          <p className={"base-responsive-table-description"}>{message ? message : "No Message"}</p>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: IAdminRequest) => {
        return <TableActions item={item} onUpdateStatus={onUpdateStatus} />;
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
    />
  );
};

export default AdminRequestsTable;
