import React, { FC } from "react";
import "./EventHashtags.scss";
import { IEventData } from "../../types/eventTypes";
import ViewInput from "../../components/reusable/viewInput/ViewInput";
import HashtagsList from "../../components/reusable/hashtagsList/HashtagsList";

interface IEventHashtags {
  event: IEventData;
  hashtagsList: string[];
}

const EventHashtags: FC<IEventHashtags> = ({ event, hashtagsList }) => {
  return (
    <div className={"event-hashtags"}>
      <div className={"event-hashtags--input-block"}>
        <ViewInput title={"Dj / Band Name"} value={event.bandName} />
      </div>
      <div className={"event-hashtags--hashtag-block"}>
        <HashtagsList
          isRemovable={false}
          isSelectable={false}
          setSelectedHashTags={() => {}}
          selectedHashTags={hashtagsList}
        />
      </div>
    </div>
  );
};

export default EventHashtags;
