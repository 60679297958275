import "./NavBar.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import userStore from "../../store/userStore";
import { isSuperAdmin } from "../../utils/isSuperAdmin";
import { FC, useMemo } from "react";
import { EVENT_ADMIN_MENU, SUPER_ADMIN_MENU, VENUE_MANAGER_MENU } from "./Helpers";
import { UserRole } from "../../types/types";
import UserDropdown from "./UserDropdown";
import { renderNavbarItem } from "./utils";

interface NavBarProps {
  userRole: UserRole;
}

export interface NavItem {
  icon: JSX.Element;
  name: string;
  to?: string;
  hasBadge?: boolean;
  menu?: Array<{ name: string; to: string }>;
}

const NavBar: FC<NavBarProps> = ({ userRole }) => {
  const navItems: Array<NavItem> = useMemo(() => {
    switch (userRole) {
      case UserRole.superAdmin:
        return SUPER_ADMIN_MENU;
      case UserRole.admin:
        return EVENT_ADMIN_MENU;
      case UserRole.venueManager:
      default:
        return VENUE_MANAGER_MENU;
    }
  }, [userRole]);

  return (
    <div className={"nav-bar"}>
      <div className={"nav-bar--logo"}>
        <Link to={isSuperAdmin(userStore.user) ? "/dashboard" : "/"}>
          <Logo />
        </Link>
      </div>
      <div className={"nav-bar-wrapper"}>{navItems.map(renderNavbarItem)}</div>
      <UserDropdown />
    </div>
  );
};

export default NavBar;
