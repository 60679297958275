import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { VERIFY_QR_CODE } from "../../graphql/mutations/verifyQrCode";
import { useParams } from "react-router-dom";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import { Result } from "antd";

const VerifyQrCode = () => {
  const [loading, setLoading] = useState(true);
  const { qrcode_id } = useParams();

  const [verifyQrCode, { error }] = useMutation(VERIFY_QR_CODE, {
    fetchPolicy: "no-cache",
  });

  const verifyQr = useCallback(async () => {
    try {
      await verifyQrCode({
        variables: {
          id: qrcode_id,
        },
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [qrcode_id]);

  useEffect(() => {
    verifyQr();
  }, [verifyQr]);

  if (error) {
    return <Result style={{ marginTop: "200px" }} status="error" title={error.message} />;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <Result
      style={{ marginTop: "200px" }}
      status="success"
      title="Your QR Code successfully verified"
    />
  );
};

export default VerifyQrCode;
