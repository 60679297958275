import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SHARE_LIKE_COMMENTS_COUNT } from "../../../graphql/query/getShareLikeCommentsCount";

const NewlyAddedPosts = () => {
  const [likesCount, setLikesCount] = useState(0);
  const [sharesCount, setSharesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [getPostStatistics, { data }] = useLazyQuery(GET_SHARE_LIKE_COMMENTS_COUNT);
  useEffect(() => {
    (async () => {
      await getPostStatistics();
    })();
  }, []);

  useEffect(() => {
    if (data) {
      setLikesCount(data.shareLikeCommentsCount.totalLikes);
      setSharesCount(data.shareLikeCommentsCount.totalShares);
      setCommentsCount(data.shareLikeCommentsCount.totalComments);
    }
  }, [data]);
  return (
    <>
      <div className={"dashboard-page-posts-analytics-total d-flex-column gap-20"}>
        <h2 className={"dashboard-page-title"}>Totals</h2>
        <div className={"d-flex-aligned-center dashboard-page-posts-analytics-total-item gap-10"}>
          Total Likes
          <p>{likesCount}</p>
        </div>
        <div className={"d-flex-aligned-center dashboard-page-posts-analytics-total-item gap-10"}>
          Total Comments
          <p>{commentsCount}</p>
        </div>
        <div className={"d-flex-aligned-center dashboard-page-posts-analytics-total-item gap-10"}>
          Total Shares
          <p>{sharesCount}</p>
        </div>
      </div>
    </>
  );
};

export default NewlyAddedPosts;
