import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { isAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import blogStore from "../../store/blogStore";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import NoData from "../../components/reusable/noData/NoData";
import BlogsSearch from "./BlogsSearch";
import { PanelItem } from "../../types/types";
import BlogTable from "../../components/reusable/baseResponsiveItemsPanel/blogTable/BlogTable";

interface BlogsViewProps {
  loading: any;
  data: any;
  goToCurrentBlog: any;
  openOrCloseDeleteModal: any;
  changePage: any;
  onEditBlog: (item: PanelItem) => void;
  createBlog: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const BlogsView: FC<BlogsViewProps> = observer(({
  data,
  loading,
  setSearch,
  createBlog,
  onEditBlog,
  goToCurrentBlog,
  openOrCloseDeleteModal,
  changePage
}) => {
  return (
    <div className={"blog-page--container"}>
      {isAdmin(userStore.user) ? (
        <BlogsSearch setSearch={setSearch} createBlog={createBlog} />
      ) : null}
      {loading || !data ? (
        <BaseLoader />
      ) : (
        <>
          {data?.listBlogs?.blogs?.length ? (
            <>
              <BlogTable
                data={blogStore.blogs}
                onSelectItem={goToCurrentBlog}
                onEditItem={onEditBlog}
                onDeleteItem={openOrCloseDeleteModal}
              />
              <BasePagination
                totalPages={Math.ceil(blogStore.allBlogsCount / blogStore.limit)}
                currentPage={blogStore.offset / blogStore.limit + 1}
                changePage={changePage}
              />
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
});

export default BlogsView;