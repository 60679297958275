// import React, { FC, useCallback, useEffect, useRef, useState } from "react";
// import "./AddressAutocomplete.scss";
// import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
// import useOnClickOutside from "../../../hooks/useOutsideClickEvent";
// import { ILabel, IValidationInput } from "../../../types/types";
// import { Select } from "antd";
// const { Option } = Select;
// interface IPredictions {
//   address: string;
// }
//
// interface BaseInputInterfaceProps {
//   addressValue: string;
//   disabled: boolean;
//   label: ILabel;
//   handleChangeValue: (address: string) => void;
//   clearErrors?: () => void;
//   adAddressLocation?: (data: string[]) => void;
//   registerValidation: IValidationInput;
//   newClassName?: string;
//   error?: string;
// }
//
// const AddressAutocomplete: FC<BaseInputInterfaceProps> = ({
//   addressValue,
//   disabled,
//   label,
//   handleChangeValue,
//   clearErrors,
//   adAddressLocation,
//   registerValidation,
//   newClassName,
//   error,
// }) => {
//   const [searchValue, setSearchValue] = useState<string>("");
//   const [options, setOptions] = useState<any[]>([]);
//
//   const {
//     ready,
//     suggestions: { status, data },
//     setValue,
//     clearSuggestions,
//   } = usePlacesAutocomplete({
//     requestOptions: {},
//     debounce: 300,
//   });
//
//   const handleSearch = (value: string) => {
//     setSearchValue(value);
//     setValue(value);
//   };
//
//   const handleSelect = (value: string) => {
//     setValue(value, false);
//     setSearchValue(value);
//     clearSuggestions();
//
//     getGeocode({ address: value })
//       .then((results: any) => {
//         const { lat, lng } = getLatLng(results[0]);
//         adAddressLocation!([lng.toString(), lat.toString()]);
//       })
//       .catch((e) => {
//         console.log(e, "error");
//       });
//
//     handleChangeValue(value);
//     if (clearErrors) {
//       clearErrors();
//     }
//   };
//
//   useEffect(() => {
//     if (searchValue && ready) {
//       setOptions(
//         data.map((suggestion: any) => ({
//           value: suggestion.description,
//           label: suggestion.description,
//         }))
//       );
//     } else {
//       setOptions([]);
//     }
//   }, [searchValue, data, ready]);
//
//   useEffect(() => {
//     setValue(addressValue, false);
//     setSearchValue(addressValue);
//     clearSuggestions();
//   }, [addressValue, clearSuggestions, setValue]);
//
//   return (
//     <div className={"base-input"}>
//       <label
//         htmlFor={`${label.htmlFor}`}
//         className={`base-input--label  ${label.className} ${registerValidation.className} ${
//           error ? "base-input--label-error" : ""
//         }`}
//       >
//         {label.text}
//       </label>
//       <Select
//         showSearch
//         value={searchValue}
//         options={options}
//         disabled={!ready || disabled}
//         onChange={handleSelect}
//         onSearch={handleSearch}
//         filterOption={false}
//         placeholder={`${registerValidation.placeholder}`}
//         className={`address-autocomplete--input  ${newClassName} ${
//           error ? "address-autocomplete--input-error" : ""
//         }`}
//       />
//       {/*<input*/}
//       {/*  autoComplete="off"*/}
//       {/*  value={value}*/}
//       {/*  onChange={handleInput}*/}
//       {/*  disabled={!ready || disabled}*/}
//       {/*  type={`${registerValidation.type}`}*/}
//       {/*  name={`${registerValidation.name}`}*/}
//       {/*  id={`${registerValidation.name}`}*/}
//       {/*  placeholder={`${registerValidation.placeholder}`}*/}
//       {/*  className={`address-autocomplete--input  ${newClassName} ${*/}
//       {/*    error ? "address-autocomplete--input-error" : ""*/}
//       {/*  }`}*/}
//       {/*/>*/}
//       {/*{status === "OK" && (*/}
//       {/*  <ul className={"address-autocomplete--location-predictions"} id={"predictions"}>*/}
//       {/*    {renderSuggestions()}*/}
//       {/*  </ul>*/}
//       {/*)}*/}
//       <span className={"base-input--error"}>{error}</span>
//     </div>
//   );
// };
//
// export default AddressAutocomplete;
import { FC, useCallback, useEffect, useRef, useState } from "react";
import "./AddressAutocomplete.scss";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnClickOutside from "../../../hooks/useOutsideClickEvent";
import { ILabel, IValidationInput } from "../../../types/types";

interface IPredictions {
  address: string;
}

interface BaseInputInterfaceProps {
  addressValue: string;
  disabled: boolean;
  label: ILabel;
  handleChangeValue: (address: string) => void;
  clearErrors?: () => void;
  adAddressLocation?: (data: string[]) => void;
  registerValidation: IValidationInput;
  newClassName?: string;
  error?: string;
}

const AddressAutocomplete: FC<BaseInputInterfaceProps> = ({
  addressValue,
  disabled,
  label,
  handleChangeValue,
  clearErrors,
  adAddressLocation,
  registerValidation,
  newClassName,
  error,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const [predictions, setPredictions] = useState<IPredictions[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const hidePredictions = useCallback(() => {
    setPredictions([]);
    clearSuggestions();
  }, [clearSuggestions]);

  useOnClickOutside(ref, hidePredictions);

  const handleInput = (e: any) => {
    setValue(e.target.value);
    setPredictions([]);
    handleChangeValue("");
    if (!e.target.value) {
      if (adAddressLocation) {
        adAddressLocation([""]);
      }
    }
  };

  const handleSelect = (address: any) => () => {
    getGeocode({ address: address.description })
      .then((results: any) => {
        const { lat, lng } = getLatLng(results[0]);
        adAddressLocation!([lng.toString(), lat.toString()]);
      })
      .catch((e) => {
        console.log(e, "error");
      });
    setValue(address.description, false);
    hidePredictions();
    handleChangeValue(address.description);
    if (clearErrors) {
      clearErrors();
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion: any) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    setValue(addressValue, false);
    hidePredictions();
  }, [addressValue, hidePredictions, setValue]);

  return (
    <div className={"base-input"} ref={ref}>
      <label
        htmlFor={`${label.htmlFor}`}
        className={`base-input--label  ${label.className} ${registerValidation.className} ${
          error ? "base-input--label-error" : ""
        }`}
      >
        {label.text}
      </label>
      <input
        autoComplete="off"
        value={value}
        onChange={handleInput}
        disabled={!ready || disabled}
        type={`${registerValidation.type}`}
        name={`${registerValidation.name}`}
        id={`${registerValidation.name}`}
        placeholder={`${registerValidation.placeholder}`}
        className={`address-autocomplete--input  ${newClassName} ${
          error ? "address-autocomplete--input-error" : ""
        }`}
      />
      {predictions.length > 0 && (
        <ul className={"address-autocomplete--location-predictions"} id={"predictions"}>
          {predictions.map((prediction, index) => (
            <li key={index} onClick={handleSelect(prediction)}>
              {prediction?.address}
            </li>
          ))}
        </ul>
      )}

      {status === "OK" && (
        <ul className={"address-autocomplete--location-predictions"} id={"predictions"}>
          {renderSuggestions()}
        </ul>
      )}
      <span className={"base-input--error"}>{error}</span>
    </div>
  );
};

export default AddressAutocomplete;
