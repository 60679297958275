import pub from "../../../../assets/images/pub.png";
import { ReactComponent as EmptyRating } from "../../../../assets/images/EmptyRating.svg";
import { ReactComponent as FilledRating } from "../../../../assets/images/FilledRating.svg";
import { memo } from "react";
import { Table } from "antd";
import { BASE_URL_IMG } from "../../../../constants/Api";
import { TableActions, TableProps } from "../helpers/TableHelper";
import { isAdmin } from "../../../../utils/isSuperAdmin";
import userStore from "../../../../store/userStore";
import { Breakpoint } from "antd/es/_util/responsiveObserver";

const VenueTable = ({ data, onSelectItem, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Image",
      dataIndex: "cover",
      key: "cover",
      render: (cover: string) => {
        return (
          <div className={"base-responsive-table-image"}>
            <img src={cover ? BASE_URL_IMG + cover : pub} alt="table_image" />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => {
        return (
          <p className={"base-responsive-table-description"}>
            {description ? description : "No Description"}
          </p>
        );
      },
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Average Rate",
      dataIndex: "avgRate",
      key: "avgRate",
      render: (avgRate: number) => {
        return (
          <div className={"base-responsive-table-rate"}>
            {new Array(avgRate).fill("rate").map((rate, index) => (
              <FilledRating key={index} />
            ))}
            {new Array(5 - avgRate!).fill("rate").map((rate, index) => (
              <EmptyRating key={index} />
            ))}
          </div>
        );
      },
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <TableActions
            item={item}
            onSelectItem={onSelectItem}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
            showEditDeleteActions={!isAdmin(userStore.user)}
          />
        );
      },
    },
  ];

  return (
    <Table
      className={"base-responsive-table base-responsive-table-mobile"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
      onRow={(record) => {
        return {
          onClick: () => {
            if (onSelectItem) {
              onSelectItem(record);
            }
          },
        };
      }}
    />
  );
};

export default memo(VenueTable);
