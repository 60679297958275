import { FC, useEffect, useState } from "react";
import "./AdminOrStaffModalForm.scss";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import { EMAIL_INVALID_TEXT, EMAIL_REQUIRED_TEXT } from "../../constants/errors";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN } from "../../graphql/mutations/createAdmin";
import { UPDATE_ADMIN } from "../../graphql/mutations/updateAdmin";
import { DELETE_ADMIN } from "../../graphql/mutations/deleteAdmin";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import adminStore from "../../store/adminStaffStore";
import { IAdminOrStaffItem } from "../../types/adminOrstaff";
import { CREATE_STAFF } from "../../graphql/mutations/createStaff";
import { useParams } from "react-router-dom";
import { UPDATE_STAFF } from "../../graphql/mutations/updateStaff";
import { DELETE_STAFF } from "../../graphql/mutations/deleteStaff";

interface AdminOrStaffModalFormProps {
  type: string;
  closModal: () => void;
  editAdminOrStaffCandidate?: IAdminOrStaffItem | null;
  getAdminsOrStaffList: (count?: number) => void;
}

const AdminOrStaffModalForm: FC<AdminOrStaffModalFormProps> = ({
  type,
  closModal,
  editAdminOrStaffCandidate,
  getAdminsOrStaffList,
}) => {
  const { venueId } = useParams();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const [createAdmin, { loading: createAdminLoading, error: createAdminError }] =
    useMutation(CREATE_ADMIN);

  const [updateAdmin, { loading: updateAdminLoading, error: updateAdminError }] =
    useMutation(UPDATE_ADMIN);

  const [removeAdmin, { loading: removeAdminLoading, error: removeAdminError }] =
    useMutation(DELETE_ADMIN);

  const [createStaff, { loading: createStaffLoading, error: createStaffError }] =
    useMutation(CREATE_STAFF);

  const [updateStaff, { loading: updateStaffLoading, error: updateStaffError }] =
    useMutation(UPDATE_STAFF);

  const [removeStaff, { loading: removeStaffLoading, error: removeStaffError }] =
    useMutation(DELETE_STAFF);

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    email: yup.string().required(EMAIL_REQUIRED_TEXT).email(EMAIL_INVALID_TEXT),
    phone: yup.string().notRequired(),
    position: yup.string().notRequired(),
    permissions: yup.array().min(1, "Select at least one!"),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IAdminOrStaffItem>({
    resolver: yupResolver(validationSchema),
  });

  const calculatePagination = (id: number) => {
    if (
      adminStore.offset > 0 &&
      (adminStore.offset / adminStore.limit + 1 <
        Math.ceil(adminStore.allDataListCount / adminStore.limit) ||
        adminStore.dataList.length === 1)
    ) {
      getAdminsOrStaffList(adminStore.dataList.length);
    } else {
      adminStore.removeData(id);
    }
    closModal();
  };

  const onUpdateAdmin: SubmitHandler<IAdminOrStaffItem> = async (data) => {
    try {
      const newData = {
        adminUserId: editAdminOrStaffCandidate?.id,
        email: data.email,
        name: data.name,
        permissions: [1],
      };
      const { data: admin } = await updateAdmin({
        variables: {
          updateAdminInput: newData,
        },
      });
      adminStore.updateDataList(admin.updateAdmin);
      closModal();
    } catch (e) {}
  };
  const onCreateAdmin: SubmitHandler<IAdminOrStaffItem> = async (data) => {
    const newData = {
      email: data.email,
      name: data.name,
      permissions: [1],
    };
    try {
      await createAdmin({
        variables: {
          createAdminInput: newData,
        },
      });
      adminStore.addOffset(0);
      closModal();
      getAdminsOrStaffList();
    } catch (e) {}
  };

  const deleteAdminConfirm = async () => {
    const { data } = await removeAdmin({
      variables: {
        id: editAdminOrStaffCandidate?.id,
      },
    });

    calculatePagination(data?.removeAdmin?.id);
  };

  const onUpdateStaff: SubmitHandler<IAdminOrStaffItem> = async (data) => {
    try {
      const newData = {
        venueStaffId: editAdminOrStaffCandidate?.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        position: data.position,
      };
      const { data: staff } = await updateStaff({
        variables: {
          updateVenueStaffInput: newData,
        },
      });
      adminStore.updateDataList(staff.updateVenueStaff);
      closModal();
    } catch (e) {}
  };
  const onCreateStaff: SubmitHandler<IAdminOrStaffItem> = async (data) => {
    const newData = {
      email: data.email,
      name: data.name,
      phone: data.phone,
      position: data.position,
      venueId: +venueId!,
    };
    try {
      await createStaff({
        variables: {
          createVenueStaffInput: newData,
        },
      });
      adminStore.addOffset(0);
      closModal();
      getAdminsOrStaffList();
    } catch (e) {}
  };

  const deleteStaffConfirm = async () => {
    const { data } = await removeStaff({
      variables: {
        venueStaffId: editAdminOrStaffCandidate?.id,
      },
    });

    calculatePagination(data?.deleteVenueStaff?.id);
  };

  const openOrCloseDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };

  useEffect(() => {
    if (editAdminOrStaffCandidate) {
      if (editAdminOrStaffCandidate.permissions?.length === 1) {
        editAdminOrStaffCandidate.permissions =
          editAdminOrStaffCandidate.permissions[0] === 2 ? [0, 2] : [1, 0];
      }
      reset(editAdminOrStaffCandidate);
    }
  }, [editAdminOrStaffCandidate, reset]);

  return (
    <form
      onSubmit={handleSubmit(
        type === "admin"
          ? editAdminOrStaffCandidate
            ? onUpdateAdmin
            : onCreateAdmin
          : editAdminOrStaffCandidate
          ? onUpdateStaff
          : onCreateStaff
      )}
      className={"admin-form"}
    >
      {createAdminLoading ||
      removeAdminLoading ||
      updateAdminLoading ||
      createStaffLoading ||
      updateStaffLoading ||
      removeStaffLoading ? (
        <BaseCreateOrDeleteModalLoading />
      ) : null}

      {createAdminError ||
      removeAdminError ||
      updateAdminError ||
      createStaffError ||
      updateStaffError ||
      removeStaffError ? (
        <BaseAlert
          type={"failed"}
          message={
            createAdminError?.message ||
            removeAdminError?.message ||
            updateAdminError?.message ||
            createStaffError?.message ||
            updateStaffError?.message ||
            removeStaffError?.message
          }
        />
      ) : null}
      {isOpenDeleteModal && (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={type === "admin" ? deleteAdminConfirm : deleteStaffConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this admin?"}
        />
      )}
      <div className={"admin-form--block"}>
        <div className={"admin-form--left-block"}>
          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Name Surname",
              htmlFor: "name",
              className: "required"
            }}
            registerValidation={{
              type: "text",
              name: "name",
              placeholder: "Name Surname...",
              validation: {
                ...register("name", {
                  required: true,
                }),
              },
            }}
            error={errors.name?.message}
          />

          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Email Address",
              htmlFor: "email",
              className: "required"
            }}
            registerValidation={{
              type: "text",
              name: "email",
              placeholder: "Email Address...",
              validation: {
                ...register("email"),
              },
            }}
            error={errors.email?.message}
          />

          {type === "staff" ? (
            <>
              <BaseInput
                newClassName={"modal-input"}
                label={{
                  text: "Phone number",
                  htmlFor: "phone",
                }}
                registerValidation={{
                  type: "text",
                  name: "phone",
                  placeholder: "Phone number...",
                  validation: {
                    ...register("phone"),
                  },
                }}
              />
              <BaseInput
                newClassName={"modal-input"}
                label={{
                  text: "Position",
                  htmlFor: "position",
                }}
                registerValidation={{
                  type: "text",
                  name: "position",
                  placeholder: "Position...",
                  validation: {
                    ...register("position"),
                  },
                }}
              />
            </>
          ) : null}
        </div>
      </div>
      <div className={"admin-form--btn"}>
        <BaseButton
          type={"submit"}
          title={
            type === "admin"
              ? editAdminOrStaffCandidate
                ? "Update Admin"
                : "Add Admin"
              : editAdminOrStaffCandidate
              ? "Update Staff"
              : "Add Staff"
          }
        />
      </div>
    </form>
  );
};

export default AdminOrStaffModalForm;
