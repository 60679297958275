import { gql } from "@apollo/client";

export const DELETE_DISCOUNT = gql`
  mutation removeDiscount($id: Int!) {
    removeDiscount(id: $id) {
      status
      message
    }
  }
`;
