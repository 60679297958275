import React, { useCallback, useEffect } from "react";
import Modal from "../Modal/Modal";
import "./UserStatusModal.scss";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_STATUS } from "../../graphql/mutations/updateUserStatus";
import appUsersStore from "../../store/appUsersStore";
import { UserStatus } from "../../types/user";
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";

interface IUserStatusModal {
  userModalVisible: boolean;
  setUserModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo: any;
  setUserInfo: React.Dispatch<React.SetStateAction<any>>;
}
const UserStatusModal: React.FC<IUserStatusModal> = ({
  userModalVisible,
  setUserModalVisible,
  userInfo,
  setUserInfo,
}) => {
  const [form] = Form.useForm();

  const closeUserModal = useCallback(() => {
    setUserInfo(null);
    setUserModalVisible(false);
  }, [setUserInfo, setUserModalVisible]);

  const [updateUserStatus, { data: userUpdatedData, loading }] = useMutation(UPDATE_USER_STATUS);

  const handleUpdateUserStatus = useCallback(
    (values: any) => {
      updateUserStatus({
        variables: {
          updateUserStatusArgs: {
            id: +userInfo.id,
            status:
              userInfo.status !== "blocked"
                ? UserStatus[UserStatus.blocked]
                : UserStatus[UserStatus.active],
            reason: values.reason,
          },
        },
      })
        .then(() => {
          closeUserModal();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [userInfo, closeUserModal, updateUserStatus]
  );

  useEffect(() => {
    if (userUpdatedData) {
      appUsersStore.updateUserStatusAction(userUpdatedData);
    }
  }, [userUpdatedData]);

  return (
    <div>
      {userModalVisible ? (
        <Modal
          closeModal={closeUserModal}
          modalIsOpen={userModalVisible}
          showCloseButton
          className={"user-status-modal"}
        >
          <div className={"user-status-modal-body d-flex-column gap-20"}>
            <h2 className={"user-status-modal-body-title"}>
              {userInfo.status === "blocked"
                ? `Are you sure you want to unblock "${userInfo.name}" ?`
                : `Are you sure you want to block "${userInfo.name}" ?`}
            </h2>
            <Form
              form={form}
              onFinish={(values: any) => {
                handleUpdateUserStatus(values);
              }}
              className={"user-status-modal-body-form"}
            >
              <Form.Item
                rules={[{ required: true, message: "You must add a reason" }]}
                name={"reason"}
              >
                <TextArea rows={4} placeholder={"Please write a reason"} />
              </Form.Item>
              <div className={"user-status-modal-body-form-actions d-flex-aligned-end gap-10"}>
                <Button type={"primary"} htmlType={"submit"} loading={loading}>
                  Update
                </Button>
                <Button type={"default"} htmlType={"button"} onClick={closeUserModal}>
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default UserStatusModal;
