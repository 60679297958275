import React, { memo, useCallback, useEffect, useState } from "react";
import "./UsersHashtag.scss";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import UsersHashtagModalForm from "../../forms/UsersHashtagModalForm/UsersHashtagModalForm";
import Modal from "../../components/Modal/Modal";
import { useLazyQuery } from "@apollo/client";
import { GET_USERS_LIST } from "../../graphql/query/getUsers";
import { observer } from "mobx-react-lite";
import appUsersStore from "../../store/appUsersStore";
import BaseError from "../../components/reusable/baseError/BaseError";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import { useDebounce } from "../../hooks/useDebounce";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import NoData from "../../components/reusable/noData/NoData";
import { Button } from "antd";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import UserTable from "../../components/reusable/baseResponsiveItemsPanel/userTable/UserTable";

type argsOption = {
  limit: number;
  offset: number;
  name?: string;
};

const UsersHashtag = observer(() => {
  const [isHashtagModalOpen, setIsHashtagModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const debounced = useDebounce(search);

  const [getAppUsers, { loading, error, data }] = useLazyQuery(GET_USERS_LIST, {
    fetchPolicy: "no-cache",
  });

  const openOrCloseHashtagModal = () => {
    setIsHashtagModalOpen((prev) => !prev);
  };

  const getAppUsersList = useCallback(
    (debounced?: string) => {
      const args: argsOption = {
        limit: appUsersStore.limit,
        offset: appUsersStore.offset,
      };

      if (debounced) {
        args.name = debounced;
      }
      getAppUsers({
        variables: {
          getAppUsersArgs: args,
        },
      });
    },
    [getAppUsers]
  );

  const fetchAppUsers = useCallback(async () => {
    await appUsersStore.resetPagination();
    if (debounced.length) {
      getAppUsersList(debounced);
    } else {
      getAppUsersList();
    }
  }, [debounced, getAppUsersList]);

  const changePage = async (page: number) => {
    await appUsersStore.resetUsers();
    appUsersStore.addOffset((page - 1) * appUsersStore.limit);
    if (debounced.length) {
      getAppUsersList(debounced);
    } else {
      getAppUsersList();
    }
  };

  useEffect(() => {
    appUsersStore.resetUsers();
    fetchAppUsers();
  }, [debounced, fetchAppUsers]);

  useEffect(() => {
    if (data) {
      appUsersStore.addUsers(data.getAppUsers.users);
      appUsersStore.addAllUsersCount(data.getAppUsers.count);
    }
  }, [data]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  const usersSearch = (
    <div className={"base-page-header space-between gap-20"}>
      <BaseSearch handleChange={(value) => setSearch(value)} />
      <div className={"d-flex-aligned-end"}>
        <Button type="primary" size="large" onClick={openOrCloseHashtagModal}>
          Add #Hashtags
        </Button>
      </div>
    </div>
  );

  return (
    <div className={"users-page"}>
      {isHashtagModalOpen ? (
        <Modal
          className={"users-page--modal"}
          closeModal={openOrCloseHashtagModal}
          modalIsOpen={isHashtagModalOpen}
          showCloseButton={false}
        >
          <UsersHashtagModalForm closeModal={openOrCloseHashtagModal} />
        </Modal>
      ) : null}

      <div className={"users-page--container"}>
        {loading || !data ? (
          <BaseLoader />
        ) : (
          <>
            {data?.getAppUsers.users.length ? (
              <>
                <PageContentLayout
                  className={"d-flex-column"}
                  actions={usersSearch}
                  view={
                    <>
                      <UserTable data={appUsersStore.users} />
                      <BasePagination
                        totalPages={Math.ceil(appUsersStore.allUsersCount / appUsersStore.limit)}
                        currentPage={appUsersStore.offset / appUsersStore.limit + 1}
                        changePage={changePage}
                      />
                    </>
                  }
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default memo(UsersHashtag);
