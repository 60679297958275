import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./VenueManagerForm.scss";
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ReactComponent as Upload } from "../../../assets/images/Upload.svg";
import { IManagerItem, IVenue } from "../../../types/venue";
import uuid from "react-uuid";
import VenueManagerItem from "./VenueManagerItem";
import { REMOVE_VENUE_MANAGER } from "../../../graphql/mutations/deleteVenueManager";
import { useMutation } from "@apollo/client";
import BaseCreateOrDeleteModalLoading from "../../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../../components/reusable/baseAlert/BaseAlert";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

interface VenueManagerFormProps {
  venueId: string | number | undefined;
  venueManagersListData: IManagerItem[];
  register: UseFormRegister<IVenue>;
  setValue: UseFormSetValue<IVenue>;
  getValues: UseFormGetValues<IVenue>;
  isDisabled: boolean;
  errors: FieldErrors<IVenue>;
}

const VenueManagerForm: FC<VenueManagerFormProps> = ({
  venueId,
  venueManagersListData,
  register,
  setValue,
  getValues,
  isDisabled,
  errors,
}) => {
  const blockRef = useRef<HTMLDivElement>(null);

  const [removalManagerIndex, setRemovalManagerIndex] = useState<number | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [managers, setManagers] = useState<IManagerItem[]>([
    {
      id: uuid(),
      isNew: true,
      email: "",
      name: "",
    },
  ]);

  const [removeManager, { loading: removeManagerLoading, error: removeManagerError }] =
    useMutation(REMOVE_VENUE_MANAGER);

  const addNewManager = () => {
    const newManagerList = [
      ...managers,
      {
        id: uuid(),
        isNew: true,
        email: "",
        name: "",
      },
    ];
    setManagers(newManagerList);
  };

  const deleteManagerConfirm = async () => {
    const managers = getValues("managers");
    const removalManager = removalManagerIndex !== null && managers[removalManagerIndex];
    if (removalManager) {
      await removeManager({
        variables: {
          venueManagerUserId: +removalManager?.id,
        },
      });
      deleteManagerByIndex(removalManagerIndex);
      setRemovalManagerIndex(null);
    }
  };

  const openOrCloseDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };

  const deleteManagerByIndex = (index: number) => {
    const managers = getValues("managers");
    const newManagersForm = [...managers];
    newManagersForm.splice(index, 1);
    const newManagers = [...managers];
    newManagers.splice(index, 1);
    setManagers(newManagers);
    setValue("managers", newManagersForm);
    for (let i = 0; i < newManagers.length; i++) {
      setValue(`managers.${i}.name`, newManagers[i].name);
      setValue(`managers.${i}.email`, newManagers[i].email);
    }
  };

  const removeManagerListData = useCallback(
    (index: number, isNew: boolean) => {
      if (isNew) {
        deleteManagerByIndex(index);
      } else {
        setRemovalManagerIndex(index);
        openOrCloseDeleteModal();
      }
    },
    [getValues, setValue]
  );

  useEffect(() => {
    if (venueManagersListData.length) {
      setManagers(venueManagersListData);
    }
  }, [venueManagersListData]);

  useEffect(() => {
    blockRef.current!.scrollTo(0, blockRef.current!.scrollHeight);
  }, [managers]);

  useEffect(() => {
    if (removeManagerError) {
      openOrCloseDeleteModal();
      setRemovalManagerIndex(null);
    }
  }, [removeManagerError]);

  return (
    <div className={"venue-manager-form"}>
      <div className={"venue-manager-form--content"} ref={blockRef}>
        {managers?.map((manager, index) => {
          const fieldName = `managers[${index}]`;
          return (
            <VenueManagerItem
              isNew={manager?.isNew}
              key={manager.email + index}
              errors={errors}
              register={register}
              getValues={getValues}
              isDisabled={isDisabled}
              fieldName={fieldName}
              index={index}
              venueId={venueId}
              removeManagerListData={removeManagerListData}
            />
          );
        })}
      </div>
      <div className={"venue-manager-form--btn-block"}>
        <button
          disabled={isDisabled}
          type={"button"}
          className={"venue-manager-form--btn"}
          onClick={addNewManager}
        >
          <Upload /> <span>Add new manager</span>
        </button>
      </div>
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteManagerConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this manager?"}
        />
      ) : null}
      {removeManagerLoading && <BaseCreateOrDeleteModalLoading />}
      {removeManagerError && <BaseAlert type={"failed"} message={removeManagerError?.message} />}
    </div>
  );
};

export default VenueManagerForm;
