import React, { FC, useCallback, useEffect, useState } from "react";
import BaseInput from "../../../components/reusable/baseInput/BaseInput";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import BaseSelect from "../../../components/reusable/baseSelect/BaseSelect";
import { ITicket } from "../../../types/ticket";
import { currencyList } from "../../../constants/currency";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "../../../components/reusable/datePicker/DatePicker";
import type { Dayjs } from "dayjs";
import BaseButton from "../../../components/reusable/baseButton/BaseButton";

interface ITicketInfoForm {
  ticketData: ITicket;
  register: UseFormRegister<ITicket>;
  setValue: UseFormSetValue<ITicket>;
  getValues: UseFormGetValues<ITicket>;
  clearErrors: UseFormClearErrors<ITicket>;
  isDisabled: boolean;
  errors: FieldErrors<ITicket>;
}

const TicketInfoForm: FC<ITicketInfoForm> = ({
  ticketData,
  register,
  setValue,
  getValues,
  clearErrors,
  isDisabled,
  errors,
}) => {
  const { ticketType } = useParams();
  const [showEarlyBirdOptions, setShowEarlyBirdOptions] = useState(false);
  const changeTicketCurrency = (value: { id: number; value: string; type?: string }) => {
    setValue("currency", value.type || "gbp");
    clearErrors("currency");
  };

  const onChangeDate = useCallback(
    (value: Dayjs | null) => {
      if (value) {
        setValue("date", value);
        clearErrors("date");
      }
    },
    [clearErrors, setValue]
  );

  const onChangeStartDate = useCallback(
    (value: Dayjs | null) => {
      if (value) {
        setValue("startTime", value);
        setValue("endTime", value);
        clearErrors("startTime");
      }
    },
    [clearErrors, setValue]
  );

  const onChangeexpireDate = useCallback(
    (value: Dayjs | null) => {
      value && setValue("endTime", value);
      clearErrors("endTime");
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    if ((ticketData && ticketData.earlyBirdAmount) || ticketData.earlyBirdCount) {
      setShowEarlyBirdOptions(true);
    }
  }, [ticketData]);
  return (
    <div>
      <BaseInput
        disabled={isDisabled}
        label={{
          text: "Name",
          htmlFor: "name",
          className: "required"
        }}
        newClassName={"modal-input"}
        registerValidation={{
          type: "text",
          name: "name",
          placeholder: "Name...",
          validation: {
            ...register("name"),
          },
        }}
        error={errors.name?.message}
      />
      <BaseInput
        disabled={isDisabled}
        newClassName={"modal-input"}
        label={{
          text: "Description",
          htmlFor: "description",
          className: "required"
        }}
        registerValidation={{
          type: "textarea",
          name: "description",
          placeholder: "description...",
          validation: {
            ...register("description"),
          },
        }}
        error={errors.description?.message}
      />
      {ticketType === "venue" && (
        <DatePicker
          title="Active till"
          newClassName="modal-input"
          disabled={isDisabled}
          error={errors.date?.message}
          onChange={onChangeDate}
          value={getValues("date")}
          format={"DD-MM-YYYY HH:mm"}
          //disabledDate={(d) => !d || d.isBefore(dayjs())}
        />
      )}

      <div className={"event-date-range gap-10"}>
        <div className={"input-field-distance"}>
          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Amount",
              htmlFor: "amount",
              className: "required"
            }}
            registerValidation={{
              type: "number",
              name: "amount",
              placeholder: "Amount...",
              validation: {
                ...register("amount"),
              },
            }}
            error={errors.amount?.message}
          />
        </div>
        <div className={"input-field-distance"}>
          <BaseInput
            newClassName={"modal-input"}
            label={{
              text: "Ticket Count",
              htmlFor: "count",
              className: "required"
            }}
            registerValidation={{
              type: "number",
              name: "count",
              placeholder: "Ticket Count...",
              validation: {
                ...register("count"),
              },
            }}
            error={errors.count?.message}
          />
        </div>
        {/*<div className={"input-field-distance currency-select"}>*/}
        {/*    <BaseSelect*/}
        {/*        value={currencyList?.find((currency) => currency.type === ticketData?.currency)?.type}*/}
        {/*        setValue={(value) => changeTicketCurrency(value)}*/}
        {/*        options={currencyList}*/}
        {/*        showOnlyType={true}*/}
        {/*        label={{*/}
        {/*            text: "Currency",*/}
        {/*            htmlFor: "currency",*/}
        {/*        }}*/}
        {/*        registerValidation={{*/}
        {/*            type: "select",*/}
        {/*            name: "currency",*/}
        {/*            placeholder: "Currency",*/}
        {/*            validation: {},*/}
        {/*        }}*/}
        {/*        error={errors.currency?.message}*/}
        {/*    />*/}
        {/*</div>*/}
      </div>

      <div className={"event-date-range"}>
        <div className={"input-field-distance"}>
          <DatePicker
            title="Start Time"
            newClassName="modal-input"
            error={errors.startTime?.message}
            onChange={onChangeStartDate}
            value={getValues("startTime")}
            format={"DD-MM-YYYY HH:mm"}
            showTime
          />
        </div>
        <div className={"input-field-distance"}>
          <DatePicker
            title="End Time"
            newClassName="modal-input"
            error={errors.endTime?.message}
            onChange={onChangeexpireDate}
            value={getValues("endTime")}
            format={"DD-MM-YYYY HH:mm"}
            showTime
          />
        </div>
      </div>

      <div className={"event-date-range"}>
        <BaseButton
          type={"button"}
          title={showEarlyBirdOptions ? "Hide early bird ticket" : "Add early bird ticket"}
          className={"add-early-bird-btn"}
          onClick={() => {
            setShowEarlyBirdOptions((prevState) => !prevState);
          }}
        />
      </div>
      {showEarlyBirdOptions ? (
        <div className={"event-date-range mt-10"}>
          <div className={"input-field-distance"}>
            <BaseInput
              newClassName={"modal-input"}
              label={{
                text: "Early Bird Amount",
                htmlFor: "earlyBirdAmount",
              }}
              registerValidation={{
                type: "number",
                name: "earlyBirdAmount",
                placeholder: "Early Bird Amount...",
                validation: {
                  ...register("earlyBirdAmount"),
                },
              }}
              error={errors.earlyBirdAmount?.message}
            />
          </div>
          <div className={"input-field-distance"}>
            <BaseInput
              newClassName={"modal-input"}
              label={{
                text: "Early Bird Count",
                htmlFor: "earlyBirdCount",
              }}
              registerValidation={{
                type: "number",
                name: "earlyBirdCount",
                placeholder: "Early Bird Count Count...",
                validation: {
                  ...register("earlyBirdCount"),
                  onChange: (e: any) => {
                    setValue(
                      "earlyBirdCount",
                      Number(e.target.value) > 0 ? Number(e.target.value) : 0
                    );
                  },
                },
              }}
              error={errors.earlyBirdCount?.message}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TicketInfoForm;
