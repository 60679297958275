import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SALES_ANALYTICS } from "../../../graphql/query/getSalesAnalytics";
import { GET_REPORTED_POST_ANALYTICS } from "../../../graphql/query/getReportedPostAnalytics";
import { Cell, Pie, PieChart } from "recharts";
import dashboard from "../../../pages/Dashboard/Dashboard";

const ReportedPostsAnalytics = () => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [getReportPostAnalytics, { data }] = useLazyQuery(GET_REPORTED_POST_ANALYTICS, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    (async () => {
      await getReportPostAnalytics();
    })();
  }, []);

  const COLORS = [
    { key: "abuse", color: "#DB3334" },
    { key: "hate_speech", color: "#FDB9E2" },
    { key: "nudity", color: "#FFE4FF" },
    { key: "other", color: "#C9BEDC" },
    { key: "spam", color: "#FC8CB4" },
    { key: "violence", color: "#F25E79" },
  ];

  useEffect(() => {
    if (data) {
      const newArr = Object.entries(data.getReportedPostAnalytics).map((item: any) => {
        let name = item[0];
        const value = item[1];
        const color = COLORS.find((elem) => elem.key === item[0])?.color;
        if (name === "hate_speech") {
          name = "hate speech";
        }

        if (name !== "__typename") {
          return {
            name,
            value,
            color,
          };
        }
      });
      setChartData(newArr);
    }
  }, [data]);

  const renderLabel = (entry: any) => {
    if (entry.value) {
      const labelValue = `${entry.name.toUpperCase()}\n${entry.value}%`;

      const lines = labelValue.split('\n');
      const isFurtherFromCentre = entry.x > entry.cx
      return (
        <text
          x={entry.x}
          y={entry.y}
          dy={-4}
          fontSize={10}
          textAnchor={isFurtherFromCentre ? "start" : "end"}
        >
          {lines.map((line, index) => {
            const lineWidth = line.length*5;
            const xPosition = isFurtherFromCentre ? entry.x - lineWidth / 2: entry.x + lineWidth / 2;
            return (
              <tspan x={xPosition} dy={index === 0 ? 0 : 10} key={index}>
                {line}
              </tspan>
            )
          })}
        </text>
      );
    }
  };

  return (
    <div className={"dashboard-page-chart"}>
      <h2 className={"dashboard-page-title"}>Reported Posts</h2>
      <PieChart height={270} width={300}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={chartData}
          cx={160}
          cy={125}
          outerRadius={110}
          fill="#8884d8"
          label={renderLabel}
        >
          {chartData?.map((entry: any, index: any) => (
            <Cell fill={entry?.color} key={index} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default ReportedPostsAnalytics;
