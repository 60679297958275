import React, { FC, useState } from "react";
import "./AdvertisementInfoForm.scss";
import BaseInput from "../../../../components/reusable/baseInput/BaseInput";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { INewsForm } from "../../../../types/news";
import BaseSelect from "../../../../components/reusable/baseSelect/BaseSelect";
import { IVenue } from "../../../../types/venue";
import { Col, Row } from "antd";
import DatePicker from "../../../../components/reusable/datePicker/DatePicker";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { VenueOption } from "../../../EventForm/components/EventDetails/EventDetails";

interface AdvertisementInfoFormProps {
  venueList: IVenue[];
  selectVenueId: string | number;
  register: UseFormRegister<INewsForm>;
  setValue: UseFormSetValue<INewsForm>;
  getValues: UseFormGetValues<INewsForm>;
  clearErrors: UseFormClearErrors<INewsForm>;
  isDisabled: boolean;
  errors: FieldErrors<INewsForm>;
}

const AdvertisementInfoForm: FC<AdvertisementInfoFormProps> = ({
  venueList,
  selectVenueId,
  register,
  setValue,
  getValues,
  clearErrors,
  isDisabled,
  errors,
}) => {
  const [activationPeriod] = useState([{ name: "7" }, { name: "15" }, { name: "30" }]);

  const changeEndDate = (value: Dayjs | null) => {
    value && setValue("endDate", value);
    clearErrors("endDate");
  };

  const changeVenueId = (value: { id: number; value: string; type?: string }) => {
    setValue("venueId", value.id.toString());
    clearErrors("venueId");
  };

  return (
    <div className={"advertisement-info-form"}>
      <div>
        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Title",
            htmlFor: "title",
            className: "required",
          }}
          registerValidation={{
            type: "text",
            name: "title",
            placeholder: "Title...",
            validation: {
              ...register("title"),
            },
          }}
          error={errors.title?.message}
        />

        <BaseInput
          disabled={isDisabled}
          newClassName={"modal-input"}
          label={{
            text: "Description",
            htmlFor: "description",
          }}
          registerValidation={{
            type: "textarea",
            name: "description",
            placeholder: "Description...",
            validation: {
              ...register("description"),
            },
          }}
        />

        <Row>
          <Col span={11} key="venues">
            <BaseSelect
              value={venueList?.find((venue) => venue.id === selectVenueId)?.name}
              setValue={(value) => changeVenueId(value as VenueOption)}
              showOnlyName={true}
              disabled={isDisabled || false}
              options={venueList}
              label={{
                text: "Venues",
                htmlFor: "Venues",
              }}
              registerValidation={{
                type: "select",
                name: "venueId",
                placeholder: "Venues",
              }}
            />
          </Col>

          <Col span={12} offset={1} key="date">
            <DatePicker
              title="Active till"
              newClassName="modal-input"
              disabled={isDisabled}
              error={errors.endDate?.message}
              onChange={changeEndDate}
              value={getValues("endDate")}
              disabledDate={(d) => !d || d.isBefore(dayjs())}
              format={"DD-MM-YYYY"}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdvertisementInfoForm;
