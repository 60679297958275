import { makeAutoObservable } from "mobx";
import { IAdminsHistory } from "../types/adminsHistory";

export interface IAdminsHistoryStore {
  adminsHistory: IAdminsHistory[];
  allAdminsHistoryCount: number;
  limit: number;
  offset: number;
}

class AdminsHistoryStore implements IAdminsHistoryStore {
  limit = 12;
  offset = 0;
  allAdminsHistoryCount = 0;
  adminsHistory = [];

  constructor() {
    makeAutoObservable(this);
  }

  addAdminsHistory(admins: []) {
    this.adminsHistory = admins;
  }

  addAllAdminsHistoryCount(count: number) {
    this.allAdminsHistoryCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetAdminsHistory() {
    this.adminsHistory = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new AdminsHistoryStore();
