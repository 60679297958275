import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./Staff.scss";
import Modal from "../../components/Modal/Modal";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import staffStore from "../../store/adminStaffStore";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import { useLazyQuery } from "@apollo/client";
import BaseError from "../../components/reusable/baseError/BaseError";
import AdminOrStaffModalViews from "../../forms/AdminOrStaffModalForm/AdminOrStaffModalForm";
import { IAdminOrStaffItem } from "../../types/adminOrstaff";
import WriteStaffEmailModalForm from "../../forms/WriteStaffEmailModalForm/WriteStaffEmailModalForm";
import { GET_STAFF_LIST } from "../../graphql/query/getStaff";
import { observer } from "mobx-react-lite";
import { IUsersItem } from "../../types/user";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import { IDiscount } from "../../types/discount";
import StaffTable from "../../components/reusable/baseResponsiveItemsPanel/staffTable/StaffTable";
import { PanelItem } from "../../types/types";
import { Button } from "antd";
import userStore from "../../store/userStore";
import { isAdmin } from "../../utils/isSuperAdmin";
import { useNavigate } from "react-router-dom";

const Staff = observer(() => {
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false);
  const [isStaffWriteEmailModalOpen, setIsStaffWriteEmailModalOpen] = useState(false);
  const [editStaffCandidate, setEditStaffCandidate] = useState<IAdminOrStaffItem | null>(null);
  const navigate = useNavigate();

  const [getStaff, { loading, error, data }] = useLazyQuery(GET_STAFF_LIST, {
    fetchPolicy: "no-cache",
  });

  const openStaffModal = (staff?: IAdminOrStaffItem) => {
    staff && setEditStaffCandidate(staff);
    setIsStaffModalOpen(true);
  };

  const openStaffWriteEmailModal = (staff?: IAdminOrStaffItem) => {
    staff && setEditStaffCandidate(staff);
    setIsStaffWriteEmailModalOpen(true);
  };

  const closeStaffModal = () => {
    setIsStaffModalOpen(false);
    setEditStaffCandidate(null);
  };

  const closeStaffWriteEmailModal = (isSendEmail?: boolean) => {
    if (isSendEmail) {
      setIsSendEmail(true);
    }
    setIsStaffWriteEmailModalOpen(false);
    setEditStaffCandidate(null);
  };

  const changePage = (page: number) => {
    staffStore.addOffset((page - 1) * staffStore.limit);
    getStaffList();
  };

  const onHandleClick = (
    staff: IAdminOrStaffItem | IUsersItem | IDiscount | PanelItem,
    type?: string
  ) => {
    if (type === "email") {
      setIsSendEmail(false);
      openStaffWriteEmailModal(staff as IAdminOrStaffItem);
      staffStore.addStaffListId(staff.id);
    }
    if (type === "edit") {
      openStaffModal(staff as IAdminOrStaffItem);
    }
  };

  const getStaffList = useCallback(
    (count?: number) => {
      const newOffset = count === 1 ? staffStore.offset - staffStore.limit : staffStore.offset;
      staffStore.resetDataList();
      getStaff({
        variables: {
          listVenueStaffArgs: {
            limit: staffStore.limit,
            offset: newOffset,
          },
        },
      });
    },
    [getStaff]
  );

  useLayoutEffect(() => {
    getStaffList();
  }, [getStaffList]);

  useEffect(() => {
    if (data) {
      staffStore.addDataList(data.listVenueStaff.venueStaffs);
      staffStore.addAllDataListCount(data.listVenueStaff.count);
    }
  }, [data]);

  useEffect(() => {
    if (isAdmin(userStore.user)) {
      navigate("/not-found");
    }
  }, [userStore.user]);
  if (loading) {
    return <BaseLoader />;
  }

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  return (
    <div className={"staff-page"}>
      {isSendEmail ? <BaseAlert type={"success"} message={"Email successfully send"} /> : null}

      <Modal
        closeModal={closeStaffModal}
        modalIsOpen={isStaffModalOpen}
        className={"staff-page--modal"}
      >
        <AdminOrStaffModalViews
          type={"staff"}
          closModal={closeStaffModal}
          editAdminOrStaffCandidate={editStaffCandidate}
          getAdminsOrStaffList={getStaffList}
        />
      </Modal>

      <Modal
        closeModal={() => closeStaffWriteEmailModal()}
        modalIsOpen={isStaffWriteEmailModalOpen}
      >
        <WriteStaffEmailModalForm
          closModal={closeStaffWriteEmailModal}
          getStaffList={getStaffList}
        />
      </Modal>

      <div className={"base-page-header"}>
        <BaseSearch />
        <Button type="primary" size="large" onClick={() => openStaffModal()}>
          Add Staff
        </Button>
      </div>

      <div className={"staff-page--container"}>
        <StaffTable data={staffStore.dataList} onEditItem={onHandleClick} />
        {/*<BaseTable tableData={staffStore.dataList} type={"staff"} onHandleClick={onHandleClick} />*/}

        <BasePagination
          totalPages={Math.ceil(staffStore.allDataListCount / staffStore.limit)}
          currentPage={staffStore.offset / staffStore.limit + 1}
          changePage={changePage}
        />
      </div>
    </div>
  );
});

export default Staff;
