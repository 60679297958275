import { gql } from "@apollo/client";

export const REJECT_EVENT_REQUEST = gql`
  mutation rejectEventRequest($rejectEventReqArgs: rejectEventReqInput!) {
    rejectEventRequest(rejectEventReqArgs: $rejectEventReqArgs) {
      id
      venueId
      creatorId
      eventId
      status
      createdAt
      updatedAt
    }
  }
`;
