import React, { FC } from "react";
import "./Stepper.scss";
import BaseButton from "../reusable/baseButton/BaseButton";
import BackSvg from "../../assets/images/BackSvg";
import { useNavigate } from "react-router-dom";

interface StepperProps {
  steppers: Array<string>;
  children: React.ReactNode;
  extraButtons?: React.ReactNode;
  stepCount: number;
  decrementStepCount: () => void;
  saveAndBack: () => void;
  isUpdateMode?: boolean;
  isSaveAndContinue?: boolean;
  isEventForm?: boolean;
}

const Stepper: FC<StepperProps> = ({
  steppers,
  children,
  extraButtons = <></>,
  stepCount = 1,
  decrementStepCount,
  saveAndBack,
  isUpdateMode,
  isSaveAndContinue,
  isEventForm,
}) => {
  const navigate = useNavigate();

  const goToStep = (stepCount: number) => {
    isUpdateMode &&
      navigate({
        search: `step-${stepCount}`,
      });
  };

  const getButtonTitle = () => {
    if (isEventForm) {
      return "Next";
    }
    if (isUpdateMode && steppers[stepCount - 1] !== "Manager") {
      return "Save and Continue";
    }
    if (!isUpdateMode && steppers[stepCount - 1] === "Manager") {
      return "Save and continue";
    }
    if (isSaveAndContinue) {
      return "Save and continue";
    }

    return "Next";
  };

  return (
    <div className={"stepper"}>
      <div className={"stepper--steps"}>
        {steppers.map((step, index) => (
          <button
            type={"button"}
            className={`stepper--block ${isUpdateMode ? "stepper--block--clickable" : ""}`}
            key={step}
            onClick={() => goToStep(index + 1)}
          >
            <div
              className={`stepper--step ${stepCount >= index + 1 ? "stepper--step-active" : ""}`}
            >
              {index + 1}
            </div>
            <span className={"stepper--name"}>{step}</span>
            <div
              className={`stepper--line ${stepCount >= index + 1 ? "stepper--line-active" : ""}`}
            />
          </button>
        ))}
      </div>
      <div className={"stepper--children"}>{children}</div>

      <div className={"stepper--buttons"}>
        {stepCount > 1 ? (
          <div className={"stepper--buttons--block-1"}>
            <button
              className={"stepper--buttons--back"}
              type={"button"}
              onClick={() => decrementStepCount()}
            >
              <BackSvg />
              <p className="stepper--buttons--back-text">Back</p>
            </button>
          </div>
        ) : null}

        <div className={"stepper--buttons--block-2"}>
          {stepCount < steppers.length ? (
            <BaseButton
              className={"stepper--buttons--next"}
              type={"submit"}
              title={getButtonTitle()}
            />
          ) : null}

          <BaseButton
            className={"stepper--buttons--save"}
            type={"submit"}
            title={"Save"}
            onClick={saveAndBack}
          />
          {extraButtons}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
