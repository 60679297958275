import { FC, useState } from "react";
import "./BaseSearch.scss";
import SearchSvg from "../../../assets/images/SearchSvg";
import { cn } from "../../../utils/common";

interface IBaseSearch {
  inputClassName?: string;
  handleChange?: (value: string) => void;
}

const BaseSearch: FC<IBaseSearch> = ({ inputClassName = "", handleChange }) => {
  const [value, setValue] = useState("");
  return (
    <div className="base-search">
      <input type="checkbox" className={"base-search-checkbox"} id="search-input" />
      <div className={cn("base-search--input", inputClassName)}>
        <input
          value={value}
          type="search"
          placeholder="Type to search..."
          onChange={(e) => {
            setValue(e.target.value);
            handleChange!(e.target.value.trim());
          }}
        />
        <label htmlFor="search-input" className="base-search--input-icon">
          <SearchSvg className={"base-search--svg"} />
        </label>
      </div>
    </div>
  );
};

export default BaseSearch;
