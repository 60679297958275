import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './Blogs.scss';
import { isAdmin, isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import BlogsSearch from "./BlogsSearch";
import { useLazyQuery, useMutation } from "@apollo/client";
import BlogsView from "./BlogsView";
import { GET_BLOGS } from "../../graphql/query/getBlogs";
import { CREATE_BLOG } from "../../graphql/mutations/createBlog";
import BaseError from "../../components/reusable/baseError/BaseError";
import { useDebounce } from "../../hooks/useDebounce";
import { DELETE_BLOG } from "../../graphql/mutations/deleteBlog";
import { PanelItem } from "../../types/types";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import blogStore from "../../store/blogStore";
import BaseCreateOrDeleteModalLoading
  from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";

type argsOption = {
  limit: number;
  offset: number;
  title?: string;
};

const Blogs = () => {
  const [search, setSearch] = useState<string>("");
  const debounced = useDebounce(search);
  const navigate = useNavigate();
  const [createBlog] =
    useMutation(CREATE_BLOG);
  const [selectItem, setSelectItem] = useState<PanelItem | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const [
    removeBlog,
    { data: dataRemove, loading: loadingRemove, error: errorRemove, reset: resetErrorRemove },
  ] = useMutation(DELETE_BLOG, {
    refetchQueries: [
      {
        query: GET_BLOGS,
        variables: {
          ListBlogsInput: {
            limit: blogStore.limit,
            offset: blogStore.offset,
          }
        },
      },
    ],
  });

  const onCreateBlog = async () => {
    await createBlog({
      variables: {
        createBlogInput: {
          title: "",
          content: "",
        },
      },
    }).then((res) => {
      if (res.data) {
        navigate({
          pathname: `/blogs/${res.data.createBlog.id}`
        });
      }
    })
  };
  const [listBlogs, { loading, error, data }] = useLazyQuery(GET_BLOGS);

  const getBlogsList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset = count === 1 ? blogStore.offset - blogStore.limit : blogStore.offset;

      const args: argsOption = {
        limit: blogStore.limit,
        offset: newOffset,
      };

      if (debounced) {
        args.title = debounced;
      }

      listBlogs({
        variables: {
          ListBlogsInput: args,
        },
      });
    },
    [listBlogs]
  );

  const fetchBlogs = useCallback(async () => {
    await blogStore.resetPagination();
    if (debounced.length) {
      getBlogsList(null, debounced);
    } else {
      getBlogsList();
    }
  }, [debounced, getBlogsList]);

  const changePage = (page: number) => {
    blogStore.addOffset((page - 1) * blogStore.limit);
    getBlogsList();
  };

  const goToCurrentBlog = (blog: PanelItem) => {
    navigate(
      {
        pathname: `/blogs/view/${blog.id.toString()}`,
      },
      { state: { blogId: blog.id } }
    );
  };

  const handleEditBlog = (item: PanelItem) => {
    navigate({
      pathname: `/blogs/${item.id.toString()}`,
    });
  };

  useEffect(() => {
    if (data) {
      blogStore.addBlogs(data.listBlogs.blogs);
      blogStore.addAllBlogsCount(data.listBlogs.count);
      if (data.listBlogs.count === 1 && !isSuperAdmin(userStore.user)) {
        goToCurrentBlog(data.listBlogs.blogs[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const openOrCloseDeleteModal = useCallback((venue?: PanelItem) => {
    if (venue) {
      setSelectItem(venue);
    }
    setIsOpenDeleteModal((prev) => !prev);
  }, []);

  const deleteBlogConfirm = async () => {
    try {
      const { data } = await removeBlog({
        variables: {
          id: +selectItem?.id!,
        },
      });

      if (
        blogStore.offset / blogStore.limit + 1 <
        Math.ceil(blogStore.allBlogsCount / blogStore.limit) ||
        (blogStore.blogs.length === 1 && blogStore.offset !== 0)
      ) {
        getBlogsList(blogStore.blogs.length);
      } else {
        blogStore.removeBlog(data?.removeVenue.id);
      }
      setIsOpenDeleteModal(false);
    } catch (e) {
      setIsOpenDeleteModal(false);
    }
  };
  
  useLayoutEffect(() => {
    blogStore.resetBlogs();
    fetchBlogs();
  }, [debounced, fetchBlogs]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  return (
    <div className="blog-page">
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteBlogConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this blog?"}
        />
      ) : null}
      {loadingRemove ? <BaseCreateOrDeleteModalLoading /> : null}
      {errorRemove ? <BaseAlert type={"failed"} message={errorRemove?.message} /> : null}
      {dataRemove?.removeBlog?.id ? (
        <BaseAlert
          type={"success"}
          message={"Blog successfully deleted"}
          reset={() => resetErrorRemove()}
        />
      ) : null}
      <PageContentLayout
        isVerticalAlign={true}
        actions={
          !isAdmin(userStore.user) ? (
            <BlogsSearch setSearch={setSearch} createBlog={onCreateBlog} />
          ) : undefined
        }
        view={
          <BlogsView
            data={data}
            loading={loading}
            setSearch={setSearch}
            createBlog={createBlog}
            onEditBlog={handleEditBlog}
            goToCurrentBlog={goToCurrentBlog}
            openOrCloseDeleteModal={openOrCloseDeleteModal}
            changePage={changePage}
          />
        }
      />
    </div>
  )
}

export default Blogs;