import { gql } from "@apollo/client";

export const UPDATE_ADMIN = gql`
  mutation updateAdmin($updateAdminInput: UpdateAdminInput!) {
    updateAdmin(updateAdminInput: $updateAdminInput) {
      id
      name
      email
      permissions
    }
  }
`;
