import React, { useMemo, useState } from "react";
import { CustomEvent } from "./customEvent/CustomEvent";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs from "dayjs";
import { IEventData } from "../../../types/eventTypes";

const EventCalendar = ({
  eventsList,
  getEventsList,
  debounced,
}: {
  eventsList: any;
  getEventsList: any;
  debounced: any;
}) => {
  const [calendarDate, setCalendarDate] = useState(dayjs().toDate());
  const { views } = useMemo(
    () => ({
      defaultDate: new Date(),
      max: dayjs().endOf("day").subtract(1, "hours").toDate(),
      views: [Views.MONTH, Views.WEEK, Views.DAY],
    }),
    []
  );
  const transformEvents = (data: IEventData[]) => {
    return data?.map((item) => ({
      id: item.id,
      start: dayjs(
        `${dayjs(item.startDate).format("MM-DD-YYYY")}, ${dayjs(item.startTime).format("HH:mm:ss")}`
      ).toDate(),
      end: dayjs(
        `${dayjs(item.startDate).format("MM-DD-YYYY")}, ${dayjs(item.endTime).format("HH:mm:ss")}`
      ).toDate(),
      title: item.name,
      description: item.description,
    }));
  };
  const onNavigate = (date: Date, view: string) => {
    let start;
    let end;
    if (view === Views.MONTH) {
      start = dayjs(date).startOf("month").startOf("week").add(1, "day").toDate();
      end = dayjs(date).endOf("month").endOf("week").add(1, "day").toDate();
    }

    setCalendarDate(dayjs(date).startOf("month").toDate());

    getEventsList(null, debounced, {
      startDate: start,
      endDate: end,
    });
  };

  return (
    <div className="calendar-view-container">
      <div className="calendar-container">
        <Calendar
          popup={true}
          showMultiDayTimes
          components={{
            event: CustomEvent,
          }}
          onShowMore={(events: any) => {
            events.shift();
          }}
          defaultDate={calendarDate}
          onNavigate={onNavigate}
          events={transformEvents(eventsList?.listEvents?.events)}
          localizer={dayjsLocalizer(dayjs)}
          views={views}
          startAccessor="start"
          endAccessor="end"
        />
      </div>
    </div>
  );
};

export default EventCalendar;
