import { memo } from "react";
import { Button, MenuProps, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import "./TableDropdown.scss";
import { PanelItem } from "../../../../types/types";

interface TableDropdownProps<T> {
  item: T;
  onSelectItem?: (item: T) => void;
  onEditItem?: (item: T) => void;
  onDeleteItem?: (item: T) => void;
  showViewAction?: boolean;
  showEditDeleteActions?: boolean;
}
const TableDropdown = <T extends PanelItem>({
  item,
  onSelectItem,
  onEditItem,
  showViewAction = true,
  showEditDeleteActions,
  onDeleteItem,
}: TableDropdownProps<T>) => {
  const items: MenuProps["items"] = [
    showViewAction
      ? {
          label: (
            <Button
              type="primary"
              onClick={() => {
                if (onSelectItem) {
                  onSelectItem(item);
                }
              }}
            >
              View
            </Button>
          ),
          key: "0",
        }
      : null,
    showEditDeleteActions
      ? {
          label: (
            <Button
              type="primary"
              onClick={() => {
                if (onEditItem) {
                  onEditItem(item);
                }
              }}
            >
              Edit
            </Button>
          ),
          key: "1",
        }
      : null,
    showEditDeleteActions
      ? {
          label: (
            <Button
              type="primary"
              onClick={() => {
                if (onDeleteItem) {
                  onDeleteItem(item);
                }
              }}
              ghost
            >
              Delete
            </Button>
          ),
          key: "2",
        }
      : null,
  ];

  return (
    <div className="table-actions-dropdown-container" onClick={(e) => e.stopPropagation()}>
      <Dropdown menu={{ items }} trigger={["click"]} overlayClassName={"table-actions-dropdown"}>
        <Button onClick={(e) => e.stopPropagation()}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default memo(TableDropdown);
