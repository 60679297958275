import { FC, useState } from "react";
import "./BaseInput.scss";
import { ILabel, IValidationInput } from "../../../types/types";
import { ReactComponent as ShowPassword } from "../../../assets/images/ShowPassword.svg";
import { ReactComponent as HidePassword } from "../../../assets/images/HidePassword.svg";
import { cn } from "../../../utils/common";

interface BaseInputInterfaceProps {
  label?: ILabel;
  registerValidation: IValidationInput;
  newClassName?: string;
  error?: any;
  readOnly?: boolean;
  disabled?: boolean;
  isPasswordInput?: boolean;
  className?: string;
}

const BaseInput: FC<BaseInputInterfaceProps> = ({
  disabled = false,
  label,
  registerValidation,
  newClassName = "",
  error,
  readOnly,
  isPasswordInput = false,
  className,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className={cn("base-input", className)}>
      {!!label?.text && (
        <label className={`base-input--label ${label.className}`}>{label.text}</label>
      )}
      {registerValidation.type === "textarea" ? (
        <textarea
          disabled={disabled}
          readOnly={readOnly}
          name={`${registerValidation.name}`}
          id={`${registerValidation.name}`}
          placeholder={`${registerValidation.placeholder}`}
          className={`base-input--textarea  ${newClassName} ${
            error ? "base-input--textarea-error" : ""
          }`}
          {...registerValidation.validation}
          autoComplete="on"
        ></textarea>
      ) : (
        <div className={"base-input--input-block"}>
          <input
            disabled={disabled}
            readOnly={readOnly}
            type={`${showPassword ? "text" : registerValidation.type}`}
            name={`${registerValidation.name}`}
            id={`${registerValidation.name}`}
            placeholder={`${registerValidation.placeholder}`}
            className={`base-input--input ${
              readOnly ? "base-input--readonly" : ""
            }   ${newClassName} ${error ? "base-input--input-error" : ""}`}
            {...registerValidation.validation}
            autoComplete="on"
            min={new Date().toISOString().slice(0, -14)}
          />
          {isPasswordInput ? (
            <div onClick={() => setShowPassword(!showPassword)} className={"base-input--icons"}>
              {showPassword ? (
                <ShowPassword width={18} height={12} />
              ) : (
                <HidePassword width={18} height={12} />
              )}
            </div>
          ) : null}
        </div>
      )}

      <span className={"base-input--error"}>{error}</span>
    </div>
  );
};

export default BaseInput;
