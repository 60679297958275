export const WRONG_EMAIL_PASSWORD = "Wrong email or password !";

export const EMAIL_REQUIRED_TEXT = "Email is required";
export const EMAIL_INVALID_TEXT = "Please enter a valid email";

export const PASSWORD_REQUIRED_TEXT = "Password is required";
export const PASSWORD_MINIMUM_TEXT = "Minimum password length 6";
export const PASSWORD_MAXIMUM_TEXT = "Maximum password length 30";

export const PASSWORD_CONFIRM_TEXT = "Passwords must match";

export const TOKEN_EXPIRED_TITLE = "Sorry, your token expired!";
export const TOKEN_EXPIRED_SUBTITLE = "We'll need re-send your authentication email";
