import { gql } from "@apollo/client";

export const GET_USERS_LIST = gql`
  query getAppUsers($getAppUsersArgs: GetAppUsersArgs!) {
    getAppUsers(getAppUsersArgs: $getAppUsersArgs) {
      count
      users {
        id
        name
        email
        hashtags {
          hashtagId
          name
        }
        status
      }
    }
  }
`;
