'use client';

import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { useDebounce } from "../../../hooks/useDebounce";
import { uploadMediaToAWS } from "../../../utils/evaporate";
import userStore from "../../../store/userStore";
import './Editor.scss';
import { hasDataUrl } from "../../../utils/common";

interface Props {
  content?: string;
  onChange?: Function;
  id: string;
  type: any;
  dataKey?: string;
  toolbar1Config?: string;
  toolbar2Config?: string;
  onEditContent: (content: string) => void;
  error?: string;
}

interface EditorBlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

const toolbar1 =
  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough removeformat | forecolor backcolor | emoticons charmap';
const toolbar2 = 'link | checklist numlist bullist indent outdent';

const Editor: FC<Props> = ({
  content = '',
  id,
  type,
  toolbar1Config = '',
  toolbar2Config = '',
  dataKey = '',
  onEditContent,
  error
}) => {
  const editorRef = useRef<TinyEditor>(null);
  const [editor, setEditor] = useState(content);
  const debText = useDebounce(editor, 1000);

  useEffect(() => {
    const currentEditorRef = editorRef.current;
    return () => {
      if (currentEditorRef) {
        currentEditorRef.editor?.remove();
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (debText && !hasDataUrl(debText)) {
        onEditContent(debText);
      }
    })();
  }, [debText, id, dataKey, type]);

  const handleChange = useCallback((a: string) => {
    setEditor(a);
  }, []);

  const uploadImage = useCallback(
    (blobInfo: EditorBlobInfo) =>
      new Promise<string>(async (resolve) => {
        const blob = blobInfo.blob();
        const file = new File([blob], blobInfo.filename());
        const data = await uploadMediaToAWS({
          id: id,
          userId: userStore.user?.id,
          file: file,
          type,
        });
        resolve(data.url);
      }),
    [id, type],
  );

  return (
    <div className="editor">
      <TinyEditor
        ref={editorRef}
        apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
        init={{
          id: 'editor',
          plugins: [
            'anchor',
            'autolink',
            'charmap',
            'codesample',
            'emoticons',
            'image',
            'link',
            'lists',
            'media',
            'searchreplace',
            'table',
            'visualblocks',
            'wordcount',
            'linkchecker',
          ],
          tinycomments_mode: 'embedded',
          tinycomments_author: 'Beeweb',
          mergetags_list: [
            { value: 'First.Name', title: 'First Name' },
            { value: 'Email', title: 'Email' },
          ],
          toolbar: `${toolbar1Config || toolbar1} | ${toolbar2Config || toolbar2}`,
          height: '400px',
          file_picker_types: 'image',
          automatic_uploads: true,
          resize: false,
          menubar: false,
          images_upload_handler: uploadImage,
        }}
        onEditorChange={handleChange}
        value={editor}
        initialValue={content}

      />
      {
        error && <div className="error-validation">{error}</div>
      }
    </div>
  );
};

export default memo(Editor);