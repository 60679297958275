import { gql } from "@apollo/client";

export const GET_VENUE = gql`
  query getVenue($id: Int!) {
    getVenue(id: $id) {
      id
      name
      address
      capacity
      description
      linkToWebsite
      linkToFaceBook
      linkToInstagram
      linkToYoutube
      cover
      internationalPhoneNumber
      categoryId
      location {
        coordinates
      }
      media {
        key
        type
        id
        fileName
        contentType
        contentLength
        isCover
      }
      workingHours {
        day
        isOpen
        openingTime
        closingTime
      }
    }
  }
`;
