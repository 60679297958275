import { makeAutoObservable } from "mobx";
import { strongCopyData } from "../utils/strongCopyData";
import { IAdminRequest } from "../types/adminRequests";

export interface IAdminRequestStore {
  adminRequests: IAdminRequest[];
  allAdminsCount: number;
  limit: number;
  offset: number;
}

class AdminRequestStore implements IAdminRequestStore {
  limit = 12;
  offset = 0;
  allAdminsCount = 0;
  adminRequests = [];

  constructor() {
    makeAutoObservable(this);
  }

  addAdmins(admins: []) {
    this.adminRequests = admins;
  }

  addAllAdminsCount(count: number) {
    this.allAdminsCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  updateAdminRequest({ id, updatedFields }: { id: string; updatedFields: Partial<IAdminRequest> }) {
    this.adminRequests = strongCopyData(this.adminRequests).map((adminRequest: IAdminRequest) => {
      const parsedNumber = Number(id);
      const contactId = Number(adminRequest.id);
      if (contactId === parsedNumber) {
        return {
          ...adminRequest,
          ...updatedFields,
        };
      }
      return adminRequest;
    });
  }

  removeAdminRequest(id: string | number) {
    this.adminRequests = strongCopyData(this.adminRequests).filter(
      (a: IAdminRequest) => a.id !== id
    );
  }

  resetAdminRequests() {
    this.adminRequests = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new AdminRequestStore();
