import { gql } from "@apollo/client";

export const UPDATE_ADMIN_SETTINGS = gql`
  mutation updateAdminSettings($args: UpdateSettingsInput!) {
    updateSettings(updateSettingsInput: $args) {
      key
      value
    }
  }
`;
