import React, { FC } from "react";
import "./ViewInput.scss";

interface ViewInputProps {
  title: string;
  value: string | undefined;
  newClass?: string;
  isLinkInput?: boolean;
}

const ViewInput: FC<ViewInputProps> = ({ title, value, newClass = "", isLinkInput = false }) => {
  return (
    <div className={`view-input`}>
      <span className={`view-input--title`}>{title}</span>
      <div className={`view-input--value ${newClass}`}>
        <span>
          {isLinkInput ? (
            <a href={value} target="_blank" rel="noreferrer">
              {value}
            </a>
          ) : (
            value
          )}
        </span>
      </div>
    </div>
  );
};

export default ViewInput;
