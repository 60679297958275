import * as React from "react";

const BackSvg = (props: any) => (
  <svg width={21} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.001 6.75a.75.75 0 0 0-.75-.75H2.561l4.721-4.718A.751.751 0 0 0 6.22.22l-6 6a.75.75 0 0 0 0 1.062l6 6a.75.75 0 1 0 1.062-1.062L2.562 7.5H20.25a.75.75 0 0 0 .75-.75Z"
      fill="#393939"
    />
  </svg>
);

export default BackSvg;
