import { gql } from "@apollo/client";

export const UPDATE_VENUE_STREAM = gql`
  mutation updateVenueStreams($updateVenueStreamInput: UpdateVenueStreamInput!) {
    updateVenueStreams(input: $updateVenueStreamInput) {
      id
      name
      streamUrl
    }
  }
`;
