import "./NoData.scss";
import { ReactComponent as NoDataSVG } from "../../../assets/images/Nodata.svg";

const NoData = () => {
  return (
    <div className={"no-data"}>
      <NoDataSVG width={150} height={280} />
      <p className={"no-data--text"}>No Data</p>
    </div>
  );
};

export default NoData;
