import { gql } from "@apollo/client";

export const GET_POSTS = gql`
  query listPosts($listPostsArgs: listPosts!) {
    listPosts(listPostsArgs: $listPostsArgs) {
      count
      posts {
        id
        userId
        description
        cover
        totalComments
        totalLikes
        eventId
        parentId
        venueId
        privacy
        type
        isShared
        totalShares
        isPublished
        total
        user {
          name
        }
        #            media{
        #                id,
        #                type,
        #                key,
        #                fileName,
        #                contentType,
        #                contentLength,
        #                isCover,
        #                sizes,
        #            }
        #            likedByMe,
        #            event: Event
        #            user: User
        #            venue: Venue
        #            parent: Feed
        #            ad: Ad
        #            hashtags: [HashtagFeed!]
        #            createdAt,
        #            updatedAt,
      }
    }
  }
`;
