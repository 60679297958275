import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "antd";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import Modal from "components/Modal/Modal";
import BaseLoader from "components/reusable/baseLoader/BaseLoader";
import BaseSearch from "components/reusable/baseSearch/BaseSearch";
import { GET_VENUE_STREAMS } from "graphql/query/getVenueStreams";
import useQueryParams from "hooks/useQueryParams";
import EditStreamModalForm from "forms/EditStreamModalForm/EditStreamModalForm";
import { useNavigate, useParams } from "react-router-dom";

import NoData from "components/reusable/noData/NoData";
import BasePagination from "components/reusable/basePagination/BasePagination";
import StreamsTable from "components/reusable/baseResponsiveItemsPanel/streamsTable/StreamsTable";
import { IStream } from "types/venue";
import { REMOVE_VENUE_STREAM } from "graphql/mutations/removeVenueStream";
import { useDebounce } from "hooks/useDebounce";
import { PER_PAGE } from "./constants";
import "./VenueViewer.scss";

const VenueViewer = () => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const { venueId } = useParams();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<IStream | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const page = useMemo(() => (query.get("page") ? Number(query.get("page")) : 1), [query]);
  const offset = useMemo(() => (page - 1) * PER_PAGE, [page]);

  const debounced = useDebounce(search);

  const { loading, data, refetch, client } = useQuery(GET_VENUE_STREAMS, {
    variables: {
      input: {
        offset,
        limit: PER_PAGE,
        search: debounced,
        venueId: Number(venueId),
      },
    },
  });
  const [remove] = useMutation(REMOVE_VENUE_STREAM);

  const streamUrls = useMemo(() => data?.getVenueStreams.venueStreams || [], [data]);
  const totalCount = data?.getVenueStreams.totalCount || 0;

  useLayoutEffect(() => {
    navigate({ search: `?page=${query.get("page") || 1}` });
  }, [navigate, query]);

  const onCloseEditModal = useCallback(() => {
    setEditModalOpen(false);
    setSelected(null);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setSelected(null);
    setDeleteModalOpen(false);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      selected && (await remove({ variables: { id: selected.id } }));
      const newTotalCounts = Math.max(totalCount - 1, 0);
      const newStreamsUrls = streamUrls.filter((stream: IStream) => stream.id !== selected?.id);

      client.writeQuery({
        query: GET_VENUE_STREAMS,
        variables: {
          input: {
            offset,
            limit: PER_PAGE,
            search: debounced,
            venueId: Number(venueId),
          },
        },
        data: { getVenueStreams: { venueStreams: newStreamsUrls, totalCount: newTotalCounts } },
      });

      if (offset > 0 && newStreamsUrls.length === 0) {
        navigate({ search: `?page=${page - 1}` });
      }
    } catch (err) {
      console.error(err);
    }
    handleCloseDeleteModal();
  }, [
    handleCloseDeleteModal,
    remove,
    selected,
    client,
    debounced,
    navigate,
    offset,
    page,
    streamUrls,
    totalCount,
    venueId,
  ]);

  const onChangePage = useCallback(
    (page: number) => navigate({ search: `?page=${page || 1}` }),
    [navigate]
  );

  const onEdit = useCallback((item: any) => {
    setSelected(item);
    setEditModalOpen(true);
  }, []);
  const onDelete = useCallback((item: any) => {
    setSelected(item);
    setDeleteModalOpen(true);
  }, []);

  return (
    <div>
      <Modal closeModal={onCloseEditModal} modalIsOpen={isEditModalOpen}>
        <EditStreamModalForm
          venueId={venueId}
          closeModal={onCloseEditModal}
          streamItem={selected}
          refetch={refetch}
        />
      </Modal>
      {isDeleteModalOpen && (
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          closeModal={handleCloseDeleteModal}
          deleteHandle={handleDelete}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this stream?"}
        />
      )}

      <div className={"base-page-header venue-viewer-header"}>
        <BaseSearch inputClassName="venue-viewer-search" handleChange={setSearch} />
        <Button type="primary" size="large" onClick={() => setEditModalOpen(true)}>
          Add New Stream
        </Button>
      </div>
      <div>
        {loading ? (
          <BaseLoader />
        ) : streamUrls.length ? (
          <>
            <StreamsTable data={streamUrls} onEditItem={onEdit} onDeleteItem={onDelete} />
            <BasePagination
              totalPages={Math.ceil(totalCount / PER_PAGE)}
              changePage={onChangePage}
              currentPage={page}
            />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default VenueViewer;
