import { makeAutoObservable } from "mobx";
import { IHashtag } from "../types/hashtagsTypes";

export interface IHashtagsStore {
  hashtagsList: IHashtag[];
  allHashtagsCount: number;
  limit: number;
  offset: number;
}

class HashtagsStore implements IHashtagsStore {
  hashtagsList: IHashtag[] = [];
  allHashtagsCount = 0;
  limit = 15;
  offset = 0;

  constructor() {
    makeAutoObservable(this);
  }

  addHashtags(hashtagsData: []) {
    this.hashtagsList = [...this.hashtagsList, ...hashtagsData];
  }

  addHashtagsCount(count: number) {
    this.allHashtagsCount = count;
  }

  addHashtag(hashtag: IHashtag) {
    this.hashtagsList = [hashtag, ...this.hashtagsList];
  }

  removeHashtagFromList(hashtagId: string | number) {
    this.hashtagsList = this.hashtagsList.filter((hashtag: IHashtag) => hashtag.id !== hashtagId);
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetHashtags() {
    this.hashtagsList = [];
  }

  resetPagination() {
    this.limit = 15;
    this.offset = 0;
  }
}

export default new HashtagsStore();
