import { gql } from "@apollo/client";

export const CREATE_STAFF = gql`
  mutation createVenueStaff($createVenueStaffInput: CreateVenueStaffInput!) {
    createVenueStaff(createVenueStaffInput: $createVenueStaffInput) {
      name
      email
    }
  }
`;
