import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import './styles/index.scss';
import reportWebVitals from "./reportWebVitals";
import { createUploadLink } from "apollo-upload-client";
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { BASE_URL } from "./constants/Api";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      Mode: "staging",
    },
  };
});

const httpLink = createUploadLink({
  uri: BASE_URL,
  fetch,
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink as any, httpLink as any]) as any,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

reportWebVitals();
