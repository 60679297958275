import React, { FC, useCallback, useEffect, useState } from "react";
import "./Events.scss";
import { useNavigate, useParams } from "react-router-dom";
import { PanelItem } from "../../types/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import { REMOVE_EVENT } from "../../graphql/mutations/deleteEvent";
import { GET_EVENTS_LIST } from "../../graphql/query/getEvents";
import BaseError from "../../components/reusable/baseError/BaseError";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import eventsStore from "../../store/eventsStore";
import { observer } from "mobx-react-lite";
import { useDebounce } from "../../hooks/useDebounce";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import NoData from "../../components/reusable/noData/NoData";
import EventTable from "../../components/reusable/baseResponsiveItemsPanel/eventTable/EventTable";
import { IEventData } from "../../types/eventTypes";
import { Button, Radio } from "antd";
import { RadioChangeEvent } from "antd/es/radio/interface";
import { UnorderedListOutlined, CalendarOutlined } from "@ant-design/icons";
import { isAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import { CREATE_EVENT_REQUEST } from "../../graphql/mutations/createEventRequest";
import { EventRequestsFilterStatus } from "../../types/eventRequestTypes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import localizedFormat from "dayjs/plugin/localizedFormat";
import EventCalendar from "../../components/reusable/eventCalendar/EventCalendar";

dayjs.locale("en");
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(timezone);

type argsOption = {
  limit?: number;
  offset: number;
  searchValue?: string;
  venueId?: number;
  status?: string;
  startDate?: Date;
  endDate?: Date;
};

interface IEvents {
  isTicketEvents?: boolean;
  createTicket?: (item: PanelItem) => void;
}

enum EventsViewTyoe {
  list = "list",
  calendar = "calendar",
}

const Events: FC<IEvents> = observer(({ isTicketEvents, createTicket }) => {
  const navigate = useNavigate();
  const { venueId } = useParams();
  const [search, setSearch] = useState<string>("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<PanelItem | null>(null);
  const [filterStatus, setFilterStatus] = useState<EventRequestsFilterStatus>(
    EventRequestsFilterStatus.ALL
  );
  const [viewType, setViewType] = useState<EventsViewTyoe>(EventsViewTyoe.list);

  const debounced = useDebounce(search);

  const [getEvents, { loading, error: getEventsError, data: eventsList }] = useLazyQuery(
    GET_EVENTS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [createEventRequest] = useMutation(CREATE_EVENT_REQUEST);

  const [
    removeEvent,
    { data: errorRemoveData, loading: loadingRemove, error: errorRemove, reset: resetErrorRemove },
  ] = useMutation(REMOVE_EVENT);

  const goToCurrentEvent = (event: PanelItem) => {
    let pathname = `/events/view/${event.id}`;
    if (venueId) {
      pathname = `/venues/${venueId}${pathname}`;
    }
    navigate({
      pathname,
    });
  };

  const handleEditEvent = (event: PanelItem | IEventData) => {
    let pathname = `/events/${event.id}`;
    if (venueId) {
      pathname = `/venues/${(event as IEventData).venueId}${pathname}`;
    }
    navigate({
      pathname: pathname,
      search: "step-1",
    });
  };

  const onCreateTicket = (event: PanelItem) => {
    createTicket && createTicket(event);
  };

  const createEvent = () => {
    let pathname = "/events/create";
    if (venueId) {
      pathname = `/venues/${venueId}${pathname}`;
    }
    navigate({
      pathname: pathname,
      search: "step-1",
    });
  };

  const openOrCloseDeleteModal = useCallback((venue?: PanelItem) => {
    if (venue) {
      setSelectItem(venue);
    }
    setIsOpenDeleteModal((prev) => !prev);
  }, []);

  const deleteEventConfirm = async () => {
    try {
      const { data } = await removeEvent({
        variables: {
          id: +selectItem?.id!,
        },
      });
      if (
        eventsStore.offset / eventsStore.limit + 1 <
          Math.ceil(eventsStore.eventsTotalCount / eventsStore.limit) ||
        (eventsStore.eventsList.length === 1 && eventsStore.offset !== 0)
      ) {
        getEventsList(eventsStore.eventsList.length);
      } else {
        eventsStore.removeEvent(data?.removeEvent.id);
      }
      setIsOpenDeleteModal(false);
    } catch (e) {
      setIsOpenDeleteModal(false);
    }
  };

  const changePage = (page: number) => {
    eventsStore.addOffset((page - 1) * eventsStore.limit);
    getEventsList();
  };

  const getEventsList = useCallback(
    (
      count?: number | null,
      debounced?: string,
      params?: {
        status?: string;
        startDate?: Date;
        endDate?: Date;
      }
    ) => {
      const newOffset = count === 1 ? eventsStore.offset - eventsStore.limit : eventsStore.offset;
      const args: argsOption = {
        offset: newOffset,
        venueId: +venueId!,
      };
      if (viewType !== EventsViewTyoe.calendar) {
        args.limit = eventsStore.limit;
      }
      if (debounced) {
        args.searchValue = debounced;
      }

      if (isAdmin(userStore.user)) {
        args.status = params?.status || filterStatus;
      }

      if (params?.startDate && params?.endDate) {
        args.startDate = params.startDate;
        args.endDate = params.endDate;
      }

      getEvents({
        variables: {
          listEventsArgs: args,
        },
      });
    },
    [getEvents, filterStatus, venueId, viewType]
  );

  const onChangeStatus = useCallback(
    (e: RadioChangeEvent) => {
      setFilterStatus(e.target.value);
      getEventsList(null, debounced, {
        status: e.target.value,
      });
    },
    [debounced, getEventsList]
  );

  const onChangeViewType = useCallback(
    (viewType: EventsViewTyoe, params?: { startDate: Date; endDate: Date }) => {
      setViewType(viewType);
      getEventsList(null, debounced, params);
    },
    [debounced, getEventsList]
  );

  const fetchEvents = useCallback(async () => {
    await eventsStore.resetPagination();
    if (debounced.length) {
      getEventsList(null, debounced);
    } else {
      getEventsList();
    }
  }, [debounced, getEventsList]);

  const onCreateEventRequest = async (eventId: number) => {
    try {
      const { data } = await createEventRequest({
        variables: {
          eventReqsArgs: { eventId },
        },
      });
      eventsStore.createEventRequest(eventId, data?.createEventRequest);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (eventsList?.listEvents?.events) {
      eventsStore.addEvents(eventsList.listEvents.events);
      eventsStore.addAllEventsTotalCount(eventsList.listEvents.count);
    }
  }, [eventsList?.listEvents]);

  useEffect(() => {
    eventsStore.resetEvents();
    fetchEvents();
  }, [debounced, fetchEvents]);

  if (getEventsError) {
    return <BaseError>{getEventsError.message}</BaseError>;
  }

  return (
    <div className={"event-page"}>
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteEventConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this event?"}
        />
      ) : null}

      {loadingRemove ? <BaseCreateOrDeleteModalLoading /> : null}
      {errorRemove ? <BaseAlert type={"failed"} message={errorRemove?.message} /> : null}
      {errorRemoveData?.removeEvent?.id ? (
        <BaseAlert
          type={"success"}
          message={"Event was successfully deleted"}
          reset={() => resetErrorRemove()}
        />
      ) : null}
      <div className={"base-page-header events-page-header"}>
        <BaseSearch
          inputClassName="events-page-search"
          handleChange={(value) => setSearch(value)}
        />
        {!isTicketEvents && (
          <Button type="primary" size="large" onClick={createEvent}>
            Add Event
          </Button>
        )}
        {isAdmin(userStore.user) ? (
          <div className={"base-page-filters"}>
            <Radio.Group onChange={onChangeStatus} defaultValue={EventRequestsFilterStatus.ALL}>
              <Radio.Button value={EventRequestsFilterStatus.ALL}>All</Radio.Button>
              <Radio.Button value={EventRequestsFilterStatus.PENDING}>Pending</Radio.Button>
              <Radio.Button value={EventRequestsFilterStatus.ACCEPTED}>Accepted</Radio.Button>
              <Radio.Button value={EventRequestsFilterStatus.REJECTED}>Rejected</Radio.Button>
            </Radio.Group>
          </div>
        ) : null}
        <div className="base-page-header--view">
          <Button
            size="large"
            icon={<UnorderedListOutlined />}
            danger={viewType === EventsViewTyoe.list}
            onClick={() => {
              onChangeViewType(EventsViewTyoe.list);
            }}
          />
          <Button
            size="large"
            icon={<CalendarOutlined />}
            className="ml-5"
            danger={viewType === EventsViewTyoe.calendar}
            onClick={() =>
              onChangeViewType(EventsViewTyoe.calendar, {
                startDate: dayjs().startOf("month").startOf("week").add(1, "day").toDate(),
                endDate: dayjs().endOf("month").endOf("week").add(1, "day").toDate(),
              })
            }
          />
        </div>
      </div>

      <div className={"event-page--container"}>
        {(loading || !eventsList) && viewType !== EventsViewTyoe.calendar ? (
          <BaseLoader />
        ) : (
          <>
            {viewType === EventsViewTyoe.calendar ? (
              <EventCalendar
                eventsList={eventsList}
                getEventsList={getEventsList}
                debounced={debounced}
              />
            ) : (
              <>
                {eventsList?.listEvents.events.length ? (
                  <>
                    <EventTable
                      data={eventsStore.eventsList}
                      onSelectItem={isTicketEvents ? onCreateTicket : goToCurrentEvent}
                      onEditItem={handleEditEvent}
                      onDeleteItem={openOrCloseDeleteModal}
                      onCreateEventRequest={
                        isAdmin(userStore.user) ? onCreateEventRequest : undefined
                      }
                    />
                    <BasePagination
                      totalPages={Math.ceil(eventsStore.eventsTotalCount / eventsStore.limit)}
                      currentPage={eventsStore.offset / eventsStore.limit + 1}
                      changePage={changePage}
                    />
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default Events;
