import "./SettingsForm.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { ISettingsForm } from "../../types/settings";
import {
  EMAIL_INVALID_TEXT,
  PASSWORD_CONFIRM_TEXT,
  PASSWORD_MAXIMUM_TEXT,
  PASSWORD_MINIMUM_TEXT,
  PASSWORD_REQUIRED_TEXT,
} from "../../constants/errors";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_SETTINGS } from "../../graphql/mutations/updateSettings";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import userStore from "../../store/userStore";
import BaseButton from "../../components/reusable/baseButton/BaseButton";

const SettingsForm = () => {
  const [updateSettings, { data, loading, error }] = useMutation(UPDATE_SETTINGS);

  const validationSchema = yup.object().shape({
    name: yup.string(),
    email: yup.string().email(EMAIL_INVALID_TEXT),
    oldPassword: yup
      .string()
      .required(PASSWORD_REQUIRED_TEXT)
      .min(6, PASSWORD_MINIMUM_TEXT)
      .max(30, PASSWORD_MAXIMUM_TEXT),
    newPassword: yup
      .string()
      .required(PASSWORD_REQUIRED_TEXT)
      .min(6, PASSWORD_MINIMUM_TEXT)
      .max(30, PASSWORD_MAXIMUM_TEXT),
    newPasswordConfirmation: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], PASSWORD_CONFIRM_TEXT),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISettingsForm>({
    resolver: yupResolver(validationSchema),
  });

  const onChangeSettings: SubmitHandler<ISettingsForm> = async (data) => {
    await updateSettings({
      variables: {
        updatePasswordInput: {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      },
    });
  };

  useEffect(() => {
    reset({
      name: userStore.user?.name,
      email: userStore.user?.email,
    });
  }, [reset]);

  return (
    <div className={"settings-view"}>
      {loading ? <BaseCreateOrDeleteModalLoading /> : null}
      {data ? <BaseAlert type={"success"} message={"Settings successfully updated"} /> : null}
      {error ? <BaseAlert type={"failed"} message={error.message} /> : null}
      <form onSubmit={handleSubmit(onChangeSettings)}>
        <div className={"settings-view--content"}>
          <h2 className={"settings-view--title"}>SETTINGS</h2>
          <div className={"settings-view--input-block"}>
            <div className={"settings-view--name-email-block"}>
              <BaseInput
                readOnly={true}
                newClassName={"modal-input"}
                label={{
                  text: "Name",
                  htmlFor: "name",
                }}
                registerValidation={{
                  type: "text",
                  name: "name",
                  placeholder: "Name...",
                  validation: {
                    ...register("name"),
                  },
                }}
                error={errors.name?.message}
              />
              <BaseInput
                readOnly={true}
                newClassName={"modal-input"}
                label={{
                  text: "Email",
                  htmlFor: "email",
                }}
                registerValidation={{
                  type: "text",
                  name: "email",
                  placeholder: "Email...",
                  validation: {
                    ...register("email"),
                  },
                }}
                error={errors.email?.message}
              />
            </div>
            <div className={"settings-view--password-block"}>
              <BaseInput
                newClassName={"modal-input password-input"}
                isPasswordInput={true}
                label={{
                  text: "Current Password",
                  htmlFor: "current-password",
                }}
                registerValidation={{
                  type: "password",
                  name: "current-password",
                  placeholder: "Current Password...",
                  validation: {
                    ...register("oldPassword"),
                  },
                }}
                error={errors.oldPassword?.message}
              />

              <BaseInput
                newClassName={"modal-input password-input"}
                isPasswordInput={true}
                label={{
                  text: "New Password",
                  htmlFor: "new-password",
                }}
                registerValidation={{
                  type: "password",
                  name: "new-password",
                  placeholder: "New Password...",
                  validation: {
                    ...register("newPassword"),
                  },
                }}
                error={errors.newPassword?.message}
              />

              <BaseInput
                newClassName={"modal-input password-input"}
                isPasswordInput={true}
                label={{
                  text: "Password Confirm",
                  htmlFor: "password-confirm",
                }}
                registerValidation={{
                  type: "password",
                  name: "password-confirm",
                  placeholder: "Password...",
                  validation: {
                    ...register("newPasswordConfirmation"),
                  },
                }}
                error={errors.newPasswordConfirmation?.message}
              />
            </div>
          </div>
          <div className={"settings-view--btn-block"}>
            <BaseButton title={"Save"} className={"settings-view--btn"} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingsForm;
