import { gql } from "@apollo/client";

export const CREATE_VENUE_STREAM = gql`
  mutation createVenueStreams($input: CreateVenueStreamInput!) {
    createVenueStreams(input: $input) {
      id
      name
      streamUrl
    }
  }
`;
