import { gql } from "@apollo/client";

export const GET_EVENT_INFO = gql`
  query findEventInfo($id: Int!) {
    findEventInfo(id: $id) {
      id
      name
      venue {
        id
        name
      }
    }
  }
`;
