import { PanelItem } from "./types";
import { IMediaItem } from "./mediaTypes";
import { IEventData } from "./eventTypes";
import type { Dayjs } from "dayjs";

export enum TicketableTypeEnum {
  VENUE = "venue",
  EVENT = "event",
}

export interface ITicket extends PanelItem {
  venueId: string | number;
  type?: "venue" | "event";
  name: string;
  description?: string;
  date?: Dayjs;
  amount?: number;
  currency: string;
  count?: number;
  index?: number;
  discountId?: string | number;
  startTime?: Dayjs;
  endTime?: Dayjs;
  ticketableId: string | number;
  ticketableType: TicketableTypeEnum;
  media?: Array<IMediaItem>;
  event?: IEventData;
  earlyBirdCount?: number;
  earlyBirdAmount?: number;
}

export interface ICreateTicket {
  type?: "venue" | "event";
  name: string;
  description?: string;
  date?: Dayjs;
  amount?: number;
  currency: string;
  count?: number;
  index?: number;
  discountId?: string | number;
  startTime: Dayjs;
  endTime: Dayjs;
  ticketableId: string | number;
  ticketableType: TicketableTypeEnum;
  media?: Array<IMediaItem>;
  event?: IEventData;
  earlyBirdCount?: number;
  earlyBirdAmount?: number;
}
