import { gql } from "@apollo/client";

export const VERIFY_QR_CODE = gql`
  mutation verifyQrCode($id: String!) {
    verifyQrCode(id: $id) {
      status
      message
    }
  }
`;
