import React, { useCallback, useEffect, useState } from "react";
import { Radio } from "antd";
import "./Tickets.scss";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import Events from "../Events/Events";
import { PanelItem } from "../../types/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_TICKETS_LIST } from "../../graphql/query/getTickets";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import NoData from "../../components/reusable/noData/NoData";
import ticketsStore from "../../store/ticketsStore";
import { ITicket } from "../../types/ticket";
import BaseError from "../../components/reusable/baseError/BaseError";
import { useDebounce } from "../../hooks/useDebounce";
import { observer } from "mobx-react-lite";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { REMOVE_TICKET } from "../../graphql/mutations/deleteTicket";
import TicketTable from "../../components/reusable/baseResponsiveItemsPanel/ticketTable/TicketTable";
import { RadioChangeEvent } from "antd/es/radio/interface";

type argsOption = {
  limit: number;
  offset: number;
  name?: string;
  eventId: number;
  filterStatus?: FilterStatus;
};

enum FilterStatus {
  ACTIVE = "active",
  UNSTARTED = "unstarted",
  EXPIRED = "expired",
  SOLD = "sold",
}

const Tickets = observer(() => {
  const navigate = useNavigate();
  const { venueId, eventId } = useParams();

  const [search, setSearch] = useState<string>("");
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  // const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  // const [selectItem, setSelectItem] = useState<PanelItem | null>(null);
  const [selectItem, setSelectItem] = useState<PanelItem | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>(FilterStatus.ACTIVE);

  const debounced = useDebounce(search);

  const [removeTicket] = useMutation(REMOVE_TICKET);

  const [getTickets, { loading, error: getTicketsError, data: ticketsList }] = useLazyQuery(
    GET_TICKETS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  );

  const getTicketsList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset =
        count === 1 ? ticketsStore.offset - ticketsStore.limit : ticketsStore.offset;
      const args: argsOption = {
        limit: ticketsStore.limit,
        offset: newOffset,
        eventId: +eventId!,
        filterStatus: filterStatus,
      };

      if (debounced) {
        args.name = debounced;
      }

      getTickets({
        variables: {
          input: args,
        },
      });
    },
    [getTickets, venueId, eventId, filterStatus]
  );

  const fetchTickets = useCallback(() => {
    ticketsStore.resetPagination();
    if (debounced.length) {
      getTicketsList(null, debounced);
    } else {
      getTicketsList();
    }
  }, [debounced, getTicketsList]);

  useEffect(() => {
    if (ticketsList?.listEventTicketsAsAdmin?.tickets) {
      ticketsStore.addTickets(ticketsList.listEventTicketsAsAdmin.tickets);
      ticketsStore.addAllTicketsTotalCount(ticketsList.listEventTicketsAsAdmin.count);
    }
  }, [ticketsList?.listEventTicketsAsAdmin]);

  useEffect(() => {
    ticketsStore.resetTickets();
    fetchTickets();
  }, [debounced, fetchTickets]);

  const createTicket = (ticketType: string, data?: any) => {
    const path = `/venues/${venueId}/events/${eventId}/tickets/${data.id}`;
    navigate({
      pathname: path,
      search: `step-1`,
    });
  };

  const openOrCloseTicketModal = () => {
    setIsTicketModalOpen((prev) => !prev);
  };

  const openOrCloseDeleteModal = useCallback((ticket?: ITicket | PanelItem) => {
    if (ticket) {
      setSelectItem(ticket);
    }
    setIsOpenDeleteModal((prev) => !prev);
  }, []);

  const handleEditTicket = (item: PanelItem | ITicket) => {
    navigate({
      pathname: venueId
        ? `/venues/${venueId}/events/${eventId}/tickets/${item.id}`
        : `/events/${eventId}/tickets/${item.id}`,
    });
  };

  const deleteTicketConfirm = async () => {
    try {
      const { data } = await removeTicket({
        variables: {
          id: +selectItem?.id!,
        },
      });
      if (
        ticketsStore.offset / ticketsStore.limit + 1 <
          Math.ceil(ticketsStore.ticketsTotalCount / ticketsStore.limit) ||
        (ticketsStore.ticketsList.length === 1 && ticketsStore.offset !== 0)
      ) {
        getTicketsList(ticketsStore.ticketsList.length);
      } else {
        ticketsStore.removeTicket(data?.removeTicket.id);
      }
      setIsOpenDeleteModal(false);
    } catch (e) {
      setIsOpenDeleteModal(false);
    } finally {
      getTicketsList();
    }
  };

  const onCreateTicket = (event: ITicket | PanelItem) => {
    createTicket("event", event);
  };

  const goToCurrentTicket = (ticket: ITicket | PanelItem) => {
    const pathname = venueId
      ? `/venues/${venueId}/events/${eventId}/tickets/view/${ticket.id}`
      : `/events/${eventId}/tickets/view/${ticket.id}`;

    navigate({ pathname });
  };

  const changePage = (page: number) => {
    ticketsStore.addOffset((page - 1) * ticketsStore.limit);
    getTicketsList();
  };

  if (getTicketsError) {
    return <BaseError>{getTicketsError.message}</BaseError>;
  }

  const handleCreateTicket = () => {
    let path = venueId
      ? `/venues/${venueId}/events/${eventId}/tickets/create`
      : `/events/${eventId}/tickets/create`;
    navigate({
      pathname: path,
      search: `step-1`,
    });
  };

  const onChangeStatus = (e: RadioChangeEvent) => {
    setFilterStatus(e.target.value);
  };

  return (
    <div className={"tickets-page"}>
      {isOpenDeleteModal ? (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteTicketConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this ticket?"}
        />
      ) : null}
      {isTicketModalOpen ? (
        <Modal
          className={"tickets-modal"}
          closeModal={openOrCloseTicketModal}
          modalIsOpen={isTicketModalOpen}
          showCloseButton={false}
        >
          <Events isTicketEvents={true} createTicket={onCreateTicket} />
        </Modal>
      ) : null}

      <div className={"base-page-header"}>
        <BaseSearch handleChange={(value) => setSearch(value)} />
        <BaseButton title={"Add Ticket"} onClick={handleCreateTicket} />
      </div>

      <div className={"base-page-filters"}>
        <Radio.Group onChange={onChangeStatus} defaultValue={FilterStatus.ACTIVE}>
          <Radio.Button value={FilterStatus.ACTIVE}>Active</Radio.Button>
          <Radio.Button value={FilterStatus.UNSTARTED}>Not Started</Radio.Button>
          <Radio.Button value={FilterStatus.EXPIRED}>Expired</Radio.Button>
          <Radio.Button value={FilterStatus.SOLD}>Sold</Radio.Button>
        </Radio.Group>
      </div>

      <div className={"ticket-page--container"}>
        {loading || !ticketsList ? (
          <BaseLoader />
        ) : (
          <>
            {ticketsList?.listEventTicketsAsAdmin.tickets.length && ticketsStore.ticketsList ? (
              <>
                <TicketTable
                  data={ticketsStore.ticketsList}
                  onSelectItem={goToCurrentTicket}
                  onEditItem={handleEditTicket}
                  onDeleteItem={openOrCloseDeleteModal}
                />
                {/*<ResponsiveItemsPanel*/}
                {/*  type={"ticket"}*/}
                {/*  canEdit={true}*/}
                {/*  data={ticketsStore.ticketsList}*/}
                {/*  selectItem={goToCurrentTicket}*/}
                {/*  deleteItem={openOrCloseDeleteModal}*/}
                {/*  onEditItem={handleEditTicket}*/}
                {/*/>*/}
                <BasePagination
                  totalPages={Math.ceil(ticketsStore.ticketsTotalCount / ticketsStore.limit)}
                  currentPage={ticketsStore.offset / ticketsStore.limit + 1}
                  changePage={changePage}
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default Tickets;
