import pub from "../../../../assets/images/pub.png";
import { memo } from "react";
import { Table, Tag } from "antd";
import { BASE_URL_IMG } from "../../../../constants/Api";
import { EventTableActions, EventTableProps } from "./EventTableActions";

const EventStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "warning";
    case "accepted":
      return "success";
    case "rejected":
      return "error";
  }
};
const EventTable = ({
  data,
  onSelectItem,
  onEditItem,
  onDeleteItem,
  onCreateEventRequest,
}: EventTableProps) => {
  let columns: (
    | { dataIndex: string; title: string; render: (name: string) => JSX.Element; key: string }
    | { dataIndex: string; title: string; render: (cover: string) => JSX.Element; key: string }
    | { dataIndex: string; title: string; render: (address: string) => JSX.Element; key: string }
    | { dataIndex: string; title: string; render: (bandName: string) => JSX.Element; key: string }
    | {
        dataIndex: string;
        title: string;
        render: (description: string) => JSX.Element;
        key: string;
      }
    | any
  )[];
  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return <p className={"base-responsive-table-name"}>{id}</p>;
      },
    },
    {
      title: "Event",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
      render: (venue: string, row: any) => {
        return <p className={"base-responsive-table-name"}>{row.venue.name}</p>;
      },
    },
    {
      title: "Image",
      dataIndex: "cover",
      key: "cover",
      render: (cover: string) => {
        return (
          <div className={"base-responsive-table-image"}>
            <img src={cover ? BASE_URL_IMG + cover : pub} alt="table_image" />
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address: string) => {
        return <p>{address ? address : "No Address"}</p>;
      },
    },
    {
      title: "Band Name",
      dataIndex: "bandName",
      key: "bandName",
      render: (bandName: string) => {
        return <p>{bandName ? bandName : ""}</p>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => {
        return (
          <p className={"base-responsive-table-description"}>
            {description ? description : "No Description"}
          </p>
        );
      },
    },
  ];

  if (onCreateEventRequest) {
    columns.push({
      title: "Status",
      dataIndex: "eventRequest",
      key: "eventRequest",
      render: (eventRequest: any, row: any) => {
        return (
          <>
            {row.eventRequest?.status ? (
              <Tag color={EventStatusColor(row.eventRequest?.status)}>
                {row.eventRequest?.status}
              </Tag>
            ) : null}
          </>
        );
      },
    });
  }

  columns.push({
    title: "Actions",
    key: "actions",
    render: (item: any) => {
      return EventTableActions(
        item,
        onSelectItem,
        onEditItem,
        onDeleteItem,
        false,
        onCreateEventRequest
      );
    },
  });

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
      onRow={(record) => {
        return {
          onClick: () => {
            if (onSelectItem) {
              onSelectItem(record);
            }
          },
        };
      }}
    />
  );
};

export default memo(EventTable);
