import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import NoData from "../../components/reusable/noData/NoData";
import "./EventRequests.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_EVENT_REQUESTS_LIST } from "../../graphql/query/getEventRequests";
import { ACCEPT_EVENT_REQUEST } from "../../graphql/mutations/acceptEventRequest";
import { REJECT_EVENT_REQUEST } from "../../graphql/mutations/rejectEventRequest";
import { PanelItem } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { isSuperAdmin, isVenueManager } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import eventRequestsStore from "../../store/eventRequestsStore";
import { EventRequestsFilterStatus } from "../../types/eventRequestTypes";
import { RadioChangeEvent } from "antd/es/radio/interface";
import BaseError from "../../components/reusable/baseError/BaseError";
import { message, Radio } from "antd";
import EventRequestsTable from "../../components/reusable/baseResponsiveItemsPanel/eventRequestsTable/EventRequestsTable";
import { useDebounce } from "../../hooks/useDebounce";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

type argsOption = {
  limit: number;
  offset: number;
  name?: string;
  status?: string;
};

const EventRequests = observer(() => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");

  const [filterStatus, setFilterStatus] = useState<EventRequestsFilterStatus>(
    EventRequestsFilterStatus.ALL
  );
  const [selectItemId, setSelectItemId] = useState<number | null>(null);
  const [isOpenAcceptModal, setIsOpenAccpetModal] = useState<boolean>(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);
  const filterRef = useRef(null);

  const debounced = useDebounce(search);
  const onPageChange = useCallback((page: number) => {
    eventRequestsStore.addOffset((page - 1) * eventRequestsStore.limit);
    getEventRequestsList();
  }, []);

  const [getEventRequests, { loading, error: getEventRequestsError, data: eventRequestsList }] =
    useLazyQuery(GET_EVENT_REQUESTS_LIST, {
      fetchPolicy: "no-cache",
    });
  const [acceptEventRequest] = useMutation(ACCEPT_EVENT_REQUEST);
  const [rejectEventRequest] = useMutation(REJECT_EVENT_REQUEST);

  const searchField = useMemo(
    () => (
      <div className={"base-page-header d-flex-column gap-20"}>
        <BaseSearch handleChange={(value) => setSearch(value)} />
      </div>
    ),
    []
  );

  const onSelectItem = useCallback((eventRequest: PanelItem) => {
    let pathname = `/event-requests/view/${eventRequest.id}`;
    navigate({
      pathname: pathname,
    });
  }, []);

  const openOrCloseAcceptModal = useCallback(() => {
    setIsOpenAccpetModal((prev) => !prev);
  }, []);

  const openOrCloseRejectModal = useCallback(() => {
    setIsOpenRejectModal((prev) => !prev);
  }, []);

  const onAcceptItem = useCallback(() => {
    if (selectItemId) {
      acceptEventRequest({
        variables: {
          id: +selectItemId,
        },
      })
        .then(() => {
          message.success("This event request was successfully accepted!", 1);
          return getEventRequestsList();
        })
        .catch((error) => {
          return message.error(error.message, 1);
        })
        .finally(() => {
          setIsOpenAccpetModal(false);
        });
    }
  }, [selectItemId]);

  const onRejectItem = useCallback(
    (reason: string | undefined) => {
      if (selectItemId) {
        rejectEventRequest({
          variables: {
            rejectEventReqArgs: {
              id: +selectItemId,
              reason,
            },
          },
        })
          .then(() => {
            return getEventRequestsList();
          })
          .catch((error) => {
            return message.error(error.message, 1);
          })
          .finally(() => {
            setIsOpenRejectModal(false);
          });
      }
    },
    [selectItemId]
  );

  const getEventRequestsList = useCallback(
    (count?: number | null, debounced?: string, status?: string) => {
      const newOffset =
        count === 1
          ? eventRequestsStore.offset - eventRequestsStore.limit
          : eventRequestsStore.offset;
      const args: argsOption = {
        limit: eventRequestsStore.limit,
        offset: newOffset,
        status: filterStatus || undefined,
      };

      if (debounced) {
        args.name = debounced;
      }

      getEventRequests({
        variables: {
          listEventReqsArgs: args,
        },
      });
    },
    [getEventRequests, filterStatus]
  );

  const onChangeStatus = useCallback(
    (e: RadioChangeEvent) => {
      setFilterStatus(e.target.value);
      getEventRequestsList(null, debounced, e.target.value);
    },
    [debounced, getEventRequestsList]
  );

  const fetchEventRequests = useCallback(async () => {
    await eventRequestsStore.resetPagination();
    if (debounced.length) {
      getEventRequestsList(null, debounced);
    } else {
      getEventRequestsList();
    }
  }, [debounced, getEventRequestsList]);

  useEffect(() => {
    if (eventRequestsList?.listEventRequests?.eventRequests) {
      eventRequestsStore.addEventRequests(eventRequestsList.listEventRequests.eventRequests);
      eventRequestsStore.addAllEventRequestsTotalCount(eventRequestsList.listEventRequests.count);
    }
  }, [eventRequestsList?.listEventRequests]);

  useEffect(() => {
    eventRequestsStore.resetEventRequests();
    fetchEventRequests();
  }, [debounced, fetchEventRequests]);

  if (getEventRequestsError) {
    return <BaseError>{getEventRequestsError.message}</BaseError>;
  }

  return (
    <div className="event-requests page-content-layout">
      {isOpenAcceptModal ? (
        <ConfirmationModal
          isOpen={isOpenAcceptModal}
          closeModal={openOrCloseAcceptModal}
          deleteHandle={onAcceptItem}
          buttonText={"Accept"}
          title={"Are you sure you want to accept this event request?"}
        />
      ) : null}

      {isOpenRejectModal ? (
        <ConfirmationModal
          isOpen={isOpenRejectModal}
          closeModal={openOrCloseRejectModal}
          rejectHandle={onRejectItem}
          buttonText={"Reject"}
          title={"Are you sure you want to reject this event request?"}
          isReject={true}
        />
      ) : null}

      <div className={"base-page-header"}>
        {searchField}
        {/*<BaseSearch handleChange={(value) => setSearch(value)} />*/}
        {isVenueManager(userStore.user) || isSuperAdmin(userStore.user) ? (
          <div className={"base-page-filters"}>
            <Radio.Group
              ref={filterRef}
              onChange={onChangeStatus}
              defaultValue={EventRequestsFilterStatus.ALL}
            >
              <Radio.Button value={EventRequestsFilterStatus.ALL}>All</Radio.Button>
              <Radio.Button value={EventRequestsFilterStatus.PENDING}>Pending</Radio.Button>
              <Radio.Button value={EventRequestsFilterStatus.ACCEPTED}>Accepted</Radio.Button>
              <Radio.Button value={EventRequestsFilterStatus.REJECTED}>Rejected</Radio.Button>
            </Radio.Group>
          </div>
        ) : null}
      </div>

      <div className={"event-requests--container"}>
        {loading || !eventRequestsList ? (
          <BaseLoader />
        ) : (
          <>
            {eventRequestsList?.listEventRequests.eventRequests.length ? (
              <>
                <EventRequestsTable
                  data={eventRequestsStore.eventRequestsList}
                  onSelectItem={onSelectItem}
                  onAcceptItem={(item: PanelItem) => {
                    setSelectItemId(+item.id);
                    openOrCloseAcceptModal();
                  }}
                  onRejectItem={(item: PanelItem) => {
                    setSelectItemId(+item.id);
                    openOrCloseRejectModal();
                  }}
                />

                <BasePagination
                  totalPages={Math.ceil(
                    eventRequestsStore.eventRequestsTotalCount / eventRequestsStore.limit
                  )}
                  currentPage={eventRequestsStore.offset / eventRequestsStore.limit + 1}
                  changePage={onPageChange}
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default EventRequests;
