import React, { useCallback, useEffect, useState } from "react";
import AdvertisementForm from "../../forms/AdvertisementForm/AdvertisementForm";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_VENUES_LIST } from "../../graphql/query/getVenues";
import { GET_AD } from "../../graphql/query/getAdvertisement";
import BaseError from "../../components/reusable/baseError/BaseError";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import AdvertisementView from "../../pageView/AdvertisementView/AdvertisementView";

const Advertisement = () => {
  const { advertisementId, advertisementName } = useParams();

  const [loading, setLoading] = useState(true);

  const [getAd, { error, data }] = useLazyQuery(GET_AD, {
    fetchPolicy: "no-cache",
  });

  const [getVenuesList, { data: venueList }] = useLazyQuery(GET_VENUES_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      listVenuesArgs: {},
    },
  });

  const fetchNews = useCallback(async () => {
    try {
      if (advertisementId) {
        await getAd({
          variables: {
            id: +advertisementId,
          },
        });
      }
      await getVenuesList();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [advertisementId, getAd, getVenuesList]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  if (loading) {
    return <BaseLoader />;
  }

  return (
    <>
      {advertisementName ? (
        <AdvertisementView venueList={venueList?.listVenues.venues} news={data?.feed} />
      ) : (
        <AdvertisementForm venueList={venueList?.listVenues.venues} news={data?.feed} />
      )}
    </>
  );
};

export default Advertisement;
