import { Button, message } from "antd";
import React, { FC, useCallback, useState } from "react";

interface PublishButtonProps {
  isPublishedInitialValue: boolean;
  publishBlog: (isPublished: boolean, callback: () => void) => void;
  hasFormErrors: boolean;
}

const PublishButton: FC<PublishButtonProps> = ({
  publishBlog,
  hasFormErrors,
  isPublishedInitialValue,
}) => {
  const [isPublished, setIsPublished] = useState(isPublishedInitialValue);

  const onPublish = useCallback(() => {
    publishBlog(!isPublished, () => {
      setIsPublished((prev) => {
        message.success(`Blog was successfully ${prev ? "unpublished" : "published"}`, 0.8);

        return !prev;
      });
    });
  }, [isPublished, publishBlog]);

  return (
    <Button
      type="primary"
      size="large"
      disabled={hasFormErrors}
      className={isPublished ? "btn-danger" : ""}
      onClick={onPublish}
    >
      {isPublished ? "Unpublished" : "Publish"}
    </Button>
  );
};

export default PublishButton;
