import { PanelItem } from "../../../../types/types";
import { Button, Dropdown, MenuProps } from "antd";
import { TableProps } from "../helpers/TableHelper";
import React from "react";
import AddToCalendarBtn from "./AddToCalendarBtn";
import { MoreOutlined } from "@ant-design/icons";
import "./EventTableActions.scss";

export interface EventTableProps extends TableProps {
  onCreateEventRequest?: (eventId: number) => Promise<void> | undefined;
}

export const EventTableActions = (
  item: any,
  onSelectItem?: (item: PanelItem) => void,
  onEditItem?: (item: PanelItem, type?: string) => void,
  onDeleteItem?: (item: PanelItem) => void,
  showEditDeleteActions?: boolean,
  onCreateEventRequest?: (eventId: number) => Promise<void> | undefined
) => {
  const items: MenuProps["items"] = [
    {
      label: (
        <>
          {onCreateEventRequest && !item.eventRequest ? (
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCreateEventRequest(item.id);
              }}
            >
              Send Event Request
            </Button>
          ) : null}
        </>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (onSelectItem) {
              onSelectItem(item);
            }
          }}
          ghost
        >
          View
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <>
          {!showEditDeleteActions ? (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                if (onEditItem) {
                  onEditItem(item);
                }
              }}
            >
              Edit
            </Button>
          ) : null}
        </>
      ),
      key: "3",
    },
    {
      label: <AddToCalendarBtn item={item} />,
      key: "4",
    },
    {
      label: (
        <>
          {!showEditDeleteActions ? (
            <Button
              type="default"
              className="btn-danger"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (onDeleteItem) {
                  onDeleteItem(item);
                }
              }}
            >
              Delete
            </Button>
          ) : null}
        </>
      ),
      key: "5",
    },
  ];
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        overlayClassName={"event-table-actions-dropdown"}
      >
        <Button onClick={(e) => e.stopPropagation()}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};
