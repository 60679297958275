import React from "react";
import { CartesianGrid, Line, XAxis, YAxis, LineChart, ResponsiveContainer } from "recharts";
import dayjs from "dayjs";

const DashboardLineChart = ({ data, title }: { data: any[]; title: string }) => {
  const formatXAxis = (tickItem: any) => {
    return dayjs().month(tickItem).format("MMM");
  };
  return (
    <div className={"dashboard-page-chart"}>
      <h2 className={"dashboard-page-chart-title"}>{title}</h2>
      <ResponsiveContainer height={280}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -33,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tickFormatter={formatXAxis} />
          <YAxis />
          <Line type="monotone" dataKey="count" stroke="#ed2f31" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DashboardLineChart;
