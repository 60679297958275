import React from "react";
import { observer } from "mobx-react-lite";
import { isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import { Button } from "antd";
interface IVenuesSearch {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  createVenue: () => void;
}
const VenuesSearch: React.FC<IVenuesSearch> = observer(({ setSearch, createVenue }) => {
  return (
    <div className={"base-page-header venue-page-actions space-between gap-20"}>
      <BaseSearch handleChange={(value) => setSearch(value)} />
      {isSuperAdmin(userStore.user) ? (
        <>
          <div className={"d-flex-aligned-end venue-page-actions-btn"}>
            <Button type="primary" size="large" onClick={createVenue}>
              Add Venue
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
});

export default VenuesSearch;
