import React from "react";
import "./PageContentLayout.scss";

interface IPageContentLayout {
  view: JSX.Element;
  actions?: JSX.Element;
  isVerticalAlign?: boolean;
  className?: string;
}

const PageContentLayout: React.FC<IPageContentLayout> = ({ view, actions, isVerticalAlign = false, className }) => {
  return (
    <div className={`page-content-layout d-flex gap-20 ${isVerticalAlign ? 'page-content-layout--vertical' : ''} ${className? className:"d-flex"}`}>
      {actions ? <>{actions}</> : null}
      <div className={"page-content-layout-view"}>{view}</div>
    </div>
  );
};

export default PageContentLayout;
