import { matchRoutes, useLocation } from "react-router-dom";

export const useValidateRoutes = (route?: string) => {
  const currentLocation = useLocation();
  const someRoutes = [
    { path: "settings" },
    { path: "admins" },
    { path: "ads" },
    { path: "users" },
    { path: "reports" },
    { path: "advertisement" },
    { path: "advertisement/:advertisementId" },
    { path: "advertisement/view/:advertisementName/:advertisementId" },
    { path: "verify-qr-code/:qrcode_id" },

    { path: "venues" },
    { path: "venues/create" },
    { path: "venues/:venueId" },
    { path: "venues/view/:venueId" },
    { path: "venues/:venueId/staff" },
    { path: "venues/:venueId/events" },
    { path: "venues/:venueId/events/create" },
    { path: "venues/:venueId/events/:eventId" },
    { path: "venues/:venueId/events/view/:eventId" },
    { path: "venues/:venueId/events/:eventId/tickets" },
    { path: "venues/:venueId/discounts" },
    { path: "venues/:venueId/viewer" },
    { path: "venues/:venueId/events/:eventId/tickets/create" },
    { path: "venues/:venueId/events/:eventId/tickets/:ticketId" },
    { path: "venues/:venueId/events/:eventId/tickets/view/:ticketId" },
  ];
  return matchRoutes(someRoutes, route || currentLocation);
};
