import React, { FC, useCallback, useEffect, useState } from "react";
import "./ImageVideoUploadCarouselItem.scss";
import Loader from "../../reusable/loader/Loader";
import { BASE_URL_IMG } from "../../../constants/Api";
import { uploadMediaToAWS } from "../../../utils/evaporate";
import filesUploadStore from "../../../store/filesUploadStore";
import { IMediaItem } from "../../../types/mediaTypes";
import { ReactComponent as RecycleBin } from "../../../assets/images/RecycleBin.svg";
import { observer } from "mobx-react-lite";

interface ImageVideoUploadCarouselItemProps {
  readOnly: boolean;
  id: string | number;
  keyType: "events" | "venues" | "feeds" | "tickets" | "blogs";
  mediaItem: IMediaItem;
  canAddCover?: boolean;
}

const ImageVideoUploadCarouselItem: FC<ImageVideoUploadCarouselItemProps> = observer(
  ({ readOnly, id, mediaItem, keyType, canAddCover }) => {
    const [fileLoading, setFileLoading] = useState<boolean>(false);

    const fileUpload = useCallback(async () => {
      if (id && mediaItem.file) {
        setFileLoading(true);
        const data = await uploadMediaToAWS({
          id: id,
          file: mediaItem.file[1],
          keyType,
        });

        const media = {
          key: `${keyType}/${id}/${data.fileName}`,
          type: mediaItem.type,
          isCover: mediaItem.isCover || false,
        };
        filesUploadStore.addAWSUploadFiles(media);

        setFileLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, mediaItem, keyType]);

    const handleChangeCover = (key: string) => {
      filesUploadStore.changeCover(key);
    };

    const removeUploadedFiles = useCallback(() => {
      filesUploadStore.removeUploadFiles(mediaItem.key);
      if (mediaItem.isCover && !!filesUploadStore.mediaFiles.length) {
        filesUploadStore.changeCover(filesUploadStore.mediaFiles[0].key);
      }
    }, [mediaItem.isCover, mediaItem.key]);

    useEffect(() => {
      if (filesUploadStore.isStartFileUploadToAWS) {
        fileUpload();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUpload, filesUploadStore.isStartFileUploadToAWS]);

    return (
      <div className={"img-video-item"}>
        {fileLoading ? (
          <div className={"img-video-item--loading"}>
            <Loader />
          </div>
        ) : null}
        <div className={"img-video-item--content"}>
          {mediaItem.type === "image" ? (
            <>
              <img
                src={
                  mediaItem.file
                    ? URL.createObjectURL(mediaItem.file[1])
                    : BASE_URL_IMG + mediaItem.key
                }
                alt="pub"
                width={"100%"}
                height={"100%"}
                className={"image"}
              />
              {mediaItem.isCover ? <div className={"cover"}>COVER</div> : null}
              {!readOnly && (
                <RecycleBin
                  width={14}
                  className={"img-video-item--recycle-bin"}
                  onClick={removeUploadedFiles}
                />
              )}
              {!readOnly && canAddCover && (
                <div className="middle">
                  <button
                    type={"button"}
                    className={"button"}
                    onClick={() => mediaItem.key && handleChangeCover(mediaItem.key)}
                  >
                    ADD COVER
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <video className={"video"} controls>
                <source
                  src={
                    mediaItem.file
                      ? URL?.createObjectURL(mediaItem?.file[1])
                      : BASE_URL_IMG + mediaItem.key
                  }
                />
              </video>
              {!readOnly && (
                <RecycleBin
                  width={14}
                  className={"img-video-item--recycle-bin"}
                  onClick={removeUploadedFiles}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default ImageVideoUploadCarouselItem;
