import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./Discounts.scss";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import Modal from "../../components/Modal/Modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_DISCOUNTS_LIST } from "../../graphql/query/getDiscounts";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import NoData from "../../components/reusable/noData/NoData";
import { IDiscount } from "../../types/discount";
import discountsStore from "../../store/discountsStore";
import BaseError from "../../components/reusable/baseError/BaseError";
import { useDebounce } from "../../hooks/useDebounce";
import { observer } from "mobx-react-lite";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import EditDiscountModalForm from "../../forms/EditDiscountModalForm/EditDiscountModalForm";
import { DELETE_DISCOUNT } from "../../graphql/mutations/deleteDiscount";
import { Button } from "antd";
import DiscountTable from "../../components/reusable/baseResponsiveItemsPanel/discountTable/DiscountTable";
import userStore from "../../store/userStore";
import { isAdmin } from "../../utils/isSuperAdmin";
// import { DELETE_DISCOUNT } from "../../graphql/mutations/deleteDiscount";

type argsOption = {
  limit: number;
  offset: number;
  name?: string;
  venueId: number;
};

const Discounts = observer(() => {
  const { venueId } = useParams();
  const [search, setSearch] = useState<string>("");
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [editDiscount, setEditDiscount] = useState<IDiscount | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const debounced = useDebounce(search);

  const [getDiscounts, { loading, error, data }] = useLazyQuery(GET_DISCOUNTS_LIST, {
    fetchPolicy: "no-cache",
  });

  const [removeDiscount] = useMutation(DELETE_DISCOUNT);

  const openDiscountModal = (discount?: IDiscount) => {
    discount && setEditDiscount(discount);
    setIsDiscountModalOpen(true);
  };

  const closeDiscountModal = () => {
    setIsDiscountModalOpen(false);
    setEditDiscount(null);
  };

  const openOrCloseDeleteModal = (discount?: IDiscount) => {
    discount && setEditDiscount(discount);
    setIsOpenDeleteModal((prev) => !prev);
  };

  const deleteDiscountConfirm = async () => {
    if (editDiscount?.id) {
      await removeDiscount({
        variables: {
          id: editDiscount.id,
        },
      });
      openOrCloseDeleteModal();
      const id = editDiscount.id;
      calculatePagination(id);
    }
  };

  const calculatePagination = (id: number | string) => {
    if (
      discountsStore.offset > 0 &&
      (discountsStore.offset / discountsStore.limit + 1 <
        Math.ceil(discountsStore.discountsTotalCount / discountsStore.limit) ||
        discountsStore.discountsList.length === 1)
    ) {
      getDiscountsList(discountsStore.discountsList.length);
    } else {
      discountsStore.removeDiscount(id);
    }
  };

  const changePage = (page: number) => {
    discountsStore.addOffset((page - 1) * discountsStore.limit);
    getDiscountsList();
  };

  const getDiscountsList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset =
        count === 1 ? discountsStore.offset - discountsStore.limit : discountsStore.offset;
      const venueIdParam = venueId ? parseInt(venueId) : 0;

      const args: argsOption = {
        venueId: venueIdParam,
        limit: discountsStore.limit,
        offset: newOffset,
      };

      if (debounced) {
        args.name = debounced;
      }

      getDiscounts({
        variables: {
          input: args,
        },
      });
    },
    [getDiscounts, venueId]
  );

  const fetchDiscount = useCallback(async () => {
    await discountsStore.resetDiscounts();
    if (debounced.length) {
      getDiscountsList(null, debounced);
    } else {
      getDiscountsList();
    }
  }, [debounced, getDiscountsList]);

  useEffect(() => {
    if (data) {
      discountsStore.addDiscounts(data.listDiscounts.discounts);
      discountsStore.addAllDiscountsTotalCount(data.listDiscounts.count);
    }
  }, [data]);

  useLayoutEffect(() => {
    discountsStore.resetDiscounts();
    fetchDiscount();
  }, [debounced, fetchDiscount]);

  useEffect(() => {
    if (isAdmin(userStore.user)) {
      navigate("/not-found");
    }
  }, [userStore.user, navigate]);
  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  // const discountSearch = <div className={"base-page-header d-flex-column gap-20"}>
  //   <BaseSearch handleChange={(value) => setSearch(value)} />
  //   <div className={"d-flex-aligned-end"}>
  //     <Button type="primary" size="large" onClick={() => openDiscountModal()}>Add Discount</Button>
  //   </div>
  // </div>;

  return (
    <div className={"admin-page"}>
      {isDiscountModalOpen ? (
        <Modal closeModal={closeDiscountModal} modalIsOpen={isDiscountModalOpen}>
          <EditDiscountModalForm
            venueId={venueId}
            closModal={closeDiscountModal}
            discountItem={editDiscount}
            getDiscountsList={getDiscountsList}
          />
        </Modal>
      ) : null}
      {isOpenDeleteModal && (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          closeModal={openOrCloseDeleteModal}
          deleteHandle={deleteDiscountConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this discount?"}
        />
      )}
      <div className={"base-page-header discounts-page-search-wrapper"}>
        <BaseSearch
          inputClassName="discounts-page-search"
          handleChange={(value) => setSearch(value)}
        />
        <Button type="primary" size="large" onClick={() => openDiscountModal()}>
          Add Discount
        </Button>
      </div>

      <div className={"admin-page--container"}>
        {loading || !data ? (
          <BaseLoader />
        ) : (
          <>
            {data?.listDiscounts.discounts.length ? (
              <>
                <DiscountTable
                  data={discountsStore.discountsList}
                  onEditItem={(discount) => openDiscountModal(discount as IDiscount)}
                  onDeleteItem={(discount) => openOrCloseDeleteModal(discount as IDiscount)}
                />
                <BasePagination
                  totalPages={Math.ceil(discountsStore.discountsTotalCount / discountsStore.limit)}
                  currentPage={discountsStore.offset / discountsStore.limit + 1}
                  changePage={changePage}
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default Discounts;
