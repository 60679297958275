import { FC } from "react";
import "./BaseTable.scss";
import DiscountItem from "./DiscountItem";

interface TableHeaderProps {
  type: "admin" | "user" | "staff" | "reportedPost" | "discount";
}

const TableHeader: FC<TableHeaderProps> = ({ type }) => {
  const getTableHeader = (type: "admin" | "user" | "staff" | "reportedPost" | "discount") => {
    switch (type) {
      case "discount":
      default:
        return (
          <DiscountItem
            hasActions={true}
            item={{ name: "Name", code: "Code", discount: "Discount" }}
          />
        );
    }
  };

  return <div className={"base-table"}>{getTableHeader(type)}</div>;
};

export default TableHeader;
