import { gql } from "@apollo/client";

export const UPLOAD_MEDIA = gql`
  mutation ($uploadMediaFromAdminInput: UploadMediaFromAdminInput!) {
    uploadMediaFromAdmin(uploadMediaFromAdminInput: $uploadMediaFromAdminInput) {
      status
      message
    }
  }
`;
