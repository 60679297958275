import React, {memo} from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "antd";
import dayjs from "dayjs";

export const CustomEvent = memo(({ event }: { event: any }) => {
  const navigate = useNavigate();
  return (
    <div>
      {dayjs(event.start) >= dayjs() ?(
        <Popover
          content={
            <div className={"event-popup-content"}>
              <h2 className={"event-popup-content-title"}>{event.title}</h2>
              <p className={"event-popup-content-desc"}>{event.description}</p>
            </div>
          }
          placement={"right"}
        >
          <div
            className="event"
            onClick={() => navigate(`/events/view/${event.id}`)}
            id={`event${event.id}`}
          >
            {event.title}
          </div>
        </Popover>
      ):
        <div
            className="past-event"
            onClick={() => navigate(`/events/view/${event.id}`)}
            id={`event${event.id}`}
          >
            {event.title}
        </div>
      }
    </div>
  );
});
