import { makeAutoObservable } from "mobx";
import { IGetMe } from "../types/types";

export interface IUserStore {
  token: string;
  id?: string;
  name?: string;
  user: IGetMe | null;
}

class UserStore implements IUserStore {
  token = "";
  name = "";
  user: IGetMe | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addUserToken(token: string) {
    this.token = token;
  }

  addUserName(name: string) {
    this.name = name;
  }

  addUser(user: IGetMe) {
    this.user = user;
  }

  removeUser() {
    this.token = "";
    this.name = "";
    this.user = null;
  }
}

export default new UserStore();
