import { FC } from "react";
import "./BaseTable.scss";
import { IBaseTable } from "../../../types/types";
import AdminOrStaffItem from "./AdminOrStaffItem";
import UserItem from "./UserItem";
import { IUsersItem } from "../../../types/user";
import { IAdminOrStaffItem } from "../../../types/adminOrstaff";
import ReportedPostItem from "./ReportedPostItem";
import { IReportedPost } from "../../../types/reportedPostTypes";
import { IDiscount } from "../../../types/discount";
import DiscountItem from "./DiscountItem";
import TableHeader from "./TableHeader";

interface BaseTableProps {
  tableData: IBaseTable[];
  type: "admin" | "user" | "staff" | "reportedPost" | "discount";
  isStaffEmailTable?: boolean;
  onHandleClick?: (item: IAdminOrStaffItem | IUsersItem | IDiscount, type?: string) => void;
  onHandleDelete?: (item: IAdminOrStaffItem | IUsersItem | IDiscount) => void;
}

const BaseTable: FC<BaseTableProps> = ({
  tableData,
  type,
  onHandleClick,
  isStaffEmailTable,
  onHandleDelete,
}) => {
  return (
    <div className={"base-table"}>
      <TableHeader type={type} />
      {tableData?.map((item) => {
        if (type === "admin" || type === "staff") {
          return (
            <AdminOrStaffItem
              key={item.id}
              item={item as IAdminOrStaffItem}
              onHandleClick={onHandleClick}
              type={type}
              isStaffEmailTable={isStaffEmailTable}
              onHandleDelete={onHandleDelete}
            />
          );
        }
        if (type === "user") {
          return <UserItem key={item.id} item={item as IUsersItem} />;
        }
        if (type === "reportedPost") {
          return <ReportedPostItem key={item.id} reportedPostItem={item as IReportedPost} />;
        }
        if (type === "discount") {
          return (
            <DiscountItem
              key={item.id}
              item={item as IDiscount}
              onHandleClick={onHandleClick}
              onHandleDelete={onHandleDelete}
              hasActions={true}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default BaseTable;
