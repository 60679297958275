import { gql } from "@apollo/client";

export const CREATE_DISCOUNT = gql`
  mutation createDiscount($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
      id
      createdBy
      name
      description
      code
      discount
      type
      currency
      startDate
      endDate
      deleted
      createdAt
      updatedAt
      createdByUser {
        id
        name
        email
      }
    }
  }
`;
