import { useLottie } from "lottie-react";
import Loading from "../../../assets/lotties/loading.json";

const BaseLoader = () => {
  const options = {
    animationData: Loading,
    loop: true,
  };
  const { View } = useLottie(options);

  return <div className={"baseLoader"}>{View}</div>;
};

export default BaseLoader;
