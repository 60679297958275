import { TableProps } from "../helpers/TableHelper";
import { Table } from "antd";
import "./HistoryTable.scss";
import dayjs from "dayjs";

const HistoryTable = ({ data }: TableProps) => {
  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (userId: string) => {
        return <p className={"base-responsive-table-name"}>{userId}</p>;
      },
    },
    {
      title: "User name",
      dataIndex: "user",
      key: "userName",
      render: (user: {
        name: string;
      }) => {
        return <p className={"base-responsive-table-name"}>{user.name}</p>;
      },
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      render: (resource: string) => {
        return <p className={"base-responsive-table-name"}>{resource}</p>;
      },
    },
    {
      title: "Action type",
      dataIndex: "actionType",
      key: "actionType",
      render: (actionType: string) => {
        return <p className={"base-responsive-table-name"}>{actionType}</p>;
      },
    },
    // dayjs().format("YYYY-MM-DD")
    {
      title: "Action",
      key: "action",
      render: (actionData: {
        action: {
          [key: string]: {
            from: string;
            to: string;
          }
        }
      }) => (
        <ul className="action-container">
          {Object.entries(actionData?.action).map(([key, action]) => {
            return (
              <li key={key}>
                <p>
                  {key}: {action?.from || '\'\''} {'=>'} {action?.to}
                </p>
              </li>
            )
          })}
        </ul>
      )
    },
    {
      title: "Action Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: string) => (
        <p className={"base-responsive-table-name"}>{dayjs(updatedAt).format("DD-MM-YYYY")}</p>
      ),
    },
  ];

  return (
    <Table
      className={"base-responsive-table"}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={"id"}
    />
  );
};

export default HistoryTable;