import { memo } from "react";
import { Table } from "antd";
import { TableActions, TableProps } from "../helpers/TableHelper";
import "./SteamTable.scss";
import { isAdmin } from "../../../../utils/isSuperAdmin";
import userStore from "../../../../store/userStore";

const StreamsTable = ({ data, onEditItem, onDeleteItem }: TableProps) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <p className={"base-responsive-table-name"}>{name}</p>;
      },
    },
    {
      title: "Stream URL",
      dataIndex: "streamUrl",
      key: "streamUrl",
      render: (url: string) => {
        return <p className="base-responsive-table-streamUrl">{url}</p>;
      },
    },

    {
      title: "Actions",
      key: "actions",
      render: (item: any) => {
        return (
          <TableActions
            item={item}
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
            showEditDeleteActions={!isAdmin(userStore.user)}
            showViewAction={false}
          />
        );
      },
    },
  ];

  return (
    <div className="overflow-wrapper">
      <Table columns={columns} dataSource={data} pagination={false} rowKey={"id"} />
    </div>
  );
};

export default memo(StreamsTable);
