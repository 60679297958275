import "./VenueView.scss";
import { ICategories, IManagerItem, IVenue } from "../../types/venue";
import { FC, useEffect } from "react";
import VenueInfo from "./VenueInfo";
import VenueManager from "./VenueManager";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import filesUploadStore from "../../store/filesUploadStore";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import { ReactComponent as Edit } from "../../assets/images/Edit.svg";
import { useNavigate } from "react-router-dom";
import { isAdmin, isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";

interface VenueViewProps {
  venue: IVenue;
  venueManagersListData: IManagerItem[];
  categories: ICategories[];
}

const VenueView: FC<VenueViewProps> = ({ venue, venueManagersListData, categories }) => {
  const navigate = useNavigate();

  const editVenue = () => {
    navigate({
      pathname: `/venues/${venue.id.toString()}`,
      search: "step-1",
    });
  };

  useEffect(() => {
    filesUploadStore.assignMediaFiles(filterMediaTypename(venue.media) || []);
  }, [venue.media]);

  return (
    <div className={"venue-view"}>
      <h2 className={"venue-view--name"}>{venue.name}</h2>
      {!isAdmin(userStore.user) ? (
        <Edit className={"venue-view--edit"} width={24} height={24} onClick={editVenue} />
      ) : null}
      <div className={"venue-view--top-block"}>
        <VenueInfo venue={venue} categories={categories} />
        {isSuperAdmin(userStore.user) ? (
          <VenueManager venueManagersListData={venueManagersListData} />
        ) : null}
      </div>
      <div className={"venue-view--bottom-block"}>
        <h2 className={"venue-view--bottom-block--title"}>Img / Video</h2>
        <ImageVideoUploadCarousel readOnly={true} keyType={"venues"} id={venue?.id!} />
      </div>
    </div>
  );
};

export default VenueView;
