import { makeAutoObservable } from "mobx";
import { strongCopyData } from "../utils/strongCopyData";
import { IContactRequest } from "../types/contactRequests";

export interface IContactsRequestStore {
  contacts: IContactRequest[];
  allContactsCount: number;
  limit: number;
  offset: number;
}

class ContactsRequestStore implements IContactsRequestStore {
  limit = 12;
  offset = 0;
  allContactsCount = 0;
  contacts = [];

  constructor() {
    makeAutoObservable(this);
  }

  addContacts(contacts: []) {
    this.contacts = contacts;
  }

  addAllContactsCount(count: number) {
    this.allContactsCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  updateContactRequest({
    id,
    updatedFields,
  }: {
    id: string;
    updatedFields: Partial<IContactRequest>;
  }) {
    this.contacts = strongCopyData(this.contacts).map((contact: IContactRequest) => {
      const parsedNumber = Number(id);
      const contactId = Number(contact.id);
      if (contactId === parsedNumber) {
        return {
          ...contact,
          ...updatedFields,
        };
      }
      return contact;
    });
  }

  removeContactsRequest(id: string | number) {
    this.contacts = strongCopyData(this.contacts).filter((a: IContactRequest) => a.id !== id);
  }

  resetContactRequests() {
    this.contacts = [];
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new ContactsRequestStore();
