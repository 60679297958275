import { computed, makeAutoObservable, observable } from "mobx";
import { IVenueBreadcrumb } from "../types/venue";
import { IEventBreadcrumb } from "../types/eventTypes";

export interface IBreadcrumbStore {
  venue: IVenueBreadcrumb | null;
  event: IEventBreadcrumb | null;
  blog: IEventBreadcrumb | null;
}

class BreadcrumbStore implements IBreadcrumbStore {
  @observable venue: IVenueBreadcrumb | null = null;
  @observable event: IEventBreadcrumb | null = null;
  @observable blog: IEventBreadcrumb | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addVenue(venue: IVenueBreadcrumb) {
    this.venue = venue;
  }

  addEvent(event: IEventBreadcrumb) {
    this.event = event;
  }

  addBlog(blog: IEventBreadcrumb) {
    this.blog = blog;
  }

  @computed get venueInfo(): IVenueBreadcrumb | null {
    return this.venue;
  }

  @computed get eventInfo(): IEventBreadcrumb | null {
    return this.event;
  }

  @computed get blogInfo(): IEventBreadcrumb | null {
    return this.blog;
  }
}

export default new BreadcrumbStore();
