import "./BaseAlert.scss";
import { FC, useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOutsideClickEvent";

interface BaseAlertProps {
  type: "failed" | "success";
  message: string | undefined;
  second?: number;
  reset?: () => void;
}

const BaseAlert: FC<BaseAlertProps> = ({ type, message, second = 4000, reset }) => {
  const alertRef = useRef<HTMLDivElement>(null);
  const [isShow, setIsShow] = useState<boolean>(true);

  const clickOutsideHandler = () => {
    setIsShow(false);
    if (reset) {
      reset();
    }
  };

  useOnClickOutside(alertRef, clickOutsideHandler);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShow(false);
      if (reset) {
        reset();
      }
    }, second);
    return () => clearTimeout(timer);
  }, [reset, second]);

  return isShow ? (
    <div className={`base-alert base-alert--${type}`} ref={alertRef}>
      {message}
    </div>
  ) : null;
};

export default BaseAlert;
