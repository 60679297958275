import { FC } from "react";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import "./RangePicker.scss";

interface Props {
  title?: string;
  newClassName?: string;
  error?: any;
}

const Picker: FC<Props & RangePickerProps> = ({ title, newClassName, error, ...rest }) => {
  return (
    <div className="date-picker range-picker">
      <label className="date-picker--label required">{title}</label>
      <div className="date-picker--input-block">
        <DatePicker.RangePicker
          {...rest}
          className={`date-picker--input ${newClassName} ${
            error ? "date-picker--input-error" : ""
          }`}
        />
      </div>
      <span className={"base-input--error"}>{error}</span>
    </div>
  );
};

export default Picker;
