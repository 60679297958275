import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login/login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import BaseLayout from "../components/layout/BaseLayout/BaseLayout";
import PermissionLayout from "../components/layout/PermissionLayout/PermissionLayout";
import UserLayout from "../components/layout/UserLayout/UserLayout";
import Notfound from "../pages/Notfound/Notfound";
import Venues from "../pages/Venues/Venues";
import Admin from "../pages/Admin/Admin";
import Events from "../pages/Events/Events";
import Event from "../pages/Event/Event";
import UsersHashtag from "../pages/UsersHashtag/UsersHashtag";
import Venue from "../pages/Venue/Venue";
import userStore from "../store/userStore";
import Staff from "../pages/Staff/Staff";
import ReportedPosts from "../pages/ReportedPosts/ReportedPosts";
import ReportedPost from "../pages/ReportedPost/ReportedPost";
import { isSuperAdmin, isVenueManager } from "../utils/isSuperAdmin";
import News from "../pages/News/News";
import Advertisement from "../pages/Advertisement/Advertisement";
import ManagerNews from "../pages/ManagerNews/ManagerNews";
import Settings from "../pages/Settings/Settings";
import Tickets from "../pages/Tickets/Tickets";
import Ticket from "../pages/Ticket/Ticket";
import Discounts from "../pages/Discounts/Discounts";
import VerifyQrCode from "../pages/VerifyQrCode/VerifyQrCode";
import EventRequests from "../pages/EventRequests/EventRequests";
import PageContentLayout from "../components/layout/PageContentLayout/PageContentLayout";
import EventRequest from "../pages/EventRequest/EventRequest";
import Posts from "../pages/Posts/Posts";
import PostView from "../pageView/PostView/PostView";
import Dashboard from "../pages/Dashboard/Dashboard";
import VenueViewer from "pages/VenueViewer/VenueViewer";
import AdminSettings from "pages/AdminSettings/AdminSettings";
import Blogs from "../pages/Blogs/Blogs";
import Blog from "../pages/Blog/Blog";
import AdminsHistory from "../pages/AdminsHistory/AdminsHistory";
import ContactRequests from "../pages/ContactRequests/ContactRequests";
import AdminRequests from "../pages/AdminRequests/AdminRequests";

const useRoutes = (isAuthenticated: string) => {
  if (isAuthenticated) {
    return (
      <Routes>
        <Route path={"/"} element={<BaseLayout />}>
          {/*  {isSuperAdmin(userStore.user) ? (*/}
          {/*    <Route*/}
          {/*      path={"/"}*/}
          {/*      element={*/}
          {/*        <UserLayout>*/}
          {/*          <Home />*/}
          {/*        </UserLayout>*/}
          {/*      }*/}
          {/*    />*/}
          {/*  ) : null}*/}

          <Route
            path={"/"}
            element={
              <UserLayout>
                <Venues />
              </UserLayout>
            }
          />

          {isSuperAdmin(userStore.user) ? (
            <Route
              path={"/users"}
              element={
                <UserLayout>
                  <UsersHashtag />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path={"/posts"}
              element={
                <UserLayout>
                  <Posts />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path={"/posts/view/:postId"}
              element={
                <UserLayout>
                  <PostView />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path={"/Dashboard"}
              element={
                <UserLayout>
                  <Dashboard />
                </UserLayout>
              }
            />
          ) : null}

          {!isSuperAdmin(userStore.user) ? (
            <Route
              path={"/settings"}
              element={
                <UserLayout>
                  <Settings />
                </UserLayout>
              }
            />
          ) : null}

          {isSuperAdmin(userStore.user) ? (
            <Route
              path="/admin-settings"
              element={
                <UserLayout>
                  <AdminSettings />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path="/admin-requests"
              element={
                <UserLayout>
                  <AdminRequests />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path="/contact-requests"
              element={
                <UserLayout>
                  <ContactRequests />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path="/blogs"
              element={
                <UserLayout>
                  <Blogs />
                </UserLayout>
              }
            />
          ) : null}
          {isSuperAdmin(userStore.user) ? (
            <Route
              path={"/admins-history"}
              element={
                <UserLayout>
                  <AdminsHistory />
                </UserLayout>
              }
            />
          ) : null}
          <Route
            path={"/venues"}
            element={
              <UserLayout>
                <Venues />
              </UserLayout>
            }
          />
          <Route
            path={"/events"}
            element={
              <UserLayout>
                <PageContentLayout view={<Events />} />
              </UserLayout>
            }
          />

          {isSuperAdmin(userStore.user) && (
            <Route
              path={"admins"}
              element={
                <UserLayout>
                  <Admin />
                </UserLayout>
              }
            />
          )}

          {(isVenueManager(userStore.user) || isSuperAdmin(userStore.user)) && (
            <>
              <Route
                path={"event-requests"}
                element={
                  <UserLayout>
                    <EventRequests />
                  </UserLayout>
                }
              />
              <Route
                path={"event-requests/view/:eventRequestId"}
                element={
                  <UserLayout>
                    <EventRequest />
                  </UserLayout>
                }
              />
            </>
          )}

          <Route
            path={"reports"}
            element={
              <UserLayout>
                <ReportedPosts />
              </UserLayout>
            }
          />
          <Route
            path={"reported-post"}
            element={
              <UserLayout>
                <ReportedPost />
              </UserLayout>
            }
          />

          <Route
            path={"ads"}
            element={
              <UserLayout>{isSuperAdmin(userStore.user) ? <News /> : <ManagerNews />}</UserLayout>
            }
          />

          {[
            "/advertisement",
            "/advertisement/:advertisementId",
            "/advertisement/view/:advertisementName/:advertisementId",
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <UserLayout>
                  <Advertisement />
                </UserLayout>
              }
            />
          ))}

          <Route path={"/"} element={<PermissionLayout />}>
            {["/venues/create", "/venues/:venueId", "/venues/view/:venueId"].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <UserLayout>
                    <Venue />
                  </UserLayout>
                }
              />
            ))}

            <Route
              path={"/venues/:venueId/staff"}
              element={
                <UserLayout>
                  <Staff />
                </UserLayout>
              }
            />

            {["/venues/:venueId/events"].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <UserLayout>
                    <Events />
                  </UserLayout>
                }
              />
            ))}

            {[
              "/venues/:venueId/events/create",
              "/venues/:venueId/events/:eventId",
              "/venues/:venueId/events/view/:eventId",
              "/events/create",
              "/events/:eventId",
              "/events/view/:eventId",
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <UserLayout>
                    <Event />
                  </UserLayout>
                }
              />
            ))}

            {["/venues/:venueId/events/:eventId/tickets", "/events/:eventId/tickets"].map(
              (path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <UserLayout>
                      <Tickets />
                    </UserLayout>
                  }
                />
              )
            )}

            <Route
              path={"/venues/:venueId/discounts"}
              element={
                <UserLayout>
                  <Discounts />
                </UserLayout>
              }
            />
            <Route
              path={"/venues/:venueId/viewer"}
              element={
                <UserLayout>
                  <VenueViewer />
                </UserLayout>
              }
            />

            {[
              "/venues/:venueId/events/:eventId/tickets/create",
              "/venues/:venueId/events/:eventId/tickets/:ticketId",
              "/venues/:venueId/events/:eventId/tickets/view/:ticketId",
              "/events/:eventId/tickets/create",
              "/events/:eventId/tickets/:ticketId",
              "/events/:eventId/tickets/view/:ticketId",
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <UserLayout>
                    <Ticket />
                  </UserLayout>
                }
              />
            ))}
            {["/blogs/create", "/blogs/:blogId", "/blogs/view/:blogId"].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <UserLayout>
                    <Blog />
                  </UserLayout>
                }
              />
            ))}
          </Route>
          <Route path={"/verify-qr-code/:qrcode_id"} element={<VerifyQrCode />} />
          <Route path="*" element={<Notfound />} />
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      {["/", "/login"].map((path) => (
        <Route key={path} path={path} element={<Login />} />
      ))}
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
};

export default useRoutes;
