import React from "react";
import VenueSvg from "../../assets/images/VenueSvg";
import EventsSvg from "../../assets/images/EventsSvg";
import NewsSvg from "../../assets/images/NewsSvg";
import UsersSvg from "../../assets/images/Users";
import ReportSvg from "../../assets/images/ReportSvg";

export const SUPER_ADMIN_MENU = [
  { icon: <VenueSvg />, name: "venues", to: "/venues" },
  {
    icon: <EventsSvg />,
    name: "events",
    menu: [
      {
        name: "event admins",
        to: "/admins",
      },
      {
        name: "event requests",
        to: "/event-requests",
      },
    ],
  },
  {
    icon: <UsersSvg />,
    name: "Posts",
    menu: [
      { icon: <UsersSvg />, name: "posts", to: "/posts" },
      { icon: <NewsSvg />, name: "ads", to: "/ads" },
      { icon: <ReportSvg />, name: "reports", to: "/reports", hasBadge: true },
    ],
  },
  {
    icon: <UsersSvg />,
    name: "users",
    to: "/users",
  },
  {
    icon: <EventsSvg />,
    name: "requests",
    menu: [
      {
        icon: <EventsSvg />,
        name: "Contact requests",
        to: "/contact-requests",
      },
      {
        icon: <EventsSvg />,
        name: "Admin requests",
        to: "/admin-requests",
      },
    ],
  },
  { icon: <EventsSvg />, name: "History", to: "/admins-history" },
  { icon: <EventsSvg />, name: "blog", to: "/blogs" },
  { icon: <EventsSvg />, name: "settings", to: "/admin-settings" },

  //To Do: Open for event request menu
  // { icon: <></>, name: "event requests", to: "/requests", hasBadge: true },
];

export const EVENT_ADMIN_MENU = [
  { icon: <VenueSvg />, name: "venues", to: "/venues" },
  { icon: <EventsSvg />, name: "events", to: "/events" },
];

export const VENUE_MANAGER_MENU = [
  { icon: <VenueSvg />, name: "venues", to: "/" },
  { icon: <EventsSvg />, name: "event requests", to: "/event-requests" },
];
