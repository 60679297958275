import { makeAutoObservable } from "mobx";
import { strongCopyData } from "../utils/strongCopyData";
import { IBLog } from "../types/types";

export interface IBlogStore {
  blogs: IBLog[];
  blog: IBLog | null;
  allBlogsCount: number;
  limit: number;
  offset: number;
}

class BlogStore implements IBlogStore {
  limit = 12;
  offset = 0;
  allBlogsCount = 0;
  blogs = [];
  blog: IBLog | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addBlogs(admins: []) {
    this.blogs = admins;
  }

  selectCurrentBlog(blog: IBLog) {
    this.blog = blog;
  }

  addAllBlogsCount(count: number) {
    this.allBlogsCount = count;
  }

  addOffset(offset: number) {
    this.offset = offset;
  }

  resetBlogs() {
    this.blogs = [];
  }

  removeBlog(id: string | number) {
    this.blogs = strongCopyData(this.blogs).filter((a: IBLog) => a.id !== id);
  }

  resetPagination() {
    this.limit = 12;
    this.offset = 0;
  }
}

export default new BlogStore();
