import { useState } from "react";
import "./Auth.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BaseInput from "../../components/reusable/baseInput/BaseInput";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import { ReactComponent as Loader } from "../../assets/images/Loader.svg";
import {
  EMAIL_INVALID_TEXT,
  EMAIL_REQUIRED_TEXT,
  PASSWORD_MAXIMUM_TEXT,
  PASSWORD_MINIMUM_TEXT,
  PASSWORD_REQUIRED_TEXT,
  WRONG_EMAIL_PASSWORD,
} from "../../constants/errors";
import { ADMIN_LOGIN } from "../../graphql/mutations/loginPage";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../hooks/useAuth";
import { useValidateRoutes } from "../../hooks/useValidateRoutes";

type ILoginForm = {
  email?: string;
  password?: string;
};

const LoginForm = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();
  const matches = useValidateRoutes(search?.substring(1));
  const [adminLogin] = useMutation(ADMIN_LOGIN);

  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    email: yup.string().required(EMAIL_REQUIRED_TEXT).email(EMAIL_INVALID_TEXT),
    password: yup
      .string()
      .required(PASSWORD_REQUIRED_TEXT)
      .min(6, PASSWORD_MINIMUM_TEXT)
      .max(30, PASSWORD_MAXIMUM_TEXT),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit: SubmitHandler<ILoginForm> = async (data) => {
    setIsLoading(true);
    try {
      const user = await adminLogin({
        variables: {
          userCredentials: {
            email: data.email,
            password: data.password,
          },
        },
      });

      if (user?.data?.loginAdmins?.token) {
        login(user?.data?.loginAdmins?.token);
        if (matches?.length) {
          navigate(`${matches[0].pathname}`);
        } else {
          if (user?.data?.loginAdmins?.user?.role === "superAdmin") {
            navigate("/dashboard");
          } else {
            navigate("/");
          }
        }
      }
    } catch (e) {
      setError(WRONG_EMAIL_PASSWORD);
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"auth-form"}>
      <div className={"auth-form--logo"}>
        <Logo />
        <p className={"auth-form--title"}>LOGIN</p>
      </div>

      <div className={"auth-form--error"}>
        <p className={"error-validation"}>{error}</p>
      </div>

      <BaseInput
        label={{
          text: "Email",
          htmlFor: "email",
        }}
        registerValidation={{
          type: "text",
          name: "email",
          placeholder: "Email...",
          validation: {
            ...register("email"),
          },
        }}
        error={errors.email?.message}
      />
      <BaseInput
        label={{
          text: "Password",
          htmlFor: "new-password",
        }}
        registerValidation={{
          type: "password",
          name: "new-password",
          placeholder: "Password...",
          validation: {
            ...register("password"),
          },
        }}
        error={errors.password?.message}
      />

      <BaseButton
        type={"submit"}
        title={"LOGIN"}
        loader={isLoading && <Loader width={30} height={30} />}
        className={"auth-form--login"}
      />

      <div className={"auth-form--forgot-password"}>
        <Link to={"/forgot-password"}>Forgot password?</Link>
      </div>
    </form>
  );
};

export default LoginForm;
