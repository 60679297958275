import { useLocation } from "react-router-dom";
import React, { useLayoutEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import BaseError from "../../components/reusable/baseError/BaseError";
import ReportedPostForm from "../../forms/ReportedPostForm/ReportedPostForm";
import { GET_REPORT } from "../../graphql/query/getReportedPost";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";

const ReportedPost = () => {
  const { search } = useLocation();

  const [getReportedFeed, { loading, data, error }] = useLazyQuery(GET_REPORT, {
    fetchPolicy: "no-cache",
  });

  useLayoutEffect(() => {
    const getReport = async () => {
      if (search) {
        await getReportedFeed({
          variables: {
            id: +search.substring(1),
          },
        });
      }
    };
    getReport();
  }, [getReportedFeed, search]);

  if (loading) {
    return <BaseLoader />;
  }

  if (error) {
    return <BaseError>{error?.message}</BaseError>;
  }

  return (
    <>
      <ReportedPostForm reportedPost={data?.getReportedFeed} />
    </>
  );
};

export default ReportedPost;
