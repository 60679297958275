import React, { useCallback, useEffect, useLayoutEffect } from "react";
import "./ReportedPosts.scss";
import { useLazyQuery } from "@apollo/client";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import { GET_REPORTS } from "../../graphql/query/getReports";
import BaseError from "../../components/reusable/baseError/BaseError";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import reportedPostsStore from "../../store/reportedPostsStore";
import { observer } from "mobx-react-lite";
import BaseTable from "../../components/reusable/baseTable/BaseTable";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import NoData from "../../components/reusable/noData/NoData";
import ReportTable from "../../components/reusable/baseResponsiveItemsPanel/reportTable/ReportTable";

type argsOption = {
  limit: number;
  offset: number;
  name?: string;
};

const ReportedPosts = observer(() => {
  const [getReports, { loading, error: getReportsError, data: reportsList }] = useLazyQuery(
    GET_REPORTS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const getReportedPosts = useCallback(
    (debounced?: string) => {
      const args: argsOption = {
        limit: reportedPostsStore.limit,
        offset: reportedPostsStore.offset,
      };
      if (debounced) {
        args.name = debounced;
      }
      getReports({
        variables: {
          getReportedFeedsArgs: args,
        },
      });
    },
    [getReports]
  );

  const fetchReportedPosts = useCallback(async () => {
    await reportedPostsStore.emptyReportedPostsStore();
    getReportedPosts();
  }, [getReportedPosts]);

  const changePage = async (page: number) => {
    await reportedPostsStore.emptyReportedPostsStore();
    reportedPostsStore.addOffset((page - 1) * reportedPostsStore.limit);
    getReportedPosts();
  };

  const getReportsList = useCallback(() => {
    getReports({
      variables: {
        getReportedFeedsArgs: {
          limit: reportedPostsStore.limit,
          offset: reportedPostsStore.offset,
        },
      },
    });
  }, [getReports]);

  useLayoutEffect(() => {
    reportedPostsStore.resetReportedPosts();
    fetchReportedPosts();
  }, [fetchReportedPosts]);

  useEffect(() => {
    getReportsList();
  }, [getReportsList]);

  useEffect(() => {
    if (reportsList?.getReportedFeeds?.reports) {
      reportedPostsStore.addReportedPosts(reportsList.getReportedFeeds.reports);
      reportedPostsStore.setReportedPostsCount(reportsList.getReportedFeeds.count);
    }
  }, [reportsList]);

  useEffect(() => {
    return () => {
      reportedPostsStore.emptyReportedPostsStore();
    };
  }, []);

  if (loading) {
    return <BaseLoader />;
  }

  if (getReportsError) {
    return <BaseError>{getReportsError.message}</BaseError>;
  }

  return (
    <div className={"reported-posts-page"}>
      {loading || !reportsList ? <BaseCreateOrDeleteModalLoading /> : null}
      {reportsList?.getReportedFeeds.reports.length ? (
        <>
          {<BaseTable tableData={reportedPostsStore.reportedPostsList} type={"reportedPost"} />}
          <BasePagination
            totalPages={Math.ceil(
              reportedPostsStore.reportedPostsTotalCount / reportedPostsStore.limit
            )}
            currentPage={reportedPostsStore.offset / reportedPostsStore.limit + 1}
            changePage={changePage}
          />
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
});

export default ReportedPosts;
