import { IVenueBreadcrumb } from "../../types/venue";
import { IEventBreadcrumb } from "../../types/eventTypes";
import { ITicket } from "../../types/ticket";
import { capitalizeFirstLetter } from "../../utils/common";

export interface INavItem {
  label: string;
  path: string;
}

export const prepareInitialNavItems = (pageName: string): INavItem[] => {
  return [{ label: capitalizeFirstLetter(pageName), path: `/${pageName}` }];
};

export const prepareVenueItems = (venue: IVenueBreadcrumb): INavItem[] => {
  return [
    { label: "Venues", path: `/venues` },
    { label: `${venue.name}`, path: `/venues/${venue.id}` },
  ];
};

export const prepareBlogItems = (blog: IEventBreadcrumb): INavItem[] => {
  return [
    { label: "Blogs", path: `/blogs` },
    { label: `${blog.name}`, path: `/blogs/${blog.id}` },
  ];
}

export const prepareEventItems = (event: IEventBreadcrumb): INavItem[] => {
  return [
    { label: "Venues", path: `/venues` },
    { label: `${event.venue!.name}`, path: `/venues/${event.venue!.id}` },
    { label: "Events", path: `/venues/${event.venue!.id}/events` },
    { label: `${event.name}`, path: `/venues/${event.venue!.id}/events/${event.id}` },
  ];
};

export const prepareTicketItems = (
  ticket: ITicket,
  eventId: string | undefined,
  venueId: string | undefined
): INavItem[] => {
  return [
    { label: "Tickets", path: `/venues/${venueId}/events/${eventId}/tickets` },
    { label: `${ticket.name}`, path: `` },
  ];
};
