import React from "react";
import { atcb_action } from "add-to-calendar-button";
import dayjs from "dayjs";
import { Button } from "antd";

const AddToCalendarBtn = ({ item }: { item: any }) => {
  const config: Object = {
    name: item.name,
    description: item.description,
    startDate: dayjs(
      `${dayjs(item.startDate).format("MM-DD-YYYY")}, ${dayjs(item.startTime).format("HH:mm:ss")}`
    ).format("YYYY-MM-DD"),
    startTime: dayjs(
      `${dayjs(item.startDate).format("MM-DD-YYYY")}, ${dayjs(item.startTime).format("HH:mm:ss")}`
    ).format("HH:mm"),
    endDate: dayjs(
      `${dayjs(item.startDate).format("MM-DD-YYYY")}, ${dayjs(item.startTime).format("HH:mm:ss")}`
    ).format("YYYY-MM-DD"),
    endTime: dayjs(
      `${dayjs(item.startDate).format("MM-DD-YYYY")}, ${dayjs(item.endTime).format("HH:mm:ss")}`
    ).format("HH:mm"),
    location: item.address,
    timeZone: "currentBrowser",
    options: ["Google"],
  };
  return (
    <Button
      type={"default"}
      onClick={() => {
        atcb_action(config);
      }}
    >
      Add to calendar
    </Button>
  );
};

export default AddToCalendarBtn;
