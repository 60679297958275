import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import NoData from "../../components/reusable/noData/NoData";
import HistoryTable from "../../components/reusable/baseResponsiveItemsPanel/historyTable/HistoryTable";
import adminsHistoryStore from "../../store/adminsHistoryStore";

interface AdminsHistoryViewProps {
  loading: boolean;
  data: any;
  changePage: (page: number) => void;
}

const AdminsHistoryView: FC<AdminsHistoryViewProps> = observer(({
  loading,
  data,
  changePage
}) => {
  return (
    <div className={"admins-history-page--container"}>
      {loading || !data ? (
        <BaseLoader />
      ) : (
        <>
          {data?.listLogs?.logs.length ? (
            <>
              <HistoryTable data={adminsHistoryStore.adminsHistory} />
              <BasePagination
                totalPages={Math.ceil(adminsHistoryStore.allAdminsHistoryCount / adminsHistoryStore.limit)}
                currentPage={adminsHistoryStore.offset / adminsHistoryStore.limit + 1}
                changePage={changePage}
              />
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
});

export default AdminsHistoryView;