import "./BaseCreateOrDeleteModalLoading.scss";
import BaseLoader from "../baseLoader/BaseLoader";

const BaseCreateOrDeleteModalLoading = () => {
  return (
    <div className={"base-create-delete-modal-loading"}>
      <BaseLoader />
    </div>
  );
};

export default BaseCreateOrDeleteModalLoading;
