import "./Loader.scss";
import { ReactComponent as LoaderSVG } from "../../../assets/images/Loader.svg";

const Loader = () => {
  return (
    <div className={"baseLoader"}>
      <LoaderSVG />
    </div>
  );
};

export default Loader;
