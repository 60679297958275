import React, { FC, useEffect } from "react";
import "./AdvertisementView.scss";
import { IVenue } from "../../types/venue";
import { INews } from "../../types/news";
import { ReactComponent as Edit } from "../../assets/images/Edit.svg";
import { useNavigate } from "react-router-dom";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import AdvertisementInfo from "./AdvertisementInfo";
import HashtagsList from "../../components/reusable/hashtagsList/HashtagsList";
import { strongCopyData } from "../../utils/strongCopyData";
import filesUploadStore from "../../store/filesUploadStore";
import { filterMediaTypename } from "../../utils/filterMediaTypename";

interface IAdvertisementViewProps {
  news: INews;
  venueList: IVenue[];
}

const AdvertisementView: FC<IAdvertisementViewProps> = ({ news, venueList }) => {
  const navigate = useNavigate();

  const editAd = () => {
    navigate({
      pathname: `/advertisement/${news?.id.toString()}`,
      search: "step-1",
    });
  };

  useEffect(() => {
    filesUploadStore.assignMediaFiles(filterMediaTypename(news?.media) || []);
  }, [news.media]);

  return (
    <div className={"advertisement-view"}>
      <h2 className={"advertisement-view--name"}>{news?.ad.title}</h2>
      <Edit className={"advertisement-view--edit"} width={24} height={24} onClick={editAd} />
      <div className={"advertisement-view--top-block"}>
        <AdvertisementInfo news={news} venueList={venueList} />
        <HashtagsList
          isRemovable={false}
          isSelectable={false}
          setSelectedHashTags={() => {}}
          selectedHashTags={
            strongCopyData(news?.hashtags)?.map(
              (hashtag: { hashtagId: number }) => hashtag.hashtagId
            ) || []
          }
        />
      </div>
      <div className={"advertisement-view--bottom-block"}>
        <h2 className={"advertisement-view--bottom-block--title"}>Img / Video</h2>
        <ImageVideoUploadCarousel readOnly={true} keyType={"venues"} id={news?.id!} />
      </div>
    </div>
  );
};

export default AdvertisementView;
