import { FC } from "react";
import { Button, Space } from "antd";
import "../BaseResponsiveItemsPanel.scss";
import { IAdminRequest } from "../../../../types/adminRequests";
import { AdminStatus } from "../../../../pages/AdminRequests/AdminRequests";

interface TableProps {
  item: IAdminRequest;
  onUpdateStatus: ({
    status,
    id,
  }: {
    status: AdminStatus;
    id: string;
    item: IAdminRequest;
  }) => void;
}
const TableActions: FC<TableProps> = ({ item, onUpdateStatus }) => {
  return (
    <Space wrap onClick={(e) => e.stopPropagation()}>
      {item.status !== AdminStatus.REJECTED && (
        <Button
          type="primary"
          onClick={
            item.status !== AdminStatus.ACCEPTED
              ? () =>
                  onUpdateStatus({
                    status: AdminStatus.ACCEPTED,
                    id: `${item.id}`,
                    item,
                  })
              : undefined
          }
          className={item.status === AdminStatus.ACCEPTED ? "btn-success" : ""}
        >
          {item.status === AdminStatus.ACCEPTED ? "Accepted" : "Accept"}
        </Button>
      )}
      {item.status !== AdminStatus.ACCEPTED && (
        <Button
          type="primary"
          onClick={
            item.status !== AdminStatus.REJECTED
              ? () =>
                  onUpdateStatus({
                    status: AdminStatus.REJECTED,
                    id: `${item.id}`,
                    item,
                  })
              : undefined
          }
          className={item.status === AdminStatus.REJECTED ? "btn-danger" : ""}
        >
          {item.status === AdminStatus.REJECTED ? "Rejected" : "Reject"}
        </Button>
      )}
    </Space>
  );
};

export default TableActions;
