import React from "react";
import SettingsForm from "../../forms/SettingsForm/SettingsForm";

const Settings = () => {
  return (
    <>
      <SettingsForm />
    </>
  );
};

export default Settings;
