import { gql } from "@apollo/client";

export const GET_CONTACT_REQUESTS = gql`
  query listAllContacts($listAllInput: ListAllContactsForSupportInput!) {
    listAllContacts(listAllInput: $listAllInput) {
      count
      contacts {
        id
        name
        phoneNumber
        email
        message
        note
        status
      }
    }
  }
`;