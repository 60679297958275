import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { message } from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import "./AdminRequests.scss";
import AdminRequestsView from "./AdminRequestsView";
import adminRequetsStore from "../../store/adminRequetsStore";
import { GET_ADMIN_REQUESTS } from "../../graphql/query/getAdminRequests";
import { UPDATE_ADMIN_REQUEST } from "../../graphql/mutations/updateAdminRequests";
import blogStore from "../../store/blogStore";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { IAdminRequest } from "../../types/adminRequests";

type argsOption = {
  limit: number;
  offset: number;
};

export enum AdminStatus {
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  PENDING = "pending",
}

const AdminRequests = () => {
  const [listAdminRequests, { loading, data }] = useLazyQuery(GET_ADMIN_REQUESTS);
  const [updateAdminRequest] = useMutation(UPDATE_ADMIN_REQUEST, {
    refetchQueries: [
      {
        query: GET_ADMIN_REQUESTS,
        variables: {
          adminReqListInput: {
            limit: blogStore.limit,
            offset: blogStore.offset,
          },
        },
      },
    ],
  });
  const [isOpenRejectModal, setIsOpenRejectModal] = React.useState(false);
  const [selectItem, setSelectItem] = useState<IAdminRequest | null>(null);

  const getAdminRequestsList = useCallback(
    (count?: number | null) => {
      const newOffset =
        count === 1 ? adminRequetsStore.offset - adminRequetsStore.limit : adminRequetsStore.offset;

      const args: argsOption = {
        limit: adminRequetsStore.limit,
        offset: newOffset,
      };

      listAdminRequests({
        variables: {
          adminReqListInput: args,
        },
      });
    },
    [listAdminRequests]
  );

  const fetchAdminRequests = useCallback(async () => {
    await adminRequetsStore.resetPagination();
    getAdminRequestsList();
  }, [getAdminRequestsList]);

  const changePage = (page: number) => {
    adminRequetsStore.addOffset((page - 1) * adminRequetsStore.limit);
    getAdminRequestsList();
  };

  useEffect(() => {
    if (data?.getAdminRequestsLists?.adminRequests) {
      adminRequetsStore.addAdmins(data.getAdminRequestsLists.adminRequests);
      adminRequetsStore.addAllAdminsCount(data.getAdminRequestsLists.count);
    }
  }, [data?.getAdminRequestsLists]);

  useLayoutEffect(() => {
    fetchAdminRequests();
  }, [fetchAdminRequests]);

  const onUpdateStatus = async ({
    status,
    id,
    item,
  }: {
    status: AdminStatus;
    id: string;
    item: IAdminRequest;
  }) => {
    if (status === AdminStatus.REJECTED) {
      setSelectItem(item);
      opeOrCloseRejectModal();
      return;
    }

    adminRequetsStore.updateAdminRequest({
      id,
      updatedFields: {
        status,
      },
    });
    await updateAdminRequest({
      variables: {
        updateAdminReqStatusInput: {
          id: Number(id),
          status,
        },
      },
    });
  };

  const opeOrCloseRejectModal = () => {
    setIsOpenRejectModal(!isOpenRejectModal);
  };

  const deleteAdminConfirm = async (reason: string) => {
    try {
      if (!selectItem) return;

      await updateAdminRequest({
        variables: {
          updateAdminReqStatusInput: {
            id: Number(selectItem.id),
            status: AdminStatus.REJECTED,
            reason,
          },
        },
      });
      adminRequetsStore.updateAdminRequest({
        id: selectItem.id,
        updatedFields: {
          status: AdminStatus.REJECTED,
        },
      });
    } catch (error) {
      message.error((error as Error).message, 1);
    } finally {
      setIsOpenRejectModal(false);
    }
  };

  return (
    <div className="admin-requests-page">
      {isOpenRejectModal ? (
        <ConfirmationModal
          isReject
          isOpen={isOpenRejectModal}
          closeModal={opeOrCloseRejectModal}
          rejectHandle={deleteAdminConfirm}
          buttonText={"Delete"}
          title={"Are you sure you want to delete this admin request?"}
        />
      ) : null}
      <PageContentLayout
        view={
          <AdminRequestsView
            data={data}
            loading={loading}
            changePage={changePage}
            onUpdateStatus={onUpdateStatus}
          />
        }
      />
    </div>
  );
};

export default AdminRequests;
