import { gql } from "@apollo/client";

export const GET_VENUES_LIST = gql`
  query listVenues($listVenuesArgs: ListVenueArgs!) {
    listVenues(listVenuesArgs: $listVenuesArgs) {
      count
      venues {
        id
        name
        description
        cover
        googlePlaceId
        avgRate
        featuredImageUrl {
          type
          key
        }
      }
    }
  }
`;
