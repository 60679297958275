import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import "./News.scss";
import BaseSearch from "../../components/reusable/baseSearch/BaseSearch";
import BaseError from "../../components/reusable/baseError/BaseError";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_NEWS_LIST } from "../../graphql/query/getNews";
import { observer } from "mobx-react-lite";
import newsStore from "../../store/newsStore";
import { useNavigate } from "react-router-dom";
import { PanelItem } from "../../types/types";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import BasePagination from "../../components/reusable/basePagination/BasePagination";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { DELETE_NEWS } from "../../graphql/mutations/deleteNews";
import { useDebounce } from "../../hooks/useDebounce";
import BaseLoader from "../../components/reusable/baseLoader/BaseLoader";
import NoData from "../../components/reusable/noData/NoData";
import { Button } from "antd";
import PageContentLayout from "../../components/layout/PageContentLayout/PageContentLayout";
import AdTable from "../../components/reusable/baseResponsiveItemsPanel/adTable/AdTable";

type argsOption = {
  limit: number;
  offset: number;
  title?: string;
};

const News = observer(() => {
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<PanelItem | null>(null);

  const debounced = useDebounce(search);

  const [getAds, { loading, error, data }] = useLazyQuery(GET_NEWS_LIST, {
    fetchPolicy: "no-cache",
  });

  const [
    removeNews,
    { data: dataRemove, loading: loadingRemove, error: errorRemove, reset: resetErrorRemove },
  ] = useMutation(DELETE_NEWS);

  const goToCurrentNews = (news: PanelItem) => {
    navigate({
      pathname: `/advertisement/view/${news.name || "advertisement"}/${news.id.toString()}`,
    });
  };

  const handleEditNews = (item: PanelItem) => {
    navigate({
      pathname: `/advertisement/${item.id.toString()}`,
    });
  };

  const createNews = () => {
    navigate({
      pathname: "/advertisement",
      search: "step-1",
    });
  };

  const openOrCloseDeleteModal = useCallback((news?: PanelItem) => {
    if (news) {
      setSelectItem(news);
    }
    setIsOpenDeleteModal((prev) => !prev);
  }, []);

  const getNewsList = useCallback(
    (count?: number | null, debounced?: string) => {
      const newOffset = count === 1 ? newsStore.offset - newsStore.limit : newsStore.offset;

      const args: argsOption = {
        limit: newsStore.limit,
        offset: newOffset,
      };

      if (debounced) {
        args.title = debounced;
      }
      getAds({
        variables: {
          getAdsArgs: args,
        },
      });
    },
    [getAds]
  );

  const deleteNewsConfirm = async () => {
    try {
      const { data } = await removeNews({
        variables: {
          adId: +selectItem?.id!,
        },
      });
      if (
        newsStore.offset / newsStore.limit + 1 <
          Math.ceil(newsStore.allNewsCount / newsStore.limit) ||
        (newsStore.news.length === 1 && newsStore.offset !== 0)
      ) {
        getNewsList(newsStore.news.length);
      } else {
        newsStore.removeNews(data?.removeAd.id);
      }
      setIsOpenDeleteModal(false);
    } catch (e) {
      setIsOpenDeleteModal(false);
    }
  };

  const fetchNews = useCallback(async () => {
    await newsStore.resetPagination();
    if (debounced.length) {
      getNewsList(null, debounced);
    } else {
      getNewsList();
    }
  }, [debounced, getNewsList]);

  const changePage = (page: number) => {
    newsStore.addOffset((page - 1) * newsStore.limit);
    getNewsList();
  };

  useEffect(() => {
    if (data) {
      newsStore.addNews(data.getAds.ads);
      newsStore.addAllNewsCount(data.getAds.count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLayoutEffect(() => {
    newsStore.resetNews();
    fetchNews();
  }, [debounced, fetchNews]);

  if (error) {
    return <BaseError>{error.message}</BaseError>;
  }

  const adsSearch = (
    <div className={"base-page-header space-between gap-20"}>
      <BaseSearch handleChange={(value) => setSearch(value)} />
      <div className={"d-flex-aligned-end"}>
        <Button type="primary" size="large" onClick={createNews}>
          Add Ad
        </Button>
      </div>
    </div>
  );

  return (
    <div className={"news-page"}>
      <ConfirmationModal
        isOpen={isOpenDeleteModal}
        closeModal={openOrCloseDeleteModal}
        deleteHandle={deleteNewsConfirm}
        buttonText={"Delete"}
        title={"Are you sure you want to delete this content?"}
      />

      {loadingRemove ? <BaseCreateOrDeleteModalLoading /> : null}
      {errorRemove ? <BaseAlert type={"failed"} message={errorRemove?.message} /> : null}
      {dataRemove?.removeAd?.id ? (
        <BaseAlert
          type={"success"}
          message={"Venue successfully deleted"}
          reset={() => resetErrorRemove()}
        />
      ) : null}

      <div className={"news-page--container"}>
        {loading || !data ? (
          <BaseLoader />
        ) : (
          <>
            {data.getAds.ads.length ? (
              <>
                <PageContentLayout
                  className={"d-flex-column"}
                  actions={adsSearch}
                  view={
                    <>
                      <AdTable
                        data={newsStore.news}
                        onSelectItem={goToCurrentNews}
                        onEditItem={handleEditNews}
                        onDeleteItem={openOrCloseDeleteModal}
                      />
                      {/*<ResponsiveItemsPanel*/}
                      {/*  type={"news"}*/}
                      {/*  data={newsStore.news}*/}
                      {/*  selectItem={goToCurrentNews}*/}
                      {/*  deleteItem={openOrCloseDeleteModal}*/}
                      {/*  onEditItem={handleEditNews}*/}
                      {/*/>*/}

                      <BasePagination
                        totalPages={Math.ceil(newsStore.allNewsCount / newsStore.limit)}
                        currentPage={newsStore.offset / newsStore.limit + 1}
                        changePage={changePage}
                      />
                    </>
                  }
                />
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default News;
