import { gql } from "@apollo/client";

export const GET_HASHTAGS_LIST = gql`
  query listHashtags($getHashtagsArgs: GetHashtags!) {
    listHashtags(getHashtagsArgs: $getHashtagsArgs) {
      hashtags {
        id
        name
      }
      count
    }
  }
`;
