import { gql } from "@apollo/client";

export const UPDATE_SETTINGS = gql`
  mutation updatePassword($updatePasswordInput: UpdatePasswordInput!) {
    updatePassword(updatePasswordInput: $updatePasswordInput) {
      user {
        id
      }
    }
  }
`;
