import * as React from "react";

const SearchSvg = (props: any) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m17.823 16.971-4.108-4.102a7.796 7.796 0 1 0-.846.846l4.102 4.108a.6.6 0 0 0 .983-.195.6.6 0 0 0-.131-.657ZM7.802 14.4a6.597 6.597 0 1 1 0-13.194 6.597 6.597 0 0 1 0 13.194Z"
      fill="#E63335"
    />
  </svg>
);

export default SearchSvg;
