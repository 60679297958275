import { FC, useCallback, useEffect, useState } from "react";
import "./VenueForm.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import VenueInfoForm from "./components/VenueInfoForm";
import VenueManagerForm from "./components/VenueManagerForm";
import VenueDaysForm from "./components/VenueDaysForm";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import { EMAIL_INVALID_TEXT } from "../../constants/errors";
import { useMutation } from "@apollo/client";
import { CREATE_VENUE } from "../../graphql/mutations/createVenue";
import { UPDATE_VENUE } from "../../graphql/mutations/updateVenue";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import filesUploadStore from "../../store/filesUploadStore";
import { observer } from "mobx-react-lite";
import { ICategories, IManagerItem, IVenue } from "../../types/venue";
import { isAdmin, isSuperAdmin } from "../../utils/isSuperAdmin";
import userStore from "../../store/userStore";
import Stepper from "../../components/Stepper/Stepper";
import { UPLOAD_MEDIA } from "../../graphql/mutations/uploadMedia";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import breadcrumbStore from "../../store/breadcrumbStore";

interface VenueFormProps {
  venueData?: IVenue;
  venueManagersListData: IManagerItem[];
  categories?: ICategories[];
}

const VenueForm: FC<VenueFormProps> = observer(
  ({ venueData, categories, venueManagersListData }) => {
    const [venue, setVenue] = useState<any>(venueData);
    const { search } = useLocation();
    const { venueId } = useParams();
    const navigate = useNavigate();

    const [steppers] = useState<Array<string>>(
      isSuperAdmin(userStore.user) ? ["Info", "Manager", "Image"] : ["Info", "Image"]
    );
    const [stepCount, setStepCount] = useState<number>(+search.split("-")[1] || 1);
    const [isSaveAndBack, seIsSaveAndBack] = useState<boolean>(false);
    const [venueAddressLocation, setVenueAddressLocation] = useState<string[]>(
      venue?.location?.coordinates || []
    );

    const [createVenue, { data: createData, loading: createLoading, error: createError }] =
      useMutation(CREATE_VENUE);
    const [updateVenue, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_VENUE);
    const [uploadMedia, { loading: uploadMediaLoading, error: uploadMediaError }] =
      useMutation(UPLOAD_MEDIA);

    const validationSchema = yup.object().shape({
      name: yup.string().required("This field is required"),
      address: yup.string().required("Address should be selected from the list"),
      capacity: yup.string().nullable(),
      description: yup.string().nullable(),
      linkToWebsite: yup.string().nullable(),
      linkToFacebook: yup.string().nullable(),
      linkToInstagram: yup.string().nullable(),
      linkToYoutube: yup.string().nullable(),
      internationalPhoneNumber: yup.string(),
      categoryId: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      managers: yup.array().of(
        yup.object().shape({
          name: yup.string(),
          email: yup.string().email(EMAIL_INVALID_TEXT),
        })
      ),
      workingHours: yup
        .array()
        .of(
          yup.object().shape({
            isOpen: yup.boolean(),
            day: yup.string(),
            openingTime: yup.string().when("isOpen", {
              is: true,
              then: yup.string().required("Please add the opening hour!"),
            }),
            closingTime: yup.string().when("isOpen", {
              is: true,
              then: yup.string().required("Please add the closing hour!"),
            }),
          })
        )
        .min(1, "You need at least three friends")
        .required("This field is required"),
    });

    const {
      reset,
      clearErrors,
      setValue,
      getValues,
      register,
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<IVenue>({
      resolver: yupResolver(validationSchema),
    });

    const { update, replace } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "workingHours", // unique name for your Field Array
    });

    const adAddressLocation = useCallback((loc: string[]) => {
      setVenueAddressLocation(loc);
    }, []);

    const decrementStepCount = () => {
      navigate({
        search: `step-${stepCount - 1}`,
      });
    };

    const updateVenueMedia = useCallback(async () => {
      try {
        await uploadMedia({
          variables: {
            uploadMediaFromAdminInput: {
              type: "venue",
              id: +venueId!,
              media: filesUploadStore.mediaFilesToUploadBackend,
            },
          },
        });
        filesUploadStore.restoreFilesUploadStore();

        if (isSuperAdmin(userStore.user)) {
          navigate({
            pathname: "/venues",
          });
        } else {
          navigate({
            pathname: `/venue`,
          });
        }
      } catch (e) {
        filesUploadStore.restoreFilesUploadStore();
      }
    }, [navigate, uploadMedia, venueId]);

    const onCreateVenue: SubmitHandler<IVenue> = async (venue: IVenue) => {
      if (
        steppers[stepCount - 1] === "Manager" ||
        (isSaveAndBack && steppers[stepCount - 1] !== "Image")
      ) {
        try {
          const venueData = {
            name: venue.name,
            address: venue.address,
            capacity: venue.capacity,
            description: venue.description,
            linkToWebsite: venue.linkToWebsite,
            linkToFaceBook: venue.linkToFaceBook,
            linkToInstagram: venue.linkToInstagram,
            linkToYoutube: venue.linkToYoutube,
            location: {
              type: "Point",
              coordinates: venueAddressLocation,
            },
            internationalPhoneNumber: venue.internationalPhoneNumber,
            categoryId: venue.categoryId,
            managers: venue.managers?.filter((manager) => manager.email.trim() !== ""),
            workingHours: venue.workingHours,
          };

          const { data } = await createVenue({
            variables: {
              createVenueInput: venueData,
            },
          });

          if (!isSaveAndBack) {
            navigate({
              pathname: `/venues/${data.createVenue.id}`,
              search: `step-${stepCount + 1}`,
            });
          } else {
            navigate({
              pathname: "/venues",
            });
          }
          return;
        } catch (e) {
          console.error(e);
          return;
        }
      }
      navigate({
        search: `step-${stepCount + 1}`,
      });
    };

    const onUpdateVenue: SubmitHandler<IVenue> = async (updateVenueItem: IVenue) => {
      if (
        steppers[stepCount - 1] === "Info" ||
        (isSaveAndBack && steppers[stepCount - 1] !== "Image")
      ) {
        const updateData = {
          id: venue?.id,
          name: updateVenueItem.name,
          address: updateVenueItem.address,
          capacity: updateVenueItem.capacity,
          description: updateVenueItem.description,
          linkToWebsite: updateVenueItem.linkToWebsite,
          linkToFaceBook: updateVenueItem.linkToFaceBook,
          linkToInstagram: updateVenueItem.linkToInstagram,
          linkToYoutube: updateVenueItem.linkToYoutube,
          internationalPhoneNumber: updateVenueItem.internationalPhoneNumber,
          categoryId: updateVenueItem.categoryId,
          location: {
            type: "Point",
            coordinates: venueAddressLocation,
          },
          workingHours: updateVenueItem.workingHours.map((w: any) => {
            const { __typename, ...rest } = w;
            return rest;
          }),
        };

        await updateVenue({
          variables: {
            updateVenueInput: updateData,
          },
        }).then((res) => {
          setVenue(updateData);
          if (res?.data) {
            breadcrumbStore.addVenue({
              id: venueId!,
              name: updateVenueItem.name,
            });
          }
        });
      }
      if (steppers[stepCount - 1] === "Image") {
        if (filesUploadStore.newMediaFiles.length) {
          filesUploadStore.startFileUploadToAWS();
        } else {
          updateVenueMedia();
        }
      } else {
        if (!isSaveAndBack) {
          navigate({
            search: `step-${stepCount + 1}`,
          });
        } else {
          if (isSuperAdmin(userStore.user)) {
            navigate({
              pathname: "/venues",
            });
          } else {
            navigate({
              pathname: `/venue`,
            });
          }
        }
      }
    };

    useEffect(() => {
      search && setStepCount(+search.split("-")[1]);
    }, [search]);

    useEffect(() => {
      if (venueData) {
        setVenue(venue);
      }
    }, [venueData]);
    useEffect(() => {
      if (venue) {
        reset({
          ...venue,
        });
        if (venue.media) {
          filesUploadStore.assignMediaFiles(filterMediaTypename(venue.media) || []);
        }
      }
    }, [reset, venue, setValue, venueManagersListData]);

    useEffect(() => {
      if (venueManagersListData.length) {
        setValue("managers", venueManagersListData);
      }
    }, [setValue, venueManagersListData]);

    useEffect(() => {
      if (isAdmin(userStore.user)) {
        navigate("/not-found");
      }
    }, [userStore.user]);

    return (
      <form onSubmit={handleSubmit(venue ? onUpdateVenue : onCreateVenue)} className={"venue-form"}>
        <Stepper
          steppers={steppers}
          stepCount={stepCount}
          decrementStepCount={decrementStepCount}
          saveAndBack={() => seIsSaveAndBack(true)}
          isUpdateMode={!!venueId}
        >
          <div className={"venue-form--content"}>
            <div
              className={`${
                stepCount === 1 ? "venue-form--info-step" : "venue-form--display-none"
              }`}
            >
              <VenueInfoForm
                venue={venue}
                categories={categories}
                register={register}
                setValue={setValue}
                clearErrors={clearErrors}
                adAddressLocation={adAddressLocation}
                isDisabled={false}
                errors={errors}
              />
              <VenueDaysForm
                update={update}
                venue={venue}
                register={register}
                clearErrors={clearErrors}
                getValues={getValues}
                isDisabled={false}
                errors={errors}
                replace={replace}
              />
            </div>
            <div
              className={`${
                stepCount === 2 && isSuperAdmin(userStore.user)
                  ? "venue-form--display-block"
                  : "venue-form--display-none"
              }`}
            >
              <VenueManagerForm
                venueManagersListData={venueManagersListData}
                venueId={venue?.id}
                register={register}
                setValue={setValue}
                getValues={getValues}
                isDisabled={false}
                errors={errors}
              />
            </div>
            <div
              className={`${
                (stepCount === 3 && isSuperAdmin(userStore.user)) ||
                (stepCount === 2 && !isSuperAdmin(userStore.user))
                  ? "venue-form--display-block"
                  : "venue-form--display-none"
              }`}
            >
              <ImageVideoUploadCarousel
                id={createData?.createVenue.id || venue?.id}
                readOnly={false}
                updateMedia={updateVenueMedia}
                keyType={"venues"}
              />
            </div>
          </div>
        </Stepper>

        {createLoading || updateLoading || uploadMediaLoading ? (
          <BaseCreateOrDeleteModalLoading />
        ) : null}

        {createError || updateError || uploadMediaError ? (
          <BaseAlert type={"failed"} message={updateError?.message || createError?.message} />
        ) : null}
      </form>
    );
  }
);

export default VenueForm;
