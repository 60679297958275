import React from "react";
import Sales from "../../components/Dashboard/Sales/Sales";
import UserQuantityAnalytics from "../../components/Dashboard/UserQuantity/UserQuantityAnalytics";
import "./Dashboard.scss";
import NewlyAddedPosts from "../../components/Dashboard/NewlyAddedPosts/NewlyAddedPosts";
import PostsStatistics from "../../components/Dashboard/PostsStatistics/PostsStatistics";
import ReportedPostsAnalytics from "../../components/Dashboard/ReportedPosts/ReportedPosts";
import DashboardEventsCalendar from "../../components/Dashboard/EventsCalendar/DashboardEventsCalendar";
import ActiveVenuesEventsQuantity from "../../components/Dashboard/ActiveVenuesEventsQuantity/ActiveVenuesEventsQuantity";

const Dashboard = () => {
  return (
    <div className={"dashboard-page"}>
      <Sales />
      <div className={"dashboard-page-content"}>
        <DashboardEventsCalendar />
        <UserQuantityAnalytics />
        <PostsStatistics />
        <ReportedPostsAnalytics />
        <ActiveVenuesEventsQuantity />
        <NewlyAddedPosts />
      </div>
    </div>
  );
};

export default Dashboard;
