import { makeAutoObservable } from "mobx";
import { IReportedPost } from "../types/reportedPostTypes";

export interface IReportedPostsStore {
  totalReportCount: number;
  reportedPostsList: IReportedPost[];
  limit: number;
  offset: number;
  reportedPostsTotalCount: number;
}

class ReportedPostsStore implements IReportedPostsStore {
  totalReportCount = 0;
  limit = 15;
  offset = 0;
  reportedPostsTotalCount = 0;
  reportedPostsList: IReportedPost[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setReportedPostsCount(reportedPostsCount: number) {
    this.reportedPostsTotalCount = reportedPostsCount;
  }

  setTotalReportCount(count: number) {
    this.totalReportCount = count;
  }

  addReportedPosts(reportedPostsListData: []) {
    this.reportedPostsList = reportedPostsListData;
  }

  addOffset(offset: number) {
    this.reportedPostsList = [];
    this.offset = offset;
  }

  emptyReportedPostsStore() {
    this.offset = 0;
    this.reportedPostsList = [];
  }

  resetReportedPosts() {
    this.reportedPostsList = [];
  }
}

export default new ReportedPostsStore();
