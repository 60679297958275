import React from "react";
import "../src/assets/styles/base.scss";
import { BrowserRouter } from "react-router-dom";
import useRoutes from "./routes/useRoutes";
import { observer } from "mobx-react-lite";
import { isUserAuthenticated } from "./utils/isUserAuthenticated";
import { ConfigProvider } from "antd";

const App = observer(() => {
  const routes = useRoutes(isUserAuthenticated());
  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          // algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: "#ED2F31",
            colorPrimaryBorder: "#ED2F31",
          },
        }}
      >
        <div className={"app"}>{routes}</div>
      </ConfigProvider>
    </BrowserRouter>
  );
});

export default App;
