import "./Notfound.scss";
import BaseButton from "../../components/reusable/baseButton/BaseButton";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useValidateRoutes } from "../../hooks/useValidateRoutes";

const Notfound = () => {
  const matches = useValidateRoutes();
  const navigate = useNavigate();

  useEffect(() => {
    if (matches?.length) {
      navigate({
        pathname: "/login",
        search: matches[0].pathname,
      });
    }
  }, [matches]);

  return (
    <div className={"notfound"}>
      <p className={"notfound--title"}>404 page not found</p>
      <Link to={"/"}>
        <BaseButton title={"GO Home"} />
      </Link>
    </div>
  );
};

export default Notfound;
