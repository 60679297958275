import { gql } from "@apollo/client";

export const GET_TICKET = gql`
  query findTicket($id: Int!) {
    findTicket(id: $id) {
      id
      name
      description
      count
      soldCount
      amount
      saleAmount
      currency
      startTime
      endTime
      image
      published
      earlyBirdAmount
      earlyBirdCount
      media {
        key
        type
        id
        fileName
        contentType
        contentLength
        isCover
      }
      event {
        name
        id
        startDate
        endDate
        cover
        bandName
        price
        venueId
        address
      }
      createdAt
      updatedAt
    }
  }
`;
