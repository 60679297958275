import { gql } from "@apollo/client";

export const CREATE_TICKET = gql`
  mutation createTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      id
      name
    }
  }
`;
