import React, { useEffect, useState } from "react";
import "./BaseLayout.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ME } from "../../../graphql/query/getMe";
import userStore from "../../../store/userStore";
import BaseError from "../../reusable/baseError/BaseError";
import NavBar from "../../NavBar/NavBar";
import { observer } from "mobx-react-lite";
import { isUserAuthenticated } from "../../../utils/isUserAuthenticated";
import { useWindowSize } from "../../../utils/useWindowSize";
import MobileNavBar from "../../NavBar/MobileNavBar";
import BaseButton from "../../reusable/baseButton/BaseButton";
import { useAuth } from "../../../hooks/useAuth";
import BaseLoader from "../../reusable/baseLoader/BaseLoader";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";

const BaseLayout = observer(() => {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const size = useWindowSize();

  const [getMe, { error }] = useLazyQuery(GET_ME, {
    fetchPolicy: "no-cache",
  });

  const backToLogin = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const { data } = await getMe();
        userStore.addUser(data?.getMe);
        userStore.addUserName(data?.getMe?.name);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    getUser();
  }, [getMe, pathname]);

  if (error) {
    return (
      <div className={"base-layout"}>
        <BaseError>{error.message}</BaseError>
        <BaseButton
          title={"Back to login"}
          className={"base-layout--back-to-login"}
          onClick={backToLogin}
        />
      </div>
    );
  }

  if ((loading && !isUserAuthenticated()) || !userStore.user) {
    return <BaseLoader />;
  }

  return (
    <div className={"base-layout"}>
      {!!userStore?.user ? (
        size[0] > 768 ? (
          <NavBar userRole={userStore?.user?.role} />
        ) : (
          <MobileNavBar userRole={userStore?.user?.role} />
        )
      ) : null}
      <Breadcrumbs />
      <Outlet />
    </div>
  );
});

export default BaseLayout;
