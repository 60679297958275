import { gql } from "@apollo/client";

export const GET_EVENT_REQUEST = gql`
  query findEventRequest($id: Int!) {
    findEventRequest(id: $id) {
      id
      venueId
      creatorId
      eventId
      status
      createdAt
      updatedAt
      event {
        id
        venueId
        name
        description
        address
        bandName
        cover
        hasTicket
        isSoldOut
        price
        startDate
        endDate
        media {
          type
          key
        }
      }
    }
  }
`;
