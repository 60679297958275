import React, { FC, memo, useCallback, useEffect, useState } from "react";
import "./AdvertisementForm.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { INews, INewsForm } from "../../types/news";
import AdvertisementInfoForm from "./components/AdvertisementInfoForm/AdvertisementInfoForm";
import filesUploadStore from "../../store/filesUploadStore";
import { IVenue } from "../../types/venue";
import ImageVideoUploadCarousel from "../../components/ImageVideoUploadCarousel/ImageVideoUploadCarousel";
import HashtagsList from "../../components/reusable/hashtagsList/HashtagsList";
import { useMutation } from "@apollo/client";
import { CREATE_NEWS } from "../../graphql/mutations/createNews";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BaseAlert from "../../components/reusable/baseAlert/BaseAlert";
import { UPDATE_NEWS } from "../../graphql/mutations/updateNews";
import BaseCreateOrDeleteModalLoading from "../../components/reusable/baseCreateOrDeleteModalLoading/BaseCreateOrDeleteModalLoading";
import Stepper from "../../components/Stepper/Stepper";
import { filterMediaTypename } from "../../utils/filterMediaTypename";
import { UPLOAD_MEDIA } from "../../graphql/mutations/uploadMedia";
import { strongCopyData } from "../../utils/strongCopyData";
import { formatDate } from "../../utils/formatDate";

interface AdvertisementFormProps {
  venueList: IVenue[];
  news?: INews;
}

const AdvertisementForm: FC<AdvertisementFormProps> = observer(({ news, venueList }) => {
  const { search } = useLocation();
  const { advertisementId } = useParams();
  const navigate = useNavigate();

  const [steppers] = useState<Array<string>>(["Info", "Image"]);
  const [stepCount, setStepCount] = useState<number>(+search.split("-")[1] || 1);
  const [isSaveAndBack, seIsSaveAndBack] = useState<boolean>(false);
  const [selectedHashTags, setSelectedHashTags] = useState<string[]>([]);

  const [createAd, { data: createData, loading: createLoading, error: createError }] =
    useMutation(CREATE_NEWS);
  const [updateAd, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_NEWS);
  const [uploadMedia, { loading: uploadMediaLoading, error: uploadMediaError }] =
    useMutation(UPLOAD_MEDIA);

  const validationSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    description: yup.string(),
    venueId: yup.number().nullable(),
    endDate: yup.date().typeError("This field is required").required("This field is required"),
  });

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<INewsForm>({
    resolver: yupResolver(validationSchema),
  });

  const decrementStepCount = () => {
    navigate({
      search: `step-${stepCount - 1}`,
    });
  };

  const onCreateAd: SubmitHandler<INewsForm> = async (news) => {
    try {
      const { data } = await createAd({
        variables: {
          createAdInput: {
            ...news,
            hashtagIds: selectedHashTags,
          },
        },
      });
      if (!isSaveAndBack) {
        navigate({
          pathname: `/advertisement/${data.createAd.id}`,
          search: `step-${stepCount + 1}`,
        });
      } else {
        navigate({
          pathname: "/ads",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateAdMedia = useCallback(async () => {
    try {
      await uploadMedia({
        variables: {
          uploadMediaFromAdminInput: {
            type: "feed",
            id: +advertisementId!,
            media: filesUploadStore.mediaFilesToUploadBackend,
          },
        },
      });
      filesUploadStore.restoreFilesUploadStore();
      navigate({
        pathname: `/ads`,
      });
    } catch (e) {
      filesUploadStore.restoreFilesUploadStore();
    }
  }, [navigate, uploadMedia, advertisementId]);

  const onUpdateAd: SubmitHandler<INewsForm> = async (newsData) => {
    if (
      (isDirty && steppers[stepCount - 1] === "Info") ||
      (isSaveAndBack && steppers[stepCount - 1] !== "Image")
    ) {
      await updateAd({
        variables: {
          updateAdInput: {
            ...newsData,
            adId: news?.ad.id,
            endDate: newsData.endDate,
          },
        },
      });
      reset({
        description: newsData.description,
        title: newsData.title,
        venueId: newsData.venueId,
        endDate: formatDate(new Date(newsData.endDate.toDate())),
      });
    }
    if (steppers[stepCount - 1] === "Image") {
      if (filesUploadStore.newMediaFiles.length) {
        filesUploadStore.startFileUploadToAWS();
      } else {
        updateAdMedia();
      }
    } else {
      if (!isSaveAndBack) {
        navigate({
          search: `step-${stepCount + 1}`,
        });
      } else {
        navigate({
          pathname: "/ads",
        });
      }
    }
  };

  useEffect(() => {
    search && setStepCount(+search.split("-")[1]);
  }, [search]);

  useEffect(() => {
    if (news) {
      reset({
        description: news.description,
        title: news.ad.title,
        venueId: news.ad.venueId,
        endDate: formatDate(new Date(news.ad.endDate)),
      });

      setSelectedHashTags(
        strongCopyData(news?.hashtags)?.map(
          (hashtag: { hashtagId: number }) => hashtag.hashtagId
        ) || []
      );
      filesUploadStore.assignMediaFiles(filterMediaTypename(news.media) || []);
    }
  }, [news, reset]);

  return (
    <form onSubmit={handleSubmit(news ? onUpdateAd : onCreateAd)} className={"advertisement-form"}>
      <Stepper
        steppers={steppers}
        stepCount={stepCount}
        decrementStepCount={decrementStepCount}
        saveAndBack={() => seIsSaveAndBack(true)}
        isUpdateMode={!!advertisementId}
      >
        <div className={"event-form--content"}>
          <div
            className={`${
              stepCount === 1 ? "advertisement-form--info-step" : "advertisement-form--display-none"
            }`}
          >
            <AdvertisementInfoForm
              venueList={venueList}
              selectVenueId={news?.ad.venueId || ""}
              register={register}
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              isDisabled={false}
              errors={errors}
            />
            <HashtagsList
              feedEventId={news?.ad?.feedId}
              isRemovable={false}
              isSelectable={true}
              setSelectedHashTags={setSelectedHashTags}
              selectedHashTags={selectedHashTags}
            />
          </div>

          <div
            className={`${stepCount === 2 ? "event-form--info-step" : "event-form--display-none"}`}
          >
            <ImageVideoUploadCarousel
              id={createData?.createAd.id || news?.id}
              readOnly={false}
              updateMedia={updateAdMedia}
              keyType={"feeds"}
            />
          </div>
        </div>
      </Stepper>

      {createLoading || updateLoading || uploadMediaLoading ? (
        <BaseCreateOrDeleteModalLoading />
      ) : null}

      {createError || updateError || uploadMediaError ? (
        <BaseAlert type={"failed"} message={updateError?.message || createError?.message} />
      ) : null}
    </form>
  );
});

export default memo(AdvertisementForm);
